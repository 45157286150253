import { createContext, useRef, useState } from "react";
import { SentryUtils } from "utils";

export const ModalContext = createContext<{
    showModal: (modal: any, settings: any, modalTitle: string) => void,
    hideModal: () => void,
    open: boolean
}>({
    showModal: (a,b,c) => { },
    hideModal: () => { },
    open: false
});

export default ({ children }) => {

    const modalRef = useRef(null);
    const modalSettings = useRef();
    const [open, setOpen] = useState(false);

    const state = useRef({
        showModal: (modal: any, settings: any, modalTitle = 'unknown') => {
            modalSettings.current = settings;
            modalRef.current = modal;
            setOpen(true);
            SentryUtils.breadCrumb({
                route: 'open-modal',
                data: {title: modalTitle, settings}
            });
        },
        hideModal: () => {
            setOpen(false);
        },
        open: open
    });
    state.current.open = open; //wouldn't update, only as ref

    return (
        <ModalContext.Provider value={state.current}>
            {children}
            {open &&
                <modalRef.current settings={modalSettings.current} />
            }
        </ModalContext.Provider>
    )
}