import colors from './colors';

export const LayoutStylesExtraLarge = {
    fullscreen: {
        width: '100%',
        height: '100%',
        flex: 1
    },
    
    p10: {
        padding: 10,
    },
    p20: {
        padding: 20,
    },
    p30: {
        padding: 30,
    },
    p40: {
        padding: 40,
    },
    pv10: {
        paddingVertical: 10,
    },
    pv20: {
        paddingVertical: 20,
    },
    pv30: {
        paddingVertical: 30,
    },
    pv40: {
        paddingVertical: 40,
    },
    ph10: {
        paddingHorizontal: 10,
    },
    ph20: {
        paddingHorizontal: 20,
    },
    ph30: {
        paddingHorizontal: 30,
    },
    ph40: {
        paddingHorizontal: 40,
    },
    pb10: {
        paddingBottom: 10,
    },
    pb20: {
        paddingBottom: 20,
    },
    pb30: {
        paddingBottom: 30,
    },
    pb40: {
        paddingBottom: 40,
    },
    pt10: {
        paddingTop: 10,
    },
    pt20: {
        paddingTop: 20,
    },
    pt30: {
        paddingTop: 30,
    },
    pt40: {
        paddingTop: 40,
    },
    pl10: {
        paddingLeft: 10,
    },
    pl20: {
        paddingLeft: 20,
    },
    pl30: {
        paddingLeft: 30,
    },
    pl40: {
        paddingLeft: 40,
    },
    pr10: {
        paddingRight: 10,
    },
    pr20: {
        paddingRight: 20,
    },
    pr30: {
        paddingRight: 30,
    },
    pr40: {
        paddingRight: 40,
    },

    flexRow: {
        flexDirection: 'row'
    },
    flexRowDynamic: {
        flexDirection: 'row'
    },
    flexCol: {
        flexDirection: 'column'
    },
    flexWrap: {
        flexWrap: 'wrap'
    },
    flexStretch: {
        flex: 1,
    },
    justifyStart: {
        justifyContent: 'flex-start'
    },
    justifyEnd: {
        justifyContent: 'flex-end'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    justifyBetween: {
        justifyContent: 'space-between'
    },
    justifyAround: {
        justifyContent: 'space-around'
    },
    justifyEven: {
        justifyContent: 'space-evenly'
    },
    alignStart: {
        alignItems: 'flex-start'
    },
    alignEnd: {
        alignItems: 'flex-end'
    },
    alignCenter: {
        alignItems: 'center'
    },
    
    gap40: {
        gap: 40,
    },
    gap30: {
        gap: 30,
    },
    gap20: {
        gap: 20,
    },
    gap10: {
        gap: 10,
    },

    borderRadius30: {
        borderRadius: 30
    },
    borderRadius20: {
        borderRadius: 20
    },
    borderRadius10: {
        borderRadius: 10
    },
    borderRadius5: {
        borderRadius: 4
    },

    line: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    highlight: {
        flex: 1,
        backgroundColor: colors.background,
        padding: 5,
        paddingVertical: 8,
        borderRadius: 8
    },
    boxShadow: {
        shadowColor: colors.black,
        shadowOpacity: 0.1,
        shadowRadius: 16,
        elevation: 12,
        shadowOffset: { width: 10, height: 10 },
    },
    boxShadowLeft: {
        shadowColor: colors.black,
        shadowOpacity: 0.1,
        shadowRadius: 16,
        elevation: 12,
        shadowOffset: { width: -10, height: 0 },
    },
    boxShadowRight: {
        shadowColor: colors.black,
        shadowOpacity: 0.1,
        shadowRadius: 16,
        elevation: 12,
        shadowOffset: { width: 10, height: 0 },
    },
    underline: {
        textDecorationLine: 'underline'
    },
    input: {
        paddingLeft: 32,
        marginVertical: 12,
        width: '100%'
    },
    title: {
        marginBottom: 12
    },
    hover: {
        shadowColor: colors.black,
        shadowOffset: { height: 2, width: 1 },
        shadowOpacity: 0.4
    },    
}

export const LayoutStylesLarge = {

}

export const LayoutStylesMedium = {
    
}

export const LayoutStylesSmall = {
    flexRowDynamic: {
        flexDirection: 'column'
    },    
}
