import { Icon, TText } from "components";
import { Card } from "components/Card";
import { BaseDrawerModal } from "components/Modals";
import BaseModal from "components/Modals/BaseModal";
import { DateTimeFormat } from "enums";
import { useStyle } from "hooks";
import moment from "moment";
import { useState } from "react";
import { Pressable, Text, View } from "react-native";
import { colors } from "styles";
import { ValutaUtils } from "utils";


export default ({visible, onClose}) => {

    const styles = useStyle();
    // const [showModal, setShowModal] = useState(true);

    return (
        <BaseDrawerModal
            drawerWidth={1000} 
            header={'Uitgangspunten berekening'}
            visible={visible}
            onClose={onClose}
            drawerStyle={[styles.gap20]}
        >
            <View style={styles.gap10}>
                <TText semibold secondary>Kan ik blijven wonen?</TText>
                <TText>
                    Deze "Kunnen wij blijven wonen?" rekentool is bedoeld voor iedereen met een hypotheek. We berekenen hiermee de impact op de betaalbaarheid en verlengmogelijkheden van je hypotheek in vijf mogelijke scenario’s: pensionering, overlijden van je partner, arbeidsongeschiktheid, werkloosheid en einde relatie. We kijken alleen naar de scenario's die voor jouw situatie relevant zijn. De uitkomst is een indicatie, gebaseerd op de gegevens die je hebt aangeleverd en de uitgangspunten die wij hanteren. In onze uitgangspunten sluiten we bepaalde zaken uit en doen we enkele aannames. Deze staan hieronder vermeld. Voor een exacte en persoonlijke berekening raden we altijd aan om een onafhankelijke hypotheekadviseur te raadplegen.

                    Ondanks de zorgvuldigheid die we besteden aan de samenstelling van deze rekentool, kunnen we niet instaan voor de volledigheid, juistheid of voortdurende actualiteit van de gegevens. Aan de berekende uitkomst kun je geen rechten ontlenen.
                </TText>
            </View>
            <View style={styles.gap10}>
                <TText semibold secondary>hypotheekrenteaftrek</TText>
                <TText>
                    We gaan ervan uit dat de hypotheekrenteaftrek doorloopt tot 1 januari 2031. Daarna nemen we aan dat de hypotheekrenteaftrek is vervallen.
                </TText>
                <TText>
                    Bij leningdelen die voor 1 januari 2001 zijn afgesloten, loopt de hypotheekrenteaftrek door tot 1 januari 2031. Bij leningdelen die na die datum zijn 
                    afgesloten loopt de hypotheekrenteaftrek in de berekening door tot 30 jaar na de oorspronkelijke afsluitdatum. Bij de berekeningen gaan we echter uit
                    van hypotheekrenteaftrek tot 1 januari 2031.
                </TText>
            </View>
            <View style={styles.gap10}>
                <TText semibold secondary>rentevaste periode</TText>
                <TText>
                    Wanneer de datum waarvoor wij de berekening uitvoeren na de afloop van jouw rentevaste periode valt, dan rekenen wij met een rente van 5%. 
                </TText>
            </View>

        </BaseDrawerModal>
    );
}