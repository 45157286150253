
const valutaString = (val: string | number = 0, withoutCents?: boolean) => {     
    let mVal = '€ ' + formatValuta(val);
    return (withoutCents) ? mVal.substring(0, mVal.indexOf(',')) : mVal;
}

const formatValuta = (val: string | number) => {    
    let mVal = unformatValuta(val).toFixed(2).replace('.', ',').replace('-', '');
    let idx = mVal.search(',');
    if (idx < 0) idx = mVal.length;
    for (let i = idx - 3; i > 0; i -= 3) {
        mVal = mVal.substring(0, i) + '.' + mVal.substring(i);
    }
    return Number(val) < 0 ? `-${mVal}` : mVal;
}

const unformatValuta = (val: string | number) => {
    // wanneer er een komma als decimal separator wordt gebruikt, formateer dan naar een float met . als decimal separator.
    if (typeof val === 'string' && val.includes(',')){
        val = val.replace(/\./g, '')
                    .replace(',', '.');
    }
    return Number(val);    
}

export default {
    valutaString,
    formatValuta,
    unformatValuta,
}