import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import Constants from "expo-constants";
import { Platform } from "react-native";
import { AuthUtils, SentryUtils } from "utils";
const Config = Constants.expoConfig.extra.config;

const getApiClient = async ({ code, responseType, multipart }: { code?: string, responseType?: string, multipart?: boolean }): Promise<AxiosInstance> => {

    const headers = await getApiHeaders(multipart);
    const tenantCode = await getTenant(code);
    const url = Config.API_URL.replace(Config.DEFAULT_TENANT, tenantCode);

    return await axios.create({
        baseURL: url,
        headers,
        responseType: responseType || undefined
    });

};

const getApiHeaders = async (multipart = false): Promise<AxiosRequestHeaders> => {

    const currentUser = await AuthUtils.getCurrentUser();
    const accessToken = currentUser.access_token;

    let headers: any = {
        Accept: "application/json",
        "Content-Type": multipart ? "multipart/form-data" : "application/json",
    };


    if (accessToken) {
        // set access_token in headers if set
        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
        };
    }

    return headers;
};

const transformTenantCode = async (code: string) => {

    try {

        const response = await axios.get(Config.PROXY_URL + `/tenant/code/${code}`);
        return response.data.data.data.subdomain;

    } catch (error) {

        return false;

    }

}

const getTenant = async (code?: string) => {

    if (!code) {
        const currentUser = await AuthUtils.getCurrentUser();
        let tenantCode = currentUser?.tenant;
        if (!tenantCode && Platform.OS == 'web') {
            const [subdomain, domain, tld] = location.hostname.split('.');
            tenantCode = ((domain == 'klantportaal' && tld == 'net') || domain == 'localhost') ? subdomain : null;
        }
        return tenantCode || Config.DEFAULT_TENANT;
    }
    return code;

};

const isRequestSuccessful = (status: number) => {
    return status >= 200 && status <= 299;
};

const isAuthenticationError = (status: number) => {
    return status >= 400 && status <= 415
};


const handleBadRequest = (code: number, message: string) => {
    if (message.includes('Network Error')) return;
    (code === 500) ? global.setError500() : global.setError(code);

    SentryUtils.sendError('Een api call went verkeerd: ' + message);
}


export default {
    getApiClient,
    getApiHeaders,
    transformTenantCode,
    getTenant,
    isRequestSuccessful,
    isAuthenticationError,
    handleBadRequest
}