export enum MortgageStatus {
    LOPEND = 0,
    IN_AANVRAAG = 1,
    WACHTEN_OFFERTE = 2,
    WACHTEN_TAXATIE = 3,
    WACHTEN_GETEKENDE_OFFERTE = 4,
    WACHTEN_ACCEPTATIE_DOOR_CLIENT = 5,
    WACHTEN_ACCEPTATIE_DOOR_GELDGEVER = 6,
    WACHTEN_MEDISCHE_ACCEPTATIE = 7,
    WACHTEN_BANKGARANTIE = 8,
    WACHTEN_PASSEREN = 9,
    WACHTEN_PROVISIE = 10,
    HYPOTHEEKGESPREK = 11,
    STUKKEN_AANLEVEREN_VOOR_AANVRAAG = 12,
    AANVRAAG_INGEDIEND = 13,
    OFFERTE_TER_ONDERTEKENING = 14,
    STUKKEN_ONTBREKEN_OPGEVRAAGD = 15,
    STUKKEN_TER_BEOORDELING_NAAR_BANK = 16,
    STUKKEN_NAAR_NOTARIS = 17,
    AFSPRAAK_VOOR_PASSEREN = 18,
    LOOPT_ELDERS = 19,
    VERVALLEN = 50,
    INGELOST = 51,
    NIET_DOORGEGAAN_BKR = 91,
    NIET_DOORGEGAAN_INKOMEN = 92,
    NIET_DOORGEGAAN_WAARDE_ONDERPAND = 93,
    NIET_DOORGEGAAN_VERZOEK_KLANT = 94,
    LEAD = 99,
}