
import ApiUtils from "./ApiUtils";

const createDevice = async (device_token: string, operating_system: string) => {
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .post('/customer/device', { device_token, operating_system })
        .then(({ data }) => data.data);
}

const deleteDevice = async (id: string) => {
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .delete(`/customer/device/${id}`)
        .then(({ data }) => data);
}

export default {
    createDevice,
    deleteDevice
};