import React, { useState } from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import TText from './TText';
import { useStyle } from 'hooks';
import { colors } from 'styles';



export default ({options, label, value, onChange, style, search, disable, error}: {options: {label: string, value: any}[], label: string, value: any, onChange: (e:any)=>void, style?: ViewStyle, search?: boolean, disable?: boolean, error?: string}) => {

  const styles = useStyle();
  const [isFocus, setIsFocus] = useState(false);

  return (
    <View style={styles.flexStretch}>
      {label && 
          <TText sm semibold style={{marginBottom: 5}}>
              {label}
          </TText>
      }
      <Dropdown
        style={[
          scopedStyles.dropdown, styles.textRegular, styles.textPrimary,
          disable && {backgroundColor: colors.grey},
          style]}
        placeholderStyle={[scopedStyles.placeholderStyle, styles.textPrimary, styles.textRegular]}
        selectedTextStyle={[scopedStyles.selectedTextStyle, styles.textPrimary, styles.textRegular]}
        itemTextStyle={[scopedStyles.selectedTextStyle, styles.textPrimary, styles.textRegular]}
        inputSearchStyle={scopedStyles.inputSearchStyle}
        iconStyle={{marginRight: 8}}
        iconColor={colors.primary}
        data={options}
        search={search}
        maxHeight={300}
        labelField="label"
        valueField="value"
        placeholder={!isFocus ? 'Kies een optie' : '...'}
        searchPlaceholder="Zoeken..."
        value={value}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={item => {
          onChange(item.value);
          setIsFocus(false);
        }}
        disable={disable}
      />
        <TText regular sm error style={{textAlign: 'right'}}>{error}</TText>
    </View>
  );
};


const scopedStyles = StyleSheet.create({
  dropdown: {
    flex: 1,
    backgroundColor: colors.background,
    borderRadius: 10,
    paddingVertical: 8,
    paddingLeft: 12,
    minHeight: 38.4
  },
  placeholderStyle: {
    fontSize: 14,
  },
  selectedTextStyle: {
    fontSize: 14,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 14,
  },
});