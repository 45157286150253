
export class Obligation {
    additional_redeemed_amount: number
    agency: string
    credit_limit: number
    current_balance: number
    principal_amount: number
    description: string
    end_date: string
    gross_annual_obligation: number
    id: number
    monthly_amount: number
    monthly_percentage: number
    months_obliged: number
    start_date: string
    type: string
    type_description: string
    updated_at: string
    number: string
    original_debt: number

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Obligation {
        this.additional_redeemed_amount = json.additional_redeemed_amount ? json.additional_redeemed_amount : 0;
        this.agency = json.agency ? json.agency : ''
        this.credit_limit = json.credit_limit ? json.credit_limit : 0;
        this.principal_amount = json.principal_amount ? json.principal_amount : 0;
        this.current_balance = json.current_balance ? json.current_balance : 0;
        this.description = json.description ? json.description : '';
        this.end_date = json.end_date ? json.end_date : '';
        this.gross_annual_obligation = json.gross_annual_obligation ? json.gross_annual_obligation : 0
        this.monthly_amount = json.monthly_amount ? json.monthly_amount : 0;
        this.monthly_percentage = json.monthly_percentage ? json.monthly_percentage : 0;
        this.months_obliged = json.months_obliged ? json.months_obliged : 0;
        this.start_date = json.start_date ? json.start_date : ''
        this.id = json.id ? json.id : 0;
        this.type = json.type ? json.type : '';
        this.type_description = json.type_description ? json.type_description : '';
        this.updated_at = json.updated_at ? json.updated_at : '';
        this.number = json.number ? json.number : '';
        this.original_debt = json.original_debt ? json.original_debt : '';
        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId()
        };
    }

    getId(): string {
        return this.id;
    }
}
