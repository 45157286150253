import { QueryKeys } from 'enums';
import { Mortgage } from "models";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { QueryUtils } from "utils";

export default () => {

    const { data: mortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);

    const [addresses, setAddresses] = useState<Record<string, string>[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<number | undefined>(undefined);

    useEffect(() => {

        if (!mortgages) return;

        const addresses: Record<string, string>[] = Object.values(
            mortgages
                .filter((mortgage: Mortgage) => mortgage.status == 0) //only running mortgages
                .map(mortgage => {
                    return mortgage.properties.map(mortgageProperty => ({
                        street_name: mortgageProperty.street,
                        house_number: mortgageProperty.number,
                        house_number_addition: mortgageProperty.number_addition,
                        postcode: mortgageProperty.postal_code,
                        city: mortgageProperty.city
                    }))                    
                })
                .flat()
                .concat(customer.addresses) //add customer addresses
                .reduce((acc, val) => { //remove duplicates
                    return {
                        ...acc,
                        [val.street_name + val.house_number + val.house_number_addition]: val
                    };
                }, {})
        );

        setAddresses(addresses);
        setSelectedAddress(addresses.length == 1 ? 0 : undefined);

    }, [mortgages]);

    return [addresses, selectedAddress, setSelectedAddress];

}