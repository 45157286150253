import { useContext, useEffect, useState } from "react";
import { BottomNavBar, Button, ContentScroller, TText } from "components";
import { Dimensions, View } from "react-native";
import { useItemSize, useSignalItems, useStyle } from "hooks";
import Content from "./Content";
import { MessageModal } from "components/Modals";
import { Screen } from "enums";
import { ModalContext } from "providers/ModalProvider";
import moment from "moment";



export default () => {

    const styles = useStyle();
    const itemSize = useItemSize();
    const { signalItems } = useSignalItems({ categorized: true, excludeFinishedUpload: true });
    const { showModal } = useContext(ModalContext);

    const [orderedMessages, setOrderedMessages] = useState([]);
    const navItems = [{ title: 'berichten', key: 'MESSAGES' }];

    useEffect(()=>{
        if (signalItems?.MESSAGES) setOrderedMessages( signalItems['MESSAGES'].sort((a, b) => moment(b.props.data.date).diff(moment(a.props.data.date))) );
    },[signalItems])

    return (
        <Content
            specificTopHeight={Dimensions.get('window').height * 0.3}
            title="#title_inbox"
        >
            <View style={styles.fullscreen}>
                <TText sm regular black>#undertitle_inbox</TText>
                <View style={{ position: 'absolute', flexDirection: 'row', bottom: 30, width: '100%' }}>
                    <Button
                        label="#action_send_message"
                        type='action'
                        onPress={() => showModal(MessageModal, { screen: Screen.VERPLICHTINGEN }, 'MessageModal')}
                    />
                </View>
            </View>

            <BottomNavBar navItems={navItems}/>

            <View style={[styles.fullscreen]}>
                <ContentScroller style={{
                    justifyContent: orderedMessages.length > 0 ? 'flex-start' : 'center',
                    alignItems: orderedMessages.length > 0 ? 'flex-start' : 'center',
                }}>

                    {orderedMessages.length > 0 ? (
                        
                        orderedMessages.map((item, key) => (
                            <View key={key} style={[
                                {
                                    width: itemSize,
                                    height: itemSize,
                                    borderRadius: 8,
                                    overflow: 'hidden'
                                }
                            ]}>
                                {item}
                            </View>
                        ))

                    ) : (
                        <TText white md semibold>#empty_screen_inbox</TText>
                    )}

                </ContentScroller>
            </View>
        </Content>
    )
}
