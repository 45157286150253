import { useStyle } from "hooks";
import PageContainer from "components/PageContainer";
import { Button, TextInput, TText, ValutaInput } from "components";
import { Card, HeaderCard } from "components/Card";
import { colors } from "styles";
import { View } from "react-native";
import { number, object, string } from "yup";
import { useFormik } from "formik";
import { EditDataUtils, QueryUtils } from "utils";
import { QueryKeys } from "enums";
import { useQuery } from "react-query";
import { Living } from "models";
import { useEffect, useRef, useState } from "react";

export default () => {

    const styles = useStyle();
    const { data: living, refetch: refetchLiving } = useQuery(QueryKeys.LIVING, QueryUtils.fetchLiving, {refetchOnWindowFocus: false, staleTime: Infinity});
    const [loading, setLoading]  = useState(false);
    const response = useRef<undefined | string>(undefined);

    useEffect(() => {
        if ( living instanceof Living && living.property ){
            const property = living.property;
            setValues({
                postal_code: property.postcode,
                house_number: property.house_number,
                house_number_addition: property.house_number_addition,
                market_value: property.market_value,
                woz_value: property.woz_value_home
            })
        }
    }, [living])

    const changeIncomeSchema = object({
        postal_code: string().required('Dit veld is verplicht'),
        house_number: string().required('Dit veld is verplicht'),
        house_number_addition: string(),
        market_value: number().required('Dit veld is verplicht'),
        woz_value: number().required('Dit veld is verplicht')
      });

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        setValues,
        values,
        errors,
        touched
    } = useFormik({
        validationSchema: changeIncomeSchema,
        initialValues: {
            postal_code: "",
            house_number: "",
            house_number_addition: "",
            market_value: 0,
            woz_value: 0
        },
        onSubmit: values => {
            setLoading(true);
            response.current = undefined;
            EditDataUtils.uploadLiving(values)
                .then(() => {
                    setLoading(false);
                    response.current = {
                        successful: true,
                        label: 'Je gegevens zijn succesvol gewijzigd'
                    };
                    refetchLiving();
                })
                .catch((error) => {
                    setLoading(false);
                    response.current = {
                        successful: false,
                        label: error.response.data.error.message.includes('Could not persist object of type \"App\\Models\\Customer\"') ?
                            'Opslaan is niet gelukt, je adviseur is bezig met jouw dossier' :
                            'Er ging iets mis bij het opslaan van je gegevens'
                    };
                });
        },
    });
    
    return (                  
        <HeaderCard header="Wonen">
            <TText>Waar woon je nu?</TText>
            <View style={styles.gap30}>
                <View style={styles.gap10}>
                    <TText secondary semibold>Adres gegevens</TText>
                    <TextInput
                        label={"Postcode"}
                        value={values.postal_code}
                        onChangeText={(txt: string) => setValues(v => ({ ...v, postal_code: txt }))}
                        onBlur={handleBlur('postal_code')}
                        error={(touched.postal_code && errors.postal_code) ? errors.postal_code : undefined}
                    />
                    <TextInput
                        label={"Huisnummer"}
                        value={values.house_number}
                        onChangeText={(txt: string) => setValues(v => ({ ...v, house_number: txt }))}
                        onBlur={handleBlur('house_number')}
                        error={(touched.house_number && errors.house_number) ? errors.house_number : undefined}
                    />
                    <TextInput
                        label={"toevoeging"}
                        value={values.house_number_addition}
                        onChangeText={(txt: string) => setValues(v => ({ ...v, house_number_addition: txt }))}
                        onBlur={handleBlur('house_number_addition')}
                        error={(touched.house_number_addition && errors.house_number_addition) ? errors.house_number_addition : undefined}
                    />
                </View>
                <View style={styles.gap10}>
                    <TText secondary semibold>Woning gegevens</TText>
                    <ValutaInput
                        label={"Marktwaarde woning"}
                        value={values.market_value}
                        onChange={(value: number) => setValues(v => ({ ...v, market_value: value }))}
                        onBlur={handleBlur('market_value')}
                        error={(touched.market_value && errors.market_value) ? errors.market_value : undefined}
                    />
                    <ValutaInput
                        label={"WOZ-waarde woning"}
                        value={values.woz_value}
                        onChange={(value: number) => setValues(v => ({ ...v, woz_value: value }))}
                        onBlur={handleBlur('woz_value')}
                        error={(touched.woz_value && errors.woz_value) ? errors.woz_value : undefined}
                    />

                </View>

                <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                    <Button
                        loading={loading}
                        label={'wijzigingen opslaan'}
                        type={'secondary'}
                        onPress={handleSubmit}
                    />
                    {response.current !== undefined && 
                        <TText style={{color: response.current.successful ? colors.approval : colors.error}}>
                            {response.current.label}
                        </TText>
                    }
                </View>
            </View>
        </HeaderCard>        
    );

}
