import { useStyle } from "hooks"
import { StyleProp, StyleSheet, View } from "react-native"
import { colors } from "styles"
import TText from "./TText";

export default ({ label, value, style }: { label?: string, value: string | undefined, style?: StyleProp<any> }) => {

    const styles = useStyle();

    return (
        <View style={[
            styles.borderRadius20,
            scopedStyles.container,
            { borderBottomColor: colors.primary },
            style
        ]}>
            <TText regular sm black style={scopedStyles.label}>
                {label}
            </TText>
            <TText light sm black>
                {value || 'Niet bekend'}
            </TText>
        </View>
    )
}

const scopedStyles = StyleSheet.create({
    container: {
        backgroundColor: colors.white,
        padding: 8
    },
    label: {
        marginBottom: 5
    }
})