import {LayoutChangeEvent, View} from "react-native";
import { ProgressCircle } from "react-native-svg-charts";
import TText from "./TText";
import { CalculationUtils, QueryUtils, ValutaUtils } from "utils";
import { QueryKeys } from "enums";
import { useQuery } from "react-query";
import { useState } from "react";
import { CreateResponsiveStyle } from "rn-responsive-styles";
import { useStyle } from "hooks";
import { colors } from "styles";

export default () => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const { data: mortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);
    const { total, restant } = CalculationUtils.totalMortgage(mortgages);
    const [pieSize, setPieSize] = useState<number | null>(null);

    const onLayoutUpdate = (e: LayoutChangeEvent) => {
        // setPieSize(e.nativeEvent.layout.height * 1);
    }

    return (

        <View
            style={[scopedStyles.progressSizer]}
            onLayout={onLayoutUpdate}
        >
            {(mortgages !== undefined) && 
                <View style={scopedStyles.progressContainer}>
                    <ProgressCircle
                        style={scopedStyles.progressCircle}
                        strokeWidth={12}
                        progress={restant / total}
                        progressColor={colors.secondary}
                        backgroundColor={colors.grey}
                        startAngle={-Math.PI * 0.95}
                        endAngle={Math.PI * 0.95}
                    />
                    <View style={[styles.gap10, styles.alignCenter]}>
                        <TText semibold sm grey center style={{textTransform: 'uppercase', letterSpacing: 2}}>#mortgage</TText>
                        <TText semibold md style={{ transform: 'scale(1.5)' }}>{ValutaUtils.valutaString(restant)}</TText>
                        <TText sm center>#openstaand</TText>
                    </View>
                </View >
            }
        </View>
    
    );
    
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        progressContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        },
        progressCircle: {
            position: 'absolute',
            height: '100%',
            width: '100%'
        },
        progressSizer: {
            padding: 10,
            height: '100%',
            width: '100%',
            right: 0,
            justifyContent: "center",
        },
    }
);
