import { DateTimeFormat, QueryKeys } from "enums";
import moment from "moment";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useQuery } from "react-query";
import { colors } from "styles";
import { QueryUtils } from "utils";
import TText from "./TText";


export default () => {

    const { data: tenant } = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const [open, setOpen] = useState(false);

    useEffect(() => {

        if (!customer || !tenant) return;

        const office = tenant?.offices?.filter(office => office.id == customer?.office_id)[0];
        const key = moment().format('DD-MM-YYYY');
        const hours_today = office?.upcoming_hours[key];

        let isOpen = false;
        hours_today && hours_today.map((hours: string) => {
            const date = moment().format(DateTimeFormat.SERVER_DATE);
            const times = hours.split('-');
            const start = moment(`${date} ${times[0]}`);
            const close = moment(`${date} ${times[1]}`);
            const now = moment();

            if (now.diff(start) > 0 && now.diff(close) < 0) isOpen = true;
        })
        if (open != isOpen) setOpen(isOpen);

    }, [tenant, customer])


    return open ? (
        <View style={[scopedStyles.container, {backgroundColor: colors.secondary9}]}>
            <TText sm semibold white>{'#currently_open'}</TText>
        </View>
    ) : (
        <></>
    );
}

const scopedStyles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: -8,
        right: -8,
        borderRadius: 4,
        paddingVertical: 3,
        paddingHorizontal: 6        
    }
})
