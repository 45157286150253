import { OnlineAfsprakenBookableTime } from 'models'
import { ApiUtils } from 'utils';
import fetchOnlineAfsprakenBookableTimesInterface from "interfaces/fetchOnlineAfsprakenBookableTimesInterface";
import storeOnlineAfsprakenAppointmentInterface from "interfaces/storeOnlineAfsprakenAppointmentInterface";

const fetchOnlineAfsprakenBookableTimes = async (body: fetchOnlineAfsprakenBookableTimesInterface) => {
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .post('customer/online_afspraken/bookable_time/by_appointment_type_and_start_date', body)
        .then(({data}) => {
            const onlineAfsprakenBookableTimes: OnlineAfsprakenBookableTime[] = [];
            data.data.map((data: object) => onlineAfsprakenBookableTimes.push(new OnlineAfsprakenBookableTime(data)));
            return onlineAfsprakenBookableTimes;
        });
}

const storeOnlineAfsprakenAppointment = async (body: storeOnlineAfsprakenAppointmentInterface) => {
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .post('customer/online_afspraken/appointment', body)
        .then(({data}) => {
            return data
        }).catch((error) => {
            return error?.message;
        });
}

const removeOnlineAfsprakenAppointment = async (id: string) => {
    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .delete(`/customer/online_afspraken/appointment/${id}`)
        .then(({data}) => {
            return data
        });
}

export default {
    fetchOnlineAfsprakenBookableTimes,
    storeOnlineAfsprakenAppointment,
    removeOnlineAfsprakenAppointment,
}
