import { Button, CloseButton, IconTitle } from "components";
import { TText } from "components";
import { DateTimeFormat } from "enums";
import { useStyle } from "hooks";
import { Policy } from "models";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { useContext } from "react";
import { Dimensions, Modal, Platform, Pressable, View } from "react-native";
import * as Animatable from 'react-native-animatable';
import { ScrollView } from "react-native-gesture-handler";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { ValutaUtils } from "utils";


export default ({ settings }) => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const { open, hideModal } = useContext(ModalContext);
    const largeScreen = Dimensions.get('window').width > 768;

    const policy: Policy = settings.policy;
    const startDate = moment(policy.start_date).format(DateTimeFormat.DISPLAY_DATE);
    const totalCosts = policy.is_package ? policy.children : [policy];
    const totalInsured = policy.is_package ? policy.children.map((child: Policy) => child.insured_amounts).flat(2) : [policy.insured_amounts];
    const totalDescriptions = policy.is_package ? [] : (typeof policy.descriptions == 'object') ? Object.values(policy.descriptions) : policy.descriptions;
    if (policy.is_package) {
        const set = {};
        policy.children.map((child: Policy) => child.descriptions).flat().map((item: string) => { set[item] = true });
        Object.keys(set).map(item => totalDescriptions.push(item));
    }

    const renderForm = () => {

        const showInsuredAmounts = totalInsured.reduce((acc, v) => typeof v == 'object' ? acc + Object.keys(v).length : acc, 0) > 0;

        return (
            <View style={scopedStyles.container}>

                <View style={{ flex: 1, marginBottom: 30 }}>

                    <IconTitle
                        Icon={settings.Icon}
                        title={policy.agency}
                    >
                        <TText lg semibold>{ValutaUtils.valutaString(policy.monthly_term_amount)}</TText>
                        <TText md> #p/m</TText>
                    </IconTitle>


                    {/* gegevens */}
                    <View style={{ justifyContent: "space-evenly" }}>

                        <TText md semibold style={{ marginBottom: 12 }}>#data</TText>

                        <View style={scopedStyles.category}>
                            <TText sm black style={{ width: 150 }}>#description</TText>
                            <View style={{ marginLeft: largeScreen ? 8 : 0, flex: 1 }}>
                                {totalDescriptions.map((item, index) => {
                                    const removeHTMLTags = item?.replace(/<.+?(?=>)>.+?(?=<)<\/.+?(?=>)>/gim, '');
                                    return <TText key={'cat' + index} sm grey semibold>● {removeHTMLTags}</TText>;
                                })}
                            </View>
                        </View>

                        {showInsuredAmounts &&
                            <View style={scopedStyles.category}>
                                <TText sm black style={{ width: 150 }}>#coverage</TText>
                                <View style={{ marginLeft: largeScreen ? 8 : 0, flex: 0.9, maxWidth: largeScreen ? 350 : 999 }}>
                                    {totalInsured.map((item) => {
                                        return Object.entries(item).map((entry, index) => {
                                            return (
                                                <View
                                                    key={'te' + index}
                                                    style={[{
                                                        flexDirection: 'row',
                                                        justifyContent: "space-between"
                                                    }]}>
                                                    <TText capitalize oneline sm grey style={{ flex: 1 }}>● {entry[0]?.replace('Dekking ', '') || '#unknown'}</TText>
                                                    <TText sm grey style={{ marginLeft: 20 }}>{entry[1]?.replace(',00', ',‐ ') || '#unknown'}</TText>
                                                </View>
                                            )
                                        })
                                    })}
                                </View>
                            </View>
                        }

                        {/* loopt af */}
                        <View style={scopedStyles.category}>
                            <TText sm black style={{ width: 150 }}>#start_date</TText>
                            <TText sm grey style={{ marginLeft: largeScreen ? 8 : 0 }}>{startDate}</TText>
                        </View>

                    </View>

                    {/* kosten */}
                    <View style={{ marginTop: 20 }}>

                        <TText md semibold style={{ marginBottom: 12 }}>#cost</TText>

                        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>

                            <View style={[scopedStyles.tItem2, { borderTopLeftRadius: 4 }]}><TText sm semibold black>#policy_number</TText></View>
                            <View style={scopedStyles.tItem}><TText sm semibold black>#per_month</TText></View>
                            <View style={[scopedStyles.tItem, { borderTopRightRadius: 4 }]}><TText sm semibold black>#per_year</TText></View>

                            {renderCosts()}

                        </View>

                    </View>

                </View>


                <View style={{ height: 100, justifyContent: 'center', alignItems: "flex-end" }}>

                    <View style={{ flexDirection: "row" }}>
                        <Button
                            label='#close'
                            type={"secondary"}
                            onPress={() => hideModal()}
                        />
                    </View>

                </View>

            </View >
        )
    }

    const renderCosts = () => {
        const costs: any[] = [];
        totalCosts.map((item: Policy) => {
            costs.push(<View key={item.id + 'c' + 1} style={scopedStyles.nItem2}><TText sm regular black>{item.name}</TText></View>);
            costs.push(<View key={item.id + 'c' + 2} style={scopedStyles.nItem}><TText sm regular black>{ValutaUtils.valutaString(item.monthly_term_amount)}</TText></View>);
            costs.push(<View key={item.id + 'c' + 3} style={scopedStyles.nItem}><TText sm regular black>{ValutaUtils.valutaString(item.monthly_term_amount * 12)}</TText></View>);
        })
        return costs;
    }






    return (
        <Modal
            visible={open}
            transparent
        >
            <Pressable
                style={styles.fullscreen}
                onPress={hideModal}
            >
                <ScrollView
                    style={scopedStyles.background}
                    contentContainerStyle={{ alignItems: "center", flexGrow: 1 }}
                >

                    <Animatable.View
                        style={[scopedStyles.form]}
                        animation={'zoomIn'}
                        duration={200}
                    >
                        <View style={scopedStyles.formDecoration} />

                        <CloseButton onPress={hideModal} />

                        <Pressable //this pressable is to prevent a click/tap on the form to close through the pressable further up
                            onPress={() => { }}
                            style={[
                                {
                                    flex: 1,
                                    flexDirection: largeScreen ? 'row' : 'column-reverse',
                                    justifyContent: "space-between"
                                },
                                Platform.OS === 'web' && { cursor: 'default' }
                            ]}
                        >

                            {renderForm()}

                        </Pressable>
                    </Animatable.View>
                </ScrollView>
            </Pressable>
        </Modal>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        background: {
            backgroundColor: 'rgba(0,0,0,0.2)',
            width: '100%',
            flex: 1
        },
        form: {
            width: '50%',
            height: 'auto',
            minHeight: 0,
            minWidth: 485,
            marginTop: 120,
            backgroundColor: colors.background,
            borderRadius: 8,
            overflow: 'visible'
        },
        formDecoration: {
            position: 'absolute',
            height: 100,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: colors.formDecoration,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
        },
        container: {
            flex: 1,
            height: '100%',
            paddingTop: 20,
            paddingHorizontal: 30
        },
        titel: {
            marginVertical: 30
        },
        noteText: {
            backgroundColor: colors.lightgrey,
            flexGrow: 1,
            marginBottom: 30,
            padding: 8,
            borderRadius: 8,
            verticalAlign: "top",
            borderColor: colors.grey,
            borderWidth: 1
        },
        category: {
            flexDirection: 'row',
            marginBottom: 12
        },
        tItem: {
            width: '25%',
            paddingVertical: 12,
            paddingLeft: 8,
            backgroundColor: colors.formDecoration
        },
        nItem: {
            width: '25%',
            paddingVertical: 12,
            paddingLeft: 8,
            backgroundColor: colors.white
        },
        tItem2: {
            width: '50%',
            paddingVertical: 12,
            paddingLeft: 8,
            backgroundColor: colors.formDecoration
        },
        nItem2: {
            width: '50%',
            paddingVertical: 12,
            paddingLeft: 8,
            backgroundColor: colors.white
        },
    },
    {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            form: {
                width: '65%'
            }
        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            form: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
            },
            container: {
                paddingTop: 20,
                paddingHorizontal: 12
            },
            category: {
                flexDirection: 'column',
                marginBottom: 12
            },
            titel: {
                marginBottom: 20,
                marginTop: 0
            },
        }
    }
)
