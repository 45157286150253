import { useEffect, useState } from "react";
import { Dimensions } from "react-native";

export default () => {

    const calcMargin = (width: number) => {
        let size = 50;
        if (width <= 768) {
            size = 22;
        } else if (width <= 997) {
            size = 28;
        } else if (width <= 1200) {
            size = 38;
        }
        return size;
    }

    const [margin, setMargin] = useState(calcMargin(Dimensions.get('window').width));


    useEffect(() => {
        const handleWindowResize = (e) => {
            const size = calcMargin(e.window.width);
            setMargin(size);
        }
        Dimensions.addEventListener('change', handleWindowResize);
    }, [])

    return margin;

}