export class OnlineAfsprakenBookableTime {
    date: string
    startTime: string
    endTime: string
    appointmentTypeId: number | null
    resourceId: number | null

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): OnlineAfsprakenBookableTime {
        this.date = json.date ? json.date : '';
        this.startTime = json.startTime ? json.startTime : '';
        this.endTime = json.endTime ? json.endTime : '';
        this.appointmentTypeId = json.appointmentTypeId ? json.appointmentTypeId : null;
        this.resourceId = json.resourceId ? json.resourceId : null;
        return this;
    }
}
