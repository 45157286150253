import { Platform } from 'react-native';
import * as SentryNative from '@sentry/react-native';
import * as SentryWeb from '@sentry/browser';

export default {
    init: (options: Record<string, any>) => {
        const selectPlatform = Platform.select({
            default: ()=>{
                SentryNative.init(options);
            },
            web: ()=>{
                SentryWeb.init(options);
            }
        })
        selectPlatform();
    },
    setUser: (options: Record<string, any>) => {
        const selectPlatform = Platform.select({
            native: () => {
                SentryNative.setUser(options);
            },
            default: () => {
                SentryWeb.setUser(options);
            }
        });
        selectPlatform();
    },
    sendError: (msg:string) => {
        const selectPlatform = Platform.select({
            default: ()=>{
                SentryNative.captureException(new Error(msg));
            },
            web: ()=>{
                SentryWeb.captureException(new Error(msg));
            }
        })
        selectPlatform();
    },
    breadCrumb: async (data: any) => {
        const selectPlatform = Platform.select({
            default: ()=>{
                SentryNative.addBreadcrumb({
                    level: 'info',
                    data: data
                });
            },
            web: ()=>{
                SentryWeb.addBreadcrumb({
                    level: 'info',
                    data: data
                });
            }
        })
        selectPlatform();
        
    }
}