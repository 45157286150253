import moment from "moment"
import { Customer } from "./Customer"
import Constants from "expo-constants";

const Config = Constants.expoConfig.extra.config;

export class UserProfile {
    name: string
    username: string
    avatar: string
    notification: boolean
    language: string
    push_id?: {}
    tenant: string
    tenant_code: string | undefined
    access_token: string | undefined
    refresh_token: string | undefined
    expires_in: string | undefined
    version: string | undefined

    fromData(data: any, customer: Customer | null, tenant: string, tenantCode?: string): UserProfile {

        const accessToken = data.access_token;
        const refreshToken = data.refresh_token;
        const expiresIn = moment().add(data.expires_in, "seconds");
        const name = (customer) ? (
            `${customer?.first_name ? customer.first_name : ''}${customer?.suffix ? ' ' + customer?.suffix : ''} ${customer?.last_name ? customer?.last_name : ''}`
        ) : (
            data.username || ''
        )

        this.name = name;
        this.username = customer?.username || data.username || '';
        this.avatar = customer?.avatar_thumb?.data?.content;
        this.notification = false;
        this.language = 'nl';
        this.push_id = undefined;
        this.access_token = accessToken;
        this.refresh_token = refreshToken;
        this.expires_in = expiresIn.toString();
        this.tenant = tenant;
        this.tenant_code = tenantCode;
        this.version = Config.RELOG_VERSION

        return this;
    }

    fromJson(json: Record<string, any>) {
        this.access_token = json.access_token;
        this.refresh_token = json.refresh_token;
        this.expires_in = json.expires_in;
        this.language = json.language;
        this.notification = json.notification;
        this.push_id = json.push_id;

        this.name = json.name;
        this.username = json.username;
        this.avatar = json.avatar;
        this.tenant = json.tenant;
        this.tenant_code = json.tenant_code;
        this.version = json.version;

        return this;
    }

    logout() {
        this.access_token = undefined;
        this.refresh_token = undefined;
        this.expires_in = undefined;
    }
}
