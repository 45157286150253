import { useEffect, useState } from "react";
import { Dimensions } from "react-native"
import { LandingSmall, LandingWide } from "screens/landing";



export default () => {

    const getSize = () => (Dimensions.get('window').width > 768) ? 'wide' : 'small';
    const [size, setSize] = useState(getSize());

    useEffect(() => {
        Dimensions.addEventListener('change', () => setSize(getSize()));
    }, []);

    return size === 'wide' ? (
        <LandingWide />
    ) : (
        <LandingSmall />
    )
}
