import { useEffect, useRef, useState } from "react"
import { Image, Platform, ViewStyle } from "react-native";
import { View } from "react-native-animatable";
import Svg, { Circle, Defs, Mask, Rect } from "react-native-svg";

const SvgMask = ({ size }) => (
    <View style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}>
        <Svg height="100%" width="100%" viewBox={`0 0 ${size} ${size}`}>
            <Defs>
                <Mask id="mask" x="0" y="0" height={size} width={size}>
                    <Rect height="100%" width="100%" fill="#fff" />
                    <Circle r="175" cx="50%" cy="50%" fill='#000' />
                </Mask>
            </Defs>
            <Rect height="100%" width="100%" fill="rgba(0, 0, 0, 0.4)" mask="url(#mask)" />
        </Svg>
    </View>
);

export default ({ width, height, source, style }: { width: number, height: number, circlemask?: boolean, source: string, style?: ViewStyle }) => {

    //state
    const mousePressed = useRef(false);
    const imgSize = useRef();
    const scale = useRef(1);
    const offset = useRef({ x: 0, y: 0 });
    const touchStartPos = useRef<null | Record<string, number>>(null);
    const touchDistance = useRef<null | number>(null);

    //state not accesible from eventlisteners. UseRef is accessible, but does not rerender page.
    const [_, setForceUpdate] = useState(false);
    const rerender = () => setForceUpdate(f => !f);


    const setLayout = (e) => {
        if (!imgSize.current) imgSize.current = e.nativeEvent.layout;
    }




    //web events
    useEffect(() => {
        if (Platform.OS != 'web') return;

        window.addEventListener('wheel', scaleImg);
        window.addEventListener('mousedown', mousePress);
        window.addEventListener('mouseup', mouseRelease);
        window.addEventListener('mousemove', moveImg);

        window.addEventListener('touchstart', touchStart);
        window.addEventListener('touchmove', touchMove);
        window.addEventListener('touchend', touchEnd);

        return () => {

            window.removeEventListener('mousemove', moveImg);
            window.removeEventListener('mousedown', mousePress);
            window.removeEventListener('mouseup', mouseRelease);
            window.removeEventListener('wheel', scaleImg);

            window.removeEventListener('touchstart', touchStart);
            window.removeEventListener('touchmove', touchMove);
            window.removeEventListener('touchend', touchEnd);

        }
    }, [])

    //event handlers
    const mousePress = () => {
        mousePressed.current = true;
    }
    const mouseRelease = () => {
        mousePressed.current = false;
    }
    const touchStart = (e) => {
        if (e.nativeEvent) e = e.nativeEvent;
        e.pageX = e.touches[0].pageX;
        e.pageY = e.touches[0].pageY;


        if (!touchStartPos.current) {

            //safe first touch position
            touchStartPos.current = {
                x: e.locationX || e.pageX,
                y: e.locationY || e.pageY,
            }

        } else if (e.touches.length == 2) {

            const touch = e.touches;
            let dx = (touch[0].locationX) ? touch[0].locationX - touch[1].locationX : touch[0].pageX - touch[1].pageX;
            let dy = (touch[0].locationY) ? touch[0].locationY - touch[1].locationY : touch[0].pageX - touch[1].pageX;
            touchDistance.current = Math.sqrt(dx * dx + dy * dy);
            touchStartPos.current = null;

        } else {

            touchDistance.current = null;
            touchStartPos.current = null;

        }

    }
    const touchMove = (e) => {
        if (e.nativeEvent) e = e.nativeEvent;
        const touch = e.touches;
        e.pageX = touch[0].pageX;
        e.pageY = touch[0].pageY;

        if (touchDistance.current && touch.length > 1) {

            let dx = (touch[0].locationX) ? touch[0].locationX - touch[1].locationX : touch[0].pageX - touch[1].pageX;
            let dy = (touch[0].locationY) ? touch[0].locationY - touch[1].locationY : touch[0].pageX - touch[1].pageX;
            const currentDistance = Math.sqrt(dx * dx + dy * dy);
            scaleImg({ deltaY: (currentDistance - touchDistance.current) * 1.2 })
            touchDistance.current = currentDistance;

        } else if (touchStartPos.current) {

            const currentPos = {
                x: e.locationX || e.pageX,
                y: e.locationY || e.pageY,
            };
            moveImg({
                movementX: currentPos.x - touchStartPos.current.x,
                movementY: currentPos.y - touchStartPos.current.y
            });
            touchStartPos.current = currentPos;

        }
    }
    const touchEnd = (e) => {
        if (e.nativeEvent) e = e.nativeEvent;
        if (e.touches.length == 0) {
            touchDistance.current = null;
            touchStartPos.current = null;
        }
    }


    const scaleImg = (e) => {
        const dir = Math.sign(e.deltaY) * 0.025;
        const nScale = Math.max(1, scale.current + dir);

        if (nScale == scale.current) return;

        const nOffset = {
            x: offset.current.x - (imgSize.current.width * dir * 0.5),
            y: offset.current.y - (imgSize.current.height * dir * 0.5)
        }

        scale.current = nScale;
        offset.current = nOffset;
        rerender();
    }

    const moveImg = (e) => {
        if (mousePressed.current || touchStartPos.current) {
            const nOffset = {
                x: offset.current.x + e.movementX,
                y: offset.current.y + e.movementY
            };
            offset.current = nOffset;
            rerender();
        }
    }




    return (
        <View
            style={[{ overflow: "hidden", borderRadius: 4 }, style]}
            onTouchStart={Platform.OS != 'web' ? touchStart : undefined}
            onTouchMove={Platform.OS != 'web' ? touchMove : undefined}
            onTouchEnd={Platform.OS != 'web' ? touchEnd : undefined}
        >
            <Image
                onLayout={setLayout}
                source={{ uri: source }}
                resizeMode="contain"
                style={{
                    position: 'absolute',
                    width: (100 * scale.current) + '%',
                    height: (100 * scale.current) + '%',
                    left: offset.current.x,
                    top: offset.current.y,                    
                }}
            />
        </View>
    )

}