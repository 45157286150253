

export class Living {

    debt_market_value_ratio: string
    debt_market_value_ratio_original: string
    living_together: boolean
    owns_property: boolean
    municipality_registration_date: string

    property: {
        street_name: string
        house_number: string
        house_number_addition: string
        postcode: string
        city: string
        rent: number
        service_costs: number
        other_costs: number

        energy_label: string
        energy_label_end_date: string
        market_value: number
        plot_size: any
        price: number
        purchase_price: number
        size: number
        woz_value_home: number
        construction_year: string | number
        solar_panels: boolean
        smart_meter: boolean
        purpose: string
        purpose_status: string
        rental_type_description: string
        residential_building_type: string
    }



    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Living {
        this.debt_market_value_ratio = json.debt_market_value_ratio ? json.debt_market_value_ratio : '';
        this.debt_market_value_ratio_original = json.debt_market_value_ratio_original ? json.debt_market_value_ratio_original : '';
        this.living_together = json?.customer_property.data.living_together ? json?.customer_property.data.living_together : false;
        this.owns_property = json?.customer_property.data.owns_property ? json?.customer_property.data.owns_property : false;
        this.municipality_registration_date = json?.customer_property.data.municipality_registration_date ? json?.customer_property.data.municipality_registration_date : false;
        this.property = this.propertyFromJson(json);
        return this;
    }

    propertyFromJson(json) {

        const property = json.customer_property?.data?.property?.data;

        return {
            street_name: property?.street ? property.street : '',
            house_number: property?.number ? property.number : '',
            house_number_addition: property?.number_letter ? property.number_letter : '',
            postcode: property?.postal_code ? property.postal_code : '',
            city: property?.city ? property.city : '',
            rent: json?.basic_rent ? json.basic_rent : (json.rental_costs ? json.rental_costs : 0),
            service_costs: json?.service_costs ? json.service_costs : 0,
            other_costs: json?.other_costs ? json.other_costs : 0,

            construction_year: property?.construction_year ? property.construction_year : '',
            energy_label: property?.energy_label ? property.energy_label.replace(/Plus/g, '+') : '',
            energy_label_end_date: property?.energy_label_end_date ? property.energy_label_end_date : '',
            market_value: property?.market_value ? property.market_value : '',
            market_value_high: property?.value_high ? property.value_high : '',
            market_value_low: property?.value_low ? property.value_low : '',
            size: property?.size ? property.size : '',
            plot_size: property?.plot_size ? property.plot_size : '',
            price: property?.price ? property.price : '',
            purchase_price: property?.purchase_price ? property.purchase_price : '',
            woz_value_home: property?.real_estate_value ? property.real_estate_value : '',
            smart_meter: property.double_or_smart_meter ? property.double_or_smart_meter : false,
            solar_panels: property.solar_panels ? property.solar_panels : false,
            purpose: property.purpose ? property.purpose : '',
            purpose_status: property.purpose_status ? property.purpose_status : '',
            rental_type_description: property.rental_type_description ? property.rental_type_description : '',
            residential_building_type: property.residential_building_type ? property.residential_building_type : ''
        }
    }

}

