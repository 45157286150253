
export default {

    //main colors
    primary: "#352171",
    primary1: "rgb(235, 233, 241)",
    primary2: "rgb(215, 211, 227)",
    primary3: "rgb(194, 188, 212)",
    primary4: "rgb(174, 166, 198)",
    primary5: "rgb(154, 144, 184)",
    primary6: "rgb(134, 122, 170)",
    primary7: "rgb(114, 100, 156)",
    primary8: "rgb(93, 77, 141)",
    primary9: "rgb(73, 55, 127)",

    secondary: "#f07a00",
    secondary1: "rgb(254, 242, 230)",
    secondary2: "rgb(252, 228, 204)",
    secondary3: "rgb(251, 215, 179)",
    secondary4: "rgb(249, 202, 153)",
    secondary5: "rgb(248, 189, 128)",
    secondary6: "rgb(246, 175, 102)",
    secondary7: "rgb(245, 162, 77)",
    secondary8: "rgb(243, 149, 51)",
    secondary9: "rgb(242, 135, 26)",    
    
    //gradient
    gradientFrom: '#4158b3',
    gradientTo: '#2c5fa3',
    
    //backgrounds
    background: '#EDEAF1',
    formBackground: 'rgb(221,227,234)',
    formDecoration: 'rgb(223,223,223)',
    
    //black
    black: '#1A141F',
    blackTransparent: 'rgba(0, 0, 0, 0.1)',
    blackLessTransparent: 'rgba(0, 0, 0, 0.2)',
    
    //grey
    grey: 'rgb(190, 190, 190)',
    greyTransparent: 'rgba(190, 190, 190, 0.2)',
    greyLessTransparent: 'rgba(190, 190, 190, 0.6)',
    darkgrey: '#898989',
    darkestgrey: 'rgb(120, 120, 120)',
    lightgrey: '#F5F3F7',
    lightergrey: '#F5F5F7AA',
    
    //white
    white: '#FFF',
    whiteTransparent: 'rgba(255, 255, 255, 0.1)',
    
    //utility colors
    transparent: 'rgba(0,0,0,0)',
    hint: '#4B3A5A',
    border: '#ABA7AF',
    disabled: '#D4D2D5',
    spacer: '#D9D1E0',
    hover: '#1053A8',
    headerNavText: '#7a8391',

    //action colors
    error: '#D51A52',
    errorDarker: '#8a1135',
    errorLighter: '#e89cb3',
    action: '#30E494',
    actionDarker : '#1ac77a',
    approval: '#5BD27C',
    link: '#0F0BAB'
}