import { useHover, useStyle, useTranslation } from "hooks";
import TText from "../../components/TText";
import { BaseDrawerModal, ChangePasswordModal } from "components/Modals";
import { StoreContext } from "providers/StoreProvider";
import { useContext, useEffect, useState } from "react";
import { Image, View } from "react-native";
import { Button, Person } from "components";
import { useQuery } from "react-query";
import { QueryKeys } from "enums";
import { QueryUtils, ValutaUtils } from "utils";
import { useNavigation } from "@react-navigation/native";
import { ModalContext } from "providers/ModalProvider";
import { Mortgage } from "models";
import { Card, IconCard } from "components/Card";
import { colors } from "styles";
import Constants from "expo-constants";

// const customerOptions = [
//     {
//         label: translate('#change_avatar'),
//         useHover: useHover(),
//         fn: () => {
//             navigation.navigate('avatar');
//         }
//     },
//     {
//         label: translate('#change_password'),
//         useHover: useHover(),
//         fn: () => {
//             showModal(ChangePasswordModal, {}, 'ChangePasswordModal');
//         }
//     },       
// ];

const Config = Constants.expoConfig.extra.config;


export default ({ visible, onClose } : { visible: boolean; onClose: () => void }) => {

    const styles = useStyle();
    const translate = useTranslation();
    const navigation = useNavigation();

    const { logout, reloadApp } = useContext(StoreContext);
    const { showModal } = useContext(ModalContext)
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const { data: family } = useQuery(QueryKeys.FAMILY, QueryUtils.fetchFamily);
    const { data: living } = useQuery(QueryKeys.LIVING, QueryUtils.fetchLiving);
    const { data: mortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);

    const activeAddress = living?.property || { street_name: '', house_number: '', house_number_addition: '', city: '', postcode: '' };
    const owner = living?.owns_property;
    const marketValue = activeAddress?.market_value || activeAddress?.market_value_high || activeAddress?.market_value_low || '#unknown';

    const [mapURL, setMapURL] = useState<string>();
    const [streetviewURL, setStreetviewURL] = useState<string>();
    const [matchedMortgage, setMatchedMortgage] = useState<Mortgage | null>();
    useEffect(() => {
        if (activeAddress.street_name !== "") {
            setMapURL(encodeURI(activeAddress ? `https://maps.google.com/maps?q=${activeAddress.street_name} ${activeAddress.house_number}${activeAddress.house_number_addition}, ${activeAddress.city}&t=&z=16&ie=UTF8&iwloc=&output=embed` : ''));
            setStreetviewURL(encodeURI(activeAddress ? `${Config.PROXY_URL}/streetview?location=${activeAddress.street_name} ${activeAddress.house_number}${activeAddress.house_number_addition}, ${activeAddress.city}&size=600x400&fov=90` : ''));
            setMatchedMortgage(mortgages?.find((mortgage: Mortgage) => {
                return mortgage.properties.find((property => {
                    return property.postal_code === activeAddress.postcode && 
                           property.number === String(activeAddress.house_number) && 
                           (property.number_addition || property.number_letter) === String(activeAddress.house_number_addition);
                })) !== undefined;                
            }));
        }
    }, [activeAddress, mortgages])

    const renderCustomer = () => {
        return (
            <Person
                customer={customer}
            />
        );
    };

    const renderFamily = () => {
        return (
            <View style={styles.gap20}>
                {family?.partner && 
                    <Person
                        customer={family.partner}
                        relation="Partner"
                    />                
                }
                {family?.children && 
                    family?.children.map((child) => (
                        <Person
                        customer={child}
                        relation="Kind"
                    />  
                    ))
                }
            </View>
        );
    }

    const renderLiving = () => {
        if (!activeAddress.postcode || !activeAddress.house_number) {
            return;
        }
        return (
            <Card style={{
                flex: 1,
                flexDirection: 'column',
                gap: 10,
                padding: 10,
                backgroundColor: colors.background,
                minHeight: 450
            }}>
                {streetviewURL && <Image 
                    source={{uri: streetviewURL}} 
                    style={{ width: '100%', flex: 1, borderRadius: 12 }}
                />}                
                <View style={styles.gap10}>
                    <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                        <IconCard iconName="IconHome" iconColor={colors.secondary} style={{backgroundColor: colors.white}}/>
                        <View>
                            <TText semibold>{`${activeAddress.street_name} ${activeAddress.house_number}${activeAddress.house_number_addition}`}</TText>                        
                            <TText>{`${activeAddress.postcode && (activeAddress.postcode + ',')} ${activeAddress.city}`}</TText>
                        </View>
                    </View>
                    <View style={{marginLeft: 44}}>
                        <View style={[styles.flexRow, styles.alignCenter, styles.justifyBetween, styles.gap10]}>
                            <TText>Type</TText>   
                            <TText semibold>{activeAddress.residential_building_type}</TText>   
                        </View>
                        <View style={[styles.flexRow, styles.alignCenter, styles.justifyBetween, styles.gap10]}>
                            <TText>Bouwjaar</TText>   
                            <TText semibold>{activeAddress.construction_year}</TText>   
                        </View>
                        <View style={[styles.flexRow, styles.alignCenter, styles.justifyBetween, styles.gap10]}>
                            <TText>WOZ-Waarde</TText>   
                            <TText semibold>{ValutaUtils.valutaString(activeAddress.woz_value_home)}</TText>   
                        </View>
                        <View style={[styles.flexRow, styles.alignCenter, styles.justifyBetween, styles.gap10]}>
                            <TText>Energielabel</TText>   
                            <TText semibold>{activeAddress.energy_label}</TText>   
                        </View>
                    </View>                        
                </View>                
            </Card>
        )
    }
    
    return (
       <BaseDrawerModal 
            drawerWidth={500} 
            visible={visible}
            onClose={onClose}
            header={''}
            drawerStyle={styles.gap20}
        >
            {renderCustomer()}
            {renderFamily()}
            {renderLiving()}
            <View style={[styles.flexStretch, styles.justifyEnd, styles.gap20]}>
                <View style={[styles.flexRowDynamic, styles.alignCenter, styles.justifyBetween, styles.gap20]}>
                    <Button
                        label="#action_logout"
                        type="secondary"
                        light
                        onPress={() => setTimeout(() => logout(), 150)}
                        style={styles.flexStretch}
                    />
                    <Button
                        label="#login_other_account"
                        type="secondary"
                        light
                        onPress={() => setTimeout(() => reloadApp(), 150)}
                        style={styles.flexStretch}
                    />
                </View>
                <Button
                    label="#change_info"
                    type="secondary"
                    onPress={() => {
                        navigation.navigate('editInfo');
                        onClose();
                    }}
                    style={{width: '100%'}}
                />
            </View>
       </BaseDrawerModal>
    );
}
