import colors from './colors';


export const TextStylesExtraLarge = {
    lineHeight:{
        lineHeight: '1.6em',
    },
    textLG: {
        fontSize: 28
    },
    textMD: {
        fontSize: 17
    },
    textSM: {
        fontSize: 14
    },
    textXS: {
        fontSize: 13
    },

    textRegular: {
        fontFamily: 'Poppins_400Regular',
        letterSpacing: 0.2
    },
    textSemiBold: {
        fontFamily: 'Poppins_600SemiBold',
        letterSpacing: 0.2
    },    

    textBlack: {
        color: colors.black
    },
    textWhite: {
        color: colors.white
    },
    textPrimary: {
        color: colors.primary
    },
    textDisabled: {
        color: colors.disabled
    },
    textError: {
        color: colors.error
    },
}

export const TextStylesLarge = {
    textXL: {
        fontSize: 32
    },
    textLG: {
        fontSize: 22
    },
    textMD: {
        fontSize: 15
    },
    textSM: {
        fontSize: 14
    },
    textXS: {
        fontSize: 13
    }
}

export const TextStylesMedium = {
    textXL: {
        fontSize: 24
    },
    textLG: {
        fontSize: 19
    },
    textMD: {
        fontSize: 13
    },
    textSM: {
        fontSize: 12,
    },
    textXS: {
        fontSize: 11
    }
}

export const TextStylesSmall = {
    textXL: {
        fontSize: 23
    },
    textLG: {
        fontSize: 18
    },
    textMD: {
        fontSize: 12
    },
    textSM: {
        fontSize: 11
    },
    textXS: {
        fontSize: 10
    }
}
