export enum LocalStorageKeys {
    USERS = 'users',
    CURRENT_USER = 'current_user',

    //DEPRECATED
    EXPIRES_IN = "expires_in",
    ACCESS_TOKEN = "access_token",
    REFRESH_TOKEN = "refresh_token",
    LANGUAGE = 'language',
    NOTIFICATION = 'notification',
    PUSHID = 'push_id',
    X_TENANT1 = "x-tenant",
    X_TENANT2 = "x_tenant",
}