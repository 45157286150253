import React from "react";
import {Linking, View} from "react-native";
import PageContainer from "components/PageContainer";
import {HeaderCard} from "components/Card";
import {useStyle} from "hooks";
import {colors} from "styles";
import {Button, FlexGrid, TText} from "components";
import Card from "components/Card/Card";
import {CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize} from "rn-responsive-styles";

export default () => {

    const scopedStyles = scopedResponsiveStyles();
    const styles = useStyle();

    const kopen = [
        {
            id: 1,
            title: 'Eigen Huis Hypotheekadvies',
            body: 'Bij jou in de buurt of online. Onafhankelijk en betrouwbaar. Het eerste gesprek is gratis en vrijblijvend.',
            buttonLabel: 'Meer informatie',
            link: 'https://www.eigenhuis.nl/diensten/kopen-diensten/hypotheekadvies?utm_campaign=hypotheekwacht&utm_source=tool&utm_medium=direct&f17utm_content=&utm_term=_button'
        },
        {
            id: 2,
            title: 'Bouwtechnische keuring',
            body: 'Meer inzicht in de bouwkundige staat van een woning.',
            buttonLabel: 'Meer informatie',
            link: 'https://www.eigenhuis.nl/diensten/kopen-diensten/bouwtechnische-keuring?utm_campaign=hypotheekwacht&utm_source=tool&utm_medium=direct&f17utm_content=&utm_term=_button'
        }
    ];

    const wonen = [
        {
            id: 1,
            title: 'Eigen Huis Verzekeringen',
            body: 'Verzekeringen tegen goede voorwaarden en scherpe premies.',
            buttonLabel: 'Meer informatie',
            link: 'https://www.eigenhuis.nl/diensten/verzekeringen/woonverzekeringen?utm_campaign=hypotheekwacht&utm_source=tool&utm_medium=direct&f17utm_content=&utm_term=_button'
        },
        {
            id: 2,
            title: 'Testamentadvies',
            body: 'Je testament goed geregeld, inclusief gespreksverslag en afwikkeling notaris.',
            buttonLabel: 'Meer informatie',
            link: 'https://www.eigenhuis.nl/diensten/wonen-en-onderhoud-diensten/testamentadvies?utm_campaign=hypotheekwacht&utm_source=tool&utm_medium=direct&f17utm_content=&utm_term=_button'
        },
        {
            id: 3,
            title: 'Eigen Huis GeldPlan',
            body: 'Wist je dat veel mensen te laat nadenken over hun financiële toekomst? Voorkom onzekerheden en neem tijdig actie.',
            buttonLabel: 'Meer informatie',
            link: 'https://www.eigenhuis.nl/diensten/wonen-en-onderhoud-diensten/eigen-geldplan?utm_campaign=hypotheekwacht&utm_source=tool&utm_medium=direct&f17utm_content=&utm_term=_button'
        }
    ];

    const verduurzamen = [
        {
            id: 1,
            title: 'Energielabel',
            body: 'Woning verkopen of verduurzaamd? Regel een energielabel.',
            buttonLabel: 'Meer informatie',
            link: 'https://www.eigenhuis.nl/diensten/diensten-verduurzamen/energielabel?utm_campaign=hypotheekwacht&utm_source=tool&utm_medium=direct&f17utm_content=&utm_term=_button'
        },
        {
            id: 2,
            title: 'Collectieve Inkoop Zonnepanelen',
            body: 'Goede zonnepanelen voor een scherpe prijs.',
            buttonLabel: 'Meer informatie',
            link: 'https://www.eigenhuis.nl/diensten/diensten-verduurzamen/collectieve-inkoop-zonnepanelen?utm_campaign=hypotheekwacht&utm_source=tool&utm_medium=direct&f17utm_content=&utm_term=_button'
        },
        {
            id: 3,
            title: 'Collectieve Inkoop Warmtepomp',
            body: 'Bespaar op gas met een hybride warmtepomp.',
            buttonLabel: 'Meer informatie',
            link: 'https://www.eigenhuis.nl/diensten/diensten-verduurzamen/collectieve-inkoop-warmtepomp?utm_campaign=hypotheekwacht&utm_source=tool&utm_medium=direct&f17utm_content=&utm_term=_button'
        }
    ];


    const renderTopSection = () => {

        return (
            <Card style={{backgroundColor: colors.primary, gap: 10}}>

                <TText white md semibold>#services_title</TText>
                <TText white sm regular>#services_description</TText>

            </Card>
        );
    };

    const renderServiceCards = (data: any[]) => {
        return data.map(card => {
            return (
                <Card key={card.title} style={[scopedStyles.card]}>
                    <View style={[styles.gap10]}>
                        <TText md semibold>{card.title}</TText>
                        <TText sm regular>{card.body}</TText>
                    </View>

                    <View style={[styles.flexRow, {marginTop: 'auto'}]}>
                        <Button
                            style={{marginTop: 5}}
                            label={card.buttonLabel}
                            type="secondary"
                            onPress={() => {
                                Linking.openURL(card.link)
                            }}
                        />
                    </View>
                </Card>
            );
        });
    };

    return (
        <PageContainer>

            <View>

                {renderTopSection()}

            </View>

            <HeaderCard header="Wonen">

                <FlexGrid columns={3} widthSmall={821}>

                    {renderServiceCards(wonen)}

                </FlexGrid>

            </HeaderCard>

            <HeaderCard header="Kopen">

                <FlexGrid columns={3} widthSmall={821}>

                    {renderServiceCards(kopen)}

                </FlexGrid>

            </HeaderCard>

            <HeaderCard header="Verduurzamen">

                <FlexGrid columns={3} widthSmall={821}>

                    {renderServiceCards(verduurzamen)}

                </FlexGrid>

            </HeaderCard>

        </PageContainer>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        card: {
            flex: 1,
            minHeight: 250,
            marginBottom: 10,
            marginHorizontal: 5,
            backgroundColor: colors.background,
            gap: 6,
        },
    }, {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            card: {
                minHeight: 220,
            }
        },
    }
);