import { useHover, useStyle } from 'hooks';
import { useState } from 'react';
import { Pressable, View } from 'react-native';
import { colors } from 'styles';
import Icon from './Icon';
import TText from './TText';

export default ({onChange, options, label, value}: {onChange: (checked: string | number) => void, options: Array<{value: string | number, label: string}>, label?: string, value: number | string}) => {

    const styles = useStyle();
    
    return (
        <View style={{gap: 5}}>
            <TText semibold>{label}</TText>

            <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                {options.map((option) => {
                    return (
                        <Pressable 
                            onPress={() => onChange(option.value)}
                            style={[styles.flexRow, styles.alignCenter, styles.gap10]}
                        >
                            <View
                                style={{
                                    width: 16,
                                    height: 16,
                                    backgroundColor: colors.white,
                                    borderColor: colors.border,
                                    borderWidth: 1,
                                    borderRadius: 16,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {value === option.value &&
                                    <View
                                        style={{
                                            width: 12,
                                            height: 12,
                                            backgroundColor: colors.secondary,
                                            borderRadius: 12,
                                        }}      
                                    ></View>
                                }
                            </View>
                            <TText>{option.label}</TText>
                        </Pressable>
                    );
                })}
            </View>
        </View>
    )
}