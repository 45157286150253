import {OnlineAfsprakenAppointmentType} from "models/OnlineAfsprakenAppointmentType";

export class OnlineAfsprakenAppointmentCategory {
    id: number | null
    name: string;
    onlineAfsprakenAppointmentTypes: Array<OnlineAfsprakenAppointmentType>

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): OnlineAfsprakenAppointmentCategory {
        this.id = json.id ? json.id : null;
        this.name = json.name ? json.name : '';
        this.onlineAfsprakenAppointmentTypes = json.onlineAfsprakenAppointmentTypes ? json.onlineAfsprakenAppointmentTypes : [];
        return this;
    }

    getLabel = () => {
        return this.name;
    }

    getValue = () => {
        return this.id;
    }
}
