
export class Office {
    id: string;
    name: string;
    city: string;
    code: string;
    default_hours: [{
        evening: boolean;
        from: string;
        key: string;
        open: boolean;
        till: string;
    }];
    email: string;
    house_number: string;
    house_number_addition: string;
    number: string;
    number_addition: string;
    number_letter: string;
    phone: string;
    postcode: string;
    street_name: string;
    upcoming_hours: Array<string>; // [key => [string]]
    website: string;

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Office {
        this.id = json.id ? json.id : "";
        this.name = json.name ? json.name : "";
        this.city = json.city ? json.city : "";
        this.code = json.code ? json.code : "";
        this.default_hours = json.default_hours ? json.default_hours : [];
        this.email = json.email ? json.email : "";
        this.house_number = json.house_number ? json.house_number : "";
        this.house_number_addition = json.house_number_addition ? json.house_number_addition : "";
        this.number = json.number ? json.number : "";
        this.number_addition = json.number_addition ? json.number_addition : "";
        this.number_letter = json.number_letter ? json.number_letter : "";
        this.phone = json.phone ? json.phone : "";
        this.postcode = json.postcode ? json.postcode : "";
        this.street_name = json.street_name ? json.street_name : "";
        this.upcoming_hours = json.upcoming_hours ? json.upcoming_hours : [];
        this.website = json.website ? json.website : "";
        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId()
        };
    }

    getId(): string {
        return this.id;
    }
}
