

export class Income {
    benefit_incomes: Array<any>
    company_incomes: Array<any>
    country: string
    employment_history: Array<any>
    employment_history_slips: Array<any>
    employment_history_slips_totals: Array<any>
    employment_history_totals: Array<any>
    employment_incomes: Array<any>
    job_market_scan_earning_capacity: number
    job_market_scan_report_number: number
    job_market_scan_score: number
    key_income: number
    key_income_based_on: string
    monthly_pension_contribution: number
    other_incomes: Array<any>
    responsible_monthly_mortgage_amount: number
    retirement_incomes: Array<any>
    retirement_responsible_monthly_mortgage_amount: number
    uwv_key_income: number

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Income {
        this.benefit_incomes = json.benefit_incomes ? json.benefit_incomes.data : [];
        this.company_incomes = json.company_incomes ? json.company_incomes.data : [];
        this.country = json.country ? json.country : '';
        this.employment_history = json.employment_history ? json.employment_history.data : [];
        this.employment_history_totals = json.employment_history_totals ? json.employment_history_totals.data : [];
        this.employment_history_slips = json.employment_history_slips ? json.employment_history_slips.data : [];
        this.employment_history_slips_totals = json.employment_history_slips_totals ? json.employment_history_slips_totals.data : [];
        this.employment_incomes = json.employment_incomes ? json.employment_incomes.data : [];
        this.job_market_scan_earning_capacity = json.job_market_scan_earning_capacity ? json.job_market_scan_earning_capacity : 0;
        this.job_market_scan_report_number = json.job_market_scan_report_number ? json.job_market_scan_report_number : 0;
        this.job_market_scan_score = json.job_market_scan_score ? json.job_market_scan_score : 0;
        this.key_income = json.key_income ? json.key_income : 0;
        this.key_income_based_on = json.key_income_based_on ? json.key_income_based_on : '';
        this.monthly_pension_contribution = json.monthly_pension_contribution ? json.monthly_pension_contribution : 0;
        this.other_incomes = json.other_incomes ? json.other_incomes.data : [];
        this.responsible_monthly_mortgage_amount = json.responsible_monthly_mortgage_amount ? json.responsible_monthly_mortgage_amount : 0;
        this.retirement_incomes = json.retirement_incomes ? json.retirement_incomes.data : [];
        this.retirement_responsible_monthly_mortgage_amount = json.retirement_responsible_monthly_mortgage_amount ? json.retirement_responsible_monthly_mortgage_amount : 0;
        this.uwv_key_income = json.uwv_key_income ? json.uwv_key_income : 0;
        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId()
        };
    }

    getId(): string {
        return this.id;
    }
}
