export enum PortalInvitationType {
  FULL = 'full',
  ASSESSMENT = 'assessment',
  SOURCE_DATA = 'source_data',
  ADVICE = 'advice',
  PARTNER = 'partner',
  PARTNER_ASSESSMENT = 'partner_assessment',
  PARTNER_SOURCE_DATA = 'partner_source_data',
  PARTNER_ADVICE = 'partner_advice',
  CUSTOMER_PROFILE = 'customer_profile',
  MORTGAGE_PENSION = 'mortgage_pension',
  MORTGAGE_ENRICH = 'mortgage_enrich',
  MORTGAGE = 'mortgage',
  PENSION = 'pension'
}
