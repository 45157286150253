
const autoDateFormatting = ( date: string ) => {

    const numberRegex = /[0-9]/;

    if (date.match(numberRegex) && date.length <= 10) {
      // eslint-disable-next-line default-case
      switch (date.length) {
        case 1:
          if (!["0", "1", "2", "3"].includes(date)) {
            return date.substring(0, date.length - 1);
          }
          break;

        case 2:
          if (date === "00") {
            return date.substring(0, date.length - 1);
          } else {
            // eslint-disable-next-line no-param-reassign
            return `${date}-`;
          }
          break;

        case 4:
          if (!["0", "1"].includes(date[date.length - 1])) {
            return date.substring(0, date.length - 1);
          }
          break;

        case 5:
          // eslint-disable-next-line no-case-declarations
          const month = Number(date.substring(date.length - 2));
          if (!(month >= 1 && month <= 12)) {
            return date.substring(0, date.length - 1);
          } else {
            // eslint-disable-next-line no-param-reassign
            return `${date}-`;
          }
          break;
      }
    } else {
        return date.substring(0, date.length - 1);
    }
};

export default {
    autoDateFormatting
};