import { registerRootComponent } from 'expo';
import { AppRegistry } from 'react-native';
import { SentryUtils } from 'utils';
import App from './src';

//ignoring invalid element property transforms for web
// console.log = () => {};
// console.warn = () => {};
// console.error = () => {};

SentryUtils.init({
    dsn: 'https://a2994438ec5246e28374248917eeb803@sentry.ddat.io/7',
    debug: false,
    sampleRate: 1
});

AppRegistry.registerComponent(() => App);
registerRootComponent(App);