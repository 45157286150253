export const RepaymentTypeEnum = Object.freeze({
    ANNUITY: 1,                  // Annuïteit
    LINEAR: 2,                   // Lineair
    LIFE_INSURANCE: 3,           // Leven
    INTEREST_ONLY: 5,            // Aflossingsvrij
    CREDIT_OR_BANK_MORTGAGE: 9,  // Krediet of bankhypotheek
    INVESTMENT_MORTGAGE: 10,     // Beleggingshypotheek
    SAVINGS_ACCOUNT: 13,         // Spaarrekening
    BANK_SAVINGS: 50,            // Banksparen
    BAUSPAR: 51,                 // Bausparen
    STARTER_LOAN: 52,            // Starterslening
    SAVINGS: 53,                 // Spaarhypotheek
});