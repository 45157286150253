import React from 'react';
import { View, ViewStyle, StyleSheet } from 'react-native';
import { colors } from 'styles';
import TText from './TText';

//
//
//
// A badge component, returns a rounded badge with a value inside.
//
//
//

interface BadgeProps {
    children?: React.ReactNode,
    value: string | number,
    style?: ViewStyle,
    sm?: boolean,
    md?: boolean,
    lg?: boolean,
    darkText?: boolean,
    action?: boolean
    disabled?: boolean
}

const Badge: React.FC<BadgeProps> = ({ children, value, style, sm, md, lg, darkText, action, disabled }) => {

    return (
        <View testID='badge' style={[
            scopedStyles.badge,
            { backgroundColor: action ? colors.secondary : colors.primary },
            style,
            disabled && { backgroundColor: colors.grey }
        ]}>
            {value !== undefined &&
                <TText
                    xs
                    md={md}
                    lg={lg}
                    semibold
                    white={!darkText}
                    center
                    style={scopedStyles.text}
                >
                    {value}
                </TText>
            }
            {children}
        </View>
    );
}

const scopedStyles = StyleSheet.create({
    badge: {
        height: 20,
        minWidth: 22,
        paddingHorizontal: 4,
        borderRadius: 18,
        justifyContent: 'center',
        alignItems: 'center'
    },
    text: {
        marginTop: 1,
        textAlign: 'center'
    }
})

export default Badge;