import { Poppins_300Light, Poppins_400Regular, Poppins_500Medium, Poppins_600SemiBold, Poppins_700Bold, useFonts } from '@expo-google-fonts/poppins';
import { NavigationContainer } from '@react-navigation/native';
import { useAutoLogin, useVersionChecker } from 'hooks';
import useDeviceToken from 'hooks/useDeviceToken';
import { Home } from 'pages/home';
import { Login } from 'pages/login';
import { Password } from 'pages/password';
import { StoreContext } from 'providers/StoreProvider';
import { useContext, useEffect, useState } from 'react';
import { Error500 } from 'screens/error';
import { ErrorGeneric } from 'screens/error/overload';
import { TenantError } from 'screens/error/tenant';
import { LoadingScreen } from 'screens/loading';



export default () => {

    const [fontsLoaded] = useFonts({ Poppins_300Light, Poppins_400Regular, Poppins_500Medium, Poppins_600SemiBold, Poppins_700Bold });
    const { loggedIn, logout, language, error500, errorGeneric, tenantError, query } = useContext(StoreContext);

    //force refresh when language changes
    const [_, setForceRefresh] = useState(false);
    useEffect(() => setForceRefresh(f => !f), [language]);

    const { checkingAuth } = useAutoLogin();
    useVersionChecker();
    useDeviceToken();

    //check for password reset query
    const resetPassword = query.has('reset_password');
    const register = query.has('register');

    return error500 ? (

        <Error500 clearCookies={logout} />

    ) : errorGeneric ? (

        <ErrorGeneric />

    ) : tenantError ? (

        <TenantError />

    ) : (

        (!fontsLoaded || checkingAuth) ? (
            <LoadingScreen />
        ) : (            
            (resetPassword || register) ? (
                <Password />
            ) : (
                loggedIn ? <Home /> : <Login />
            )            
        )

    )

}