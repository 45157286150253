import { Button, TText, SecureTextInput, TextInput } from 'components';
import { useFormik } from 'formik';
import { useStyle, useTranslation } from 'hooks';
import { useEffect } from 'react';
import { KeyboardAvoidingView, Platform, StyleSheet } from 'react-native';
import { object, ref, string } from 'yup';


interface FormProps {
    loading: boolean
    handleReset: (v: any) => void
    hideButton?: boolean,
    clearValues?: boolean,
    buttonText: string
    username?: string
}

export default ({ loading, handleReset, hideButton, clearValues, buttonText, username }: FormProps) => {

    const styles = useStyle();
    const translate = useTranslation();

    useEffect(() => {
        resetForm();
    }, [clearValues])

    useEffect(() => {
        const set = async () => {
            setValues({
                ...values,
                username
            })
        }
        set();
    }, [username])

    const loginSchema = object({
        username: string(),
        password: string().required('#login_error_password').min(8, '#password_min_length'),
        passwordConfirmation: string().required('#login_error_password').min(8, '#password_min_length').when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: string().oneOf([ref('password')], '#new_password_equal')
        })
    });

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        setValues,
        errors,
        touched,
        resetForm
    } = useFormik({
        validationSchema: loginSchema,
        initialValues: { username, password: '', passwordConfirmation: '' },
        onSubmit: values => handleReset(values),
    });

    return (
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} >

            <TextInput
                disabled
                label='#login_username'
                style={[styles.text, scopedStyles.input]}
                value={values.username}
                onChangeText={handleChange('username')}
                onBlur={handleBlur('username')}
                onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                autoCapitalize='none'
                autoCompleteType='username'
                error={(touched.username && errors.username) ? errors.username : undefined}
            />

            <SecureTextInput
                label='#new_password'
                style={[styles.text, scopedStyles.input]}
                placeholder={translate('#new_password')}
                value={values.password}
                onChangeText={handleChange('password')}
                onBlur={handleBlur('password')}
                onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                autoCapitalize='none'
                autoCompleteType='password'
                error={(touched.password && errors.password) ? errors.password : undefined}
            />

            <SecureTextInput
                label='#new_password_confirmation'
                style={[styles.text, scopedStyles.input]}
                placeholder={translate('#new_password_confirmation')}
                value={values.passwordConfirmation}
                onChangeText={handleChange('passwordConfirmation')}
                onBlur={handleBlur('passwordConfirmation')}
                onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                autoCapitalize='none'
                autoCompleteType='password'
                error={(touched.passwordConfirmation && errors.passwordConfirmation) ? errors.passwordConfirmation : undefined}
            />

            {!hideButton &&
                <Button
                    label={`${buttonText}`}
                    onPress={handleSubmit}
                    style={scopedStyles.button}
                    loading={loading}
                />
            }

        </KeyboardAvoidingView>
    );
};

const scopedStyles = StyleSheet.create({
    input: {
        width: '100%',
        marginVertical: 8
    },
    button: {
        marginTop: 24,
        width: '100%'
    },
    error: {
        textAlign: 'right',
        marginBottom: -8
    }
});
