
export class Policy {
    id: string;
    name: string;
    descriptions: string[];
    is_package: boolean;
    parent_id: number;
    start_date: string;
    expiration_date: string;
    contract_expiration_date: string;
    package_number: string;
    contract_number: string;
    tracking_number: string;
    insured_amounts: Array<number>;
    claim_free_years: number;
    mutation_code: number;
    agency: string;
    total_term_amount: number;
    status: object;
    description: string;
    mutation_date: string;
    mutation_reason: string;
    duration: number;
    active: boolean;
    payment_term: number;
    payment_term_description: string;
    monthly_term_amount: number;
    view_group_name: string
    branche_group_name: string

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Policy {

        this.id = json.id ? json.id : "";
        this.name = json.name ? json.name : "";
        this.descriptions = json.descriptions ? json.descriptions : [];
        this.is_package = json.is_package ? json.is_package : false;
        this.parent_id = json.parent_id ? json.parent_id : 0;
        this.start_date = json.start_date ? json.start_date : '';
        this.expiration_date = json.expiration_date ? json.expiration_date : '';
        this.contract_expiration_date = json.contract_expiration_date ? json.contract_expiration_date : '';
        this.package_number = json.package_number ? json.package_number : '';
        this.contract_number = json.contract_number ? json.contract_number : '';
        this.tracking_number = json.tracking_number ? json.tracking_number : '';
        this.insured_amounts = json.insured_amounts ? json.insured_amounts : new Array<number>();
        this.claim_free_years = json.claim_free_years ? json.claim_free_years : 0;
        this.mutation_code = json.mutation_code ? json.mutation_code : 0;
        this.agency = json.agency ? json.agency : "";
        this.view_group_name = json.view_group_name ? json.view_group_name : "";
        this.branche_group_name = json.branche_group_name ? json.branche_group_name : "";

        this.total_term_amount = json.total_term_amount ? ((typeof json.total_term_amount == 'string') ? Number(json.total_term_amount.replace('€ ', '').replace(',', '.')) : json.total_term_amount ) : 0;        
        this.monthly_term_amount = this.total_term_amount ? this.total_term_amount / (json.payment_term || 1) : 0;        

        this.status = json.status ? json.status : {};
        this.description = json.description ? json.description : "";
        this.mutation_date = json.mutation_date ? json.mutation_date : 0;
        this.mutation_reason = json.mutation_reason ? json.mutation_reason : "";
        this.duration = json.duration ? json.duration : 0;
        this.active = json.active ? json.active : false;
        this.payment_term = json.payment_term ? json.payment_term : 0;
        this.payment_term_description = json.payment_term_description ? json.payment_term_description : "";
        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId()
        };
    }

    getId(): string {
        return this.id;
    }
}
