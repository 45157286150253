import { Image, ImageStyle } from "react-native";
import IconCard from "./IconCard";
import { colors } from "styles";

interface AvatarCardProps{
    avatar: string
    style?: ImageStyle
}

export default ({ avatar, style }: AvatarCardProps) => {

    const useAvatar = avatar && !avatar.includes('iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAACXBIWXMAAAsTAAALEwEAmpwYAAA4JmlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYm');

    return useAvatar ? (
        <Image
            source={{ uri: `data:image/png;base64,${avatar}` }}
            width={32}
            height={32}
            style={[{
                width: 32,
                height: 32,
                borderRadius: 10
            }, style]}
        />
    ) : (
        <IconCard iconName="IconUserFilled" iconColor={colors.white} />
    );
}
