import axios from "axios";
import { AuthFailureCodes, LocalStorageKeys } from "enums";
import Constants from "expo-constants";
import { UserProfile } from "models/UserProfile";
import moment from "moment";
import { SentryUtils, StorageUtils } from "utils";
import ApiUtils from "./ApiUtils";

const Config = Constants.expoConfig.extra.config;

interface user {
    code?: string;
    username: string;
    password: string;
    tenant?: string;
    token?: string;
    otp?: number | string;
}




const expiresInMs = async (expiresIn) => {

    const expiresInAsMoment = moment(expiresIn); //NB: gives a warning.
    if (!expiresIn) return null;
    const expiresOver = moment.isMoment(expiresInAsMoment)
        ? expiresInAsMoment.diff(moment())
        : null;

    // remove 5 minutes from expiresIn to refresh tokens 5 minutes before expiration
    return expiresOver ? expiresOver - 120000 : null;
};



const retrieveTokens = async (data: user) => {

    const { code, username, password, tenant: subdomain, otp } = data;

    const tenant = subdomain || await ApiUtils.transformTenantCode(code);
    if (tenant == false) return 'could not transform tenantcode';

    return axios.post(
        Config.PROXY_URL + '/auth/login',
        {
            username,
            password,
            tenant,
            otp,
            scope: Config.SCOPE
        },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )
        .then(async (response) => {

            if (ApiUtils.isRequestSuccessful(response.status)) {

                await storeLogin(response.data.data, tenant, code);
                SentryUtils.breadCrumb({
                    route: 'login',
                    data: response
                });
                return true;

            } else {

                Promise.reject(response);

            }


        })
        .catch(async (error) => {

            //check if 2fa error, add authuser anyway, without
            const data = error.response.data.data;
            if (data?.error == AuthFailureCodes.TWO_FACTOR_REQUIRED ||
                data?.error?.message == AuthFailureCodes.USER_TWO_FACTOR_REQUIRED
            ) {
                await storeLoginWithoutToken(username, tenant, code);
            }

            return { ...error.response.data.data, transformedTenant: tenant };

        });

}


const refreshTokens = async (token: string) => {

    const tenant = await ApiUtils.getTenant();

    return axios.post(
        Config.PROXY_URL + '/auth/refresh',
        {
            tenant,
            token
        },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )
        .then(async (response) => {

            if (ApiUtils.isRequestSuccessful(response.status)) {

                await storeLogin(response.data.data, tenant);
                SentryUtils.breadCrumb({
                    route: 'login_refresh',
                    data: response.data.data
                });
                return true;

            }

            Promise.reject();

        })
        .catch(() => false);
}

interface Data {
    access_token: string,
    refresh_token: string,
    expires_in: string,
}

const storeLogin = async (data: Data, tenant: string, tenantCode?: string) => {

    const users = await getUsers();
    const customerdata = await getAuthUserDataByAccessToken(data.access_token, tenant);
    users[customerdata.id] = new UserProfile().fromData(data, customerdata, tenant, tenantCode);
    delete users[customerdata.username];
       
    storeUsers(users);
    storeCurrentUserId(customerdata.id);

};

const storeLoginWithoutToken = async (username: string, tenant: string, tenantCode?: string) => {

    const users: UserProfile[] = await getUsers();
    const existing: string = Object.keys(users).filter((userId) => users[userId].username == username)[0];

    if (existing) {
        users[existing].logout();
        storeCurrentUserId(existing);
    } else {
        users[username] = new UserProfile().fromData({ username }, null, tenant, tenantCode);
        storeCurrentUserId(username);
    }

    storeUsers(users);

};

const getAuthUserDataByAccessToken = async (accessToken: string, tenant: string) => {

    const url = Config.API_URL.replace(Config.DEFAULT_TENANT, tenant);

    const response = await axios.get('/customer/customer?include=avatar_thumb',
        {
            baseURL: url,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            responseType: "json"
        });

    return response.data.data;
}

const getUsers = async () => {
    const profiles = JSON.parse((await StorageUtils.getStorageItem(LocalStorageKeys.USERS)) || '{}');
    for (let key in profiles) {
        profiles[key] = new UserProfile().fromJson(profiles[key]);
    }
    return profiles;
}

const storeUsers = async (users: any) => {
    await StorageUtils.setStorageItem(LocalStorageKeys.USERS, JSON.stringify(users));
}

const getCurrentUser = async () => {
    const users = await getUsers();
    const currentUserId = await getCurrentUserId();
    return users[currentUserId] || {};
}

const getCurrentUserId = async () => {
    return (await StorageUtils.getStorageItem(LocalStorageKeys.CURRENT_USER)) || '';
}

const storeCurrentUserId = async (id: string) => {
    await StorageUtils.setStorageItem(LocalStorageKeys.CURRENT_USER, id);
}

const removeCurrentUser = () => StorageUtils.removeStorageItem(LocalStorageKeys.CURRENT_USER);

const changePassword = async (current_password: string, new_password: string, new_password_confirmation: string) => {

    const tenantCode = await ApiUtils.getTenant();
    const client = await ApiUtils.getApiClient({ code: tenantCode });

    try {
        const response = await client.post(
            "/customer/auth/change/password",
            {
                current_password,
                new_password,
                new_password_confirmation
            }
        )
        return response;

    } catch (error) {

        return { data: { success: false } };

    }

}

const resetPassword = async (password: string, token: string) => {

    const client = await ApiUtils.getApiClient({});

    try {
        const response = await client.post(
            "/customer/auth/reset/password",
            {
                token,
                password
            }
        )
        return response.data;

    } catch (error) {

        return { data: { success: false } };

    }

}

const recoverPassword = async (username: string, code: string, tenant: string | boolean) => {

    if (!tenant) tenant = await ApiUtils.transformTenantCode(code);
    if (tenant == false) return false;

    const client = await ApiUtils.getApiClient({ code: tenant });

    try {
        await client.post(
            "/customer/auth/recover/password",
            {
                username,
                portal: 'customer_portal'
            }
        );
        return true;
    } catch (error) {
        return false;
    }

}

const requestTwoFactorMail = async (username: string, tenant: string) => {

    const client = await ApiUtils.getApiClient({ code: tenant });

    client.post(
        '/customer/auth/two_factor_code/email',
        {
            username,
            scope: Config.SCOPE
        },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );
}

export default {
    expiresInMs,
    storeLogin,
    getUsers,
    storeUsers,
    storeCurrentUserId,
    getCurrentUser,
    getCurrentUserId,
    removeCurrentUser,
    retrieveTokens,
    refreshTokens,
    changePassword,
    resetPassword,
    recoverPassword,
    requestTwoFactorMail
};
