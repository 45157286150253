import CountryFlag from "react-native-country-flag";
import { Pressable, View } from 'react-native';
import { colors } from "styles";
import { useContext } from "react";
import { StoreContext } from "providers/StoreProvider";
import { useHover } from "hooks";
import { useNavigation } from "@react-navigation/native";


export default () => {

    const navigation = useNavigation();
    const languages = [
        { lang: 'nl', iso: 'nl' },
        { lang: 'en', iso: 'gb' },
        { lang: 'de', iso: 'de' },
    ]
    const { language, setLanguage } = useContext(StoreContext);
    const changeLanguage = (lang) => {
        setLanguage(lang);
        setTimeout(()=>{setLanguage(lang)}, 200);
    }


    return (
        <View style={{ flexDirection: "row" }}>
            {languages.map((lang, index) => {
                const [hovered, hoverElt] = useHover();
                return (
                    <Pressable
                        key={index}
                        ref={hoverElt}
                        style={[
                            {
                                marginLeft: 8,
                                padding: 4,
                                borderColor: colors.border,
                                borderWidth: 1,

                            },
                            lang.lang == language && {
                                borderWidth: 2,
                                borderColor: colors.black
                            },
                            hovered && {
                                backgroundColor: '#d4d3d6'
                            }
                        ]}
                        onPress={() => changeLanguage(lang.lang)}
                    >
                        <CountryFlag isoCode={lang.iso} size={16} style={{}} />
                    </Pressable>
                )
            })}
        </View>
    )
}