import React, { ChangeEvent, useEffect, useState } from 'react';
import { NativeSyntheticEvent, StyleProp, StyleSheet, TextInput, TextInputFocusEventData, View } from 'react-native';
import { useStyle } from 'hooks';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { colors } from 'styles';
import TText from './TText';
import { PercentageUtils } from 'utils'; // Import your percentage utils

interface Props {
    label?: string;
    placeholder?: string;
    error?: string;
    value: number;
    onChange?: (txt: number | ChangeEvent<any>) => any;
    onBlur?: (e: string | ChangeEvent<any>) => void;
    onFocus?: ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void) | undefined;
    onKeyPress?: (e: any) => void;
    style?: StyleSheet.NamedStyles<any> | StyleProp<any>;
    disabled?: boolean;
    autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined;
    autoCompleteType?: 'username' | 'password' | 'code';
    keyboardType?: any;
}

const PercentageInput: React.FC<Props> = ({
    label,
    value,
    placeholder,
    error,
    onChange,
    onFocus,
    onBlur,
    onKeyPress,
    style,
    disabled,
    autoCapitalize,
    autoCompleteType,
    keyboardType = "numeric", // Default to numeric for percentages
}) => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const [displayValue, setDisplayValue] = useState('');

    useEffect(() => {
        setDisplayValue(PercentageUtils.formatPercentage(value));
    }, [value]);

    const handleBlur = (e: any) => {
        const unformattedValue = Number(PercentageUtils.unformatPercentage(displayValue) || 0);
        setDisplayValue(PercentageUtils.formatPercentage(unformattedValue));
        onChange && onChange(unformattedValue);
        onBlur && onBlur(e);
    };

    return (
        <View style={styles.flexStretch}>
            {label && (
                <TText sm semibold style={scopedStyles.label}>
                    {label}
                </TText>
            )}

            {disabled ? (
                <TText sm black style={[scopedStyles.input, scopedStyles.inputDisabled, styles.borderRadius10]}>
                    {displayValue}
                </TText>
            ) : (
                <View style={[styles.flexRow, styles.alignCenter]}>
                    <TextInput
                        placeholder={placeholder}
                        value={displayValue}
                        onChangeText={(newValue) => {
                            const textIsValid = !newValue.match(/[^0-9.,]/);
                            textIsValid && setDisplayValue(newValue);
                        }}
                        onBlur={handleBlur}
                        onFocus={onFocus}
                        onKeyPress={onKeyPress}
                        autoCapitalize={autoCapitalize}
                        spellCheck={false}
                        autoCompleteType={autoCompleteType}
                        keyboardType={keyboardType}
                        style={[
                            styles.textRegular,
                            styles.flexStretch,
                            styles.textSM,
                            styles.textPrimary,
                            styles.borderRadius10,
                            scopedStyles.input,
                            style,
                        ]}
                    />
                    <View style={[scopedStyles.iconRight, {backgroundColor: colors.primary2}]}>
                        <TText semibold>%</TText>
                    </View>
                </View>
            )}

            <TText regular sm error style={{ textAlign: 'right' }}>
                {error}
            </TText>
        </View>
    );
};

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        label: {
            marginBottom: 5,
        },
        input: {
            flex: 1,
            paddingVertical: 8,
            paddingHorizontal: 12,
            paddingRight: 50,
            backgroundColor: colors.background,
            borderRadius: 10,
        },
        inputDisabled: {
            backgroundColor: colors.disabled,
            paddingVertical: 8,
        },
        iconRight: {
            position: 'absolute',
            width: 40,
            height: '100%',
            right: 0,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.greyTransparent,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
        },
    },
    {
        [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
            input: {
                fontSize: 14,
                paddingVertical: 6,
            },
            inputDisabled: {
                paddingVertical: 6,
            },
        },
    }
);

export default PercentageInput;
