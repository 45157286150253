import { QueryKeys } from 'enums';
import { Damage, Mortgage, Note, Signal, Task } from "models";
import { StoreContext } from "providers/StoreProvider";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { QueryUtils } from "utils";

interface count {
    total: number;
    new: number;
}
interface state {
    inbox: count,
    tasks: count,
    damages: count,
    appointments: count,
    totalNew: number
}

export default () => {

    const { appointments_made } = useContext(StoreContext);
    const { data: tasks } = useQuery(QueryKeys.TASKS, QueryUtils.fetchTasks);
    const { data: signals } = useQuery(QueryKeys.SIGNALS, QueryUtils.fetchSignals);
    const { data: notes } = useQuery(QueryKeys.NOTES, QueryUtils.fetchNotes);
    const { data: damages } = useQuery(QueryKeys.DAMAGE, QueryUtils.fetchDamage);
    const { data: mortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);
    const { data: appointments } = useQuery(QueryKeys.APPOINTMENTS, QueryUtils.fetchOnlineAfsprakenAppointments);

    const [amountState, setAmountState] = useState<state>({
        inbox: { total: 0, new: 0 },
        tasks: { total: 0, new: 0 },
        damages: { total: 0, new: 0 },
        appointments: { total: 0, new: 0 },
        totalNew: 0
    });

    useEffect(() => {

        const newState = {
            inbox: { total: 0, new: 0 },
            tasks: { total: 0, new: 0 },
            damages: { total: 0, new: 0 },
            appointments: { total: 0, new: 0 },
            totalNew: 0
        };

        mortgages?.map((mortgage: Mortgage) => {
            if (mortgage.openProgress > 0) {
                newState.tasks.total++;
                if (!mortgage.seen_by_customer) newState.tasks.new++;
            }
        });

        damages?.map((damage: Damage) => {
            newState.damages.total++;
            const progressItems = damage.progress_items.reduce((acc, v) => (v.received) ? acc : acc + 1, 0);
            if (!damage.seen_by_customer && !damage.status && progressItems > 0) {
                newState.damages.new++;
            }
        });

        signals?.map((signal: Signal) => {
            newState.inbox.total++;
            if (!signal.seen_by_customer) {
                newState.inbox.new++;
            }
        });

        tasks?.unfinishedTasks?.map((task: Task) => {
            newState.inbox.total++;
            if (!task.seen_by_customer) {
                newState.inbox.new++;
            }
        })
        tasks?.finishedTasks?.map(() => {
            newState.inbox.total++;
        })

        // notes
        notes?.map((note: Note) => {
            newState.inbox.total++;
            if (!note.seen_by_customer) {
                newState.inbox.new++;
            }
        })

        //appointments
        newState.appointments.total = appointments?.length || 0;
        newState.appointments.new = appointments_made;

        newState.totalNew = newState.inbox.new + newState.tasks.new + newState.damages.new + newState.appointments.new;
        setAmountState(newState);

    }, [tasks, signals, notes, mortgages, damages, appointments])

    return amountState;
}