export enum PolicyTypes {
    VOERTUIG = 'Motorrijtuigen',
    REIS = 'Buitenland, reizen',
    ONGEVAL = 'Ongevallen en ziekte',
    AANSPRAKELIJKHEID = 'Aansprakelijkheid',
    BRAND = 'Brand',
    RECHTSBIJSTAND = 'Rechtsbijstand',
    LEVEN = 'Leven',
    SCHEEPVAART = 'Scheepvaart en transport',
    DIVERS = 'Diverse geldelijke verliezen',
    LUCHTVAART = 'Luchtvaart',
    KREDIET = 'Krediet en borgtocht',
    HYPOTHEKEN = 'Hypotheken',
    PAKKET = 'Pakket'
}