import { useStyle } from "hooks";
import { useEffect, useRef, useState } from "react";
import { ValutaUtils } from "utils";
import { colors } from 'styles'
import { PieChart } from 'react-native-svg-charts';
import * as scale from 'd3-scale'
import { Platform, StyleProp, View } from "react-native";
import TText from "./TText";
import { Obligation } from "models";

interface pieProps {
    style?: StyleProp<any>
    dark?: boolean
    opklik?: boolean
    obligations: Record<string, Obligation[]>
}

export default ({ style, dark, opklik, obligations }: pieProps) => {

    const styles = useStyle();
    const [data, setData] = useState({ data: [], totaal: 0 });
    const [selectedPart, setSelectedPart] = useState(null);

    //prepare pie data
    useEffect(() => {
        if (obligations) {

            const rColors = [
                scale.scaleLinear()
                    .domain([0, 100])
                    .range([colors.secondary, colors.white]),
                scale.scaleLinear()
                    .domain([0, 100])
                    .range([colors.grey, colors.black]),
                scale.scaleLinear()
                    .domain([0, 100])
                    .range([colors.secondary, 'rgb(100, 100, 100)']),
                scale.scaleLinear()
                    .domain([0, 100])
                    .range([colors.primary, colors.black]),
            ];
            let colorInd = 0;
            const getRandomColor = () => {
                const rColor = rColors[colorInd](Math.floor(Math.random() * 50));
                if (++colorInd >= rColors.length) colorInd = 0;
                return rColor;
            }

            const data = Object.entries(obligations).map((entry) => {
                const [title, children] = entry;
                const total = children.reduce((acc, child) => acc + child.monthly_amount, 0);
                return {
                    color: getRandomColor(),
                    value: total,
                    title: title
                }
            });

            const totaal = data.reduce((acc, val) => acc + val.value, 0);

            setData({ data, totaal })

        }
    }, [obligations])

    //compile pie data
    const money = (selectedPart === null) ? ValutaUtils.valutaString(data.totaal).split(',') : ValutaUtils.valutaString(data.data[selectedPart].value).split(',');
    const title = (selectedPart === null) ? '#total' : data.data[selectedPart].title;
    const euros = money[0];
    const cents = money[1];
    const pieData = data.data.map((pd, index) => ({
        key: `pie-${index}`,
        value: pd.value,
        svg: {
            fill: pd.color,
            onPress: () => { (opklik) && setSelectedPart(index === selectedPart ? null : index) },
        },
        arc: {
            innerRadius: selectedPart === index ? '75%' : '80%',
            outerRadius: selectedPart === index ? '99%' : '95%'
        }
    }))

    //add hover events to pie
    const hoverElt = useRef(null);
    if (opklik && hoverElt.current && Platform.OS === 'web') {
        setTimeout(() => {
            if (!hoverElt.current) return;
            const parts = hoverElt.current.querySelectorAll('path');
            parts.forEach((part, index) => {
                part.addEventListener('mouseenter', () => setSelectedPart(index))
                part.addEventListener('mouseleave', () => setSelectedPart(null))
            })
        }, 200)
    }

    return (
        <View ref={hoverElt} style={[styles.fullscreen, style]}>

            <View style={{ position: 'absolute', height: '100%', width: '100%', justifyContent: 'center' }}>

                <TText sm center style={{ color: colors["dark-grey"] }}>{title}</TText>

                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <TText white xl semibold style={[
                        dark && styles.textBlack
                    ]}>
                        {`${euros},`}
                    </TText>
                    <TText white md semibold style={[
                        { marginTop: 6 },
                        dark && styles.textBlack
                    ]}>
                        {cents}
                    </TText>
                </View>

                <TText white sm regular center style={[
                    dark && styles.textBlack
                ]}>
                    #per_month
                </TText>
            </View>

            <PieChart
                style={{ width: '100%', height: '100%' }}
                data={pieData}
                innerRadius={'85%'}
                outerRadius={'95%'}
                padAngle={0}
            />

        </View>
    )

}