import TText from "../../TText";
import {BaseDrawerModal, ConfirmModal} from "components/Modals";
import {useHover, useStyle} from "hooks";
import {View} from "react-native-animatable";
import Button from "components/Button";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {DateTimeFormat, QueryKeys} from "enums";
import {Icon} from "components";
import {colors} from "styles";
import {AdviesWidgetUtils, QueryUtils, ValutaUtils} from "utils";
import {Card, IconCard} from "components/Card";
import {ActivityIndicator, Pressable, Text} from "react-native";
import {useQuery} from "react-query";
import {Customer, Mortgage, Person} from "models";
import {useNavigation} from "@react-navigation/native";
import VerdictInformation from "./VerdictInformation";
import PrincipleInformation from "./PrincipleInformation";

const repaymentTypeApiKeysEnum = {
    1: 'annuity',
    2: 'linear',
    3: 'life_insurance',
    5: 'interest_only',
    9: 'interest_only',
    10: 'investment_mortgage',
    13: 'savings',
    50: 'bank_savings',
    51: 'bank_savings',
    52: 'interest_only',
    53: 'savings',
}
const scenarios = [
    {
        key: 'retirement',
        label: 'Gepensioneerd',
        icon: 'IconOld',
    },
    {
        key: 'death_partner',
        label: 'Overlijden',
        icon: 'IconGrave2',
    },
    {
        key: 'incapacitated',
        label: 'Arbeidsongeschiktheid',
        icon: 'IconDisabled',
        subScenarios: [
            {
                key: 'incapacitated',
                label: 'Arbeidsongeschiktheid',
            },
            {
                key: 'incapacitated-80-not-durable',
                label: 'Arbeidsongeschiktheid 80% niet duurzaam',
            },
        ],
    },
    {
        key: 'unemployment',
        label: 'Werkloosheid',
        icon: 'IconBriefcaseOff',
    },
    {
        key: 'break_up',
        label: 'Einde relatie',
        icon: 'IconHeartOff',
    },
];

const translateScenarioKey: Record<string, string> = {
    retirement: 'Gepensioneerd',
    death_partner: 'Overlijden',
    incapacitated: 'Arbeidsongeschiktheid',
    unemployment: 'Werkloosheid',
    break_up: 'Einde relatie',
};
const scenarioIcons: Record<string, string> = {
    incapacitated: 'IconDisabled',
    unemployment: 'IconBriefcaseOff',
    retirement: 'IconOld',
    death_partner: 'IconGrave2',
    break_up: 'IconHeartOff',
};
const scenarioEffectDescription = {
    incapacitated: 'Als je langdurig ziek wordt of arbeidsongeschikt raakt kan je',
    incapacitated_partner: 'Als je partner langdurig ziek wordt of arbeidsongeschikt raakt kan je',
    'incapacitated-80-not-durable': 'Als je langdurig ziek wordt of arbeidsongeschikt raakt kan je',
    'incapacitated-80-not-durable_partner': 'Als je partner langdurig ziek wordt of arbeidsongeschikt raakt kan je',
    unemployment: 'Als je werkloos raakt kan je',
    unemployment_partner: 'Als je partner werkloos raakt kan je',
    retirement: 'Als je met pensioen gaat kan je',
    retirement_partner: 'Als je partner met pensioen gaat kan je',
    death_partner: 'Als je partner overlijdt kan je',
    death_partner_partner: 'Als je overlijdt kan je partner',
    break_up: 'Als jullie relatie eindigt kan je',
    break_up_partner: 'Als jullie relatie eindigt kan je partner',
}

const possibilityDescription = {
    death_partner: 'Voor meer zekerheid kun je een ORV afsluiten of je huidige verhogen. Met een ORV kun je (een deel van) je hypotheekschuld aflossen als er sprake is van overlijden.',
    retirement: 'Voor meer zekerheid kun je een lijfrenteregeling aanvragen. Die zorgt ervoor dat je meer uitkering krijgt als je met pensioen gaat. We gaan er in de berekening van uit dat je (als je een partner hebt) in aanmerking komt voor het partnerpensioen. We houden geen rekening met eventuele kinderen of eerdere huwelijken. Deze hebben namelijk ook invloed op je verwachte inkomsten.',
    unemployment: 'Neem contact met ons op om te bespreken hoe je ook in het geval van werkloosheid niet voor verrassingen komt te staan.',
    incapacitated: 'Voor meer zekerheid kun je een arbeidsongeschiktheidverzekering afsluiten. Meer weten? Neem contact met ons op door op de knop hieronder te klikken.',
    'incapacitated-80-not-durable': 'Voor meer zekerheid kun je een arbeidsongeschiktheidverzekering afsluiten. Meer weten? Neem contact met ons op door op de knop hieronder te klikken.',
    break_up: 'Meer weten? Neem contact met ons op door op de knop hieronder te klikken.'
}

interface maximumMortgageType {
    monthly_payments: number,
    maximum_mortgage: number,
    interest_rate: number,
    total_gross_annual_income: number
}

interface scenarioResultType {
    maximum_mortgage: maximumMortgageType,
    maximum_mortgage_partner?: maximumMortgageType,
    scenario_description: string
}

export default ({settings}: { settings: Record<string, any> }) => {

    const styles = useStyle();
    const navigation = useNavigation();
    const [selectedScenario, setSelectedScenario] = useState<number | undefined>(undefined);
    const [selectedSubScenarioKey, setSelectedSubScenarioKey] = useState<string | undefined>(undefined);
    const [result, setResult] = useState<Record<string, scenarioResultType>>();
    const [submitting, setSubmitting] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(undefined);
    const [showPrincipleInfo, setShowPrincipleInfo] = useState(false);

    const {data: customer} = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const {data: family} = useQuery(QueryKeys.FAMILY, QueryUtils.fetchFamily);
    const {data: mortgages} = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);
    const {data: living} = useQuery(QueryKeys.LIVING, QueryUtils.fetchLiving);

    const hoverBank: any[] = useHover(Object.keys(translateScenarioKey).length);

    useEffect(() => {

        if (customer && living && !result) {
            setResult({loading: true});
            fetchResults();
        }

    }, [customer, living])

    useEffect(() => {
        if (selectedScenario !== undefined) {
            const selectedScenarioObj = scenarios[selectedScenario];
            if (selectedScenarioObj.subScenarios) {
                setSelectedSubScenarioKey(selectedScenarioObj.subScenarios[0].key);
            } else {
                setSelectedSubScenarioKey(undefined);
            }
        }
    }, [selectedScenario]);

    const collectData = (step) => {
        const Customer: Customer = customer;

        const data = {
            step,
            applicant: {
                date_of_birth: moment(Customer.date_of_birth).format(DateTimeFormat.SERVER_DATE),
                income: {
                    annual_employment_income: Customer.gross_annual_income,
                    state_pension_income: Customer.gross_annual_old_age_pension_amount,
                    old_age_pension_income: Customer.gross_annual_employment_pension_amount,
                    survivor_pension_income: Customer.gross_annual_partner_pension_amount
                },
                obligations: {
                    has_student_debt: false,
                    has_lease_loan: false,
                    has_private_lease: false,
                    has_loans: false,
                    has_partner_alimony: false,
                }
            },
            energy_label: living.property.energy_label.toLowerCase().replace(/\+/g, '_plus'),
            property_tax_value: living.property.woz_value_home,
            loan_parts: [],
            partner: {}
        };

        const matchedMortgage: Mortgage = mortgages?.find((mortgage: Mortgage) => {
            return mortgage.properties.find((property => {
                return property.postal_code === living.property.postcode &&
                    property.number === String(living.property.house_number) &&
                    (property.number_addition || property.number_letter) === String(living.property.house_number_addition);
            })) !== undefined;
        }) || mortgages[0];

        if (matchedMortgage) {
            data.loan_parts = matchedMortgage.mortgageParts.map(part => ({
                repayment_type: repaymentTypeApiKeysEnum[part.repayment_type],
                first_deposit_amount: part.first_deposit_amount,
                current_amount: Math.floor(Number(part.remaining_debt)),
                premium: part.premium,
                interest_rate: part.interest,
                return_rate: part.return_rate,
                duration_in_months: Math.max(0, part.remaining_months_duration),
                deductible_period_in_months: 0,
                remaining_interest_period_in_months: Math.max(0, part.remaining_months_fixed_interest_duration),
            }));
        }

        if (family?.partner) {
            const Partner: Person = family.partner;
            data.partner = {
                date_of_birth: moment(Partner.date_of_birth).format(DateTimeFormat.SERVER_DATE),
                income: {
                    annual_employment_income: Partner.gross_annual_income,
                    state_pension_income: Partner.gross_annual_old_age_pension_amount,
                    old_age_pension_income: Partner.gross_annual_employment_pension_amount,
                    survivor_pension_income: Partner.gross_annual_partner_pension_amount
                },
                obligations: {
                    has_student_debt: false,
                    has_lease_loan: false,
                    has_private_lease: false,
                    has_loans: false,
                    has_partner_alimony: false,
                }
            };
        }

        if (Person && moment(Customer.date_of_birth).isBefore(Person.date_of_birth)) {
            scenarioEffectDescription['retirement'] = 'Als jullie met pensioen zijn kunnen jullie';
        } else if (Person && moment(Customer.date_of_birth).isAfter(Person.date_of_birth)) {
            scenarioEffectDescription['retirement_partner'] = 'Als jullie met pensioen zijn kunnen jullie';
        }

        if (step === 'thanks') {
            data.lead = {
                first_name: Customer.first_name,
                insertion: Customer.suffix,
                last_name: Customer.last_name,
                phone_number: Customer.phone,
                email: Customer.email_address,
                subscribe_to_mailing: false,
                i_give_permission: true
            }
        }

        return data;
    }

    const fetchResults = () => {
        const data = collectData('scenarios');
        AdviesWidgetUtils.getScenarios(data)
            .then((response) => setResult(response.data.result))
            .catch(() => setResult('error'));
    }

    const submitResults = () => {
        setSubmitting(true);
        const data = collectData('thanks');
        AdviesWidgetUtils.getScenarios(data)
            .then(() => {
                setShowConfirmModal(true);
                setSubmitting(false);
            })
            .catch(() => {
                setShowConfirmModal(false);
                setSubmitting(false);
            });
    }


    const renderResults = () => {
        return (
            <View style={[styles.flexCol, styles.gap20]}>

                <TText md secondary semibold>
                    {Person ? (
                        'Kunnen wij hier blijven wonen?'
                    ) : (
                        'Kan ik blijven wonen?'
                        )}
                </TText>
                <TText>Er kan veel in je leven gebeuren, en veranderingen kunnen gevolgen hebben voor je hypotheek en financiële situatie, wat erg vervelend kan zijn. Wij tonen hier of je in bepaalde scenario’s in je huis kunt blijven wonen.</TText>
                <View>
                    <TText>Let op: in onze berekeningen houden we momenteel alleen rekening met de wettelijke kaders.
                        Heb je zelf aanvullende regelingen getroffen, zoals een overlijdensrisicoverzekering of arbeidsongeschiktheidsverzekering?
                        Dan kan je situatie afwijken van wat hieronder wordt getoond.
                        Bekijk hier de </TText>
                    <Pressable onPress={() => setShowPrincipleInfo(true)}>
                        <TText url secondary>uitgangspunten berekeningen</TText>
                    </Pressable>
                </View>

                {result && result === 'error' ? (
                    <View style={[styles.flexRow, styles.justifyCenter, styles.alignCenter, styles.gap20]}>
                        <IconCard
                            iconName="IconExclamationCircle"
                            iconColor={colors.white}
                            iconSize={25}
                            style={{
                                backgroundColor: colors.error,
                                width: 40,
                                height: 40,
                            }}
                        />
                        <View>
                            <TText md error>Er ging iets mis met het ophalen van de resultaten. </TText>
                            <TText>Controleer of je gegevens correct ingevult zijn.</TText>
                        </View>
                    </View>
                ) : result && !result.loading ? (
                    <View style={[styles.flexCol, styles.gap20]}>
                        {renderItems()}
                        {renderSelectedScenario()}
                    </View>
                ) : (
                    <View style={[styles.alignCenter, styles.justifyCenter]}>
                        <ActivityIndicator color={colors.primary} size={20}/>
                    </View>
                )}
            </View>
        );
    }

    const renderItems = () => {
        return (
            <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                {scenarios.map((scenario, scenarioIndex) => {
                    let hasData = false;

                    if (result[scenario.key]) {
                        hasData = true;
                    } else if (scenario.subScenarios) {
                        hasData = scenario.subScenarios.some(subScenario => result[subScenario.key]);
                    }

                    if (!hasData) {
                        return null;
                    }

                    let verdict = null;
                    if (result[scenario.key]) {
                        verdict = result[scenario.key]?.applicant.verdict;
                    } else if (scenario.subScenarios) {
                        for (const subScenario of scenario.subScenarios) {
                            if (result[subScenario.key]) {
                                verdict = result[subScenario.key]?.applicant.verdict;
                                break;
                            }
                        }
                    }

                    const [hovered, hoverElt] = hoverBank[scenarioIndex];
                    return (
                        <Pressable
                            ref={hoverElt}
                            style={[styles.flexStretch, styles.alignCenter]}
                            onPress={() => setSelectedScenario(selectedScenario === scenarioIndex ? undefined : scenarioIndex)}
                        >
                            <Card
                                style={{
                                    width: '100%',
                                    maxWidth: 200,
                                    flex: 1,
                                    backgroundColor: colors.background,
                                    padding: 10,
                                    gap: 20,
                                    transform: hovered ? 'scale(1.02)' : ''
                                }}
                            >

                                <Card style={[styles.alignCenter, styles.justifyCenter, {backgroundColor: colors.white}]}>
                                    <Icon iconName={scenario.icon} iconColor={colors.secondary} iconSize={40}/>
                                </Card>
                                <TText semibold center style={{
                                    width: '100%',
                                    paddingBottom: 20
                                }}>{scenario.label}</TText>
                                {verdict && (
                                    <IconCard
                                        iconName={verdict.livable ? 'IconCheck' : 'IconClose'}
                                        iconColor={colors.white}
                                        iconSize={20}
                                        style={{
                                            backgroundColor: verdict.livable ? colors.approval : colors.error,
                                            position: 'absolute',
                                            left: '50%',
                                            bottom: -15,
                                            transform: 'translateX(-50%)',
                                        }}
                                    />
                                )}
                                {(selectedScenario === scenarioIndex) &&
                                    <View
                                        style={{
                                            backgroundColor: colors.background,
                                            position: 'absolute',
                                            width: 20,
                                            height: 20,
                                            left: '50%',
                                            bottom: -60,
                                            transform: `translateX(-50%) rotateZ(45deg)`
                                        }}
                                    />
                                }
                            </Card>
                        </Pressable>
                    );
                })
                }
            </View>
        );
    }
    const renderSelectedScenario = () => {
        if (selectedScenario === undefined) return null;

        const selectedScenarioObj = scenarios[selectedScenario];
        let scenarioKey = selectedSubScenarioKey || selectedScenarioObj.key;

        const scenarioData = result[scenarioKey];
        if (!scenarioData) return null;

        const scenarioDescription = scenarioData.description;
        const verdict = scenarioData.applicant;
        const verdictPartner = scenarioData.partner;

        return (
            <View>
                {/* Render sub-scenario buttons if available */}
                <Card style={[{marginTop: 30, backgroundColor: colors.background, minHeight: 300}]}>
                    {selectedScenarioObj.subScenarios && (
                        <View style={[styles.flexRow, styles.gap10, {marginBottom: 30}]}>
                            {selectedScenarioObj.subScenarios.map(subScenario => (
                                <Pressable
                                    disabled={selectedSubScenarioKey === subScenario.key}
                                    onPress={() => setSelectedSubScenarioKey(subScenario.key)}
                                >
                                    <TText
                                        semibold={selectedSubScenarioKey === subScenario.key}
                                        style={[
                                            styles.p10,
                                            styles.borderRadius5,
                                            {
                                                backgroundColor: selectedSubScenarioKey === subScenario.key ? colors.secondary2 : colors.transparent,
                                                color: selectedSubScenarioKey === subScenario.key ? colors.secondary : colors.primary,
                                            }
                                        ]}
                                    >
                                        {subScenario.label}
                                    </TText>
                                </Pressable>
                            ))}
                        </View>
                    )}
                    <TText semibold>{scenarioDescription}</TText>

                    {/* Applicant's Verdict */}
                    <View style={[styles.flexCol, styles.gap20, styles.p20]}>
                        <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                            <IconCard
                                iconName={verdict.verdict.livable ? 'IconCheck' : 'IconClose'}
                                iconColor={colors.white}
                                iconSize={20}
                                style={{backgroundColor: verdict.verdict.livable ? colors.approval : colors.error}}
                            />
                            <Text>
                                <TText>
                                    {scenarioEffectDescription[`${scenarioKey}`]}
                                </TText>
                                <TText
                                    semibold
                                    style={{color: verdict.verdict.livable ? colors.approval : colors.error}}
                                >
                                    {` waarschijnlijk ${verdict.verdict.livable ? 'wel' : 'niet'} blijven wonen`}
                                </TText>
                            </Text>
                        </View>
                        <View style={[styles.flexCol, styles.gap20, {marginLeft: 52}]}>
                            <Text>
                                {scenarioKey === 'retirement' ? (
                                    <TText>
                                        {`Op ${moment(verdict.mortgage_situation.date).format(DateTimeFormat.DISPLAY_DATE)} is je netto maandinkomen `}
                                    </TText>
                                ) : (
                                    <TText>
                                        {`Wanneer dit gebeurt is je netto maandinkomen `}
                                    </TText>
                                )}
                                <TText semibold>
                                    {ValutaUtils.valutaString(verdict.income.total_net_monthly_income)}{' '}
                                </TText>
                                <TText>. Dit is </TText>
                                <TText semibold error>
                                    {ValutaUtils.valutaString(Math.abs(verdict.income.difference_net_monthly_income))}
                                </TText>
                                <TText>
                                    {verdict.income.difference_net_monthly_income < 0 ? ' minder' : ' meer'} dan op dit
                                    moment.
                                </TText>
                            </Text>
                            <Card style={[styles.flexCol, styles.gap10, styles.pv10]}>
                                <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                                    <IconCard
                                        iconName={verdict.verdict.can_pay_mortgage ? 'IconCheck' : 'IconClose'}
                                        iconColor={colors.white}
                                        iconSize={15}
                                        style={{backgroundColor: verdict.verdict.can_pay_mortgage ? colors.approval : colors.error}}
                                    />
                                    <TText>
                                        {Person? 'Jullie kunnen dan de  hypotheek':'Je kunt dan de hypotheek '}{` ${verdict.verdict.can_pay_mortgage ? 'nog wel' : 'niet meer'} betalen`}
                                    </TText>
                                    <VerdictInformation
                                        person={verdict}
                                        type={'can_pay_mortgage'}
                                        scenarioKey={scenarioKey}
                                    />
                                </View>
                                <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                                    <IconCard
                                        iconName={verdict.verdict.can_prolong_mortgage ? 'IconCheck' : 'IconClose'}
                                        iconColor={colors.white}
                                        iconSize={15}
                                        style={{backgroundColor: verdict.verdict.can_prolong_mortgage ? colors.approval : colors.error}}
                                    />
                                    <TText>
                                        {Person? 'Jullie kunnen dan de  hypotheek':'Je kunt dan de hypotheek '}{`${verdict.verdict.can_prolong_mortgage ? 'nog wel' : 'niet meer'} verlengen`}
                                    </TText>
                                    <VerdictInformation
                                        person={verdict}
                                        type={'can_prolong_mortgage'}
                                        scenarioKey={scenarioKey}
                                    />
                                </View>
                            </Card>
                            <TText xs>
                                Let op: er is geen rekening gehouden met een eventuele bestaande
                                arbeidsongeschiktheidsverzekering of woonlastenverzekering. Wij adviseren je om de
                                hoogte van je arbeidsongeschiktheidsverzekering te bekijken.
                            </TText>
                        </View>
                    </View>

                    {/* Partner's Verdict (if available) */}
                    {verdictPartner && (
                        <View style={[styles.flexCol, styles.gap20, styles.p20]}>
                            <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                                <IconCard
                                    iconName={verdictPartner.verdict.livable ? 'IconCheck' : 'IconClose'}
                                    iconColor={colors.white}
                                    iconSize={20}
                                    style={{backgroundColor: verdictPartner.verdict.livable ? colors.approval : colors.error}}
                                />
                                <Text>
                                    <TText>
                                        {scenarioEffectDescription[`${scenarioKey}_partner`]}
                                    </TText>
                                    <TText
                                        semibold
                                        style={{color: verdictPartner.verdict.livable ? colors.approval : colors.error}}
                                    >
                                        {` waarschijnlijk ${verdictPartner.verdict.livable ? 'wel' : 'niet'} blijven wonen`}
                                    </TText>
                                </Text>
                            </View>
                            <View style={[styles.flexCol, styles.gap20, {marginLeft: 52}]}>
                                <Text>
                                    {scenarioKey === 'retirement' ? (
                                        <TText>
                                            {`Op ${moment(verdictPartner.mortgage_situation.date).format(DateTimeFormat.DISPLAY_DATE)} is netto maandinkomen van je partner`}
                                        </TText>
                                    ) : (
                                        <TText>
                                            {`Wanneer dit gebeurt is het netto maandinkomen van je partner `}
                                        </TText>
                                    )}
                                    <TText semibold>
                                        {ValutaUtils.valutaString(verdictPartner.income.total_net_monthly_income)}{' '}
                                    </TText>
                                    <TText>dit is </TText>
                                    <TText semibold error>
                                        {ValutaUtils.valutaString(Math.abs(verdictPartner.income.difference_net_monthly_income))}
                                    </TText>
                                    <TText>
                                        {verdictPartner.income.difference_net_monthly_income < 0 ? ' minder' : ' meer'} dan
                                        op dit moment
                                    </TText>
                                </Text>
                                <Card style={[styles.flexCol, styles.gap10, styles.pv10]}>
                                    <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                                        <IconCard
                                            iconName={verdictPartner.verdict.can_pay_mortgage ? 'IconCheck' : 'IconClose'}
                                            iconColor={colors.white}
                                            iconSize={15}
                                            style={{backgroundColor: verdictPartner.verdict.can_pay_mortgage ? colors.approval : colors.error}}
                                        />
                                        <TText>
                                            {`Jullie kunnen dan de hypotheek ${verdictPartner.verdict.can_pay_mortgage ? 'nog wel' : 'niet meer'} betalen`}
                                        </TText>
                                        <VerdictInformation
                                            person={verdictPartner}
                                            type={'can_pay_mortgage'}
                                            scenarioKey={scenarioKey}
                                        />
                                    </View>
                                    <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                                        <IconCard
                                            iconName={verdictPartner.verdict.can_prolong_mortgage ? 'IconCheck' : 'IconClose'}
                                            iconColor={colors.white}
                                            iconSize={15}
                                            style={{backgroundColor: verdictPartner.verdict.can_prolong_mortgage ? colors.approval : colors.error}}
                                        />
                                        <TText>
                                            {`Je partner kan dan de hypotheek ${verdictPartner.verdict.can_prolong_mortgage ? 'nog wel' : 'niet meer'} verlengen`}
                                        </TText>
                                        <VerdictInformation
                                            person={verdictPartner}
                                            type={'can_prolong_mortgage'}
                                            scenarioKey={scenarioKey}
                                        />
                                    </View>
                                </Card>
                                <TText xs>
                                    Let op: er is geen rekening gehouden met een eventuele bestaande
                                    arbeidsongeschiktheidsverzekering of woonlastenverzekering. Wij adviseren je om de
                                    hoogte van je arbeidsongeschiktheidsverzekering te bekijken.
                                </TText>
                            </View>
                        </View>
                    )}

                    {/* Possibility Description */}
                    <View style={[styles.flexCol, styles.gap10]}>
                        <TText md semibold>Wat kan ik er aan doen?</TText>
                        <TText>{possibilityDescription[scenarioKey]}</TText>
                        <Button
                            loading={submitting}
                            label="Stuur deze gegevens op naar je adviseur"
                            type="secondary"
                            onPress={submitResults}
                        />
                    </View>
                </Card>
            </View>
        );
    };


    return (
        <BaseDrawerModal
            drawerWidth={1200}
            visible={true}
            onClose={settings.hideModal}
            header={'Kan ik blijven wonen?'}
            drawerStyle={{gap: 20, paddingTop: 10}}
        >
            <Card style={[styles.flexRow, styles.alignCenter, styles.justifyBetween, styles.p20, {backgroundColor: colors.primary}]}>
                <View>
                    <TText white>De volgende scenario’s zijn berekend met de gegevens zoals deze bij ons bekend
                        zijn.</TText>
                    <TText white>Zorg voor de meest actuele gegevens voor het beste advies!</TText>
                </View>
                <Button
                    label="Gegevens bijwerken"
                    type="secondary"
                    onPress={() => {
                        navigation.navigate('editInfo');
                        settings.hideModal();
                    }}
                />
            </Card>

            <Card style={[
                styles.gap10,
                {backgroundColor: colors.white, borderWidth: 1, borderColor: colors.border}
            ]}>
                {renderResults()}
            </Card>

            <Card style={{backgroundColor: colors.white, borderWidth: 1, borderColor: colors.border, gap: 10}}>
                <TText md secondary semibold>Wat kan ik doen?</TText>
                <View>
                    <TText semibold>Extra aflossen</TText>
                    <TText>Heb je spaargeld dat je voor je hypotheek kunt gebruiken? Je kunt extra aflossen om je
                        hypotheekschuld omlaag te brengen. Aflossen kan zowel eenmalig als periodiek (elke maand of
                        jaar). Dit verhoogt de kans dat je later je hypotheek kunt blijven betalen.</TText>
                </View>
                <View>
                    <TText semibold>Hypotheekrente afkopen</TText>
                    <TText>Is de actuele rente lager dan de rente die u nu betaalt? U kunt de oude hypotheekrente
                        tussentijds aanpassen en overstappen naar een lagere rente.</TText>
                </View>
                <View>
                    <TText semibold>Extra sparen</TText>
                    <TText>Wilt u een extra reserve opbouwen om uw hypotheek (mogelijk eerder) terug te betalen? Dan kan
                        sparen een optie voor u zijn.</TText>
                </View>
            </Card>

            <PrincipleInformation visible={showPrincipleInfo} onClose={() => setShowPrincipleInfo(false)}/>

            {showConfirmModal !== undefined &&
                <ConfirmModal settings={{
                    title: 'Kan ik blijven wonen?',
                    text: showConfirmModal ? 'Het resultaat is succesvol verstuurd aan je adviseur.' : 'Er ging iets mis tijdens het versturen van je resultaat',
                    buttons: [
                        <Button label="#close" type="primary" onPress={() => setShowConfirmModal(undefined)}/>
                    ]
                }}/>
            }

        </BaseDrawerModal>
    );
}
