import { useLayoutMargin } from "hooks";
import { useEffect, useState } from "react";
import { Dimensions } from "react-native";

export default () => {

    const [itemSize, setItemSize] = useState(0);
    const calcMargin = (width: number) => {
        let size = 50;
        if (width <= 768) {
            size = 22;
        } else if (width <= 997) {
            size = 28;
        } else if (width <= 1200) {
            size = 38;
        }
        return size;
    }

    const handleWindowResize = (e) => {
        const width = e.window.width;
        const margin = calcMargin(width);

        let size = width - (margin * 2);
        if (width > 1500) {
            size = ((width - (margin * 6)) / 5);
        } else if (width > 768) {
            size = ((width - (margin * 4)) / 3);
        }

        setItemSize(size - 4); //small amount less, so it won't wrap
    }

    useEffect(() => {

        Dimensions.addEventListener('change', handleWindowResize);
        handleWindowResize({ window: { width: Dimensions.get('window').width } });

    }, [])


    return itemSize;
}