
export class Advisor {
    id: string;
    email: string
    first_name: string;
    full_name: string;
    last_name: string;
    phone: string;
    avatar?: string;

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Advisor {
        this.id = json.id ? json.id : "";
        this.email = json.email ? json.email : "";
        this.first_name = json.first_name ? json.first_name : "";
        this.full_name = json.full_name ? json.full_name : "";
        this.last_name = json.last_name ? json.last_name : "";
        this.phone = json.phone ? json.phone : "";
        this.avatar = json?.avatar?.data ? json.avatar.data.data : "";
        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId()
        };
    }

    getId(): string {
        return this.id;
    }
}
