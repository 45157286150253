import { PlatformWebview } from "components";
import { useStyle } from "hooks";
import { View } from "react-native";

import adviesWidgetHTML from 'assets/html/advies_widgets';
import { colors } from "styles";

interface widgetProps{
    state: string
    widgetName: string,
    widgetUrl: string
}

export default ({state, widgetUrl, widgetName}: widgetProps) => {

    const styles = useStyle();

    return (
        <View style={styles.fullscreen}>
            {(state && widgetName && widgetUrl) && 
                <PlatformWebview
                    html={adviesWidgetHTML}
                    style={[styles.fullscreen, styles.borderRadius10]}
                    replaceVars={[
                        ['WIDGET_STATE', state],
                        ['WIDGET_NAME', widgetName],
                        ['WIDGET_URL', widgetUrl],
                        ['PRIMARY_COLOR8', colors.primary8],
                        ['PRIMARY_COLOR2', colors.primary2],
                        ['PRIMARY_COLOR', colors.primary],
                        ['SECONDARY_COLOR8', colors.secondary8],
                        ['SECONDARY_COLOR2', colors.secondary2],
                        ['SECONDARY_COLOR', colors.secondary],
                    ]}
                />            
            }
        </View>
    )

}
