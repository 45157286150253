import { useStyle, useTranslation } from "hooks"
import { Dimensions, View } from "react-native"
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles"
import { colors } from "styles"
import { Calendar, Button } from 'components';
import BaseModal from './BaseModal';
import moment from "moment";

export default ({ hideModal, handleDateSelect, initialDate, preventFutureDate = false, preventPastDate = false }) => {

    const scopedStyles = scopedResponsiveStyles();
    const translate = useTranslation();

    const weekdays = [
        translate('#monday_short'),
        translate('#tuesday_short'),
        translate('#wednesday_short'),
        translate('#thursday_short'),
        translate('#friday_short'),
        translate('#saturday_short'),
        translate('#sunday_short'),
    ];
    const months = moment.months();

    return (
        <BaseModal visible={true} style={scopedStyles.modal} hideModal={hideModal}>
            
            <Calendar
                style={scopedStyles.calendar}
                calendarProps={{        
                    weekdays, 
                    months,
                    nextTitle: translate('#next') + ' >',
                    previousTitle: '< ' + translate('#previous'),
                    startFromMonday: true,
                    selectedDayColor: colors.primary,
                    selectedDayTextColor: colors.white,
                    textStyle: {
                        fontFamily: 'Poppins_400Regular'
                    },
                    headerWrapperStyle: scopedStyles.calendarHeader,
                    showDayStragglers: false,
                    selectedStartDate: initialDate,
                    initialDate: initialDate,
                    maxDate: preventFutureDate ? moment() : undefined,
                    minDate: preventPastDate ? moment() : undefined

                }}
                onDateChange={(date) => handleDateSelect(date)}
            />
            <View style={{ flexDirection: "row", justifyContent: "flex-end", marginBottom: 10, marginRight: 20 }}>
                <Button
                    label="#action_date"
                    type="action"
                    style={scopedStyles.button}
                    onPress={hideModal}
                />
            </View>
            
        </BaseModal>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            flex: 1,
            width: '25%',
            minWidth: 250,
            minHeight: 0,
            marginLeft: 0,
            padding: 20,
            marginVertical: 150,
            backgroundColor: colors.background,
            borderRadius: 8,
            gap: 10,
            overflow: 'visible',
            zIndex: 999
        },
        calendar: {
            width: '100%'
        },
        calendarHeader: {
            width: '95%'
        },
        button: {}
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                width: '90%',
                marginHorizontal: 12,
                marginVertical: 30,
                paddingLeft: 0,
                minHeight: Dimensions.get('window').height * 0.75,
            },
            calendar: {
                marginTop: '30%',
                width: '100%',
                flex: 1
            },
            calendarHeader: {
                width: '100%'
            },
            button: {
                width: '60%',
                marginLeft: '40%'
            },
        }
    }
)