import {useStyle, useTranslation} from "hooks";
import {useState} from "react";
import {Pressable, ScrollView, TextInput, useWindowDimensions, View} from "react-native";
import {CreateResponsiveStyle, DEVICE_SIZES, maxSize} from 'rn-responsive-styles';
import {colors} from "styles";
import TText from "./TText";

interface props {
    handleSearchQuery?: (e: any) => void,
    handleNavItemSelect?: (e: any) => void,
    navItems?: any[],
    noSearch?: boolean
}

export default ({handleSearchQuery, navItems = [], handleNavItemSelect, noSearch}: props) => {

    const {width} = useWindowDimensions();
    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const searchString = useTranslation()("#search");
    const [selectedNav, setSelectedNav] = useState(0);
    const changeNavItem = (item, index) => {
        setSelectedNav(index);
        handleNavItemSelect && handleNavItemSelect(item);
    }

    return (
        <View style={[scopedStyles.bottomNav]}>

            {navItems && navItems.length > 0 &&
                <ScrollView
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    style={scopedStyles.navContainer}
                    contentContainerStyle={{flexDirection: 'row', overflow: 'visible'}}
                >
                    {navItems.map((item, index) => {
                        return (
                            <Pressable
                                disabled={navItems.length == 1 || item.amount == 0}
                                key={index}
                                onPress={() => changeNavItem(item, index)}
                                style={{
                                    height: '100%',
                                    alignItems: 'center',
                                    marginRight: 10,
                                }}
                            >
                                <View style={[index == selectedNav && {
                                    fontWeight: 'bold',
                                    backgroundColor: colors.secondary2
                                }, scopedStyles.navItem
                                ]}>
                                    <TText
                                        secondary
                                        sm
                                        style={[
                                            index !== selectedNav && {color: colors.primary},
                                            item.amount == 0 && {color: colors.darkgrey},
                                            index == selectedNav && {fontWeight: 'bold'},
                                            {textTransform: "capitalize"}
                                        ]}
                                    >
                                        {item.title}
                                    </TText>

                                    {item.amount > 0 &&
                                        <View style={[
                                            scopedStyles.badge,
                                            index !== selectedNav && {backgroundColor: colors.background},
                                            {marginLeft: 5},
                                        ]}>
                                            <TText white regular style={[
                                                index !== selectedNav && {color: colors.primary},
                                                {fontSize: 10}
                                            ]}>
                                                {item.amount}
                                            </TText>
                                        </View>
                                    }
                                </View>

                            </Pressable>
                        )
                    })}
                </ScrollView>
            }

            {!noSearch &&
                <View style={[
                    scopedStyles.searchBox,
                    width < 768 && navItems.length == 0 && {marginBottom: 8}
                ]}>
                    <TextInput
                        placeholder={searchString}
                        placeholderTextColor={colors.lightgrey}
                        onChangeText={handleSearchQuery && handleSearchQuery}
                        spellCheck={false}
                        style={[
                            styles.textSemiBold,
                            styles.textSM,
                            styles.borderRadius10,
                            scopedStyles.searchInput,
                            {
                                backgroundColor: colors.whiteTransparent,
                                color: colors.lightgrey,
                            },
                        ]}
                    />
                    <View style={scopedStyles.searchIcon}>
                    </View>
                </View>
            }

        </View>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        bottomNav: {
            width: '100%',
            height: 60,
            zIndex: 10,
            flexDirection: 'row',
            justifyContent: "space-between",
            alignItems: 'center',
        },
        navContainer: {
            flexDirection: 'row',
            height: '100%'
        },
        searchBox: {
            height: '100%',
            width: 350,
            justifyContent: "center",
        },
        searchInput: {
            width: '100%',
            height: 35,
            paddingVertical: 8,
            paddingLeft: 8,
            paddingRight: 30,
            borderWidth: 1,
            borderColor: '#0000'
        },
        searchIcon: {
            position: 'absolute',
            right: 8,
            flex: 1,
            justifyContent: 'center'
        },
        badge: {
            paddingHorizontal: 4,
            backgroundColor: colors.secondary,
            textAlign: 'center',
            borderRadius: 12,
        },
        navItem: {
            borderRadius: 10,
            padding: 10,
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center'
        }
    },
    {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            navContainer: {
                width: '100%',
                height: 45
            },
            searchBox: {
                width: 250,
                height: 40
            }
        }
    }
);