import axios from 'axios';
import Constants from "expo-constants";
import * as Linking from 'expo-linking';
import { StoreContext } from 'providers/StoreProvider';
import { useContext, useEffect } from 'react';
import { Alert, Platform } from 'react-native';
import UseTranslation from './UseTranslation';

export default () => {

    const translate = UseTranslation();
    const {loggedIn} = useContext(StoreContext);

    const gotoStore = Platform.select({
        ios: ()=>{
            Linking.openURL('https://apps.apple.com/us/app/klantportaal/id1634156223');
        },
        default: () =>{
            Linking.openURL('https://play.google.com/store/apps/details?id=com.dossierdata.app');
        }
    })

    const getVersion = (response: string) => {
        const scriptRegex = />AF_initDataCallback[\s\S]*?<\/script/g;
        const keyRegex = /(ds:.*?)'/;
        const valueRegex = /data:([\s\S]*?), sideChannel: {}}\);<\//;    
        const matches = response.match(scriptRegex);    
        if (!matches) return null;
        
        const parsedData = matches.reduce((accum, data) => {
            const keyMatch = data.match(keyRegex);
            const valueMatch = data.match(valueRegex);    
            if (keyMatch && valueMatch) {
            const key = keyMatch[1];
            const value = JSON.parse(valueMatch[1]);
            return {...accum, [key]: value};
            }
            return accum;
        }, {});
        //@ts-ignore-next-line
        return parsedData['ds:5'][1][2][140][0][0][0];
    }

    const checkVersion = (version: string) =>{
        if ( version && version != Constants?.expoConfig?.version){
            Alert.alert(
                'DossierData klantportaal',
                translate('#install_update'),
                [
                    {
                        text: translate("#no"),
                        style: "cancel",
                        onPress: () => {
                            return;
                        }
                    },
                    {
                        text: translate("#yes"),
                        onPress: () => gotoStore(),
                    },
                ]
            );
        }
    }

    useEffect(()=>{
        if ( !loggedIn || Platform.OS === 'web' ) return;
        setTimeout(() => {
            axios.get('https://play.google.com/store/apps/details?id=com.dossierdata.app')
            .then(({data})=> checkVersion( getVersion(data) ) );
        }, 3500);
    }, [loggedIn])


}