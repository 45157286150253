export const RepaymentTypeAPIMappingEnum = Object.freeze({
    1: 'annuity',
    2: 'linear',
    3: 'life_insurance',
    5: 'interest_only',
    9: 'interest_only',
    10: 'investment_mortgage',
    13: 'savings',
    50: 'bank_savings',
    51: 'bank_savings',
    52: 'interest_only',
    53: 'savings',
});