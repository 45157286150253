
export class Note {
    id: string;
    title: string;
    description: string;
    date: string;
    seen_by_customer: boolean

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Note {
        this.id = json.id ? json.id : "";
        this.title = json.title ? json.title : "";
        this.description = json.content ? json.content : "";
        this.date = json.datetime ? json.datetime : "";
        this.seen_by_customer = json.seen_by_customer ? json.seen_by_customer : false;
        return this;
    }

}
