import { useStyle } from "hooks";
import PageContainer from "components/PageContainer";
import { Button, TText } from "components";
import { Card } from "components/Card";
import { colors } from "styles";
import { View } from "react-native";
import { QueryUtils } from "utils";
import { QueryKeys } from "enums";
import { useQuery } from "react-query";
import { Customer } from "models";

import EditAccount from './editAccount';
import { useEffect, useState } from "react";
import EditIncome from "./editIncome";
import EditLiving from "./editLiving";
import EditMortgage from "./editMortgage";
import EditPersonalia from "./editPersonalia";
import EditContactDetails from "./editContactDetails";


const editableCategories = [
    {
        label: 'Account',
        component: <EditAccount/>
    },
    {
        label: 'Persoonsgegevens',
        component: <EditPersonalia/>
    },
    {
        label: 'Inkomen',
        component: <EditIncome/>
    },
    {
        label: 'Woning',
        component: <EditLiving/>
    },
    {
        label: 'Hypotheek',
        component: <EditMortgage/>
    },
];

const nonEditableCategories = [
    {
        label: 'Account',
        component: <EditAccount/>
    },
    {
        label: 'Contact gegevens',
        component: <EditContactDetails/>
    },
];


export default () => {

    const styles = useStyle();
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (customer instanceof Customer) {
            const selectedCategories = customer.can_update_self ? editableCategories : nonEditableCategories;
            setCategories(selectedCategories);
            if (selectedCategory >= selectedCategories.length) setSelectedCategory( 0 );
        }
    }, [customer])

    return (
        <>
            <Card
                style={[
                    styles.gap10,
                    styles.p20,
                    {
                        backgroundColor: colors.primary,
                        width: 330,
                        position: 'absolute',
                        left: 40,
                        zIndex: 9
                    }
                ]}
            >
                <TText md white>Jouw gegevens</TText>
                <TText white style={styles.pb20}>Hier kun je de gegevens zoals die bij ons bekend zijn wijzigen.</TText>
                
                {categories.map((category, categoryIndex) => (
                    <Button
                        label={category.label}
                        type='secondary'
                        textAlignLeft
                        style={{
                            width: '100%',
                            backgroundColor: selectedCategory === categoryIndex ? colors.secondary : colors.transparent
                        }}
                        onPress={() => setSelectedCategory(categoryIndex)}
                    />
                ))}                
            </Card>
        <PageContainer>            
            <View style={{marginLeft: 360, flex: 1}}>
                {categories[selectedCategory]?.component}
            </View>
        </PageContainer>
        </>
    )

}
