import { ViewStyle, View, Platform } from "react-native"
import { useHover, useSignalItems, useStyle } from "hooks";
import HeaderCard from "./HeaderCard";
import FlexGrid from "components/FlexGrid";

interface props {
    children?: React.ReactNode
    style?: ViewStyle[]
}

export default ({style}: {style: ViewStyle | ViewStyle[]}) => {

    const styles = useStyle();
    const { signalItems, brondata_added } = useSignalItems({ includeContactAndBrondata: false });
    
    let hookBankIndex = 0;
    const hookBank: any[] = [];
    for (let i = 0; i < 1000; i++) {
        hookBank[i] = useHover();
    }

    return (
        <HeaderCard header="#title_tasks_and_messages" style={style}>

            <FlexGrid columns={3}>
                {signalItems && signalItems}
            </FlexGrid>

                {/* {signalItems && signalItems.map((item, index) => {
                    // const [hovered, hoverElt] = hookBank[hookBankIndex++];
                    return ( 
                        <View key={index} style={{flex: '1 1 calc((100% - 60px) / 3)', maxWidth: 'calc((100% - 60px) / 3)'}}>
                            {item} 
                        </View>
                    );
                    // <View key={index} ref={hoverElt} style={[
                    //     (Platform.OS == 'web') && { transition: 'transform 0.2s ease-in-out' },
                    //     hovered && { transform: 'scale(1.015)'}                        
                    // ]}>
                    // </View>
                })}

            </View> */}

        </HeaderCard>
    )
}