
export class Person {
    id: string;
    full_name: string;
    initials: string;
    first_name: string;
    suffix: string;
    last_name: string;
    date_of_birth: number;
    age: number;
    sex: number
    marital_status: string
    emailaddress: string;
    phone: number;
    mobile: number;
    phone_work: number;
    retirement_date: number;
    username: string;
    nationality: Record<string, any>;
    avatar: string;

    gross_annual_income: number;
    gross_annual_old_age_pension_amount: number;
    gross_annual_annuity_amount: number;
    gross_annual_half_orphan_benefit_amount: number;
    gross_annual_partner_pension_amount: number;
    gross_annual_employment_pension_amount: any;

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Person {
        this.id = json.id ? json.id : "";
        this.full_name = json.full_name ? json.full_name : "";
        this.initials = json.initials ? json.initials : "";
        this.first_name = json.first_name ? json.first_name : "";
        this.suffix = json.suffix ? json.suffix : "";
        this.last_name = json.last_name ? json.last_name : "";
        this.date_of_birth = json.date_of_birth ? json.date_of_birth : '';
        this.age = json.age ? json.age : '#unknown';
        this.sex = (json.sex != undefined) ? json.sex : '#unknown';
        this.marital_status = json.marital_status ? json.marital_status : '#unknown'
        this.emailaddress = json.emailaddress ? json.emailaddress : "";
        this.phone = json.phone ? json.phone : '';
        this.mobile = json.mobile ? json.mobile : '';
        this.phone_work = json.phone_work ? json.phone_work : '';
        this.retirement_date = json.retirement_date ? json.retirement_date : '';
        this.username = json.username ? json.username : "";
        this.nationality = json.nationality ? json.nationality : { code: '' };
        this.avatar = json?.avatar?.content ? json.avatar.content : '';

        this.gross_annual_income = json.gross_annual_income ? json.gross_annual_income : 0;
        this.gross_annual_old_age_pension_amount = json.gross_annual_old_age_pension_amount ? json.gross_annual_old_age_pension_amount : 0;     
        this.gross_annual_employment_pension_amount = json.gross_annual_employment_pension_amount ? json.gross_annual_employment_pension_amount : 0;   
        this.gross_annual_annuity_amount = json.gross_annual_annuity_amount ? json.gross_annual_annuity_amount : 0;
        this.gross_annual_half_orphan_benefit_amount = json.gross_annual_half_orphan_benefit_amount ? json.gross_annual_half_orphan_benefit_amount : 0;
        this.gross_annual_partner_pension_amount = json.gross_annual_partner_pension_amount ? json.gross_annual_partner_pension_amount : 0;
        
        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId()
        };
    }

    getId(): string {
        return this.id;
    }
}
