import { TText } from "components";
import { View } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import BaseModal from "./BaseModal";


export default ({ settings }) => {

    const scopedStyles = scopedResponsiveStyles();

    return (
        <BaseModal style={scopedStyles.modal}>

            <View style={scopedStyles.container}>

                <TText md semibold style={scopedStyles.titel}>{settings.title}</TText>                
                <TText sm regular style={{ marginBottom: 20 }}>{settings.text}</TText>                

                <View style={{ minHeight: 100, flexDirection: 'row', justifyContent: 'flex-end', gap: 10, alignItems: 'center' }}>
                    {settings.buttons.map((button, index) => (
                        <View key={'button' + index} style={{ flexDirection: "row", marginRight: 8 }}>
                            {button}
                        </View>
                    ))}
                </View>

            </View >

        </BaseModal>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            width: 'auto',
            minWidth: 'auto',
            minHeight: 'auto',
            marginTop: 150
        },
        container: {
            flex: 1,
            height: '100%',
            paddingTop: 20,
            paddingHorizontal: 30
        },
        titel: {
            marginVertical: 20
        }
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
            },
            titel: {
                marginBottom: 20,
                marginTop: 0
            }
        }
    }
)