
export class MortgageProgress {
    comment: string
    completed: boolean
    created_at: string
    id: number
    mortgage_id: number
    name: string
    received: boolean
    sent: boolean
    updated_at: string

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): MortgageProgress {
        this.comment = json.comment ? json.comment : '';
        this.completed = json.completed ? json.completed : false;
        this.received = json.received ? json.received : false;
        this.sent = json.sent ? json.sent : false;
        this.created_at = json.created_at ? json.created_at : '';
        this.id = json.id ? json.id : 0;
        this.mortgage_id = json.mortgage_id ? json.mortgage_id : 0;
        this.name = json.name ? json.name : '';
        this.updated_at = json.updated_at ? json.updated_at : '';
        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId()
        };
    }

    getId(): string {
        return this.id;
    }
}
