import TText from "../../TText";
import { BaseDrawerModal, DatePickerModal } from "components/Modals";
import { useHover, useStyle } from "hooks";
import { View } from "react-native-animatable";
import Button from "components/Button";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { DateTimeFormat, QueryKeys, RepaymentTypeAPIMappingEnum } from "enums";
import { Dropdown, HdnCompanyImage, Icon, RadioGroup } from "components";
import { colors } from "styles";
import { AdviesWidgetUtils, CalculationUtils, QueryUtils, ValutaUtils } from "utils";
import { Card, IconCard } from "components/Card";
import { ActivityIndicator, Image, Pressable, ScrollView, Text } from "react-native";
import { useQuery } from "react-query";
import { Customer, Mortgage, Person } from "models";
import { useNavigation } from "@react-navigation/native";
import ProposalItem from "./ProposalItem";
import ProposalDetails from "./ProposalDetails";
import { MortgagePart } from "models/MortgagePart";

const periodOptions = [    
    { label: '1 jaar', value: 12 },
    { label: '5 jaar', value: 60 },
    { label: '10 jaar', value: 120 },
    { label: '15 jaar', value: 180 },
    { label: '20 jaar', value: 240 },
    { label: '30 jaar', value: 360 }
];


export default ({ settings } : { settings: Record<string, any> }) => {

    const styles = useStyle();
    const navigation = useNavigation();
    const [marketvalueLimit, setMarketvalueLimit] = useState<Record<string, any>>();
    const [result, setResult] = useState<Record<string, any>>();
    const [selectedPeriod, setSelectedPeriod] = useState(120);
    const [applyNHG, setApplyNHG] = useState(false);
    const [matchedMortgage, setMatchedMortgage] = useState<Mortgage | false>(false);
    const [showProposal, setShowProposal] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const { data: family } = useQuery(QueryKeys.FAMILY, QueryUtils.fetchFamily);
    const { data: mortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);
    const { data: living } = useQuery(QueryKeys.LIVING, QueryUtils.fetchLiving);    

    useEffect(() => {
        fetchMarketvalueLimit();
    }, [])
    
    useEffect(() => {

        if (!result && customer && living && matchedMortgage && marketvalueLimit) {
            fetchResults();
        }

    }, [customer, living, matchedMortgage])

    useEffect(() => {
        if (mortgages){
            /*@ts-ignore*/ 
            setMatchedMortgage(mortgages?.find((mortgage: Mortgage) => {
                return mortgage.properties.find((property => {
                    return property.postal_code === living.property.postcode && 
                           property.number === String(living.property.house_number) && 
                           (property.number_addition || property.number_letter) === String(living.property.house_number_addition);
                })) !== undefined;
            }) || mortgages[0]);
        }
    }, [mortgages])

    useEffect(() => {

        if (customer && living && matchedMortgage) fetchResults();

    }, [selectedPeriod])

    const fetchMarketvalueLimit = async () => {

        const response = await AdviesWidgetUtils.getMarketvalueLimit();
        const limit = Number(response.data.data.nhg_applicable_market_value_limit);
        setMarketvalueLimit(limit);

    };

    const fetchResults = async () => {
        
        setLoading(true);
        const Customer: Customer = customer;
        
        const data = {
            step: "refinance",
            applicant: {
                date_of_birth: moment(Customer.date_of_birth).format(DateTimeFormat.SERVER_DATE),
                annual_employment_income: Customer.gross_annual_income,
                state_pension_income: Customer.gross_annual_old_age_pension_amount,
                old_age_pension_income: Customer.gross_annual_employment_pension_amount
            },
            property_tax_value: living.property.woz_value_home,
            market_value: living.property.market_value,
            new_interest_period_in_months: selectedPeriod,
            new_nhg_applicable: living.property.market_value < marketvalueLimit,
            finance_repayment_penalty: false,
            lender_hdn_code: 'AA010',
            loan_parts: [],
            partner: {}
        };

        if ( matchedMortgage ){
            data.lender_hdn_code = matchedMortgage.agency?.hdn_code;
            data.loan_parts = matchedMortgage.mortgageParts.map(part => ({                
                repayment_type: RepaymentTypeAPIMappingEnum[part.repayment_type],
                original_amount: part.hoofdsom,
                first_deposit_amount: part.current_value,
                current_amount: Math.floor( Number(part.remaining_debt) ),
                premium: part.monthly_deposit,
                interest_rate: part.interest,
                return_rate: 0,
                duration_in_months: part.remaining_months_duration,
                deductible_period_in_months: Math.max(0, moment().diff(moment(part.interest_deduction_end_date), 'months')),
                remaining_interest_period_in_months: Math.max(0, part.remaining_months_fixed_interest_duration),
                nhg_applicable: part.national_mortgage_guarantee,
            }));
        }

        if ( family?.partner ){
            const Partner: Person = family.partner;
            data.partner = {
                date_of_birth: moment(Partner.date_of_birth).format(DateTimeFormat.SERVER_DATE),
                annual_employment_income: Partner.gross_annual_income,
                state_pension_income: Partner.gross_annual_old_age_pension_amount,
                old_age_pension_income: Partner.gross_annual_employment_pension_amount,
            };
        }

        const response = await AdviesWidgetUtils.getRefinance(data);
        const result = response.data.result;

        Object.keys(result.interest_rates_per_product).forEach(productKey => {

            const ratesPerPeriod = result.interest_rates_per_product[productKey];            

            if (!ratesPerPeriod[selectedPeriod]){
                delete result.interest_rates_per_product[productKey];
                return;
            }

            const periodKeys = periodOptions.map(option => option.value);
            
            result.interest_rates_per_product[productKey] = {};
            periodKeys.forEach( periodKey => {
                result.interest_rates_per_product[productKey][periodKey] = (ratesPerPeriod[periodKey] * 100).toFixed(2) || undefined;
            });
        })
        
        // put current agency on top
        result.refinance_proposals.sort((proposal) => proposal.lender_id === matchedMortgage.agency.hdn_code ? -1 : 1);

        console.log(result);
        setResult(result);
        setLoading(false);
    }

    const renderProposals = () => {

        return result && !loading && result.refinance_proposals.map((proposal, index) => {
            const interestRates = result.interest_rates_per_product[proposal.product_line_id];
            if (!interestRates) return;
    
            return (
                <ProposalItem
                    proposal={proposal}
                    interestRates={interestRates}
                    selectedPeriod={selectedPeriod}
                    index={index}
                    onSelectProposal={() => setShowProposal(proposal)}
                />
            );
        })
    };

    const renderCurrentMortgage = () => {

        if (!matchedMortgage) return;
        const highestInterest = matchedMortgage.mortgageParts.reduce((acc, part: MortgagePart) => {
            if (part.interest > acc) return part.interest;
            return acc;
        }, 0);

        return (
            <View style={[styles.flexRow, styles.gap30, {height: '100%', paddingVertical: 20}]}>

                <View style={{width: 100}}></View>

                <Card style={[{borderWidth: 1, borderColor: colors.background, padding: 5, borderRadius: 10, height: '100%'}]}>
                    <HdnCompanyImage
                        code={matchedMortgage.agency?.hdn_code}
                    />
                </Card>

                <View style={[styles.flexRow, styles.flexStretch, styles.alignCenter, styles.justifyBetween, styles.pr30]}>
                    <View>
                        <Text>
                            <TText semibold>{matchedMortgage.agency_name}</TText>
                            <TText> -{matchedMortgage.hypotheeknummer}</TText>
                        </Text>
                        <TText>Je huidige hypotheek</TText>
                    </View>
                    <View style={[styles.flexRow, styles.gap20, styles.alignCenter]}>
                        <View style={[styles.flexCol, styles.alignEnd]}>
                            <TText semibold>Hypotheekrente</TText>
                            <TText>{highestInterest}%</TText>
                        </View>
                        <View style={[styles.flexCol, styles.alignEnd]}>
                            <TText semibold>Maandelijke lasten</TText>
                            <TText>{ValutaUtils.valutaString(matchedMortgage.monthly_amount)}</TText>
                        </View>
                    </View>
                </View>

            </View>
        );
    }

    return (
       <BaseDrawerModal 
            drawerWidth={1400} 
            visible={true}
            onClose={settings.hideModal}
            header={'Hypotheek oversluiten'}
            drawerStyle={{gap: 20, flex: 1, maxHeight:"100%"}}
        >            

            <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                    <TText>Rentevasteperiode</TText>
                    <Dropdown
                        options={periodOptions}
                        value={selectedPeriod}
                        onChange={(value) => setSelectedPeriod(value)}
                        style={{minWidth: 150}}
                    />
                </View>
                
                <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                    <TText>Boeteberekening ofzoiets</TText>
                    <RadioGroup
                        options={[{label: '#yes', value: true}, {label: '#no', value: false}]}
                        value={applyNHG}
                        onChange={(value) => setApplyNHG(value)}
                    />
                    {/* <Dropdown
                        options={[{label: 'wel toepassen', value: true}, {label: 'niet toepassen', value: false}]}
                        value={applyNHG}
                        onChange={(value) => setApplyNHG(value)}
                        style={{minWidth: 125}}
                    /> */}
                </View>
            </View>

            
            {loading ? (
                <View style={[styles.fullscreen, styles.alignCenter, styles.justifyCenter, {marginBottom: 150}]}>
                    <ActivityIndicator color={colors.primary} size={40} />
                </View>
            ) : (
                <ScrollView style={{marginBottom: 100, marginLeft: - 30, marginRight: -30}} contentContainerStyle={[styles.gap20, styles.ph30]}>
                    {renderProposals()}
                </ScrollView>
            )}

            <View style={{
                backgroundColor: colors.background, 
                width: '100%', 
                height: 130, 
                position: 'absolute', 
                bottom: 0, 
                marginLeft: - 30, 
                paddingRight: 30,
                borderBottomLeftRadius: 35
            }}>
                {renderCurrentMortgage()}
            </View>

            {showProposal &&
                <ProposalDetails
                    onClose={() => setShowProposal(undefined)}
                    proposal={showProposal}
                    matchedMortgage={matchedMortgage}
                    currentSituation={result.current_mortgage_situation}
                    repaymentPenalty={result.repayment_penalty}
                    refinanceCosts={result.refinance_costs}
                    selectedPeriod={selectedPeriod}
                />
            }

       </BaseDrawerModal>
    );
}
