import { useEffect, useState } from "react";

export default (unsortedData?: any[]) => {

    const [query, setQuery] = useState('');
    const [data, setData] = useState(unsortedData || []);
    const [sorted, setSorted] = useState([]);
    const handleSearchQuery = (q: string) => {
        setQuery(q);
    }

    useEffect(() => setData(unsortedData),[unsortedData]);

    useEffect(() => {

        if (Array.isArray(data)) {

            const sortedData = data
                .map(item => {
                    if (checkProperties(item, query)) return item;
                })
                .filter(item => item);

            setSorted(sortedData);

        } else {

            setSorted(data);

        }

    }, [data, query]);

    return { data: sorted, handleSearchQuery, setData };
}

const checkProperties = (item, query) => {

    if (typeof item == 'object') {

        let response = false;
        for (let key in item) {
            if (checkProperties(item[key], query)) response = true;
            if (checkProperties(key, query)) response = true;
        }
        return response;

    } else if (Array.isArray(item)) {

        let response = false;
        for (let element of item) {
            if (checkProperties(element, query)) response = true;
        }
        return response;

    } else {

        return (String(item).toUpperCase().includes(query.toUpperCase()));

    }

}