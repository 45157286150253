import {Button, TextInput, TText, Dropdown} from 'components';
import {useFormik} from 'formik';
import {useStyle} from 'hooks';
import {Dimensions, Linking, Platform, Pressable, useWindowDimensions, View} from 'react-native';
import {object, string} from 'yup';
import {CreateResponsiveStyle, DEVICE_SIZES, maxSize} from "rn-responsive-styles";
import {colors} from "styles";
import BaseModal from "components/Modals/BaseModal";
import {DocumentUtils} from "utils";
import {useContext, useState} from "react";
import {ModalContext} from "providers/ModalProvider";

export default () => {

    const {width} = useWindowDimensions();
    const { open, hideModal } = useContext(ModalContext);

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();

    const [loading, setLoading]  = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);


    const contactSchema = object({
        question: string().required('Vraag is verplicht'),
        subject: string().required('Onderwerp is verplicht'),
        message: string().required('Bericht is verplicht')
    });

    const [messageOptions] = useState([
        {label: 'Hypotheek', value: 'mortgage'},
        {label: 'Polis', value: 'polis'},
        {label: 'Inkomen', value: 'income'},
        {label: 'Pensioen', value: 'retirement'},
    ]);

    const {
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
    } = useFormik({
        validationSchema: contactSchema,
        initialValues: {
            question: '',
            subject: '',
            message: ''
        },
        onSubmit: values => {
            setLoading(true);
            let message = `Geselecteerde categorie: ${messageOptions.find(option => option.value === values.question).label}\n`;
            message = message + `Onderwerp: ${values.subject}\n`;
            message = message + `Bericht:\n${values.message}`;
            DocumentUtils.uploadMessage(message)
                .then(() => setLoading(false))
                .then(() => handleSubmitSuccess())
                .finally(() => hideModal())
                .catch(() => setLoading(false) );
        }
    });

    const handleSubmitSuccess = () => {
        values.question = ''
        values.subject = ''
        values.message = ''

        setSubmissionSuccess(true);

        setTimeout(() => setSubmissionSuccess(false), 2000);
    }

    return (
        <View
            style={[[
                {
                    flex: 1,
                    flexDirection: width < 768 ? 'column-reverse' : 'row',
                    justifyContent: "space-between",
                    backgroundColor: colors.white,
                    borderRadius: 20
                },
                Platform.OS === 'web' && {cursor: 'default'}
            ]]}
        >
            <View style={scopedStyles.container}>
                <View style={{marginBottom: 20}}>
                    <TText semibold lg>
                        Bericht sturen
                    </TText>

                    <View style={{flexDirection: 'row', display: 'ruby'}}>
                        <TText>
                            Wil je contact met een medewerker?
                            Vul dan hier je vraag of opmerking in.
                            We nemen zo snel mogelijk contact met je op.
                            Via
                        </TText>

                        <Pressable onPress={() => { Linking.openURL(`https://dossierdata.nl`) }}>
                            <TText url style={{marginHorizontal: 3}}>onze website</TText>
                        </Pressable>

                        <TText>
                            kun je ons ook op andere manieren bereiken.
                        </TText>
                    </View>
                </View>
                <View style={{marginBottom: 10}}>
                    <Dropdown
                        label='Waar gaat je bericht over'
                        options={messageOptions || []}
                        value={values.question}
                        onChange={handleChange('question')}
                        error={(touched.question && errors.question) ? errors.question : undefined}
                        search
                        style={styles.textPrimary}
                    />
                </View>

                <View>
                    <TextInput
                        label='Het onderwerp is'
                        placeholder='Voer in..'
                        value={values.subject}
                        onChangeText={handleChange('subject')}
                        error={(touched.subject && errors.subject) ? errors.subject : undefined}
                        style={[
                            styles.textRegular,
                            styles.textPrimary,
                            styles.textSM,
                            scopedStyles.noteText
                        ]}
                    />
                </View>

                <View style={{flex: 1}}>
                    <TextInput
                        label='Je bericht'
                        multiline={true}
                        placeholder='Schrijf hier je bericht..'
                        value={values.message}
                        onChangeText={handleChange('message')}
                        error={(touched.message && errors.message) ? errors.message : undefined}
                        style={[
                            styles.textRegular,
                            styles.textPrimary,
                            styles.textSM,
                            scopedStyles.noteText
                        ]}
                    />
                </View>

                <View style={{minHeight: 100, flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{flexDirection: "row"}}>
                        <Button
                            loading={loading}
                            success={submissionSuccess}
                            label='Bericht versturen'
                            type={"secondary"}
                            onPress={handleSubmit}
                        />
                    </View>
                </View>
            </View>
        </View>
    );
};

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        container: {
            flex: 1,
            height: '100%',
            paddingTop: 20,
            paddingHorizontal: 30
        },
        noteText: {
            backgroundColor: colors.background,
            flexGrow: 1,
            marginBottom: 10,
            padding: 8,
            borderRadius: 8,
            verticalAlign: "top",
        },
    },
)

