import { ViewStyle, View, Pressable, useWindowDimensions } from "react-native"
import { useStyle } from "hooks";
import Card from "./Card";
import { colors } from "styles";
import TText from "components/TText";
import Icon from "components/Icon";
import IconCard from "./IconCard";
import { useContext } from "react";
import { ModalContext } from "providers/ModalProvider";
import FlexGrid from "components/FlexGrid";
import {CreateResponsiveStyle, DEVICE_SIZES, maxSize} from "rn-responsive-styles";
import { QueryUtils } from "utils";
import { QueryKeys } from "enums";
import { useQuery } from "react-query";

import MaximumMortgage from "screens/AdviesWidgets/MaximumMortgage";
import LivableWidget from "components/widgets/livability";
import RefinanceWidget from "components/widgets/refinance";


const Scenarios = [
    {
        title: 'Kan ik blijven wonen?',
        iconName: 'IconHome',
        component: LivableWidget
    },
    {
        title: 'Hoeveel kan ik lenen?',
        iconName: 'IconPigMoney',
        component: MaximumMortgage
    },
    {
        title: 'Hypotheek oversluiten',
        iconName: 'IconClipboardData',
        component: RefinanceWidget
    },
    // {
    //     title: 'Kan ik blijven wonen?',
    //     iconName: 'IconHome',
    //     component: LivableWidget
    // },
    // {
    //     title: 'Kan ik blijven wonen?',
    //     iconName: 'IconHome',
    //     component: LivableWidget
    // },
];

export default ({style}: {style?: ViewStyle}) => {

    const styles = useStyle();
    const { showModal, hideModal } = useContext(ModalContext);
    const { width, height } = useWindowDimensions();
    const scopedStyles = scopedResponsiveStyles();
    let itemsToShow: number = 1;
    if(width > 1150) itemsToShow = 2;
    if(width > 1400) itemsToShow = 3;
    const { data: mortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);


    const showScenario = (scenario: any) => {

        showModal(scenario.component, {hideModal}, scenario.title);

    }
    
    const renderScenarioButtons = () => {
        return mortgages && Scenarios.slice(0, 3).map(scenario => {
            
            return (
                <Pressable
                    key={scenario.title}
                    style={styles.flexStretch}
                    onPress={() => showScenario(scenario)}
                >
                    <Card 
                        hoverable
                        style={{
                            backgroundColor: colors.white, 
                            color: colors.primary,                            
                            gap: 20,
                            flex: 1
                        }}
                    >
                        <IconCard
                            iconName={scenario.iconName}
                            iconSize={25}
                            iconColor={colors.white}
                            style={{
                                position: 'absolute', 
                                width: 40, 
                                height: 40, 
                                left: 20, 
                                top: -20
                            }}
                        />
                        <TText md semibold style={{color: colors.primary}}>{scenario.title}</TText>
                        <Icon
                            iconName="IconArrowRight"
                            iconSize={30}
                            iconColor={colors.secondary}
                            style={{
                                position:'absolute',
                                bottom: 10,
                                right: 20
                            }}
                        />
                    </Card>
                </Pressable>
            );
        });
    }

    return (
        <Card style={[
            {backgroundColor: colors.secondary1}, 
            styles.flexRowDynamic,
            style
        ]}>

            <View style={{flex: 0.31}}>
                <TText md semibold>Scenario's</TText>
                <TText sm style={{width: '75%', marginTop: 10}}>Bekijk snel hoeveel je kan lenen, of je kan blijven en zelfs of je je huis kan verduurzamen.</TText>
            </View>

            {/* <View style={[styles.flexRow, styles.alignCenter, styles.gap30, {flex: 0.57}]}> */}
            <FlexGrid columns={itemsToShow} style={{flex: 0.57}}>
                {renderScenarioButtons()}
            </FlexGrid>

            <View style={[styles.justifyCenter, {flex: 0}]}>
                <Card 
                    style={scopedStyles.moreScenariosCard}
                >
                    {/* <TText md semibold style={{color: colors.secondary}}>Bekijk meer scenario's</TText> */}
                    <TText md semibold style={{color: colors.secondary}}>Binnenkort meer scenario's</TText>
                    <Icon
                        iconName="IconHourglassLow"
                        iconSize={20}
                        iconColor={colors.secondary}
                        style={{
                            position:'absolute',
                            bottom: 20,
                            right: 20
                        }}
                    />
                </Card>
            </View>

        </Card>
    )
}
const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        moreScenariosCard: {
            backgroundColor: colors.secondary5,
            color: colors.secondary,
            width: 160,
            height: 170,
            transform: 'translateX(30px)'
        },
    }, {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            moreScenariosCard: {
                padding: 20,
                height: 80,
                width: 200,
                marginTop: 120,
                transform: ''
            },
        },
        [DEVICE_SIZES.MEDIUM_DEVICE]: {
            moreScenariosCard: {
                width: 120,
                marginLeft: 10,
                transform: ''
            },
        },
        [DEVICE_SIZES.LARGE_DEVICE]: {
            moreScenariosCard: {
                width: 160,
                marginLeft: 20
            },
        },
    }
)
