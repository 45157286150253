import { Icons } from "icons";
import { Button, Item, TText } from "components";
import { DamageFileNames, DateTimeFormat, QueryKeys } from "enums";
import { useTranslation } from "hooks";
import { Damage, Document } from "models";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { StoreContext } from "providers/StoreProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { ActivityIndicator, Platform, Pressable, View, useWindowDimensions } from "react-native";
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { DocumentUtils, QueryUtils, ValutaUtils } from "utils";
import BaseModal from "./BaseModal";
import DocumentModal from "./DocumentModal";
import IosUploadSelectModal from "./IosUploadSelectModal";



export default ({ settings }) => {

    //styles
    const { width } = useWindowDimensions();
    const scopedStyles = scopedResponsiveStyles();
    const { hideModal } = useContext(ModalContext);
    const { data: documents, refetch: refetchDocuments } = useQuery(QueryKeys.DOCUMENTS, QueryUtils.fetchDocuments);
    const { data: damages, refetch: refetchDamage } = useQuery(QueryKeys.DAMAGE, QueryUtils.fetchDamage);
    const { triggerLoading } = useContext(StoreContext);
    const translate = useTranslation();

    const damage: Damage | undefined = damages?.filter(d => d.id == settings.damageProgressId)[0];


    //the size of the upload items
    const itemSize = useRef('44%');

    //uploading files
    const [{ uploading, documentID }, setUploading] = useState({ uploading: false, documentID: undefined });
    const [removing, setRemoving] = useState(null);
    const [uploadedDocs, setUploadedDocs] = useState({});

    const [sharedDocuments, setSharedDocuments] = useState([]);
    const [showDocument, setShowDocument] = useState(undefined);
    const [iosupload, setIosUpload] = useState(false);

    //collect shared documents
    useEffect(() => {
        if (documents['Schades']) {
            setSharedDocuments(documents['Schades'].filter(document => document.damage && document?.damage.id == damage.id));
        }
    }, [documents]);



    const upload = async (index: number, iosMethod = undefined) => {

        if (Platform.OS == 'ios' && !iosMethod) {
            setIosUpload(index);
            return;
        }

        const pickMethod = Platform.select({
            ios: async () => {
                if (iosMethod == 'image') {
                    return await DocumentUtils.pickImage();
                } else {
                    return await DocumentUtils.pickDocument();
                }
            },
            default: async () => {
                return await DocumentUtils.pickDocument();
            }
        })

        const pickedDocument = await pickMethod();
        if (!pickedDocument) return;

        setIosUpload(false);

        setUploading({ uploading: true, documentID: index });

        const extension = pickedDocument.name.split('.');
        const filename = tryNameTranslation(damage.progress_items[index].name) + '.' + extension[extension.length - 1];

        DocumentUtils.uploadDocument(pickedDocument, filename, { damageProgressId: damage.progress_items[index].id })
            .then(async ({ data }) => {

                if (data?.success) {
                    setUploadedDocs({
                        ...uploadedDocs,
                        [index]: data.success
                    });
                    await new Promise(resolve => {
                        setTimeout(async () => {
                            await refetchDamage();
                            await refetchDocuments();
                            resolve(true);
                        }, 1500)
                    });
                    triggerLoading();
                }

                setUploading({ uploading: false, documentID: undefined });

            })

    }

    const removeDocument = async (document: Document) => {
        setRemoving(document.id);
        const response = await DocumentUtils.deleteDocument(document);
        if (response.success == true) {
            setSharedDocuments(sharedDocuments.filter(doc => doc !== document));
            refetchDocuments();
        }
        setRemoving(null);
    }

    const tryNameTranslation = (txt: string) => {
        if (DamageFileNames[txt]) return translate(DamageFileNames[txt]);
        return txt;
    }




    const renderDamageInfo = () => (
        <View style={{ marginHorizontal: '1%', marginTop: 20 }}>
            <TText md semibold style={{ marginBottom: 12 }}>#data</TText>

            <View style={scopedStyles.category}>
                <TText sm black style={{ width: 150 }}>#description</TText>
                <View style={{ marginLeft: width > 768 ? 8 : 0, flex: 1 }}>
                    <TText noTranslate sm grey semibold>{damage.cause}</TText>
                </View>
            </View>

            <View style={scopedStyles.category}>
                <TText sm black style={{ width: 150 }}>#damage_amount</TText>
                <View style={{ marginLeft: width > 768 ? 8 : 0, flex: 1 }}>
                    <TText sm grey semibold>{ValutaUtils.valutaString(damage.estimated)}</TText>
                </View>
            </View>

            {damage.payments.length > 0 &&
                <View style={scopedStyles.category}>
                    <TText sm black style={{ width: 150 }}>#damage_payments</TText>
                    <View style={{ marginLeft: width > 768 ? 8 : 0, flex: 1 }}>
                        {damage.payments.map((payment, index) => {
                            return (
                                <View key={'p' + index}>
                                    <TText sm grey semibold>● {ValutaUtils.valutaString(payment.amount)}#damage_payment_to{'#damage_payment_to_' + damage.pay_to}#damage_payment_on{moment(payment.payed_at).format(DateTimeFormat.DISPLAY_DATE)}</TText>
                                </View>
                            )
                        })}
                    </View>
                </View>
            }

        </View>
    );


    const renderUploadItems = () => {

        const result: any[] = [];
        damage.progress_items.map((p, index) => (!p.sent) && result.push(renderItem(p, index)));
        return result.sort((a, b) => a.received - b.received).map(r => r.component);
    }

    const renderItem = (progress, index) => {

        return {
            received: progress.received,
            component: (
                <View
                    key={index}
                    style={[
                        scopedStyles.uploadItem,
                        { width: itemSize.current }
                    ]}>

                    <TText sm semibold style={{ marginBottom: 8 }}>{tryNameTranslation(progress.name)}</TText>

                    <Item
                        style={{ width: '100%', flex: 0.9 }}
                    >

                        {(uploading && documentID === index) ? (

                            //show loading_in_prgress
                            <View style={scopedStyles.uploadIcon}>
                                <ActivityIndicator
                                    color={colors.primary}
                                    size={32}
                                />
                            </View>

                        ) : (

                            //show success/upload another
                            uploadedDocs[index] != undefined ? (

                                <View style={scopedStyles.uploadIcon}>

                                    <View style={{
                                        backgroundColor: uploadedDocs[index] ? colors.secondary9 : colors.error,
                                        width: 50,
                                        height: 50,
                                        borderRadius: 25,
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        
                                    </View>

                                    <Pressable disabled={uploading} onPress={() => upload(index)}>
                                        <TText xs regular black style={{ width: '100%', marginTop: 4, textDecorationLine: 'underline' }} >#uploads_retry</TText>
                                    </Pressable>

                                </View>

                            ) : (
                                <>
                                    {/* het upload icon */}
                                    <View style={[{ width: '100%', flex: 1, justifyContent: "center", alignItems: "center" }]}>
                                        <Pressable
                                            disabled={Boolean(progress.received) || Boolean(uploading)}
                                            onPress={() => upload(index)}
                                        >
                                            
                                        </Pressable>
                                        {progress.received != 0 &&
                                            <Pressable disabled={uploading} onPress={() => upload(index)}>
                                                <TText xs regular black center style={{ marginTop: 2, textDecorationLine: 'underline' }} >#uploads_retry</TText>
                                            </Pressable>
                                        }
                                    </View>

                                    {(progress.received == 0 && progress.comment) &&
                                        <TText sm regular grey style={[
                                            { position: 'absolute', bottom: 8 },
                                            Platform.OS == 'web' ? {
                                                wordBreak: "all",
                                                width: '98%',
                                                left: '2%'
                                            } : {
                                                width: '110%',
                                                left: '3%'
                                            }
                                        ]}>
                                            {progress.comment?.substring(0, 110)}{progress.comment?.length > 110 ? '...' : ''}
                                        </TText>
                                    }

                                </>
                            )

                        )}

                    </Item>
                </View>
            )
        };

    }

    const renderSharedItems = () => {
        return sharedDocuments.map((document: Document, index) => {
            return (
                <View
                    key={index + document.id}
                    style={scopedStyles.sharedDocumentButtonContainer}
                >
                    <TText sm semibold>{document.name}</TText>
                    <View style={scopedStyles.sharedDocumentButtons}>
                        {document.deletable ? (
                            <Button
                                loading={removing === document.id}
                                label={'#action_remove'}
                                type='error'
                                onPress={() => removeDocument(document)}
                                style={{ marginRight: 8 }}
                            />
                        ) : (
                            <View></View>
                        )}
                        <View></View>
                        <Button
                            label={'#action_view'}
                            type='primary'
                            onPress={() => {
                                setShowDocument(document);
                            }}
                        />
                    </View>

                </View>
            )
        })
    }



    return (
        <BaseModal style={scopedStyles.modal}>

            <TText lg regular style={{ margin: '1%', marginTop: 20, marginBottom: 12 }}>{'#damage_report'}</TText>

            {renderDamageInfo()}

            {damage?.progress_items?.length > 0 &&
                <View style={{ marginTop: 20 }}>
                    <TText md semibold style={{ marginLeft: '1%', marginBottom: 8 }}>#documents #action_upload</TText>

                    <View style={scopedStyles.uploadContainer}>
                        {renderUploadItems()}
                    </View>
                </View>
            }

            {sharedDocuments.length > 0 &&
                <View style={{ marginTop: 20 }}>
                    <TText md semibold style={{ marginLeft: '1%' }}>#shared_documents</TText>
                    <TText sm regular black style={{ marginLeft: '1%', marginBottom: 12 }}>#removing_document</TText>
                    <View style={scopedStyles.sharedContainer}>
                        {renderSharedItems()}
                    </View>
                </View>
            }

            <View style={{ flex: 1 }}></View>

            <View style={{ height: 100, justifyContent: 'center', alignItems: "flex-end" }}>

                <View style={{ flexDirection: "row" }}>
                    <Button
                        label='#close'
                        type={"secondary"}
                        onPress={() => hideModal()}
                    />
                </View>

            </View>

            {showDocument &&
                <DocumentModal settings={{ ...showDocument, hide: () => { setShowDocument(undefined) } }} />
            }

            {iosupload &&
                <IosUploadSelectModal
                    close={() => setIosUpload(false)}
                    onPick={(type: string) => {
                        upload(iosupload, type);
                    }}
                />
            }

        </BaseModal>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            width: '40%',
            paddingTop: 20,
            paddingHorizontal: 30
        },
        uploadContainer: {
            width: '100%',
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: "space-between"
        },
        sharedContainer: {
            marginLeft: '1%',
            marginBottom: 20,
        },
        category: {
            flexDirection: 'row',
            marginBottom: 12
        },
        uploadItem: {
            height: 250,
            margin: '1%',
            width: '50',
            backgroundColor: colors.lightgrey
        },
        uploadMessage: {
            width: '90%',
            height: '30%',
            borderRadius: 8,
            justifyContent: 'center',
            alignItems: 'center'
        },
        uploadIcon: {
            position: "absolute",
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
        },
        sharedDocumentButtonContainer: {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: colors.white,
            borderRadius: 8,
            marginBottom: 20,
            paddingHorizontal: 8,
            paddingVertical: 8
        },
        sharedDocumentButtons: {
            flexDirection: 'row',
            alignItems: "center"
        },
    },
    {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            modal: {
                width: '65%'
            }
        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
            },
            container: {
                flex: 1
            },
            uploadItem: {
                marginBottom: 12,
                marginHorizontal: 0
            },
            uploadIcon: {
                position: "relative",
                margin: 0
            },
            category: {
                flexDirection: 'column',
                marginBottom: 12
            },
            sharedDocumentButtonContainer: {
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start"
            },
            sharedDocumentButtons: {
                marginTop: 8,
                width: '100%',
                justifyContent: 'space-between'
            },
        }
    }
)