import { useWindowDimensions, View, ViewStyle } from "react-native"
import { useStyle } from "hooks";

interface props {
    children?: React.ReactNode
    style?: ViewStyle,
    childStyle?: ViewStyle,
    columns?: number,
    widthSmall?: number
}

export default ({ children, style, childStyle, columns = 4, widthSmall = 768}: props) => {

    const styles = useStyle();
    const windowSize = useWindowDimensions();
    const largeScreen = windowSize.width >= 1200;
    const smallScreen = windowSize.width >= widthSmall;
    const itemsPerRow = largeScreen ? columns : smallScreen ? 2 : 1;

    const gridItemStyle = {
        flex: `1 1 calc((100% - ${(itemsPerRow - 1) * 30}px) / ${itemsPerRow})`,
        maxWidth: `calc((100% - ${(itemsPerRow - 1) * 30}px) / ${itemsPerRow})`
    };
    
    return (
        <View style={[styles.flexRow, styles.flexWrap, styles.gap30, style]}>            
            {children && children.map((child: React.ReactNode, index: number) => (
                <View 
                    key={index} 
                    style={[
                        gridItemStyle,
                        childStyle
                    ]}
                >
                    {child}
                </View>
            ))}
        </View>
    );
}
