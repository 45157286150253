import { LocalStorageKeys } from "enums";
import Constants from "expo-constants";
import { StoreContext } from "providers/StoreProvider";
import { useContext, useEffect, useState } from "react";
import { Platform } from "react-native";
import { useQueryClient } from "react-query";
import { AuthUtils, QueryUtils, StorageUtils } from "utils";

const Config = Constants.expoConfig.extra.config;


export default () => {

    const { login, logout, reloadApp } = useContext(StoreContext);
    const [checkingAuth, setCheckingAuth] = useState(true);
    const queryClient = useQueryClient();

    useEffect(() => {
        checkOldAuthStorage();
        checkAuthentication();
    }, [])

    const checkOldAuthStorage = async () => {

        const accessToken = await StorageUtils.getStorageItem(LocalStorageKeys.ACCESS_TOKEN);
        if (accessToken) {

            const refreshToken = await StorageUtils.getStorageItem(LocalStorageKeys.REFRESH_TOKEN);
            const expiresIn = await StorageUtils.getStorageItem(LocalStorageKeys.EXPIRES_IN);
            const tenant = (await StorageUtils.getStorageItem(LocalStorageKeys.X_TENANT1)) || (await StorageUtils.getStorageItem(LocalStorageKeys.X_TENANT2));

            await AuthUtils.storeLogin(
                {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                    expires_in: expiresIn
                },
                tenant
            );

            await StorageUtils.removeStorageItem(LocalStorageKeys.ACCESS_TOKEN);
            await StorageUtils.removeStorageItem(LocalStorageKeys.REFRESH_TOKEN);
            await StorageUtils.removeStorageItem(LocalStorageKeys.EXPIRES_IN);
            await StorageUtils.removeStorageItem(LocalStorageKeys.PUSHID);
            await StorageUtils.removeStorageItem(LocalStorageKeys.X_TENANT1);
            await StorageUtils.removeStorageItem(LocalStorageKeys.X_TENANT2);

            reloadApp();

        }
    }

    const checkAuthentication = async () => {

        //@ts-ignore
        const getQuery = Platform.select({
            web: () => { return new URLSearchParams(window.location.search) },
            default: () => { return { has: (e: string) => false, get: (e: string) => '' } }
        });
        const query = getQuery();

        if (!query.get('register') && !query.get('reset_password')) {

            const currentUser = await AuthUtils.getCurrentUser();
            const accessToken = currentUser.access_token;
            const refreshToken = currentUser.refresh_token;
            const expiresIn = await AuthUtils.expiresInMs(currentUser.expires_in);
            const shouldRelog = !currentUser.version || currentUser.version < Config.RELOG_VERSION;

            if (expiresIn && expiresIn < 0 && accessToken && refreshToken && !shouldRelog) {

                //refresh accesstoken
                const res = await AuthUtils.refreshTokens(refreshToken);
                if (res === true) {

                    login();
                    await QueryUtils.prefetch(queryClient);

                } else {

                    logout();

                }

            } else if (expiresIn && expiresIn > 0 && accessToken && !shouldRelog) {

                //use active accesstoken
                await QueryUtils.prefetch(queryClient);
                login();

            } else {

                //logout
                logout();

            }
        }

        setCheckingAuth(false);
    };


    return { checkingAuth }
}