import { useState } from "react";
import { StyleSheet } from "react-native"
import { View } from "react-native-animatable"
import CalendarPicker from "react-native-calendar-picker"

export default ({style, calendarProps, onDateChange})=>{

    const [calenderSize, setCalenderSize] = useState(0);

    return (
        <View 
            style={[scopedStyles.datePickerContainer, style]}
            onLayout={(e)=>{setCalenderSize(e.nativeEvent.layout.width)}}
        >
            <CalendarPicker
                {...calendarProps}
                width={calenderSize}
                height={calenderSize}
                onDateChange={onDateChange}
            />                    
        </View>
    )
}

const scopedStyles = StyleSheet.create({
    datePickerContainer:{
        width: '100%',
        flex: 1
    }
})