import { useStyle } from 'hooks';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { NativeSyntheticEvent, StyleProp, StyleSheet, TextInput, TextInputFocusEventData, View } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { colors } from 'styles';
import TText from './TText';
import { ValutaUtils } from 'utils';


interface Props {
    label?: string
    placeholder?: string,
    error?: string,
    value: number,
    onChange?: (txt: number | ChangeEvent<any>) => any,
    onBlur?: (e: string | ChangeEvent<any>) => void,
    onFocus?: ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void) | undefined,
    onKeyPress?: (e: any) => void,
    style?:StyleSheet.NamedStyles<any> | StyleProp<any>,
    disabled?: boolean
    autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined
    autoCompleteType?: 'username' | 'password' | 'code'
    keyboardType?: any    
}

const CustomTextInput: React.FC<Props> = ({ label, value, placeholder, error, onChange, onFocus, onBlur, onKeyPress, style, disabled, autoCapitalize, autoCompleteType, keyboardType }) => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const [displayValue, setDisplayValue] = useState('');

    useEffect(() => {
        setDisplayValue(ValutaUtils.formatValuta(value));
    }, [value])

    return (
        <View style={styles.flexStretch}>
            {label && 
                <TText sm semibold style={scopedStyles.label}>
                    {label}
                </TText>
            }

            {disabled ? (
                <TText sm black style={[scopedStyles.input, scopedStyles.inputDisabled, styles.borderRadius10]}>
                    {value}
                </TText>
            ) : (
                <View>
                    <TextInput
                        placeholder={placeholder}
                        value={displayValue}
                        onChangeText={newValue => {
                            const textIsNumbers = !newValue.match(/[^0-9.,]/);               
                            textIsNumbers && setDisplayValue(newValue);
                        }}
                        onBlur={(e) => {
                            onChange && onChange(Number(ValutaUtils.unformatValuta(displayValue) || 0));
                            if (displayValue) setDisplayValue(ValutaUtils.formatValuta(displayValue));
                            onBlur && onBlur(e);
                        }}
                        onFocus={onFocus}
                        onKeyPress={onKeyPress}
                        autoCapitalize={autoCapitalize}
                        spellCheck={false}
                        autoCompleteType={autoCompleteType}
                        keyboardType={keyboardType}
                        style={[
                            styles.textRegular, styles.flexStretch, styles.textSM, styles.textPrimary, styles.borderRadius10,
                            scopedStyles.input,
                            style
                        ]}
                    />
                    <View
                        style={[scopedStyles.icon, {backgroundColor: colors.primary2}]}
                    >
                        <TText semibold>€</TText>
                    </View>                       
                </View>
            )}

            <TText regular sm error style={{textAlign: 'right'}}>{error}</TText>
        </View>
    );
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        label: {
            marginBottom: 5
        },
        input: {
            paddingLeft: 50,
            paddingVertical: 8,
            backgroundColor: colors.background,
        },
        inputDisabled: {
            backgroundColor: colors.disabled,
            paddingVertical: 8,
        },
        icon: {
            position: 'absolute',
            width: 40,
            height: '100%',
            left: 0,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.greyTransparent,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
        }
    },
    {
        [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
            input: {
                fontSize: 14,
                paddingVertical: 6
            },
            inputDisabled: {
                paddingVertical: 6,
            }
        }
    }
)

export default CustomTextInput;