import ApiUtils from "./ApiUtils";

export default {
    async uploadContactDetails(data: Record<string, any>) {
        const client = await ApiUtils.getApiClient({});
        return client.post('/customer/edit_screen/contact_details', data);
    },
    async uploadPersonalia(data: Record<string, any>) {
        const client = await ApiUtils.getApiClient({});
        return client.post('/customer/edit_screen/personalia', data);
    },
    async uploadLiving(data: Record<string, any>) {
        const client = await ApiUtils.getApiClient({});
        return client.post('/customer/edit_screen/current_living', data);
    },
    async uploadIncome(data: Record<string, any>) {
        const client = await ApiUtils.getApiClient({});
        return client.post('/customer/edit_screen/income', data);
    },
    async uploadMortgage(data: Record<string, any>) {
        const client = await ApiUtils.getApiClient({});
        return client.post('/customer/edit_screen/mortgage', data);
    },
}