import { colors } from "styles";
import Constants from 'expo-constants';

const Config = Constants.expoConfig.extra.config;

const map = `<!DOCTYPE html>
<html>
<head>
    <base href="https://klantportaal.net/api">
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <script src="WIDGET_URL"></script>
    <link href="https://blackbirds-cdn.cluster02.k8s.rapide.software/css/latest/bundle.css" rel="stylesheet"/>
    <style>
        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;&display=swap');
        html,
        body {
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
            overflow-x: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: ${colors.background};
        }
        * {
            font-family: "Poppins", sans-serif !important;
        }
        #advies-widgets-div {
            max-width: 800px;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            min-height: calc(100% - 60px);
        }

        #advies-widgets-div > .advies-widget {
            flex: 1 !important;
            display: flex !important;
            flex-direction: column !important;
        }
        #advies-widgets-div > .advies-widget > .d-flex.my-3 {
            display: none !important;
        }
        #advies-widgets-div > .advies-widget > div {
            flex: 1 !important;
        }

        #advies-widgets-div .bg-linear-gradient-2.px-3.py-2{
            background-color: PRIMARY_COLOR !important;
        }
        #advies-widgets-div .card-footer{
            background-color: PRIMARY_COLOR2 !important;
        }
        #advies-widgets-div #step-summary > .card-footer{
            background-color: PRIMARY_COLOR !important;
        }
        #advies-widgets-div .btn-primary.btn-circle {
            background-color: PRIMARY_COLOR8 !important;
        }
        #advies-widgets-div .btn-primary-light {
            background-color: PRIMARY_COLOR2 !important;
        }

        #advies-widgets-div .btn-primary:not(:disabled):not(.disabled){
            background-color: PRIMARY_COLOR !important;
        }
        #advies-widgets-div .btn-primary:hover{
            background-color: PRIMARY_COLOR8 !important;
            border: none !important;
        }

        #advies-widgets-div .btn-outline-primary {
            color: PRIMARY_COLOR !important;
            border: none !important;
        }
        #advies-widgets-div .btn-outline-primary:hover {
            color: white !important;
            background-color: PRIMARY_COLOR2 !important;
            border: none !important;
        }

        #advies-widgets-div .btn-secondary:not(:disabled):not(.disabled){
            background-color: SECONDARY_COLOR !important;
            border: none !important;
        }
        #advies-widgets-div .btn-secondary:hover{
            background-color: SECONDARY_COLOR8 !important;
        }
       

        #advies-widgets-div .btn-outline-secondary {
            color: SECONDARY_COLOR !important;
            border: none !important;
        }
        #advies-widgets-div .btn-outline-secondary:hover {
            color: white;
            background-color: SECONDARY_COLOR2 !important;
            border: none !important;
        }

        #advies-widgets-div select option:checked {
            background-color: SECONDARY_COLOR8 !important;
        }
    </style>
</head>

<body>
    <div id="advies-widgets-div"></div>
    <script>
        const widgetState = WIDGET_STATE;
        // document.body.innerHTML = document.body.innerHTML + '<p>' + JSON.stringify(widgetState, null, 4) + '</p>';
        
        window.widget = new WIDGET_NAME({
            api_key: 'tsglDkWr9wgrz6JdSTihYZrhEm6JefhAqdOt5MAhVU1h1MNgEusMs5hOdhiieDes',
            url: '${Config.ADVIES_WIDGET_API_URL}'
        });
        widget?.init('#advies-widgets-div');

        const fillState = (key, value) => {
            let keys = key.split('.');
            let obj = window;

            for (let i = 0; i < keys.length - 1; i++) {
                obj = obj[keys[i]];
            }

            obj[ keys[ keys.length - 1 ] ] = value; 
        }

        const interval = setInterval(() => {
            if (!window.widget.loading){
                clearInterval(interval);
                // document.body.innerHTML = document.body.innerHTML + '<p> widget is loaded! Adding state</p>';
                Object.entries(widgetState).map(([key, value]) => fillState(key, value));
                // document.body.innerHTML = document.body.innerHTML + '<p>' + JSON.stringify(window.state, null, 4) + '</p>';
                window.widget?.reload();
            }
        }, 100);
        
    </script>
</body>

</html>`

export default map;