import { useNavigation } from "@react-navigation/native";
import { Button, MortgageProgressCircle, TText } from "components";
import { MortgageModal, MortgageRequestModal } from "components/Modals";
import { PortalInvitationType } from "enums";
import { useLayoutMargin, useSorted, useStyle } from "hooks";
import { Mortgage } from "models";
import { ModalContext } from "providers/ModalProvider";
import React, { useContext, useState } from "react";
import { Dimensions, Platform, Pressable, View, useWindowDimensions } from "react-native";
import { PieChart, ProgressCircle } from "react-native-svg-charts";
import { useQuery } from "react-query";
import {CreateResponsiveStyle, DEVICE_SIZES, maxSize} from "rn-responsive-styles";
import { colors } from "styles";
import { CalculationUtils, QueryUtils, ValutaUtils } from "utils";

import { QueryKeys } from 'enums';
import PageContainer from "components/PageContainer";
import { Card, HeaderCard, ScenariosCard } from "components/Card";
import MortgageItemCard from "components/Card/MortgageItemCard";

export default () => {
    const navigation = useNavigation();
    const { width, height } = useWindowDimensions();

    const styles = useStyle();
    const { showModal } = useContext(ModalContext);
    const margin = useLayoutMargin();

    const scopedStyles = scopedResponsiveStyles();
    const { data: mortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const { data: sortedMortgages, handleSearchQuery } = useSorted(mortgages);

    const { total, restant, payed } = CalculationUtils.totalMortgage(mortgages);
    const { monthly, aflossing, rente } = CalculationUtils.monthlyMortgage(mortgages);
    const [pieSize, setPieSize] = useState(null);

    const renderMortgageProgessCircle = () => {

        const amountAfgelost = (total != 0) ? (100 - Math.floor(restant / total * 100)) : 0;

        return (

            <View
                style={[scopedStyles.progressSizer, { width: pieSize }]}
                onLayout={(e) => setPieSize(e.nativeEvent.layout.height)}
            >
                <View style={scopedStyles.progressContainer}>
                    <ProgressCircle
                        style={scopedStyles.progressCircle}
                        strokeWidth={16}
                        progress={restant / total}
                        progressColor={colors.secondary}
                        backgroundColor={colors.darkgrey}
                        startAngle={-Math.PI * 0.95}
                        endAngle={Math.PI * 0.95}
                    />
                    <View style={[styles.flexRow, { justifyContent: 'space-between' }]}>
                        <TText semibold xl style={{ transform: 'scale(1.5)' }}>{amountAfgelost}</TText>
                        <TText md style={{ marginTop: 15, marginLeft: 15 }}> %</TText>
                    </View>
                    <TText semibold sm center>#afgelost</TText>
                </View >
            </View>
        )
    }

    const renderButtons = () => {
        return (
            <Card style={scopedStyles.mortgageOptionsCard}>
                <TText md semibold white>#your_mortgages</TText>
                <TText sm white>#mortgages_info</TText>
                <Button
                    label="#mortgage_hypotheekcheck"
                    type='secondary'
                    onPress={() => showModal(MortgageRequestModal, { mode: 'hypotheekcheck' }, 'MortgageRequestModal')}
                />
                <Button
                    label="#mortgage_verbouwen"
                    type='secondary'
                    onPress={() => showModal(MortgageRequestModal, { mode: 'verbouwen' }, 'MortgageRequestModal')}
                />
                <Button
                    label="#mortgage_verhuizen"
                    type='secondary'
                    onPress={() => showModal(MortgageRequestModal, { mode: 'verhuizen' }, 'MortgageRequestModal')}
                />
                {Platform.OS === 'web' ?                     
                    <Button
                        label={mortgages?.length == 0 ? "#mortgage_create" : "#mortgage_enrich"}
                        type='secondary'
                        onPress={() => navigation.navigate('portaal', { type: PortalInvitationType.MORTGAGE_ENRICH })}                        
                    />
                    : null}
            </Card>
        )
    }

    const renderMortgageTotal = () => {
        return (
            <Card style={scopedStyles.mortgageTotalCard}>
                <View style={scopedStyles.mortgageTotalCircle}>
                    <MortgageProgressCircle/>
                </View>

                <View style={scopedStyles.mortgageTotalList}>

                    <Card style={{backgroundColor: colors.background, width: '100%'}}>

                        <TText md semibold style={{ marginBottom: 8 }}>#mortgage_data</TText>

                        <View style={[styles.flexRow, scopedStyles.dataRows]}>
                            <TText sm regular>#original_value</TText>
                            <TText sm semibold >{ValutaUtils.valutaString(total)}</TText>
                        </View>
                        <View style={[styles.flexRow, scopedStyles.dataRows]}>
                            <TText sm regular>#rest_value</TText>
                            <TText sm semibold >{ValutaUtils.valutaString(restant)}</TText>
                        </View>
                        <View style={[styles.flexRow, scopedStyles.dataRows]}>
                            <TText sm regular>#paid_value</TText>
                            <TText sm semibold >{ValutaUtils.valutaString(payed)}</TText>
                        </View>
                    
                        <View style={{
                            height: 0,
                            width: '100%',
                            marginVertical: 8,
                            borderColor: colors.border,
                            borderBottomWidth: 1
                        }}></View>


                        <View style={[styles.flexRow, scopedStyles.dataRows]}>
                            <TText sm regular>#monthly</TText>
                            <TText sm semibold>{ValutaUtils.valutaString(monthly)}</TText>
                        </View>
                        <View style={[styles.flexRow, scopedStyles.dataRows]}>
                            <TText sm regular style={{ alignSelf: 'center', flexGrow: 1 }}>#redeemed</TText>
                            <TText sm semibold style={{ alignSelf: 'center' }} >{ValutaUtils.valutaString(aflossing)}</TText>
                        </View>
                        <View style={[styles.flexRow, scopedStyles.dataRows]}>
                            <TText sm regular style={{ alignSelf: 'center', flexGrow: 1 }}>#interest</TText>
                            <TText sm semibold style={{ alignSelf: 'center' }} >{ValutaUtils.valutaString(rente)}</TText>
                        </View>
                    </Card>



                </View>
            </Card>
        );
    }

    const renderPie = (data, label = 0, drawZero = false) => {

        const pieData = data.map((val, index) => ({
            value: val.value,
            svg: {
                fill: val.color,
            },
            key: `pie-${index}`,
        }))

        return (drawZero && (!label || label == 0)) ? (
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <View style={{
                    width: width < 992 ? 100 : 80,
                    height: width < 992 ? 100 : 80,
                    borderRadius: 200,
                    borderColor: colors.primary,
                    borderWidth: 8,
                    justifyContent: "center",
                    alignItems: 'center'
                }}>
                    <TText sm center >{ValutaUtils.valutaString('0,0')}</TText>
                </View>
            </View>
        ) : (
            <View style={styles.fullscreen}>
                <PieChart style={{ width: '100%', height: '100%' }} data={pieData} innerRadius={'85%'} padAngle={0} />
                <View style={{ position: 'absolute', height: '100%', width: '100%', justifyContent: 'center' }}>
                    {label !== 0 && <TText sm regular center >{ValutaUtils.valutaString(label)}</TText>}
                </View>
            </View>
        );
    }

    const renderCurrentAddressBadge = (currentAdress: boolean) => {
        return (currentAdress) ? (
            <Button
                label="#primary_living"
                type='action'
                onPress={() => { }}
                unclickable
                style={{
                    position: 'absolute',
                    left: -10,
                    top: -10
                }}
            />
        ) : undefined;
    }


    const renderMortgageItems = () => {

        const activeAddress = customer?.addresses?.filter(address => address.active)[0] || { street_name: '', house_number: '', house_number_addition: '', city: '', postcode: '' };

        return mortgages?.length == 0 ? (
            <View style={[{ height: '100%', width: '100%', justifyContent: "center", alignItems: "center", flexDirection: 'row', marginLeft: margin * -0.5 }]}>
                <TText white md semibold>#empty_screen_pre #empty_screen_mortgages #empty_screen_post</TText>
            </View>
        ) : (
            sortedMortgages?.sort((a: Mortgage, b: Mortgage) => {
                const currentAddress = a.mainProperty.street && (
                    a.mainProperty.street == activeAddress.street_name &&
                    a.mainProperty.number == activeAddress.house_number &&
                    a.mainProperty.number_addition == activeAddress.house_number_addition &&
                    a.mainProperty.postal_code == activeAddress.postcode
                );
                return currentAddress ? -1 : 1;
            })
                ?.map((mortgage: Mortgage, index) => {

                    return (
                        <Pressable
                            key={mortgage.id + 'm' + index}
                            onPress={() => showModal(MortgageModal, { mortgage }, 'MortgageModal')}
                        >
                            <MortgageItemCard mortgage={mortgage} activeAddress={activeAddress} />
                        </Pressable>
                    )
                })
        );
    }

    return (
        <PageContainer>

            <View style={[scopedStyles.mortgageTopRow, styles.gap30]}>
                
                {renderMortgageTotal()}

                {renderButtons()}

            </View>

            <ScenariosCard/>

            <HeaderCard header="#title_mortgage">

                {renderMortgageItems()}

            </HeaderCard>

        </PageContainer>
    )
}


const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        mortgageTopRow: {
            flexDirection: 'row'
        },
        mortgageTotalCard: {
            flexDirection: 'row',
        },
        mortgageOptionsCard: {
            backgroundColor: colors.primary,
            gap: 10
        },
        mortgageTotalCircle: {
            // margin: 10,
            flex: 0.4,
            alignItems: "center",
        },
        mortgageTotalList: {
            // margin: 10,
            flex: 0.6,
            alignItems: "center",
        },
        progressContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        },
        progressCircle: {
            position: 'absolute',
            height: '100%',
            width: '100%'
        },
        progressSizer: {
            height: '100%',
            right: 0,
            justifyContent: "center"
        },
        buttonContainer: {
            position: "absolute",
            bottom: 0,
            left: 0,
            width: '50%',
            flexDirection: 'row',
        },
        dataRows: {
            flexWrap: 'nowrap',
            width: '100%',
            justifyContent: 'space-between'
        },
        dataContainer: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            bottom: 0,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: "flex-end"
        },        

    }, {
        [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
            mortgageTotalCard: {
            },
        },
        [DEVICE_SIZES.SMALL_DEVICE]: {
            mortgageTotalCard: {
            },
        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            progressContainer: {
                width: Dimensions.get('window').height * 0.32,
                height: Dimensions.get('window').height * 0.32,
                left: 0,
                top: 40
            },
            progressSizer: {
                height: 'auto',
                alignItems: 'center'
            },
            progressCircle: {
                height: '85%',
                width: '85%'
            },
            dataContainer: {
                width: '100%',
                flexDirection: 'column-reverse',
                justifyContent: 'space-between',
                alignItems: 'center'
            },
            buttonContainer: {
                position: 'relative',
                marginTop: 12,
                width: '100%',
                flexWrap: 'wrap'
            },
        },
        [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
            mortgageTopRow: {
                flexDirection: 'column',
            },
            mortgageTotalCard: {
                flexDirection: 'column',
            },
            mortgageTotalList: {
                marginBottom: 40,
            },
        },
        [DEVICE_SIZES.LARGE_DEVICE]: {
            mortgageTopRow: {
                flexDirection: 'column',
            },
            mortgageTotalCard: {
            },
        },
        [DEVICE_SIZES.EXTRA_LARGE_DEVICE]: {
            mortgageTotalCard: {
                flex: 0.69,
            },
            mortgageOptionsCard: {
                flex: 0.31,
            },
        },
}
)
