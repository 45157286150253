
export class Signal {
    comment: string
    created_at: string
    customer_id: number
    customer_message: string
    customer_message_html: string
    description: string
    description_html: string
    finished_at: string
    finished_by: string
    id: number
    searchable_id: number
    searchable_type: string
    signal_query_id: number
    snoozed_until: string
    status: number
    updated_at: string
    isSignal: boolean
    seen_by_customer: boolean

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Signal {
        this.comment = json.comment ? json.comment : '';
        this.created_at = json.created_at ? json.created_at : '';
        this.customer_id = json.customer_id ? json.customer_id : 0;
        this.customer_message = json.customer_message ? json.customer_message : '';
        this.customer_message_html = json.customer_message_html ? json.customer_message_html : '';
        this.description = json.description ? json.description : '';
        this.description_html = json.description_html ? json.description_html : '';
        this.finished_at = json.finished_at ? json.finished_at : '';
        this.finished_by = json.finished_by ? json.finished_by : '';
        this.id = json.id ? json.id : 0;
        this.searchable_id = json.searchable_id ? json.searchable_id : 0;
        this.searchable_type = json.searchable_type ? json.searchable_type : '';
        this.signal_query_id = json.signal_query_id ? json.signal_query_id : '';
        this.snoozed_until = json.snoozed_until ? json.snoozed_until : '';
        this.status = json.status ? json.status : 0;
        this.updated_at = json.updated_at ? json.updated_at : '';
        this.seen_by_customer = json.seen_by_customer ? json.seen_by_customer : false;
        this.isSignal = true;
        return this;
    }
    
}
