import { QueryKeys } from 'enums';
import i18n from 'i18n-js';
import de from 'i18n/de';
import en from 'i18n/en';
import nl from 'i18n/nl';
import { StoreContext } from 'providers/StoreProvider';
import { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { QueryUtils } from 'utils';

i18n.fallbacks = true;
i18n.translations = {
    nl,
    en,
    de
};
i18n.locale = 'nl'; //Localization.locale;


export default () => {

    const { language, loggedIn } = useContext(StoreContext);
    const { data: tenant } = useQuery(QueryKeys.TENANT, loggedIn ? QueryUtils.fetchTenant : () => { return undefined });
    useEffect(() => { i18n.locale = language }, [language])

    const translate = (text: string | string[]): string => {

        const replacePronoun = (translated: string) => {
            if (language == 'nl' && (tenant?.pronoun && tenant?.pronoun != 'JE') && (translated.search(/jouw/i) >= 0 || translated.search(/je/i) >= 0)) {
                return i18n.t(text + '_u');
            }
            if (language == 'de' && (tenant?.pronoun && tenant?.pronoun != 'JE') && (translated.search(/ du /i) >= 0 || translated.search(/dein/i) >= 0)) {
                return i18n.t(text + '_u');
            }
            return translated;
        }

        //split strings with a space in them
        if (typeof text == 'string' && text.includes(' ')) text = text.split(' ');

        //omit valuta values
        if (typeof text == 'string' && text.includes('€')) return text;

        //translate every word in an array
        if (Array.isArray(text)) {
            return text.map(t => translate(t)).join(' ');
        }

        //translate actual translatable text ( prefix with # )
        return (text?.includes && text.includes('#')) ? replacePronoun(i18n.t(text)) : text;
    }

    return translate;
}
