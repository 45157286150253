import { openSettings } from "expo-linking";
import * as Notifications from "expo-notifications";
import { useTranslation } from "hooks";
import { StoreContext } from "providers/StoreProvider";
import { useContext, useEffect } from "react";
import { Alert, Platform } from 'react-native';
import { AuthUtils, DeviceUtils } from "utils";

export default () => {

    const { loggedIn, notifications, setNotification } = useContext(StoreContext);
    const translate = useTranslation();

    useEffect(() => {
        if (loggedIn) checkPushSubscription(true);
    }, [loggedIn])

    useEffect(() => {
        if (loggedIn) checkPushSubscription(false);
    }, [notifications])


    const checkPushSubscription = async (reportDevice: boolean) => {

        const users = await AuthUtils.getUsers();
        const currentUserId = await AuthUtils.getCurrentUserId();
        const storagePushID = users[currentUserId]?.push_id;
        let { id = null, token = null } = storagePushID ? storagePushID : {};

        if (!token && notifications && Platform.OS !== 'web') {

            // @ts-ignore-next-line
            token = await registerPushSubscriptions();

        } else if (token && !notifications) {

            removePushSubscription(id);
            token = id = null;

        }

        if (reportDevice) {

            const { device } = await DeviceUtils.createDevice(token ? token : 'NO_TOKEN', Platform.OS);
            if (device.id) id = String(device.id);

        }

        if (currentUserId) {
            users[currentUserId] = {
                ...users[currentUserId],
                push_id: { id: id, token: token }
            };
            AuthUtils.storeUsers(users);
        }

    };

    const registerPushSubscriptions = () => {

        return new Promise((resolve, _) => {

            setTimeout(async () => {

                const { status: existingStatus } = await Notifications.getPermissionsAsync();
                let finalStatus = existingStatus;

                if (existingStatus !== 'granted') {
                    const { status } = await Notifications.requestPermissionsAsync();
                    finalStatus = status;
                }
                if (finalStatus !== 'granted') {
                    Alert.alert(
                        translate("#notifications_title"),
                        translate("#notifications_text"),
                        [
                            {
                                text: translate("#cancel"),
                                style: "cancel",
                                onPress: () => setNotification(false)
                            },
                            {
                                text: translate("#open_settings"),
                                onPress: openSettings,
                            },
                        ]
                    );
                    resolve(null);
                }

                const pushtoken: string = (await Notifications.getExpoPushTokenAsync()).data;
                resolve(pushtoken);

            }, notifications ? 0 : 4 * 1000);

        });

    }

    const removePushSubscription = async (id: string) => {
        if (id) DeviceUtils.deleteDevice(id);
        const users = await AuthUtils.getUsers();
        const currentUserId = await AuthUtils.getCurrentUserId();
        if (currentUserId) {
            users[currentUserId] = {
                ...users[currentUserId],
                push_id: undefined
            };
            AuthUtils.storeUsers(users);
        }
    }

}