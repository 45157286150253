import { CreateResponsiveStyle} from "rn-responsive-styles";
import TText from "./TText";
import { Pressable, Text, View, ViewStyle } from "react-native";

export default ({style} : {style?: ViewStyle}) => {
    const scopedStyles = scopedResponsiveStyle();
    return (
        <View style={[scopedStyles.footer, style]}>
            <Text style={{textAlign: "center"}}>
                <TText xs>#footer_text{new Date().getFullYear()} </TText>
                <TText xs>#footer_disclaimer1</TText>
                <TText xs>#footer_disclaimer2</TText>
            </Text>
            <Text style={{marginTop: -5}}>
                <Pressable onPress={() => window.open('https://www.eigenhuis.nl/privacystatement-en-cookiebeleid')}>
                    <TText url xs>privacy</TText>
                </Pressable>
                <TText>{' • '}</TText>
                <Pressable onPress={() => window.open('https://www.eigenhuis.nl/disclaimer')}>
                    <TText url xs>disclaimer</TText>
                </Pressable>
                <TText>{' • '}</TText>
                <Pressable onPress={() => window.open('https://www.eigenhuis.nl/privacystatement-en-cookiebeleid')}>
                    <TText url xs>cookies</TText>
                </Pressable>
                <TText>{' • '}</TText>
                <Pressable onPress={() => window.open('https://www.eigenhuis.nl/algemene-voorwaarden')}>
                    <TText url xs>gebruikersovereenkomst</TText>
                </Pressable>
            </Text>
        </View>
    )
}

const scopedResponsiveStyle = CreateResponsiveStyle({
    footer: {
        alignItems: 'center',
    }
});