import TText from "../../TText";
import { useHover, useStyle } from "hooks";
import Button from "components/Button";
import { colors } from "styles";
import { ValutaUtils } from "utils";
import { Card, IconCard } from "components/Card";
import { Image, View, Text } from "react-native";
import Constants from "expo-constants";
import { HdnCompanyImage, Icon } from "components";

const Config = Constants.expoConfig.extra.config;


export default ({proposal, interestRates, index, selectedPeriod, onSelectProposal}) => {

    const styles = useStyle();
    const [hovered, hoverElt] = useHover();

    return (
        <View ref={hoverElt} style={[styles.flexRow, styles.gap20, styles.alignCenter, {height: 150, width: '100%', paddingVertical: 0}]}>

        {index > 0 ? (
            <Card 
                style={{
                    backgroundColor: hovered ? colors.secondary : colors.secondary2,
                    width: 30,
                    height: 30,
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <TText 
                    secondary 
                    semibold
                    md
                    style={{color: hovered ? colors.white : colors.secondary}}
                >
                    {index}
                </TText>
            </Card>
        ) : (
            <IconCard 
                iconName="IconArrowRight" 
                iconSize={25} 
                iconColor={hovered ? colors.white : colors.secondary}
                style={{
                    width: 60,
                    height: 60,
                    borderRadius: 20,
                    backgroundColor: hovered ? colors.secondary : colors.secondary2
                }}
            />
        )}

            <Card 
                style={[styles.flexRow, styles.alignCenter, styles.gap20, {
                    flex: 1, 
                    height: '100%', 
                    borderWidth: 1, 
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    backgroundColor: hovered ? colors.secondary1 : colors.white,
                    borderColor: hovered ? colors.secondary : colors.background
                }]}
            >

                <Card style={[{borderWidth: 1, borderColor: colors.background, padding: 5, borderRadius: 10, height: '100%'}]}>
                    <HdnCompanyImage
                        code={proposal.lender_id}
                    />
                </Card>

                <View style={[styles.flexRow, styles.flexStretch, styles.justifyBetween, styles.gap30, {height: '100%'}]}>

                    <View style={[styles.justifyBetween, styles.pv10, {height: '100%'}]}>
                        <View>
                            <TText semibold>{proposal.product_line_name}</TText>
                            <Text>
                                <TText>Bespaar </TText>
                                <TText semibold>{ValutaUtils.valutaString(Math.abs(proposal.net_total_difference_during_interest_period))}</TText>
                                <TText> per maand</TText>
                            </Text>
                        </View>
                        <Button
                            type="secondary"
                            label="vergelijk met jouw situatie"
                            onPress={() => onSelectProposal()}
                        />
                    </View>

                    <View style={[styles.flexRow,  styles.flexStretch, styles.alignCenter, styles.justifyEnd, styles.gap10, {height: '100%'}]}>

                        {Object.entries(interestRates).map(([key, rate]) => {
                            const isPeriodSelected = Number(key) === Number(selectedPeriod);                            

                            return (
                                <View 
                                    style={[
                                        styles.flexCol, styles.alignCenter, styles.justifyBetween, styles.gap10, styles.borderRadius10,
                                        {
                                            height: '100%',
                                            minWidth: 100,
                                            padding: 10,
                                            backgroundColor: isPeriodSelected ? hovered ? colors.secondary3 : colors.background : colors.transparent
                                        }
                                    ]}
                                >

                                    <View style={[styles.flexCol, styles.alignCenter, styles.gap10]}>
                                        <TText semibold>{Number(key) / 12} jaar</TText>
                                        <TText md>{rate ? `${rate} %` : ''}</TText>
                                    </View>

                                    <View style={{flex: 1, justifyContent: "flex-end"}}>
                                        {isPeriodSelected ? (
                                            <View style={[
                                                styles.borderRadius5, styles.alignCenter, styles.justifyCenter, 
                                                {backgroundColor: colors.primary, padding: 5, paddingVertical: 5}
                                            ]}>
                                                <TText semibold white>{ValutaUtils.valutaString(proposal.gross_monthly_payments)}</TText>
                                            </View>
                                        ):(
                                            <View style={styles.p10}></View>
                                        )
                                        }
                                    </View>
                                </View>
                            )
                        })}
                    </View>

                </View>

            </Card>

        </View>
    );
};
