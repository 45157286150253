import axios from 'axios';
import { DateTimeFormat } from 'enums';
import Constants from 'expo-constants';
import {Customer, Living, Obligation, Person} from 'models';
import moment from 'moment';
import {ObligationTypes} from "enums";

const Config = Constants.expoConfig.extra.config;


export default {
    getMaximumMortgageState(customer: Customer, family: any, obligations: Array<Obligation>, living: Living) {
        
        let state = {};
        let partner: Person = family.partner;
        let hasPartner = partner != null;

        /*
         * Mijn situatie
         */
        state = {
            ...state,
            'state.has_partner': hasPartner,
            'state.applicant.day_of_birth': moment(customer.date_of_birth).format('DD'),
            'state.applicant.month_of_birth': moment(customer.date_of_birth).format('MM'),
            'state.applicant.year_of_birth': moment(customer.date_of_birth).format('YYYY'),
            'state.first_name': customer.first_name,
            'state.insertion': customer.suffix,
            'state.last_name': customer.last_name,
            'state.email': customer.email_address,
            'state.phone_number': customer.phone ?? customer.phone_work,
            'state.contact_preference': null,
            'state.subscribe_to_mailing': null,
            'state.collaborating_party': null,
            'state.i_give_permission': null,
        }

        if (hasPartner) {
            state = {
                ...state,
                'state.partner.day_of_birth': moment(partner.date_of_birth).format('DD'),
                'state.partner.month_of_birth': moment(partner.date_of_birth).format('MM'),
                'state.partner.year_of_birth': moment(partner.date_of_birth).format('YYYY'),
            }
        }

        this.addIncomeToState(state, customer, partner);
        this.addObligationsToState(state, obligations, null);


        if (living
            && living.property
        ) {
            state = {
                ...state,
                'state.postal_code': living.property.postcode,
                'state.house_number': living.property.house_number,
                'state.house_number_addition': living.property.house_number_addition,
            }
        }

        return JSON.stringify(state);
    },
    async getScenarios( data: Record<string, any> ){
        return await axios.post(`${Config.ADVIES_WIDGET_API_URL}/scenarios?api_key=qQfAWU4IBh50TCMNATaDkLJEItQfotGMDeLpSBvRXF5Q5c18vBjo4zLyCD5lhNLa`, data);
    },
    async getRefinance( data: Record<string, any> ){
        return await axios.post(`${Config.ADVIES_WIDGET_API_URL}/refinance?api_key=nqAZBChEp8tVNsPMWsXFqr2oYwiF8BXEhOTJFGMutUY7JHdbPrc9Zrln56JsWR0q`, data);
    },
    async getMarketvalueLimit(){
        return await axios.get(`${Config.ADVIES_WIDGET_API_URL}/refinance?api_key=nqAZBChEp8tVNsPMWsXFqr2oYwiF8BXEhOTJFGMutUY7JHdbPrc9Zrln56JsWR0q`);
    },
    addObligationsToState(state: Record<any, any>, obligations: Array<Obligation>, partnerObligations: Array<Obligation> | null) {
        if (obligations.length === 0) {
            return;
        }

        let studyLoanObligation: Obligation | undefined = obligations.find(obligation => obligation.type === ObligationTypes.STUDYLOAN);
        if (studyLoanObligation !== undefined) {
            state['state.applicant.has_student_debt'] = true;
            state['state.applicant.student_debt_monthly_payment'] = studyLoanObligation.monthly_amount;
        } else {
            state['state.applicant.has_student_debt'] = false;
        }

        let alimonyObligation: Obligation | undefined = obligations.find(obligation => obligation.type === ObligationTypes.ALIMONY);
        if (alimonyObligation !== undefined) {
            state['state.applicant.has_partner_alimony'] = true;
            state['state.applicant.partner_alimony'] = Math.floor(alimonyObligation.gross_annual_obligation / 12);
        } else {
            state['state.applicant.has_student_debt'] = false;
        }

        let creditObligation: Obligation | undefined = obligations.find((obligation) => obligation.type === ObligationTypes.CREDIT);
        if (creditObligation !== undefined) {
            state['state.applicant.has_loans'] = true;
            state['state.applicant.loans'] = creditObligation.credit_limit;
        } else {
            state['state.applicant.has_loans'] = true;
        }

        state = {
            ...state,
            'state.applicant.has_lease_hold': false,
            'state.applicant.has_private_lease': false,
            'state.partner.has_lease_hold': false,
            'state.partner.has_private_lease': false,
        }

        if (partnerObligations === null || partnerObligations.length === null) {
            return;
        }

        let partnerStudyLoanObligation: Obligation | undefined = partnerObligations.find(obligation => obligation.type === ObligationTypes.STUDYLOAN);
        if (partnerStudyLoanObligation !== undefined) {
            state['state.partner.has_student_debt'] = true;
            state['state.partner.student_debt_monthly_payment'] = partnerStudyLoanObligation.monthly_amount;
        } else {
            state['state.partner.has_student_debt'] = false;
        }

        let partnerAlimonyObligation: Obligation | undefined = partnerObligations.find(obligation => obligation.type === ObligationTypes.ALIMONY);
        if (partnerAlimonyObligation !== undefined) {
            state['state.partner.has_partner_alimony'] = true;
            state['state.partner.partner_alimony'] = Math.floor(partnerAlimonyObligation.gross_annual_obligation / 12);
        } else {
            state['state.partner.has_student_debt'] = false;
        }

        let partnerCreditObligation: Obligation | undefined = partnerObligations.find(obligation => obligation.type === ObligationTypes.CREDIT);
        if (partnerCreditObligation !== undefined) {
            state['state.partner.has_loans'] = true;
            state['state.partner.loans'] = partnerCreditObligation.credit_limit;
        } else {
            state['state.partner.has_loans'] = true;
        }
    },
    addIncomeToState(state: Record<any, any>, customer: Customer, partner: Person) {
        /*
         * Inkomen
         *
         * Voor deze eerste versie zetten we het geven de klant een vast contract met het maandinkomen dat in dossierdata bekend is.
         * Voor een latere versie kunnen we kijken naar de specifice inkomentypes.
         */
        state['state.applicant.employment'] = 'salary';
        state['state.applicant.contract_type'] = 'fixed';
        state['state.applicant.income'] = Math.floor(customer.gross_annual_income / 12);
        state['state.applicant.has_holiday_allowance'] = false;
        state['state.applicant.has_extra_payment'] = false;

        if (partner !== null) {
            if (partner.gross_annual_income > 0) {
                state['state.partner.employment'] = 'salary';
                state['state.partner.contract_type'] = 'fixed';
                state['state.partner.income'] = Math.floor(partner.gross_annual_income / 12);
                state['state.partner.has_holiday_allowance'] = false;
                state['state.partner.has_extra_payment'] = false;
            } else {
                state['state.partner.employment'] = 'none';
            }
        }
    }
}