import { DrawerItem } from '@react-navigation/drawer';
import { DrawerActions } from '@react-navigation/native';
import { Icons } from 'icons';
import { Badge, DrawerToggle, HypotheekwachtLogo, LanguageToggle, NotificationToggle, Person, TText } from 'components';
import {IconCard} from 'components/Card';
import { ChangePasswordModal, EditModal } from 'components/Modals';
import { DateTimeFormat, QueryKeys, TenantShowCategoryKeys } from 'enums';
import Constants from "expo-constants";
import { useHover, useNewMessageCount, useStyle, useTranslation } from 'hooks';
import moment from 'moment';
import { ModalContext } from 'providers/ModalProvider';
import { StoreContext } from 'providers/StoreProvider';
import { useContext, useEffect, useRef, useState } from 'react';
import { Image, Platform, Pressable, ScrollView, Text, View, ViewStyle } from 'react-native';
import { useQuery } from 'react-query';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { colors } from 'styles';
import { QueryUtils, SentryUtils } from 'utils';

interface NavItemType {
    route: string, 
    title: string, 
    icon: string, 
    iconColor: string, 
    iconStyle?: ViewStyle, 
    cardStyle?: ViewStyle, 
    count?: number
}

const Drawer: React.FC<{ navigation: any, state: any }> = ({ navigation, state }) => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();

    const { data: tenant } = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);
    const { data: policies } = useQuery(QueryKeys.POLICY, QueryUtils.fetchPolicy);    
    const { data: mortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);
    const { data: incomes } = useQuery(QueryKeys.INCOME, QueryUtils.fetchIncome);
    const { data: retirements } = useQuery(QueryKeys.RETIREMENT, QueryUtils.fetchRetirement);

    const navState = navigation.getState();
    const route = navState.routeNames[navState.index];

    const navItemsHoverBank: any[] = useHover(9);
    const [navItems, setNavItems] = useState<NavItemType[]>([]);
    useEffect(() => {
        if (tenant) {
            const items = [];
            items.push({ 
                route: 'dashboard', 
                title: '#menu_overview', 
                icon: 'IconLayoutDashboard'
            });
            items.push({ 
                route: 'wonen', 
                title: '#menu_living', 
                icon: 'IconHome'
            });
            if (tenant?.show_mortgages !== false) {
                items.push({ 
                    route: 'hypotheken', 
                    title: '#menu_mortgage', 
                    icon: 'IconClipboardData',
                    count: mortgages?.length
                });
            }
            if (tenant?.show_policies !== false) {
                items.push({ 
                    route: 'verzekeringen', 
                    title: '#menu_policy', 
                    icon: 'IconChartPie4',
                    count: policies?.length
                });
            }
            if (tenant?.show_income !== false) {
                items.push({ 
                    route: 'inkomen', 
                    title: '#menu_income', 
                    icon: 'IconCash',
                });
            }
            if (tenant?.show_income !== false) {
                items.push({ 
                    route: 'pensioen', 
                    title: '#menu_retirement', 
                    icon: 'IconOld',
                });
            }
            if (tenant?.show_obligations !== false) {
                items.push({ 
                    route: 'verplichtingen', 
                    title: '#menu_obligation', 
                    icon: 'IconChecklist',
                });
            }
            items.push({ 
                route: 'services', 
                title: '#menu_other_services', 
                icon: 'IconMessage'
            });
            items.push({ 
                route: 'contact', 
                title: '#menu_contact', 
                icon: 'IconMailPin'
            });
            // items.push({
            //     route: 'documenten',
            //     title: '#title_documents',
            //     icon: 'IconFileDescription'
            // });
            setNavItems(items);
        }
    }, [tenant, mortgages, policies, incomes, retirements])


    const renderHeader = () => {
        return (
            <View style={scopedStyles.header}>
                <Pressable
                    style={{ width: '100%', height: 110 }}
                    onPress={() => navigation.navigate('dashboard')}
                >
                    <Image
                        source={tenant?.logo ? { uri: 'data:image/png;base64,' + tenant?.logo } : {}}
                        resizeMode='contain'
                        style={{ width: '100%', height: '100%' }}
                    />
                </Pressable>  
            </View>
        );
    }

    const renderNavItems = () => {
        return navItems.map((item, index) => {
            const selectedRoute = route === item.route;
            const [hovered, hoverElt] = navItemsHoverBank[index];
            return (          
                <Pressable 
                    ref={hoverElt}
                    key={index}
                    disabled={selectedRoute}
                    style={[
                        scopedStyles.navItem,
                        {backgroundColor: selectedRoute ? colors.background : colors.transparent}
                    ]} 
                    onPress={() => {
                        navigation.navigate(item.route);
                        SentryUtils.breadCrumb({
                            route: 'drawer-open-route',
                            data: { ...item, icon: undefined }
                        });
                    }}
                >                    
                    <IconCard
                        iconName={item.icon}
                        iconColor={selectedRoute ? colors.secondary : hovered ? colors.primary9 : colors.primary6}
                        style={{backgroundColor: selectedRoute ? colors.white : colors.transparent}}
                    />
                    <TText 
                        semibold={selectedRoute} 
                        style={{
                            flex: 1,
                            color: selectedRoute ? colors.primary : hovered ? colors.primary9 : colors.primary6,
                            transform: hovered && !selectedRoute ? 'scale(1.015)' : undefined,
                            userSelect: 'none'
                        }}
                    >
                        {item.title}
                    </TText>
                    {item.count ? (
                        <Badge
                            sm
                            value={item.count}
                            style={{backgroundColor: hovered ? colors.primary6 : selectedRoute ? colors.primary6 : colors.primary2}}
                        />
                    ) : (
                        <></>
                    ) }
                </Pressable>
            );
        });

    }

    const renderFooter = () => {
        return (
            <View style={{justifyContent: 'flex-end'}}>
                <TText xs grey regular center style={{ marginVertical: 4 }}>
                    DossierData © {new Date().getFullYear() + ' ' + Constants?.expoConfig?.version}
                </TText>
            </View>
        )
    }

    return (
        <View style={scopedStyles.drawer}>

            {renderHeader()}

            <ScrollView 
                showsVerticalScrollIndicator={false}
                style={[styles.ph20, styles.flexStretch, styles.gap10]} 
                contentContainerStyle={[styles.flexStretch]}
            >

                {renderNavItems()}

            </ScrollView>

            
            {renderFooter()}            

        </View>
    );
};

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        drawer: {
            flex: 1,
            overflow: 'hidden'
        },
        header: {
            alignItems: 'center',
            justifyContent: 'center',
            height: 120,
            width: '100%',
        },
        navItem: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 20,
            padding: 10,
            borderRadius: 10,
        },
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            
        }
    }
);

export default Drawer;
