
export class Task {
    id: string;
    title: string;
    status: number;
    description: string;
    date: string | null;
    time: string | null;
    seen_by_customer: boolean
    isTask: boolean;

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Task {
        this.id = json.id ? json.id : "";
        this.title = json.title ? json.title : "";
        this.status = json.status ? json.status : 0;
        this.description = json.description ? json.description : "";
        this.date = json.date ? json.date : '';
        this.time = json.time ? json.time : '';
        this.seen_by_customer = json.seen_by_customer ? json.seen_by_customer : false;
        this.isTask = true;
        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId()
        };
    }

    getId(): string {
        return this.id;
    }
}
