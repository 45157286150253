import {useStyle} from "hooks";
import {Button, TText, ValutaInput} from "components";
import {HeaderCard} from "components/Card";
import {colors} from "styles";
import {useQuery} from "react-query";
import {QueryKeys} from "enums";
import {CustomerUtils, EditDataUtils, QueryUtils} from "utils";
import {Customer, Person} from "models";
import {useEffect, useRef, useState} from "react";
import {number, object} from "yup";
import {useFormik} from "formik";
import {View} from "react-native";
import TabView from "components/TabView";

export default () => {

    const styles = useStyle();
    const {data: customer, refetch: refetchCustomer} = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer, {refetchOnWindowFocus: false, staleTime: Infinity});
    const {data: family, refetch: refetchFamily} = useQuery(QueryKeys.FAMILY, QueryUtils.fetchFamily, {refetchOnWindowFocus: false, staleTime: Infinity});

    const response = useRef<undefined | string>(undefined);
    const [loading, setLoading] = useState(false);
    const [tabs, setTabs] = useState<string[]>([]);

    const changeIncomeSchema = object({
        gross_annual_income: number(),
        gross_annual_old_age_pension_amount: number(),
        gross_annual_annuity_amount: number(),
        gross_annual_partner_pension_amount: number(),
        gross_annual_half_orphan_benefit_amount: number(),

        partner_gross_annual_income: number(),
        partner_gross_annual_old_age_pension_amount: number(),
        partner_gross_annual_annuity_amount: number(),
        partner_gross_annual_partner_pension_amount: number(),
        partner_gross_annual_half_orphan_benefit_amount: number(),
    });

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        setValues,
        values,
        errors,
        touched
    } = useFormik({
        validationSchema: changeIncomeSchema,
        initialValues: {
            gross_annual_income: 0,
            gross_annual_old_age_pension_amount: 0,
            gross_annual_employment_pension_amount: 0,
            gross_annual_annuity_amount: 0,
            gross_annual_partner_pension_amount: 0,
            gross_annual_half_orphan_benefit_amount: 0,

            partner_gross_annual_income: 0,
            partner_gross_annual_old_age_pension_amount: 0,
            partner_gross_annual_employment_pension_amount: 0,
            partner_gross_annual_annuity_amount: 0,
            partner_gross_annual_partner_pension_amount: 0,
            partner_gross_annual_half_orphan_benefit_amount: 0,
        },
        onSubmit: values => {
            setLoading(true);
            response.current = undefined;
            Object.keys(values).map(key => values[key] = Math.floor(values[key]));
            EditDataUtils.uploadIncome(values)
                .then(() => {
                    setLoading(false);
                    response.current = {
                        successful: true,
                        label: 'Je gegevens zijn succesvol gewijzigd'
                    };
                    refetchCustomer();
                    refetchFamily();
                })
                .catch((error) => {
                    setLoading(false);
                    response.current = {
                        successful: false,
                        label: error.response.data.error.message.includes('Could not persist object of type \"App\\Models\\Customer\"') ?
                            'Opslaan is niet gelukt, je adviseur is bezig met jouw dossier' :
                            'Er ging iets mis bij het opslaan van je gegevens'
                    };
                });
        },
    });

    useEffect(() => {
        if (customer instanceof Customer) {
            const newTabs = [
                CustomerUtils.getFullName(customer)
            ];
            const newValues = {
                ...values,
                gross_annual_income: customer.gross_annual_income || 0,
                gross_annual_old_age_pension_amount: customer.gross_annual_old_age_pension_amount || 0,
                gross_annual_employment_pension_amount: customer.gross_annual_employment_pension_amount || 0,
                gross_annual_annuity_amount: customer.gross_annual_annuity_amount || 0,
                gross_annual_partner_pension_amount: customer.gross_annual_partner_pension_amount || 0
            }

            if (customer.can_update_partner && family?.partner instanceof Person) {
                newTabs.push(CustomerUtils.getFullName(family.partner));

                newValues.partner_gross_annual_income = family.partner.gross_annual_income || 0;
                newValues.partner_gross_annual_old_age_pension_amount = family.partner.gross_annual_old_age_pension_amount || 0;
                newValues.partner_gross_annual_employment_pension_amount = family.partner.gross_annual_employment_pension_amount || 0;
                newValues.partner_gross_annual_annuity_amount = family.partner.gross_annual_annuity_amount || 0;
                newValues.partner_gross_annual_partner_pension_amount = family.partner.gross_annual_partner_pension_amount || 0;
            }

            setTabs(newTabs);
            setValues(newValues);
        }
    }, [customer, family])

    return (
        <HeaderCard header="Inkomen" style={styles.gap30}>
            <TText>Op basis van je inkomen wordt bepaald hoeveel je mag lenen voor je hypotheek.</TText>
            <TabView tabs={tabs}>

                <View style={styles.gap30}>
                    <View style={styles.gap10}>
                        <TText secondary semibold>Inkomen</TText>
                        <ValutaInput
                            label={"Bruto inkomen per jaar"}
                            value={values.gross_annual_income}
                            onChange={(value: number) => setValues(v => ({...v, gross_annual_income: value}))}
                            onBlur={(e) => handleBlur('gross_annual_income')}
                            error={(touched.gross_annual_income && errors.gross_annual_income) ? errors.gross_annual_income : undefined}
                        />
                    </View>
                    <View style={styles.gap10}>
                        <TText secondary semibold>Pensioeninkomen</TText>
                        <ValutaInput
                            label={"Bruto werknemerspensioen per jaar"}
                            value={values.gross_annual_employment_pension_amount}
                            onChange={(value: number) => setValues(v => ({
                                ...v,
                                gross_annual_employment_pension_amount: value
                            }))}
                            onBlur={(e) => handleBlur('gross_annual_employment_pension_amount')}
                            error={(touched.gross_annual_employment_pension_amount && errors.gross_annual_employment_pension_amount) ? errors.gross_annual_employment_pension_amount : undefined}
                        />
                        <ValutaInput
                            label={"Bruto AOW per jaar"}
                            value={values.gross_annual_old_age_pension_amount}
                            onChange={(value: number) => setValues(v => ({
                                ...v,
                                gross_annual_old_age_pension_amount: value
                            }))}
                            onBlur={(e) => handleBlur('gross_annual_old_age_pension_amount')}
                            error={(touched.gross_annual_old_age_pension_amount && errors.gross_annual_old_age_pension_amount) ? errors.gross_annual_old_age_pension_amount : undefined}
                        />

                        {(customer.can_update_partner && family?.partner instanceof Person) && (
                            <View>
                                <ValutaInput
                                    label={"Bruto nabestaandenpensioen per jaar"}
                                    value={values.gross_annual_partner_pension_amount}
                                    onChange={(value: number) => setValues(v => ({
                                        ...v,
                                        gross_annual_partner_pension_amount: value
                                    }))}
                                    onBlur={(e) => handleBlur('gross_annual_partner_pension_amount')}
                                    error={(touched.gross_annual_partner_pension_amount && errors.gross_annual_partner_pension_amount) ? errors.gross_annual_partner_pension_amount : undefined}
                                />
                                <TText regular sm style={{textAlign: 'left'}}>Dit krijg jij als je partner
                                    overlijdt.</TText>
                            </View>
                        )}
                    </View>
                    <View style={styles.gap10}>
                        <TText secondary semibold>Lijfrente gegevens</TText>
                        <ValutaInput
                            label={"Jaarlijks annuïtaire uitkering"}
                            value={values.gross_annual_annuity_amount}
                            onChange={(value: number) => setValues(v => ({...v, gross_annual_annuity_amount: value}))}
                            onBlur={(e) => handleBlur('gross_annual_annuity_amount')}
                            error={(touched.gross_annual_annuity_amount && errors.gross_annual_annuity_amount) ? errors.gross_annual_annuity_amount : undefined}
                        />
                    </View>
                </View>

                <View style={styles.gap30}>
                    <View style={styles.gap10}>
                        <TText secondary semibold>Inkomen</TText>
                        <ValutaInput
                            label={"Bruto inkomen per jaar"}
                            value={values.partner_gross_annual_income}
                            onChange={(value: number) => setValues(v => ({...v, partner_gross_annual_income: value}))}
                            onBlur={(e) => handleBlur('partner_gross_annual_income')}
                            error={(touched.partner_gross_annual_income && errors.partner_gross_annual_income) ? errors.partner_gross_annual_income : undefined}
                        />
                    </View>
                    <View style={styles.gap10}>
                        <TText secondary semibold>Pensioeninkomen</TText>
                        <ValutaInput
                            label={"Bruto werknemerspensioen per jaar"}
                            value={values.partner_gross_annual_employment_pension_amount}
                            onChange={(value: number) => setValues(v => ({
                                ...v,
                                partner_gross_annual_employment_pension_amount: value
                            }))}
                            onBlur={(e) => handleBlur('partner_gross_annual_employment_pension_amount')}
                            error={(touched.partner_gross_annual_employment_pension_amount && errors.partner_gross_annual_employment_pension_amount) ? errors.partner_gross_annual_old_age_pension_amount : undefined}
                        />
                        <ValutaInput
                            label={"Bruto AOW per jaar"}
                            value={values.partner_gross_annual_old_age_pension_amount}
                            onChange={(value: number) => setValues(v => ({
                                ...v,
                                partner_gross_annual_old_age_pension_amount: value
                            }))}
                            onBlur={(e) => handleBlur('partner_gross_annual_old_age_pension_amount')}
                            error={(touched.partner_gross_annual_old_age_pension_amount && errors.partner_gross_annual_old_age_pension_amount) ? errors.partner_gross_annual_old_age_pension_amount : undefined}
                        />
                        <View>
                            <ValutaInput
                                label={"Bruto nabestaandenpensioen per jaar"}
                                value={values.partner_gross_annual_partner_pension_amount}
                                onChange={(value: number) => setValues(v => ({
                                    ...v,
                                    partner_gross_annual_partner_pension_amount: value
                                }))}
                                onBlur={(e) => handleBlur('partner_gross_annual_partner_pension_amount')}
                                error={(touched.partner_gross_annual_partner_pension_amount && errors.partner_gross_annual_partner_pension_amount) ? errors.partner_gross_annual_partner_pension_amount : undefined}
                            />
                            <TText regular sm style={{textAlign: 'left'}}>Dit krijg jij als je partner
                                overlijdt.</TText>
                        </View>
                    </View>
                    <View style={styles.gap10}>
                        <TText secondary semibold>Lijfrente gegevens</TText>
                        <ValutaInput
                            label={"Jaarlijks annuïtaire uitkering"}
                            value={values.partner_gross_annual_annuity_amount}
                            onChange={(value: number) => setValues(v => ({
                                ...v,
                                partner_gross_annual_annuity_amount: value
                            }))}
                            onBlur={(e) => handleBlur('partner_gross_annual_annuity_amount')}
                            error={(touched.partner_gross_annual_annuity_amount && errors.partner_gross_annual_annuity_amount) ? errors.partner_gross_annual_annuity_amount : undefined}
                        />
                    </View>
                </View>
            </TabView>
            <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                <Button
                    loading={loading}
                    label={'wijzigingen opslaan'}
                    type={'secondary'}
                    onPress={handleSubmit}
                />
                {response.current !== undefined &&
                    <TText style={{color: response.current.successful ? colors.approval : colors.error}}>
                        {response.current.label}
                    </TText>
                }
            </View>
        </HeaderCard>
    );

}
