import { useStyle } from "hooks";
import PageContainer from "components/PageContainer";
import { Button, TextInput, TText } from "components";
import { AvatarCard, Card, HeaderCard } from "components/Card";
import { colors } from "styles";
import { View } from "react-native";
import { QueryUtils } from "utils";
import { QueryKeys } from "enums";
import { useQuery } from "react-query";
import { useContext } from "react";
import { ModalContext } from "providers/ModalProvider";
import { EditAvatarModal } from "components/Modals";
import { EditPassword } from "components/edit";

export default () => {

    const styles = useStyle();
    const { showModal } = useContext(ModalContext);
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);

    return (                  
        <HeaderCard header="Mijn account">

            <TText>Hier kan je alles met betrekking tot jouw account wijzigen.</TText>

            <View  style={[styles.pt30, styles.gap30]}>

                <View style={styles.gap10}>
                    <TText secondary semibold>Je profielfoto</TText>
                    <View style={[styles.flexRow, styles.gap20, styles.alignCenter]}>
                        <AvatarCard avatar={customer?.avatar} style={{width: 120, height: 120, borderRadius: 60}}/>
                        <Button
                            label="Bewerken"
                            type="secondary"
                            onPress={()=>showModal(EditAvatarModal, {}, 'EditAvatarModal')}
                            style={{alignSelf: "center"}}
                        />                
                        {/* <Button
                            disabled
                            label="Verwijderen"
                            type="error"
                            style={{alignSelf: "center"}}
                        />                 */}
                    </View>
                </View>

                <View style={styles.gap10}>
                    <TText secondary semibold>Gebruikersnaam</TText>
                    <TextInput disabled value={customer?.username}/>
                </View>

                <View>
                    <EditPassword/>
                </View>

            </View>

        </HeaderCard>        
    );

}
