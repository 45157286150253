import { useStyle, useTranslation } from "hooks"
import { Text, TextStyle } from "react-native"
import { colors } from "styles"

interface props {
    children?: React.ReactNode
    style?: TextStyle | TextStyle[] | false | (false | { color: string; })[]
    center?: boolean
    right?: boolean
    regular?: boolean
    semibold?: boolean
    xs?: boolean
    sm?: boolean
    md?: boolean
    lg?: boolean
    black?: boolean
    white?: boolean
    secondary?: boolean
    primary2?: boolean
    grey?: boolean
    greyTransparent?: boolean
    error?: boolean
    url?: boolean
    oneline?: boolean
    noTranslate?: boolean
    capitalize?: boolean
}

export default ({ children, noTranslate, semibold, xs, md, lg, black, white, secondary, primary2, grey, greyTransparent, error, style, center, right, url, oneline, capitalize }: props) => {

    const translate = useTranslation();
    const styles = useStyle();

    return (
        <Text
            numberOfLines={oneline ? 1 : undefined}
            style={[
                styles.textRegular,
                styles.lineHeight,
                semibold && styles.textSemiBold,
                styles.textSM,
                xs && styles.textXS,
                md && styles.textMD,
                lg && styles.textLG,
                { color: colors.primary },
                black && styles.textBlack,
                white && styles.textWhite,
                primary2 && { color: colors.primary2 },
                secondary && { color: colors.secondary },
                grey && { color: colors.grey },
                greyTransparent && { color: colors.greyTransparent },
                error && { color: colors.error },
                center && { textAlign: "center" },
                right && { textAlign: "right" },
                url && { textDecorationLine: 'underline' },
                capitalize && { textTransform: 'capitalize' },
                style
            ]}>
            {noTranslate ? children : translate(children)}
        </Text>
    )
}
