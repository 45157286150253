import { BronDataModule, ContactModule, DamageModule, RecentDocumentsModule, SignalModule, UploadModule } from "components/Modules";
import { InboxCategories, QueryKeys } from "enums";
import { Damage, Document, Mortgage, Note, Signal, Task } from "models";
import moment from "moment";
import { StoreContext } from "providers/StoreProvider";
import { useContext, useEffect, useState } from "react";
import { Dimensions, Platform } from "react-native";
import { useQuery } from "react-query";
import { moduleWidths } from 'screens/landing/SignalItems';
import { QueryUtils } from "utils";

const sortByDate: (a: any[] | undefined) => any[] = (a) => {
    if (!a) return [];
    return a.sort((a, b) => moment(b.date).diff(moment(a.date)));
}

export default ({ includeContactAndBrondata = false, categorized = false, excludeFinishedUpload = false, excludeSettledDamage = false, excludeReadMessages = false }) => {

    const { loadingTrigger } = useContext(StoreContext);

    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const { data: documents } = useQuery(QueryKeys.DOCUMENTS, QueryUtils.fetchDocuments);
    const { data: mortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);
    const { data: damages } = useQuery(QueryKeys.DAMAGE, QueryUtils.fetchDamage);
    const { data: tasks } = useQuery(QueryKeys.TASKS, QueryUtils.fetchTasks);
    const { data: signals } = useQuery(QueryKeys.SIGNALS, QueryUtils.fetchSignals);
    const { data: notes } = useQuery(QueryKeys.NOTES, QueryUtils.fetchNotes);

    const [signalItems, setItems] = useState<any[] | {}>([]);
    const [brondata_added, setBrondata_added] = useState(false);
    const [recentDocuments, setRecentDocuments] = useState<Document[]>([]);

    //collect recent documents
    useEffect(() => {
        if (documents) {
            // fill array
            let documentsArray: Document[] = [];
            Object.entries(documents).map(([_, docs]) => {
                documentsArray = [...documentsArray, ...docs];
            })
            // sort array (terugval op created_at)
            if (documentsArray[0]?.updated_at) {
                documentsArray = documentsArray.sort((a: Document, b: Document) => {
                    return !a.updated_at ? -1 : moment(b.updated_at).diff(moment(a.updated_at));
                });
            } else {
                documentsArray = documentsArray.sort((a: Document, b: Document) => {
                    return !a.created_at ? -1 : moment(b.created_at).diff(moment(a.created_at));
                });
            }
            //return array
            documentsArray.length = Math.min(documentsArray.length, 4);
            setRecentDocuments(documentsArray);

        }
    }, [documents]);

    //collect all signalitem modules
    useEffect(() => {
        const collectItems = async () => {
            const items: any[] = [];

            const compileComponent = (elt: any, disabled: boolean, category?: string) => {
                return {
                    component: elt,
                    disabled,
                    category
                }
            }

            if (includeContactAndBrondata) {

                //add contact module
                items.push(compileComponent(<ContactModule />, false));

                //add brondata module
                const valid_invitation = customer?.invitation?.active && (moment(customer?.invitation?.valid_until).diff(moment()) > 0) && !customer?.invitation?.submitted_at;
                if (valid_invitation && Platform.OS == 'web' && Dimensions.get('window').width > 768) {

                    //add brondata
                    items.push(compileComponent(<BronDataModule moduleWidths={moduleWidths} />, false))
                    setBrondata_added(true);

                } else {

                    setBrondata_added(false);

                }

            }

            //add mortgage upload modules
            mortgages?.map((mortgage: Mortgage) => {
                const openProgresses = mortgage.openProgress;
                let documentName = openProgresses == 1 && mortgage.progress.reduce((acc, p) => (!p.received) ? p.name : acc, '');
                if (!(excludeFinishedUpload && openProgresses == 0)) items.push(compileComponent(<UploadModule mortgage={mortgage} amount={openProgresses} filename={openProgresses == 1 ? documentName : ''} warning={!categorized} />, openProgresses == 0, InboxCategories.task));
            })

            // //add recently shared documents            
            // if (recentDocuments.length > 0) {
            //     items.push(compileComponent(<RecentDocumentsModule recentDocuments={recentDocuments} />, false));
            // }

            //add signals
            sortByDate(signals).map((signal: Signal) => {
                const read = signal.seen_by_customer;
                if (!(excludeReadMessages && read)) items.push(compileComponent(<SignalModule data={signal} disabled={read} />, read, InboxCategories.message));
            })

            //add tasks
            sortByDate(tasks?.unfinishedTasks).map((task: Task) => {
                const read = task.seen_by_customer;
                if (!(excludeReadMessages && read)) items.push(compileComponent(<SignalModule data={task} disabled={read} />, read, InboxCategories.message));
            })

            if (!excludeReadMessages) {
                sortByDate(tasks?.finishedTasks).map((task: Task) => {
                    items.push(compileComponent(<SignalModule data={task} disabled={true} />, true, InboxCategories.message));
                })
            }

            //add notes
            sortByDate(notes).map((note: Note) => {
                const read: boolean = note.seen_by_customer;
                if (!(excludeReadMessages && read)) items.push(compileComponent(<SignalModule data={note} disabled={read} />, read, InboxCategories.message));
            })

            //add damageUpload modules
            sortByDate(damages).map((damage: Damage) => {
                const progressItems = damage.progress_items.reduce((acc, v) => (v.received) ? acc : acc + 1, 0);
                const read = damage.status || progressItems == 0 || damage.seen_by_customer;
                if (!(excludeSettledDamage && read)) items.push(compileComponent(<DamageModule damage={damage} warning={!categorized} />, read, InboxCategories.damage));
            });



            // sorting items on disabledness
            if (categorized) {

                //object with categorie arrays
                const result: Record<string, any[]> = {};
                Object.values(InboxCategories).map(key => result[key] = []);

                //add items to categories
                items.map(item => item.category && result[item.category].push(item));

                //sort each category and map items to components
                Object.keys(result).map(key => {
                    result[key] = result[key]
                        .sort((a, b) => a.disabled - b.disabled)
                        .map(item => item.component);
                });

                setItems(result);

            } else {

                setItems(items.sort((a, b) => a.disabled - b.disabled).map(item => item.component));

            }
        };
        collectItems();
    }, [loadingTrigger, tasks, signals, notes, mortgages, damages, recentDocuments])


    return { signalItems, brondata_added };

}