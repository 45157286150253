import { Icons } from "icons";
import { useStyle } from "hooks";
import { Image, Pressable, StyleProp, View } from "react-native";
import CountryFlag from "react-native-country-flag";
import { colors } from "styles";
import TText from "./TText";
import { AvatarCard, Card } from "./Card";
import { Customer } from "models";
import { CustomerUtils } from "utils";



interface PersonProps {
    customer?: Customer,
    relation?: string,
    options?: []
}

export default ({ customer, relation, options }: PersonProps) => {

    const styles = useStyle();
    const fullName = CustomerUtils.getFullName(customer);

    return (
        <Card style={[styles.p10, styles.flexRow, styles.alignCenter, styles.gap10, {backgroundColor: colors.background}]}>
            <AvatarCard avatar={customer?.avatar}/>            
            <View>
                <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                    <TText semibold>{fullName}</TText>
                    {/* <CountryFlag 
                        isoCode={customer?.nationality?.code || 'nl'} 
                        size={8} 
                        style={{
                            marginRight: 8,
                            marginTop: -2,
                            alignSelf: 'center'
                        }}
                    /> */}
                </View>
                <View style={styles.flexRow}>
                    <TText sm>{!customer?.is_business ? (customer?.sex ? '#gender_male' : '#gender_female') : ''}</TText>
                    <TText sm>{!customer?.is_business ? ',' : ''}{customer?.age}#age</TText>
                </View>                
            </View>
            {relation && 
                <View style={{flex: 1, flexDirection: 'row', justifyContent: "flex-end", alignItems: 'center'}}>
                    <TText grey semibold>{relation}</TText>
                </View>
            }
        </Card>
    );
}