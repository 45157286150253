import { Button, IconTitle, MortgageProgress } from "components";
import { TText } from "components";
import { Mortgage } from "models";
import { MortgagePart } from "models/MortgagePart";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { useContext } from "react";
import { useWindowDimensions, View} from "react-native"
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { ValutaUtils } from "utils";
import MortgageUploadModal from "./MortgageUploadModal";
import { DateTimeFormat } from 'enums'
import { BaseDrawerModal } from "components/Modals";
import MortgageItemCard from "components/Card/MortgageItemCard";
import { Card } from 'components/Card';
import MortgageTimeline from "components/MortgageTimeline";
import TabView from "components/TabView";
import {useStyle} from "hooks";



export default ({ settings }: any) => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const { hideModal, showModal } = useContext(ModalContext);
    const mortgage: Mortgage = settings.mortgage;
    console.log('mortgage', mortgage);

    const { width } = useWindowDimensions();
    const modalWidth = Math.min(width, 1200);
    const componentWidth = modalWidth - 260;

    const renderTimeline = () => {
        return (mortgage.status === 0) && (
            <Card style={scopedStyles.timelineContainer}>
                <MortgageTimeline mortgage={mortgage} componentWidth={componentWidth} />
            </Card>
        );
    }

    const renderProgress = () => {
        return (mortgage.status !== 0) && (
            <View style={scopedStyles.progressContainer}>
                <TText md semibold center style={{ marginBottom: 5 }}>#mortgage_requested</TText>

                <MortgageProgress mortgage={mortgage} />

                {mortgage.openProgress > 0 &&
                    <View style={{ flexDirection: "row", justifyContent: "center" }}>
                        <Button
                            type="action"
                            label="#action_upload"
                            onPress={() => {
                                hideModal();
                                setTimeout(() => {
                                    showModal(MortgageUploadModal, { mortgage }, 'MortgageUploadModal');
                                }, 1);
                            }}
                        />
                    </View>
                }

            </View>
        );
    }

    const renderProperties = () => {
        return (
            <>
                <TText md semibold style={{ marginVertical: 12 }}>{mortgage.properties.length > 1 ? 'Onderpanden' : 'Onderpand'}</TText>

                {mortgage.properties.map(property => {
                    return (
                        <IconTitle
                            keepLayout
                            iconName={'IconHome'}
                            title={''}
                            style={{marginVertical: 8, marginLeft: 8}}
                            iconSize={22}
                        >                    
                            <TText md semibold style={{marginRight: 8}}>{property.street ? `${property.street} ${property.number}${property.number_addition}` : ''}</TText>
                            <TText sm grey regular>{property.street ? `${property.postal_code}, ${property.city}` : ' '}</TText>                    
                        </IconTitle>
                    );
                })}
            </>
        );
    }

    const renderDetails = () => {
        return (
            <>
                <TText md semibold style={{ marginTop: 35, marginBottom: 6 }}>#data</TText>

                {/*<View style={scopedStyles.category}>*/}
                {/*    <TText sm style={{ width: '60%' }}>#mortgage_inschrijving</TText>*/}
                {/*    <View style={{ flex: 1 }}>*/}
                {/*        <TText sm right>{ValutaUtils.valutaString(mortgage.hypotheciareInschrijving)}</TText>*/}
                {/*    </View>*/}
                {/*</View>*/}

                <View style={scopedStyles.category}>
                    <TText sm style={{ width: '60%' }}>#mortgage_original_hoofdsom</TText>
                    <View style={{ flex: 1 }}>
                        <TText sm right>{ValutaUtils.valutaString(mortgage.totaalSom)}</TText>
                    </View>
                </View>

                <View style={scopedStyles.category}>
                    <TText sm style={{ width: '60%' }}>#mortgage_rest_hoofdsom</TText>
                    <View style={{ flex: 1 }}>
                        <TText sm right>{ValutaUtils.valutaString(mortgage.restantHoofdsom)}</TText>
                    </View>
                </View>

                <View style={scopedStyles.category}>
                    <TText sm style={{ width: '60%' }}>#mortgage_verhogen_zonder_notaris</TText>
                    <View style={{ flex: 1 }}>
                        <TText sm right>{ValutaUtils.valutaString(mortgage.total_withdrawal_space_amount)}</TText>
                    </View>
                </View>
            </>
        );
    }
    
    const renderCosts = () => {
        return (
            <>
                <TText md semibold style={{ marginBottom: 12, marginTop: 35 }}>#mortgage_costs</TText>

                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 40 }}>

                    <View style={[scopedStyles.tmortgage2, { borderTopLeftRadius: 4 }]}><TText sm semibold>#cost</TText></View>
                    <View style={scopedStyles.tmortgage}><TText sm semibold right>#per_month</TText></View>
                    <View style={[scopedStyles.tmortgage, { borderTopRightRadius: 4 }]}><TText sm semibold right>#per_year</TText></View>


                    <View style={scopedStyles.nmortgage2}><TText sm regular>#interest</TText></View>
                    <View style={scopedStyles.nmortgage}><TText sm regular right>{ValutaUtils.valutaString(mortgage.monthly_interest_amount)}</TText></View>
                    <View style={scopedStyles.nmortgage}><TText sm regular right>{ValutaUtils.valutaString(mortgage.monthly_interest_amount * 12)}</TText></View>

                    <View style={scopedStyles.nmortgage2}><TText sm regular>#redeemed</TText></View>
                    <View style={scopedStyles.nmortgage}><TText sm regular right>{ValutaUtils.valutaString(mortgage.monthly_repayment_amount)}</TText></View>
                    <View style={scopedStyles.nmortgage}><TText sm regular right>{ValutaUtils.valutaString(mortgage.monthly_repayment_amount * 12)}</TText></View>

                    <View style={scopedStyles.nmortgage2}><TText sm regular>#premium</TText></View>
                    <View style={scopedStyles.nmortgage}><TText sm regular right>{ValutaUtils.valutaString(mortgage.monthly_premium)}</TText></View>
                    <View style={scopedStyles.nmortgage}><TText sm regular right>{ValutaUtils.valutaString(mortgage.monthly_premium * 12)}</TText></View>

                    <View style={scopedStyles.nmortgage2}><TText sm>#total_small</TText></View>
                    <View style={scopedStyles.nmortgage}><TText sm right>{ValutaUtils.valutaString(mortgage.monthly_amount)}</TText></View>
                    <View style={scopedStyles.nmortgage}><TText sm right>{ValutaUtils.valutaString(mortgage.monthly_amount * 12)}</TText></View>

                </View>
            </>
        );
    }

    const renderMortgageParts = () => {
        return mortgage?.mortgageParts?.length > 0 && (
            <Card style={{backgroundColor: colors.primary2}}>
                <TText md semibold style={{ marginBottom: 12 }}>#mortgage_parts</TText>
                <TabView tabs={mortgage.mortgageParts.map(part => `#leningdeel ${part.leningdeelnummer}`)}>
                    {mortgage.mortgageParts.map((part, index) => renderMortgagePart(part, index))}
                </TabView>
            </Card>
        );
    }

    const renderMortgagePart = (part: MortgagePart, index: number) => {
        const smallScreen = componentWidth < 500;
        return (
            <View key={part.id} style={{ width: '100%', marginBottom: 10 }}>
                <View style={smallScreen ? scopedStyles.mortgagePartFullWidth : scopedStyles.mortgagePartSplitter}>
                    <View style={[smallScreen ? scopedStyles.mortgagePartItemFullWidth : scopedStyles.mortgagePartItemSplitter, styles.borderRadius10]}>

                        <TText sm semibold style={{ marginLeft: 12, marginVertical: 10 }}>#data</TText>

                        <View style={scopedStyles.category2}>
                            <TText sm style={{ width: '50%' }}>#mortgage_vorm</TText>
                            <View style={{ flex: 1 }}>
                                <TText sm right>{part.repayment_type_description}</TText>
                            </View>
                        </View>

                        <View style={scopedStyles.category2}>
                            <TText sm style={{ width: '50%' }}>#obligation_hoofdsom</TText>
                            <View style={{ flex: 1 }}>
                                <TText sm right>{ValutaUtils.valutaString(part.hoofdsom)}</TText>
                            </View>
                        </View>

                        <View style={scopedStyles.category2}>
                            <TText sm style={{ width: '50%' }}>#rest_debt</TText>
                            <View style={{ flex: 1 }}>
                                <TText sm right>{ValutaUtils.valutaString(part.remaining_debt)}</TText>
                            </View>
                        </View>

                        <View style={scopedStyles.category2}>
                            <TText sm style={{ width: '50%' }}>#start_date</TText>
                            <View style={{ flex: 1 }}>
                                <TText sm right>
                                    { (part.begindatum !== null && part.begindatum !== undefined)
                                        ? moment(part.begindatum).format(DateTimeFormat.DISPLAY_DATE)
                                        : '#unknown'
                                    }
                                </TText>
                            </View>
                        </View>

                        <View style={scopedStyles.category2}>
                            <TText sm style={{ width: '50%' }}>#end_date</TText>
                            <View style={{ flex: 1 }}>
                                <TText sm right>
                                    { (part.einddatum !== null && part.einddatum !== undefined)
                                        ? moment(part.einddatum).format(DateTimeFormat.DISPLAY_DATE)
                                        : '#unknown'
                                    }
                                </TText>
                            </View>
                        </View>
                    </View>

                    <View style={[{ backgroundColor: colors.white, width: smallScreen ? '100%' : '48%', paddingBottom: 3 }, styles.borderRadius10]}>

                        <TText sm semibold style={{ marginLeft: 12, marginVertical: 10 }}>#interest</TText>

                        <View style={scopedStyles.category2}>
                            <TText sm style={{ width: '50%' }}>#mortgage_interest_type</TText>
                            <View style={{ flex: 1 }}>
                                <TText sm right>{part.interest_type_description}</TText>
                            </View>
                        </View>

                        <View style={scopedStyles.category2}>
                            <TText sm style={{ width: '50%' }}>#mortgage_interest_percentage</TText>
                            <View style={{ flex: 1 }}>
                                <TText sm right>{part.interest} %</TText>
                            </View>
                        </View>

                        <View style={scopedStyles.category2}>
                            <TText sm style={{ width: '50%' }}>#start_date #mortgage_fixed_interest</TText>
                            <View style={{ flex: 1 }}>
                                <TText sm right>
                                    { (part.begindatumRentevast !== null && part.begindatumRentevast !== undefined)
                                        ? moment(part.begindatumRentevast).format(DateTimeFormat.DISPLAY_DATE)
                                        : '#unknown'
                                    }
                                </TText>
                            </View>
                        </View>

                        <View style={scopedStyles.category2}>
                            <TText sm style={{ width: '50%' }}>#end_date #mortgage_fixed_interest</TText>
                            <View style={{ flex: 1 }}>
                                <TText sm right>
                                    { (part.einddatumRentevast !== null && part.einddatumRentevast !== undefined)
                                        ? moment(part.einddatumRentevast).format(DateTimeFormat.DISPLAY_DATE)
                                        : '#unknown'
                                    }
                                </TText>
                            </View>
                        </View>

                    </View>
                </View>

                <View style={[{ backgroundColor: colors.white, width: '100%' }, styles.borderRadius10]}>
                    <View style={{ marginHorizontal: 8, flexDirection: 'row', flexWrap: 'wrap' }}>

                        <View style={[scopedStyles.tmortgage2, { borderTopLeftRadius: 4 }]}><TText sm semibold>#cost</TText></View>
                        <View style={scopedStyles.tmortgage}><TText sm semibold right>#per_month</TText></View>
                        <View style={[scopedStyles.tmortgage, { borderTopRightRadius: 4 }]}><TText sm semibold right>#per_year</TText></View>

                        <View style={scopedStyles.nmortgage2}><TText sm>#interest</TText></View>
                        <View style={scopedStyles.nmortgage}><TText sm right>{ValutaUtils.valutaString(part.monthly_interest_amount)}</TText></View>
                        <View style={scopedStyles.nmortgage}><TText sm right>{ValutaUtils.valutaString(part.monthly_interest_amount * 12)}</TText></View>

                        <View style={scopedStyles.nmortgage2}><TText sm>#redeemed</TText></View>
                        <View style={scopedStyles.nmortgage}><TText sm right>{ValutaUtils.valutaString(part.monthly_repayment_amount)}</TText></View>
                        <View style={scopedStyles.nmortgage}><TText sm right>{ValutaUtils.valutaString(part.monthly_repayment_amount * 12)}</TText></View>

                        <View style={scopedStyles.nmortgage2}><TText sm>#premium</TText></View>
                        <View style={scopedStyles.nmortgage}><TText sm right>{ValutaUtils.valutaString(part.monthly_premium)}</TText></View>
                        <View style={scopedStyles.nmortgage}><TText sm right>{ValutaUtils.valutaString(part.monthly_premium * 12)}</TText></View>

                        <View style={scopedStyles.nmortgage2}><TText sm>#total_small</TText></View>
                        <View style={scopedStyles.nmortgage}><TText sm right>{ValutaUtils.valutaString(part.monthly_amount)}</TText></View>
                        <View style={scopedStyles.nmortgage}><TText sm right>{ValutaUtils.valutaString(part.monthly_amount * 12)}</TText></View>

                    </View>

                </View>
            </View>
        )
    }

    return (
        <BaseDrawerModal
            drawerWidth={1200}
            visible={true}
            onClose={hideModal}
            header={`${mortgage.agency_name} - ${mortgage.hypotheeknummer}`}
        >
            <View style={scopedStyles.container}>

                <MortgageItemCard mortgage={mortgage} />

                {renderTimeline()}

                {renderProgress()}

                {/*{renderProperties()}*/}

                {renderDetails()}

                {renderCosts()}

                {renderMortgageParts()}

            </View >
        </BaseDrawerModal>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            width: '50%',
            height: 'auto',
            minHeight: 0,
            minWidth: 485,
            marginTop: 120,
            backgroundColor: colors.background
        },
        container: {
            height: '100%',
            paddingTop: 20,
            paddingHorizontal: 30
        },
        titleContainer: {
            marginVertical: 20,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: "center"
        },
        timelineContainer: {
            flex: 1,
            backgroundColor: colors.primary
        },
        progressContainer: {
            width: '60%',
            minWidth: 400,
            alignSelf: "center",
            marginBottom: 12
        },
        agencyContainer: {
            marginRight: 20
        },
        category: {
            flexDirection: 'row',
            paddingVertical: 10,
            borderTopWidth: 0.5,
            borderColor: colors.lightgrey,
        },
        category2: {
            marginHorizontal: 12,
            flexDirection: 'row',
            paddingVertical: 10,
            borderTopWidth: 0.5,
            borderColor: colors.lightgrey,
        },
        tmortgage: {
            width: '25%',
            textAlign: 'right',
            paddingVertical: 10,
            paddingHorizontal: 8,
        },
        nmortgage: {
            width: '25%',
            textAlign: 'right',
            paddingVertical: 10,
            paddingHorizontal: 8,
            borderTopWidth: 0.5,
            borderColor: colors.lightgrey,
            // backgroundColor: colors.white
        },
        tmortgage2: {
            width: '50%',
            paddingVertical: 10,
            paddingLeft: 8,
        },
        nmortgage2: {
            width: '50%',
            paddingVertical: 10,
            paddingLeft: 8,
            borderTopWidth: 0.5,
            borderColor: colors.lightgrey,
            // backgroundColor: colors.white
        },
        mortgagePartSplitter: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 20
        },
        mortgagePartFullWidth: {
            width: '100%',
            marginBottom: 20
        },
        mortgagePartItemSplitter: {
            backgroundColor: colors.white,
            width: '48%',
            paddingBottom: 3
        },
        mortgagePartItemFullWidth: {
            backgroundColor: colors.white,
            width: '100%',
            marginBottom: 20,
            paddingBottom: 3
        },
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
            },
            titleContainer: {
                flexDirection: 'column',
                alignItems: "flex-start"
            },
            progressContainer: {
                width: '100%',
                marginLeft: 0
            },
            agencyContainer: {
                marginLeft: 80
            },
            tmortgage: {
                width: '35%'
            },
            nmortgage: {
                width: '35%'
            },
            tmortgage2: {
                width: '30%'
            },
            nmortgage2: {
                width: '30%'
            }
        }
    }
)
