const map = `<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        html,
        body {
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
            overflow: hidden;
        }

        iframe {
            background-color: lightgrey;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            border: none !important;
        }
    </style>
</head>

<body>
    <iframe src="MAPURL" />
</body>

</html>`

export default map;