import { Buffer } from 'buffer';
import { DamageUploadFileNames, Screen } from 'enums';
import Constants from "expo-constants";
import { getDocumentAsync } from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import { MediaTypeOptions, launchImageLibraryAsync } from 'expo-image-picker';
import { startActivityAsync } from 'expo-intent-launcher';
import { shareAsync } from 'expo-sharing';
import { Platform } from 'react-native';
import ApiUtils from './ApiUtils';

import FormData from 'form-data';
import { Document } from 'models';


//send data
const sendMethod = Platform.select({
    web: async (url, form) => {

        try {

            const client = await ApiUtils.getApiClient({ multipart: true });
            const response = await client.post(
                url,
                form
            );
            return response.data;

        } catch (error) {

            return error?.response || error;

        }

    },
    default: async (url, form) => {

        try {
            const tenantCode = await ApiUtils.getTenant();
            const host = Config.API_URL.replace(Config.DEFAULT_TENANT, tenantCode);
            const headers = await ApiUtils.getApiHeaders(true);

            const response = await fetch(
                host + url, {
                method: 'POST',
                headers: headers,
                body: form
            });

            return await response.json();

        } catch (error) {

            return error?.response || error;

        }


    }
})


const Config = Constants.expoConfig.extra.config;

const atob = (base64: string) => {
    return Buffer.from(base64, 'base64').toString('binary');
};
const base64IntoBuffer = (base64: string) => {
    const splitDataURI = base64.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : splitDataURI[1];
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}



const pickDocument = async (mimeType?: string) => {

    const document = await getDocumentAsync({
        multiple: false,
        type: mimeType,
    });    
    if (document.type === 'cancel' || document.canceled) return false;
    return document.output ? document.output[0] : document.assets[0];

}

const pickImage = async () => {

    const pickedImage = await launchImageLibraryAsync({
        mediaTypes: MediaTypeOptions.Images,
        allowsMultipleSelection: false,
        allowsEditing: false,
        quality: 1
    });

    if (pickedImage.canceled) return false;

    const image = pickedImage.assets[0];
    image.name = image.fileName ? image.fileName : image?.uri?.substring(image?.uri.lastIndexOf('/') + 1) || 'Afbeelding'
    return image;

}

const openDocument = (uri) => {
    const openMethod = Platform.select({
        android: () => {
            FileSystem.getContentUriAsync(uri).then(cUri => {

                startActivityAsync('android.intent.action.VIEW', {
                    data: cUri,
                    flags: 1,
                });

            });
        },
        default: () => shareAsync(uri)
    })
    openMethod();
}

const uploadDocument = async (document, filename, { mortgageProgressId = undefined, damageProgressId = undefined }) => {

    if (Platform.OS === 'web') {

        const form = new FormData();
        form.append('name', filename);
        form.append('content', document);

        if (mortgageProgressId) {
            form.append('mortgage_progress_id', mortgageProgressId);
            form.append('screen', Screen.HYPOTHEKEN);

        } else if (damageProgressId) {
            form.append('damage_progress_id', damageProgressId);
            form.append('screen', Screen.VERZEKERINGEN);
        }

        const client = await ApiUtils.getApiClient({ multipart: true });

        const response = await client.post(
            '/customer/document',
            form
        );

        return response.data;

    } else {

        const headers = await ApiUtils.getApiHeaders(true);
        const tenantCode = await ApiUtils.getTenant();
        const url = Config.API_URL.replace(Config.DEFAULT_TENANT, tenantCode);
        const params = { name: filename, screen: '' };
        if (mortgageProgressId) {

            // @ts-ignore-next-line
            params['mortgage_progress_id'] = `${mortgageProgressId}`;
            params['screen'] = Screen.HYPOTHEKEN;
        }
        if (damageProgressId) {
            // @ts-ignore-next-line
            params['damage_progress_id'] = `${damageProgressId}`;
            params['screen'] = Screen.VERZEKERINGEN;
        }

        const options: FileSystem.FileSystemUploadOptions = {
            headers: headers,
            uploadType: FileSystem.FileSystemUploadType.MULTIPART,
            fieldName: 'content',
            parameters: params
        }

        const response = await FileSystem.uploadAsync(
            url + '/customer/document',
            document.uri,
            options
        )

        return JSON.parse(response.body);
    }

}

const uploadAvatar = async (base64) => {

    if (Platform.OS === 'web') {

        if (!base64.includes('base64')) base64 = `data:image/png;base64,${base64}`
        const file = base64IntoBuffer(base64);

        const form = new FormData();
        form.append('avatar', file);

        const client = await ApiUtils.getApiClient({ multipart: true });

        try {

            const response = await client.post(
                '/customer/customer/avatar/',
                form
            );
            return response.data;

        } catch (error) {

            return { success: false };

        }

    } else {

        const { exists } = await FileSystem.getInfoAsync(FileSystem.cacheDirectory + "data");
        if (!exists) await FileSystem.makeDirectoryAsync(FileSystem.cacheDirectory + "data");

        const filename = FileSystem.cacheDirectory + 'data/avatar.png';
        await FileSystem.writeAsStringAsync(filename, base64, { encoding: FileSystem.EncodingType.Base64 });

        const headers = await ApiUtils.getApiHeaders(true);
        const tenantCode = await ApiUtils.getTenant();
        const url = Config.API_URL.replace(Config.DEFAULT_TENANT, tenantCode);

        const response = await FileSystem.uploadAsync(
            url + '/customer/customer/avatar/',
            filename,
            {
                headers: headers,
                uploadType: FileSystem.FileSystemUploadType.MULTIPART,
                fieldName: 'avatar'
            }
        )

        return JSON.parse(response.body);
    }

}


const uploadDamage = async (type, cost, date, body, documents) => {


    //convert file
    const promises: any[] = [];
    documents && Object.keys(documents).map(key => {
        documents[key].map(async (document, index) => {

            if (!document.file) {

                if (document.uri.includes(';base64,')) {

                    promises.push(new Promise(async (resolve, reject) => {
                        const blob = await (await fetch(document.uri)).blob();
                        documents[key][index]['file'] = blob
                        resolve(true);
                    }))

                } else if (document.uri.includes('file://')) {

                    const ext = document.name.split('.').pop();
                    const type = (ext == 'jpg') ? 'jpeg' : ext;
                    documents[key][index]['file'] = { uri: document.uri, name: document.name, type: document.mimeType || 'image/' + type };

                }

            }

        })
    })

    //wait for all the files to load
    await Promise.all(promises);

    //compose formdata
    const form = new FormData();
    form.append('type', type);
    form.append('estimated', cost);
    form.append('date', date);
    form.append('cause', body);
    form.append('screen', Screen.VERZEKERINGEN);

    //add files
    documents && Object.keys(documents).map(key => {
        documents[key].map(async (document, i) => {

            const documentCount = documents[key].length > 1 ? ` ${i + 1}` : '';
            const extension = document.mimeType ? document.mimeType.split('/')[1] :
                document.fileName ? document.fileName.split('.')[1] :
                    document.uri ? document?.uri?.substring(document.uri.lastIndexOf('.') + 1) : '#unknown';
            const customName = `${DamageUploadFileNames[key]}${documentCount}.${extension}`;

            if (Platform.OS != 'web') document.file.name = customName;
            form.append(key.replace('damage', 'other') + '[]', document.file, customName);

        })
    })

    return await sendMethod('/customer/damage', form);

}



const uploadMessage = async (body: string, screen?: string) => {

    const form = new FormData();
    form.append('message', body);
    if (screen) form.append('screen', screen);

    return await sendMethod('/customer/task/message/', form);

}


const downloadDocument = (document, filename) => {

    const downloadMethod = Platform.select({
        native: async () => {

            const { exists } = await FileSystem.getInfoAsync(FileSystem.cacheDirectory + "data");
            if (!exists) await FileSystem.makeDirectoryAsync(FileSystem.cacheDirectory + "data");

            return FileSystem.downloadAsync(
                document.link,
                FileSystem.cacheDirectory + "data/" + filename.replace(/ /g, ''),
                { headers: await ApiUtils.getApiHeaders() }
            )
                .then((response) => {
                    openDocument(response.uri);
                    return response;
                })

        },
        default: async () => {

            const documentID = document.link?.substring(document.link.lastIndexOf('/') + 1, document.link.length); //because baseURL of axios instance
            const apiClient = await ApiUtils.getApiClient({ responseType: 'blob' });

            return apiClient
                .get('/customer/document/' + documentID)
                .then(response => {

                    // @ts-ignore 
                    saveAs(window.URL.createObjectURL(response.data), filename);
                    return response;

                })
                .catch((response) => {
                    alert('Dit bestand is helaas niet toegankelijk');
                    return response;

                })

        }
    })

    return downloadMethod();

}

const deleteDocument = async (document: Document) => {
    const apiClient = await ApiUtils.getApiClient({});
    try {
        return apiClient
            .delete(document.link)
            .then(async (response) => {
                return response.data.data;
            });
    } catch (error) {
        return { success: false };
    }
}


const downloadTenantDocument = async (document, filename) => {

    const apiClient = await ApiUtils.getApiClient({});
    return apiClient
        .get(document.link)
        .then(async (response) => {

            const mime_type = response.data.data.mime_type;
            const data = response.data.data.content.data.data;

            if (Platform.OS == 'web') {

                const cUrl = `data:${mime_type};base64,` + data;
                // @ts-ignore 
                saveAs(cUrl, filename);

            } else {

                const { exists } = await FileSystem.getInfoAsync(FileSystem.cacheDirectory + "data");
                if (!exists) await FileSystem.makeDirectoryAsync(FileSystem.cacheDirectory + "data");

                const localFilename = FileSystem.cacheDirectory + "data/" + filename.replace(/ /g, '')

                return FileSystem.writeAsStringAsync(
                    localFilename,
                    data,
                    { encoding: FileSystem.EncodingType.Base64 }
                )
                    .then(response => {
                        openDocument(localFilename);
                        return response;
                    })
                    .catch(error => {
                        console.error(error)
                    })

            }
            return response;

        })
        .catch((response) => {
            alert('Dit bestand is helaas niet toegankelijk');
            return response;

        })

}


export default {
    atob,
    pickDocument,
    pickImage,
    openDocument,
    downloadDocument,
    uploadDocument,
    deleteDocument,
    downloadTenantDocument,
    uploadAvatar,
    uploadDamage,
    uploadMessage,
    sendMethod
};















