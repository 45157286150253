import { Pressable, Keyboard, Platform } from "react-native";

const HideKeyboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const hideKeyboard = () => (Platform.OS !== 'web') && Keyboard.dismiss();
    return (
        <Pressable onPress={hideKeyboard} style={{
            cursor: 'default',
            width: '100%',
            flex: 1
        }}>
            {children}
        </Pressable>
    );
}

export default HideKeyboardProvider;
