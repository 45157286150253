
export class Retirement {
    employer_retirements: Array<any>
    gross_annual_annuity_pension_amount: number
    gross_annual_annuity_private_pension_amount: number
    gross_annual_employment_pension_amount: number
    gross_annual_old_age_pension_amount: number
    life_insurance_retirements: Array<any>
    old_age_retirement_incomes: Array<any>
    private_retirements: Array<any>
    remaining_months_retirement: number
    remaining_years_residual_retirement: number
    remaining_years_retirement: number
    retirement_benefit_income: Array<any> | undefined
    retirement_date: string
    total_gross_annual_pension_amount: number

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Retirement {
        this.employer_retirements = json.employer_retirements ? json.employer_retirements.data : [];
        this.gross_annual_annuity_pension_amount = json.gross_annual_annuity_pension_amount ? json.gross_annual_annuity_pension_amount : 0;
        this.gross_annual_annuity_private_pension_amount = json.gross_annual_annuity_private_pension_amount ? json.gross_annual_annuity_private_pension_amount : 0;
        this.gross_annual_employment_pension_amount = json.gross_annual_employment_pension_amount ? json.gross_annual_employment_pension_amount : 0;
        this.gross_annual_old_age_pension_amount = json.gross_annual_old_age_pension_amount ? json.gross_annual_old_age_pension_amount : 0;
        this.life_insurance_retirements = json.life_insurance_retirements ? json.life_insurance_retirements.data : [];
        this.old_age_retirement_incomes = json.old_age_retirement_incomes ? json.old_age_retirement_incomes : [];
        this.private_retirements = json.private_retirements ? json.private_retirements.data : [];
        this.remaining_months_retirement = json.remaining_months_retirement ? json.remaining_months_retirement : 0;
        this.remaining_years_residual_retirement = json.remaining_years_residual_retirement ? json.remaining_years_residual_retirement : 0;
        this.remaining_years_retirement = json.remaining_years_retirement ? json.remaining_years_retirement : 0;
        this.retirement_benefit_income = json.retirement_benefit_income ? json.retirement_benefit_income.data : undefined;
        this.retirement_date = json.retirement_date ? json.retirement_date : '';
        this.total_gross_annual_pension_amount = json.total_gross_annual_pension_amount ? json.total_gross_annual_pension_amount : 0;
        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId()
        };
    }

    getId(): string {
        return this.id;
    }
}
