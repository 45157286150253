
import mapHtml from 'assets/html/maps';
import { Icons } from "icons";
import { BottomNavBar, Button, EnergyLabel, Footer, PlatformWebview, TText } from "components";
import { Card, ScenariosCard } from 'components/Card';
import { LivingModal } from "components/Modals";
import PageContainer from 'components/PageContainer';
import { DateTimeFormat, QueryKeys } from 'enums';
import Constants from "expo-constants";
import { LinearGradient } from "expo-linear-gradient";
import { useLayoutMargin, useStyle } from "hooks";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { useContext, useEffect, useState } from "react";
import { Image, View, useWindowDimensions } from "react-native";
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { Content } from 'screens/home';
import { colors } from "styles";
import { QueryUtils, ValutaUtils } from "utils";
import { Mortgage } from 'models';

const Config = Constants.expoConfig.extra.config;



export default () => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const { showModal } = useContext(ModalContext);
    const { data: living } = useQuery(QueryKeys.LIVING, QueryUtils.fetchLiving);
    const { data: mortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);

    const activeAddress = living?.property || { street_name: '', house_number: '', house_number_addition: '', city: '', postcode: '' };
    const owner = living?.owns_property;
    const marketValue = activeAddress?.market_value || activeAddress?.market_value_high || activeAddress?.market_value_low || '#unknown';

    const [mapURL, setMapURL] = useState<string>();
    const [streetviewURL, setStreetviewURL] = useState<string>();
    const [matchedMortgage, setMatchedMortgage] = useState<Mortgage | null>();
    useEffect(() => {
        if (activeAddress.street_name !== "") {
            setMapURL(encodeURI(activeAddress ? `https://maps.google.com/maps?q=${activeAddress.street_name} ${activeAddress.house_number}${activeAddress.house_number_addition}, ${activeAddress.city}&t=&z=16&ie=UTF8&iwloc=&output=embed` : ''));
            setStreetviewURL(encodeURI(activeAddress ? `${Config.PROXY_URL}/streetview?location=${activeAddress.street_name} ${activeAddress.house_number}${activeAddress.house_number_addition}, ${activeAddress.city}&size=600x400&fov=90` : ''));
            setMatchedMortgage(mortgages?.find((mortgage: Mortgage) => {
                return mortgage.properties.find((property => {
                    return property.postal_code === activeAddress.postcode && 
                           property.number === String(activeAddress.house_number) && 
                           (property.number_addition || property.number_letter) === String(activeAddress.house_number_addition);
                })) !== undefined;                
            }));
        }
    }, [activeAddress, mortgages])
    

    
    
    const renderMap = () => {

        return (
            <View style={{
                height: '50%',
                flexDirection: 'column', 
            }}>
                {mapURL && <PlatformWebview
                    html={mapHtml}
                    replaceVars={[
                        ['MAPURL', mapURL]
                    ]}
                    style={{ width: '100%', flex: 1, borderRadius: 12 }}
                />}
            </View>
        )
    }

    const renderStreetView = () => {

        return (
            <View style={{
                flex: 1,
                flexDirection: 'column',
            }}>
                {streetviewURL && <Image 
                    source={{uri: streetviewURL}} 
                    style={{ width: '100%', flex: 1, borderRadius: 12, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                />}
                {matchedMortgage && renderAddress()}
            </View>
        )
    }

    const renderAddress = () => {
        return (
            <Card style={{backgroundColor: colors.secondary, position: 'absolute', left: 30, bottom: 20}}>
                <TText md semibold white regular>{`${activeAddress.street_name} ${activeAddress.house_number}${activeAddress.house_number_addition}`}</TText>                        
                <TText md semibold white regular>{`${activeAddress.postcode && (activeAddress.postcode + ',')} ${activeAddress.city}`}</TText>
            </Card>
        );
    }

    const renderButtons = () => {
        return (
            <View style={[scopedStyles.buttonContainer, {paddingVertical: 5}]}>
                <Button
                    type="action"
                    label="#sell"
                    onPress={() => { showModal(LivingModal, { mode: 'sell' }, 'LivingModal') }}
                />
                <Button
                    type="action"
                    label="#sustain"
                    onPress={() => { showModal(LivingModal, { mode: 'sustain' }, 'LivingModal') }}
                />
                <Button
                    type="action"
                    label="#appraise"
                    onPress={() => { showModal(LivingModal, { mode: 'appraise' }, 'LivingModal') }}
                />
            </View>
        );
    }


    const renderInfo = () => {
        return (
            <View style={[styles.flexRowDynamic, styles.gap30, styles.flexStretch]}>
                <View style={styles.flexStretch}>
                    {/* bouw */}
                    <TText md white semibold style={scopedStyles.infoTitle}>#house_build</TText>

                    <View style={scopedStyles.infoLine}>
                        <TText sm grey style={scopedStyles.infoDescription}>#house_type</TText>
                        <TText sm white semibold style={scopedStyles.infoValue}>{activeAddress?.residential_building_type || '#unknown'}</TText>
                    </View>

                    <View style={scopedStyles.infoLine}>
                        <TText sm grey style={scopedStyles.infoDescription}>#house_buildyear</TText>
                        <TText sm white semibold style={scopedStyles.infoValue}>{activeAddress?.construction_year || '#unknown'}</TText>
                    </View>

                    <View style={scopedStyles.infoLine}>
                        <TText sm grey style={scopedStyles.infoDescription}>#house_function</TText>
                        <TText sm white semibold style={scopedStyles.infoValue}>{activeAddress?.purpose || '#unknown'}</TText>
                    </View>

                    <View style={scopedStyles.infoLine}>
                        <TText sm grey style={scopedStyles.infoDescription}>#house_function_status</TText>
                        <TText sm white semibold style={scopedStyles.infoValue}>{activeAddress?.purpose_status || '#unknown'}</TText>
                    </View>

                    <View style={scopedStyles.infoLine}>
                        <TText sm grey style={scopedStyles.infoDescription}>#house_rentable</TText>
                        <TText sm white semibold style={scopedStyles.infoValue}>{activeAddress?.rental_type_description || '#unknown'}</TText>
                    </View>



                    {/* oppervlaktes */}
                    <TText md white semibold style={[scopedStyles.infoTitle, styles.pt30]}>#house_size</TText>

                    <View style={scopedStyles.infoLine}>
                        <TText sm grey style={scopedStyles.infoDescription}>#house_house</TText>
                        <TText sm white semibold right style={scopedStyles.infoValue}>{activeAddress?.size ? activeAddress?.size + ' m²' : '#unknown'}</TText>
                    </View>

                    <View style={scopedStyles.infoLine}>
                        <TText sm grey style={scopedStyles.infoDescription}>#house_land</TText>
                        <TText sm white semibold right style={scopedStyles.infoValue}>{activeAddress?.plot_size ? activeAddress?.plot_size + ' m²' : '#unknown'}</TText>
                    </View>
                </View>

                <View style={styles.flexStretch}>
                    {/* overige */}
                    <TText md white semibold style={[scopedStyles.infoTitle]}>#house_energy</TText>

                    <View style={scopedStyles.infoLine}>
                        <TText sm grey style={scopedStyles.infoDescription}>#house_energy_label</TText>                        
                        {activeAddress?.energy_label ? 
                        <View style={[styles.flexRow, styles.alignCenter, styles.justifyEnd, scopedStyles.infoValue]}>
                            <EnergyLabel label={activeAddress?.energy_label}/> 
                        </View>
                        :
                            <TText sm white semibold right style={scopedStyles.infoValue}>#unknown</TText>
                        }
                    </View>

                    <View style={scopedStyles.infoLine}>
                        <TText sm grey style={scopedStyles.infoDescription}>#house_energy_label_end_date</TText>
                        <TText sm white semibold right style={scopedStyles.infoValue}>{activeAddress?.energy_label_end_date ? moment(activeAddress?.energy_label_end_date).format(DateTimeFormat.DISPLAY_DATE) : '#unknown'}</TText>
                    </View>

                    <View style={scopedStyles.infoLine}>
                        <TText sm grey style={scopedStyles.infoDescription}>#house_solar</TText>
                        <TText sm white semibold right style={scopedStyles.infoValue}>{activeAddress?.solar_panels ? '#yes' : '#no'}</TText>
                    </View>

                    <View style={scopedStyles.infoLine}>
                        <TText sm grey style={scopedStyles.infoDescription}>#house_smartmeter</TText>
                        <TText sm white semibold right style={scopedStyles.infoValue}>{activeAddress?.smart_meter ? '#yes' : '#no'}</TText>
                    </View>

                    {/* kosten */}
                    <TText md white semibold style={[scopedStyles.infoTitle, styles.pt30]}>#cost</TText>

                    {owner ? (
                        <>
                            <View style={scopedStyles.infoLine}>
                                <TText sm grey style={scopedStyles.infoDescription}>#koopsom</TText>
                                <TText sm white semibold right style={scopedStyles.infoValue}>{activeAddress.purchase_price ? ValutaUtils.valutaString(activeAddress.purchase_price, true) : '#unknown'}</TText>
                            </View>

                            <View style={scopedStyles.infoLine}>
                                <TText sm grey style={scopedStyles.infoDescription}>#wozwaarde</TText>
                                <TText sm white semibold right style={scopedStyles.infoValue}>{activeAddress.woz_value_home ? ValutaUtils.valutaString(activeAddress.woz_value_home, true) : '#unknown'}</TText>
                            </View>
                            
                            <View style={scopedStyles.infoLine}>
                                <TText sm grey style={scopedStyles.infoDescription}>#marktwaarde</TText>
                                <TText sm white semibold right style={scopedStyles.infoValue}>{marketValue ? ValutaUtils.valutaString(marketValue, true) : '#unknown'}</TText>
                            </View>                        
                        </>
                    ) : (
                        <>
                         <View style={scopedStyles.infoLine}>
                            <TText sm grey style={scopedStyles.infoDescription}>#kale_huur</TText>
                            <TText sm white semibold right style={scopedStyles.infoValue}>{activeAddress.rent ? ValutaUtils.valutaString(activeAddress.rent, true) : '#unknown'}</TText>
                        </View>

                        <View style={scopedStyles.infoLine}>
                            <TText sm grey style={scopedStyles.infoDescription}>#service_kosten</TText>
                            <TText sm white semibold right style={scopedStyles.infoValue}>{activeAddress.service_costs ? ValutaUtils.valutaString(activeAddress.service_costs, true) : '#unknown'}</TText>
                        </View>

                        <View style={scopedStyles.infoLine}>
                            <TText sm grey style={scopedStyles.infoDescription}>#other_kosten</TText>
                            <TText sm white semibold right style={scopedStyles.infoValue}>{activeAddress.other_costs ? ValutaUtils.valutaString(activeAddress.other_costs, true) : '#unknown'}</TText>
                        </View>
                        </>
                    )}
                </View>

            </View>
        )
    }

    const renderMortgageInfo = () => {
        const total = matchedMortgage.hoofdsomHypotheek;        
        const restant = matchedMortgage.restantHoofdsom;
        const paid = total - restant;
        const monthly = matchedMortgage.monthly_amount;
        return (
            <View style={styles.flexStretch}>
                <TText md semibold style={[scopedStyles.infoTitle, styles.pt30]}>#mortgage</TText>

                <View style={scopedStyles.infoLine}>
                    <TText sm style={scopedStyles.infoDescription}>#original_value</TText>
                    <TText sm semibold right style={scopedStyles.infoValue}>{ValutaUtils.valutaString(total)}</TText>
                </View>
                <View style={scopedStyles.infoLine}>
                    <TText sm style={scopedStyles.infoDescription}>#rest_value</TText>
                    <TText sm semibold right style={scopedStyles.infoValue}>{ValutaUtils.valutaString(restant)}</TText>
                </View>
                <View style={scopedStyles.infoLine}>
                    <TText sm style={scopedStyles.infoDescription}>#paid_value</TText>
                    <TText sm semibold right style={scopedStyles.infoValue}>{ValutaUtils.valutaString(paid)}</TText>
                </View>
                <View style={scopedStyles.infoLine}>
                    <TText sm style={scopedStyles.infoDescription}>#monthly_value</TText>
                    <TText sm semibold right style={scopedStyles.infoValue}>{ValutaUtils.valutaString(monthly)}</TText>
                </View>
            </View>
        );
    }
    
    const renderAddressInfo = () => {
        return (
            <View>
                <TText md semibold style={[scopedStyles.infoTitle, styles.pt30]}>#address</TText>
                
                <View style={scopedStyles.infoLine}>
                    <TText sm grey style={scopedStyles.infoDescription}>Adres</TText>
                    <TText sm white semibold right style={scopedStyles.infoValue}>{`${activeAddress.street_name} ${activeAddress.house_number}${activeAddress.house_number_addition}`}</TText>
                </View>
                <View style={scopedStyles.infoLine}>
                    <TText sm grey style={scopedStyles.infoDescription}>Postcode</TText>
                    <TText sm white semibold right style={scopedStyles.infoValue}>{activeAddress.postcode}</TText>
                </View>
                <View style={scopedStyles.infoLine}>
                    <TText sm grey style={scopedStyles.infoDescription}>Woonplaats</TText>
                    <TText sm white semibold right style={scopedStyles.infoValue}>{activeAddress.city}</TText>
                </View>
            </View>
        );
    }

    const renderHome = () => {
        return (
            <Card style={[{flex: 0.69, backgroundColor: colors.primary, padding: 0}]}>
                {renderStreetView()}
                <View style={[styles.flexStretch, styles.p30]}>
                    {renderInfo()}
                </View>
            </Card>
        );
    }

    const renderMortgage = () => {
        return (
            <Card style={[{flex: 0.31, maxWidth: '100%'}]}>
                {renderMap()}
                {matchedMortgage ? renderMortgageInfo() : renderAddressInfo()}
                {/* <Button type='secondary' label="Meer informatie"/> */}
            </Card>
        );
    }

    return (
        <PageContainer>

            <View style={[styles.flexRowDynamic, styles.gap30, {minHeight: 655}]}>
                
                {renderHome()}

                {renderMortgage()}

            </View>

            {/* <Card>
                {renderButtons()}
            </Card> */}

            <ScenariosCard />

        </PageContainer>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        gradientContainer: {
            flexDirection: 'row',
            overflow: 'visible'
        },
        contactInfo: {
            position: 'absolute',
            width: 360,
            right: '40%',
            top: -120,
            height: 'auto',
            backgroundColor: colors.white,
            borderRadius: 8,
            padding: 20,
            zIndex: 10
        },
        mapContainer: {
            position: 'absolute',
            right: 0,
            top: 0,
            height: '100%',
            width: '32%',
            borderRadius: 12,
            overflow: 'visible',
            zIndex: 1
        },
        underTitleContainer: {
            width: '60%',
            flexDirection: 'row',
            justifyContent: "space-between",
            borderColor: colors.grey,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            paddingVertical: 12
        },
        underTitleItem: {
            flexDirection: "row",
            alignItems: "center"
        },
        addressContainer: {
            flexDirection: 'row',
            height: 'auto',
            width: '100%',
            alignItems: "center"
        },
        homeAddress: {
            marginLeft: 20,
            flex: 1
        },
        buttonContainer: {
            flexDirection: "row",
            gap: 20
        },
        infoTitle: {
            paddingBottom: 2
        },
        infoLine: {
            width: '100%',
            flexDirection: "row",
            alignItems: 'center',
            paddingVertical: 2,            
        },
        infoDescription: {
            width: '50%', 
        },
        infoValue:{
            width: '50%', 
            textAlign: 'right'
        }
    }, {


    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
        gradientContainer: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },

    },

    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
        mapContainer: {
            position: 'relative',
            width: '100%',
            height: 'auto',
        },
        addressContainer: {
            marginBottom: 14
        },
        underTitleContainer: {
            width: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginBottom: 24
        },
        underTitleItem: {
            width: '80%',
            justifyContent: 'space-between'
        },
        buttonContainer: {
            justifyContent: 'space-between'
        },
        infoContainer: {
            width: '100%'
        },
        infoDescription: {
            width: 'auto'
        },
        infoLine: {
            justifyContent: 'space-between'
        },
        homeAddress: {
            marginLeft: 4
        }
    }
}
)