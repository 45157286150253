import { useNavigation } from "@react-navigation/native";
import { TText, Button } from "components"
import { useStyle } from "hooks";
import { View } from "react-native";
import { colors } from "styles";
import Card from "./Card";


export default () => {

    const navigation = useNavigation();
    const styles = useStyle();

    return (
        <Card style={[{backgroundColor: colors.primary, flex: 0.4}]}>

                <View style={[styles.flexStretch, styles.gap10]}>
                    <TText white md semibold>#brondata_collect</TText>
                    <TText white sm regular>#brondata_description</TText>
                </View>
                <View style={[styles.flexRow, styles.alignEnd, styles.justifyEnd]}>
                    <Button
                        label='#brondata_action'
                        type="secondary"
                        onPress={() => navigation.navigate('portaal')}
                    />
                </View>
            
        </Card>
    )
}