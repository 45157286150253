
import { Image, ImageStyle } from "react-native"
import Constants from "expo-constants";

const Config = Constants.expoConfig.extra.config;

interface IconProps{
    code: string,
    style?: ImageStyle, 
}

export default ({ code, style = {} }: IconProps) => {
    return (
        <Image
            source={{uri: `${Config.ADVIES_WIDGET_API_URL}/lender/${code}/image?api_key=sFqcTeTXPDrwcGREA8qPQ8cTYeiEu70DJSF0RHnxnkZNpwqGVqwQfBOX4t2jPzak`}}
            resizeMode="contain"
            style={[{width: 170, height: '100%'}, style]}
        />
    );
}
