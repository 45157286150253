import { useStyle } from "hooks";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { Card } from "./Card";
import { ScrollView, Text } from "react-native";
import TText from "./TText";
import Footer from "./Footer";

export default ({children} : {children?: any}) => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();    

    return (
        <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{minHeight: '100%'}}>
            <Card style={[scopedStyles.page, styles.flexStretch, styles.gap30, styles.ph40]}>
                {children}
               <Footer style={{marginTop: -25}}/>
            </Card>
        </ScrollView>
    );
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        page: {
            padding: 0,
            backgroundColor: colors.background,
            borderRadius: 0,
            borderBottomLeftRadius: 35
        }
    }
);