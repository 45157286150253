export enum TenantShowCategoryKeys {
    Hypotheken = 'show_mortgages',
    Polissen = 'show_policies',
    Krediet = 'show_obligations',
    Schades = 'show_policies',
    Inkomen = 'show_income',
    Verplichtingen = 'show_obligations',
    Afspraak = 'online_afspraken_enabled',
    AccountManager = 'show_account_manager',
    VisitAddress = 'show_visiting_address',
    OpeningHours = 'show_opening_hours',
};
