import {BottomNavBar, Button, FlexGrid, Icon, TText} from "components";
import {ConfirmModal, DocumentModal} from "components/Modals";
import OtherUploadModal from "components/Modals/OtherUploadModal";
import {DateTimeFormat, DocumentCategories, DocumentCategoryNames, QueryKeys} from "enums";
import {useItemSize, useStyle, useTranslation} from "hooks";
import {Document} from "models";
import moment from "moment";
import {ModalContext} from "providers/ModalProvider";
import React, {useContext, useEffect, useState} from "react";
import {Platform, Pressable, useWindowDimensions, View} from "react-native";
import {useQuery} from "react-query";
import {CreateResponsiveStyle, DEVICE_SIZES, maxSize} from "rn-responsive-styles";
import {colors} from "styles";
import {DocumentUtils, QueryUtils} from "utils";
import {Card, HeaderCard} from "components/Card";
import PageContainer from "components/PageContainer";


export default () => {

    const translate = useTranslation();
    const styles = useStyle();
    const {showModal, hideModal} = useContext(ModalContext);
    const scopedStyles = scopedResponsiveStyles();
    const {width} = useWindowDimensions();
    const itemSize = useItemSize();

    //the next line has a refreshInterval at the end, 30s
    const {data: tenant} = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);
    const {
        data: documents,
        isFetching: fetchingDocuments,
        refetch: refetchDocuments
    } = useQuery(QueryKeys.DOCUMENTS, QueryUtils.fetchDocuments, {refetchInterval: 30000});
    const {
        data: tenantDocuments,
        isFetching: fetchinTenantDocuments
    } = useQuery(QueryKeys.TENANT_DOCUMENTS, QueryUtils.fetchTenantDocuments, {refetchInterval: 30000});

    const [category, setCategory] = useState('KLANTEN');
    const [navItems, setNavItems] = useState<({
        title: any;
        key: string;
        amount?: number | undefined;
    } | undefined)[]>(Object.keys(DocumentCategoryNames).map((key: string) => {

        return {title: DocumentCategoryNames[key], key};

    }));
    const [categoryItems, setCategoryItems] = useState(null)

    // handling downloads
    const [{downloading, file}, setDownloading] = useState({downloading: false, file: null});

    const download = async (document) => {

        const desktop = Platform.OS == 'web' && width > 768;
        const iOs = Platform.OS == 'ios';

        if (desktop || iOs) {

            showModal(DocumentModal, document, 'DocumentModal');

        } else {

            setDownloading({downloading: true, file: document.id});

            //add extension if filename has no extension, default to txt
            const filename = (document.name.indexOf('.') < 0) ? document.name + (document.extension ? '.' + document.extension : '.txt') : document.name;

            if (document.is_tenant_document) {

                await DocumentUtils.downloadTenantDocument(document, filename);

            } else {

                await DocumentUtils.downloadDocument(document, filename);

            }

            setDownloading({downloading: false, file: null});

        }

    }

    const removeDocument = async (document: Document) => {

        const doRemove = async () => {
            hideModal();
            const response = await DocumentUtils.deleteDocument(document);
            if (response.success == true) {
                refetchDocuments();
            }
        };

        showModal(
            ConfirmModal,
            {
                title: '#document #remove',
                text: '#remove_document_text',
                buttons: [
                    <Button
                        label={'#no'}
                        type='error'
                        onPress={hideModal}
                        icon={(color) => {/* <Icons.Delete size={12} color={color} /> */
                        }}
                    />
                    ,
                    <Button
                        label={'#yes'}
                        type={"secondary"}
                        onPress={doRemove}
                        icon={(color) => {/* <Icons.Check size={12} color={color} /> */
                        }}
                    />
                ]
            },
            'ConfirmModal - remove document - ' + document.name
        );

    }

    //sort after search
    const [searchItems, setSearchItems] = useState();
    const search = (txt) => {
        if (txt == '') {
            setSearchItems(null);
            return;
        }

        const items = [];
        categoryItems && Object.keys(categoryItems).map(key => {
            categoryItems[key].map(item => {
                if (item.document.name.toLowerCase().includes(txt.toLowerCase())) items.push(item);
            })
        })
        setSearchItems(items);

    }

    //pre-check if files exist on device
    useEffect(() => {
        //categories
        const preloadCategories = async () => {
            const data = await getCategories();
            setCategoryItems(data);
        };
        preloadCategories();

        //navigation items
        if (!downloading && !fetchingDocuments && !fetchinTenantDocuments) {

            setNavItems(Object.keys(DocumentCategoryNames).map((key: string) => {

                if (!categoryItems) return undefined;
                if (DocumentCategoryNames[key] == DocumentCategoryNames.HYPOTHEKEN && tenant?.show_mortgages === false) return undefined;
                if (DocumentCategoryNames[key] == DocumentCategoryNames.POLISSEN && tenant?.show_policies === false) return undefined;
                if (DocumentCategoryNames[key] == DocumentCategoryNames.SCHADES && tenant?.show_policies === false) return undefined;
                if (DocumentCategoryNames[key] == DocumentCategoryNames.KREDIET && tenant?.show_obligations === false) return undefined;

                const amount = key == 'ADVISOR' ? tenantDocuments?.length || undefined : documents[DocumentCategories[key]]?.length || 0;
                return {title: DocumentCategoryNames[key], key, amount};

            }).filter(p => p));
        }

    }, [downloading, fetchingDocuments, fetchinTenantDocuments]);

    const getCategories = async () => {

        const result: Record<any, any> = {};

        for (let key in documents) {

            if (documents[key]?.length > 0) {
                result[key.toUpperCase()] = documents[key].sort((a: Document, b: Document) => {
                    return moment(b.updated_at || b.created_at).diff(moment(a.updated_at || a.created_at));
                });
            }

        }

        if (tenantDocuments) {
            result['ADVISOR'] = tenantDocuments.sort((a: Document, b: Document) => {
                return moment(b.updated_at || b.created_at).diff(moment(a.updated_at || a.created_at));
            });
        }

        return result;
    }

    //render items
    const renderSelectedCategory = () => {

        if (!categoryItems) return;
        const items = searchItems ? searchItems : categoryItems[category];
        if (!items) return;

        return items
            .map((document: Document) => {
                return renderItem(document);
            })
            .filter(item => item);
    }

    const renderItem = (document: Document) => {
        return (
            <Card style={scopedStyles.documentContainer}>
                <Pressable key={document.id} onPress={() => download(document)}>
                    <View style={scopedStyles.documentIcon}>
                        <View style={[styles.flexRow, {backgroundColor: colors.white}]}>
                            <Icon
                                iconName={'IconFileDescription'}
                                iconSize={60}
                                iconColor={colors.secondary}
                            />
                        </View>
                    </View>

                    <View>
                        <TText xs semibold style={{
                           color: colors.primary,
                           textAlign: 'center',
                           paddingTop: 10,
                           minHeight: 100
                        }}>
                            {document.name.replace(/_/g, ' ').replace(/#/g, '')}
                        </TText>
                    </View>
                    <View style={[styles.flexRow, styles.alignEnd, styles.justifyBetween, {gap: 0}]}>
                        <TText xs style={{color: colors.primary}}>
                            {(document.created_at || document.updated_at) ? moment(document.updated_at || document.created_at).format(DateTimeFormat.DISPLAY_DATE) : '#unknown'}
                        </TText>
                        <Icon
                            iconName={'IconArrowRight'}
                            iconColor={colors.secondary}
                            iconSize={20}
                        />
                    </View>
                </Pressable>
            </Card>
        )
    }

    const renderButtons = () => {
        return (
            <Card style={{backgroundColor: colors.primary, width: '100%'}}>
                <View style={[styles.flexStretch, styles.gap10, {paddingBottom: 20}]}>
                    <TText white md semibold>#title_documents</TText>
                    <TText white xs regular>#undertitle_documents</TText>
                </View>

                <Button
                    label="#upload"
                    type='secondary'
                    onPress={() => showModal(OtherUploadModal, {}, 'OtherUploadModal')}
                />
            </Card>
        )
    }

    return (
        <PageContainer>

            <View style={[styles.flexRowDynamic]}>

                {renderButtons()}

            </View>

            <HeaderCard header="Alle documenten">

                <BottomNavBar
                    noSearch={true}
                    navItems={navItems}
                    handleNavItemSelect={(e) => setCategory(e.key)}
                    handleSearchQuery={(e) => search(e)}
                />

                <FlexGrid columns={4}>
                    {renderSelectedCategory()}
                </FlexGrid>

            </HeaderCard>

        </PageContainer>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        topContainer: {
            height: 100,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: "center",
            backgroundColor: colors.background
        },
        buttonContainer: {
            width: '70%',
            flexDirection: 'row',
            justifyContent: "flex-end"
        },
        uploadButton: {
            marginRight: 20,
            flex: 0.47,
            maxWidth: 250
        },
        sortButton: {
            width: 250
        },
        categoryDropDown: {
            position: 'absolute',
            top: 100,
            right: 0,
            width: 250,
            flexGrow: 1,
            paddingRight: 8,
            alignItems: "center",
            backgroundColor: colors.background,
            borderColor: colors.spacer,
            borderWidth: 1,
            borderTopWidth: 0,
            borderRadius: 8,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            zIndex: 9000,
            overflow: "hidden",
        },
        gradientContainer: {
            width: '100%',
            flex: 1
        },
        categoryContainer: {
            width: '100%',
            flexBasis: Platform.OS == 'web' ? 'auto' : 1, //!!! important, do not change. scrollview item on webmobile
            borderRadius: 8,
            backgroundColor: colors.background
        },
        categoryTitle: {
            marginBottom: 20
        },
        button: {
            position: 'absolute',
            width: '90%',
            height: '20%',
            left: '5%',
            bottom: 12
        },
        documentContainer: {
            flex: 1,
            maxHeight: 250,
            borderRadius: 15,
            paddingVertical: 10,
            paddingHorizontal: 10,
            backgroundColor: colors.background,
        },
        documentIcon: {
            alignItems: 'center',
            justifyContent: 'center',
            height: 100,
            borderRadius: 15,
            paddingVertical: 20,
            paddingHorizontal: 20,
            backgroundColor: colors.white,
        },
    }, {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            documentContainer: {
                flexBasis: '47%',
            },
        },
        [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
            documentContainer: {
                flexBasis: '45%',
            },
        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            categoryDropDown: {
                left: 0,
                top: 99,
                width: '100%',
            },
            categoryContainer: {
                width: '90%',
                marginLeft: '5%',
                marginVertical: 20,
                paddingHorizontal: 12,
                paddingVertical: 12
            },
            button: {
                width: '80%',
                marginLeft: Platform.OS != 'web' ? 12 : 0,
                alignSelf: 'center'
            },
            buttonContainer: {
                position: 'absolute',
                top: 0,
                right: 4,
                height: 100,
                width: '32%',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
            },
            uploadButton: {
                flex: 0.5,
                width: '100%'
            },
            sortButton: {
                marginTop: 2,
                height: 32,
                width: '100%'
            },
        },
        [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
            documentContainer: {
                flexBasis: '100%',
            },
        },
    }
);