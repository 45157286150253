import { TText } from "components";
import { useStyle } from "hooks";
import { Image, View } from "react-native";

const headerImage = require('assets/images/header.png');

export const TenantError = () => {

    const styles = useStyle();

    let title = 'Dit kantoor is onbekend bij DossierData';
    let message = 'Het subdomein in de url is onbekend. Zorg er voor dat je het juiste adres gebruikt.';

    return (
        <View style={[styles.fullscreen, styles.justifyCenter, styles.alignCenter]}>
            <Image source={headerImage} style={{ width: 500, height: 100, marginBottom: 22 }} resizeMethod='resize' />
            <TText xl semibold>{title}</TText>
            <View style={{ position: 'absolute', flexDirection: 'row', justifyContent: 'center', bottom: 40 }}>
                <TText md semibold>{message}</TText>
            </View>
        </View>
    )

}