import { TText } from "components";
import { DamageUploadModal } from "components/Modals";
import { CustomerViewableObjects, DateTimeFormat, QueryKeys } from "enums";
import { Damage } from "models";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { StoreContext } from "providers/StoreProvider";
import { useContext } from "react";
import { View } from "react-native";
import { useQuery } from "react-query";
import { CustomerUtils, QueryUtils } from "utils";
import BaseModule from "./BaseModule";

export default ({ damage, warning }: { damage: Damage, warning: boolean }) => {

    const { showModal } = useContext(ModalContext);
    const { triggerLoading } = useContext(StoreContext);
    const { refetch: refetchDamage } = useQuery(QueryKeys.DAMAGE, QueryUtils.fetchDamage);

    const progressItems = damage.progress_items.reduce((acc, v) => (v.received) ? acc : acc + 1, 0);
    const disabled = damage.status || progressItems == 0;

    return (
        <BaseModule
            disabled={disabled}
            warning={warning}
            title={'#damage_report'}
            underTitle={damage.date ? moment(damage.date).format(DateTimeFormat.DISPLAY_DATE) : '#unknown'}
            text={
                <View>
                    <TText noTranslate regular sm black>{damage.description ? damage.description.split(' - ')[1] : ''}</TText>
                </View>
            }
            buttonValue={disabled ? '#action_view_message' : '#action_documenten_leveren'}
            buttonAction={async () => {
                showModal(DamageUploadModal, { damageProgressId: damage.id }, 'DamageUploadModal')
                if (!disabled) {
                    damage.seen_by_customer = true;
                    await CustomerUtils.view(CustomerViewableObjects.DAMAGE, damage.id);
                    refetchDamage();
                    triggerLoading();
                }
            }}
        />
    )
}