import { Tenant } from 'models';
import { useEffect, useState } from 'react';
import { QueryUtils } from 'utils';

const header = require('assets/images/header.png');

export default () => {

    const [logo, setLogo] = useState('');

    useEffect(() => {
        const [_, domain, tld] = window.location ? window.location.hostname.split('.') : [];

        if ((domain == 'klantportaal' && tld == 'net') || domain == 'localhost') {

            QueryUtils.fetchTenant().then((tenant: Tenant) => {
                if (tenant.error) {
                    window.setTenantError();
                    return;
                } else {
                    if (tenant?.logo) {
                        setLogo('data:image/png;base64,' + tenant.logo)
                    } else {
                        setLogo(header);
                    }
                }
            })

        } else {

            setLogo(header);

        }
    }, [])

    return logo;

}