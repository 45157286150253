import { useEffect, useRef, useState } from "react";

export default ( amount = 1) => {

    const hoverBank = [];

    for(let i = 0; i < amount; i++){

        hoverBank[i] = [
            useRef(null),
            useState(false),
        ];

        const hoverElt = hoverBank[i][0];
        const [ _, setHovered ] = hoverBank[i][1];

        useEffect(() => {
                        
            const enter = () => setHovered(true);
            const leave = () => setHovered(false);

            if (hoverElt.current?.addEventListener) {
                hoverElt?.current?.addEventListener('mouseenter', enter)
                hoverElt?.current?.addEventListener('mouseleave', leave)
                return () => {
                    hoverElt?.current?.removeEventListener('mouseenter', enter);
                    hoverElt?.current?.removeEventListener('mouseleave', leave);
                }
            }
        }, [hoverElt.current])

    }

    const output = hoverBank.map(([hoverElt, hoverState]) => [hoverState[0], hoverElt]);
    return output.length === 1 ?  output[0] : output;
}