import { Icons } from "icons";
import { AuthUser } from "models/UserProfile";
import { useEffect, useRef, useState } from "react";
import { Image, Platform, Pressable, View } from "react-native";
import { colors } from "styles";
import { AuthUtils } from "utils";
import TText from "./TText";
import Icon from "./Icon";
import { useStyle } from "hooks";

export default ({ handleAccountChange, handleAccountDelete }: { handleAccountChange: (id: string) => void, handleAccountDelete: () => void }) => {

    const styles = useStyle();
    const [users, setUsers] = useState<[string, AuthUser][]>([]);
    const currentUserId: any = useRef(null);

    useEffect(() => {
        const getUsers = async () => {
            currentUserId.current = await AuthUtils.getCurrentUserId();
            const users = await AuthUtils.getUsers();
            setUsers(Object.entries(users));
        }
        getUsers();
    }, []);

    const removeAccount = async (id: string) => {
        const users = await AuthUtils.getUsers();
        if (users[id]) {
            delete users[id];
            setUsers(Object.entries(users));
            await AuthUtils.storeUsers(users);
            handleAccountDelete && handleAccountDelete();
        }
    }

    const renderAvatar = (avatar) => {
        return avatar ? (
            <Image
                source={{ uri: `data:image/png;base64,${avatar}` }}
                width={50}
                height={50}
                style={{
                    width: 50,
                    height: 50,
                    borderRadius: 10,
                    alignSelf: 'center'
                }}
            />
        ) : (
            <View style={{
                width: 50,
                height: 50,
                alignSelf: 'center'
            }}>
            </View>
        );
    }

    return (
        <View>
            {users.map(([id, user]: [string, AuthUser]) => (
                <Pressable
                    disabled={id === currentUserId.current}
                    key={id}
                    style={[
                        {
                            flexDirection: "row",
                            gap: 10,
                            backgroundColor: id === currentUserId.current ? colors.blackTransparent : colors.background,
                            borderRadius: 12,
                            padding: 12,
                            marginBottom: 30
                        },
                        Platform.OS === 'web' && id !== currentUserId.current && {
                            cursor: 'pointer'
                        }
                    ]}
                    onPress={() => handleAccountChange(id)}
                >                    
                    {renderAvatar(user.avatar)}

                    <View style={[styles.flexRow, styles.alignCenter, styles.flexStretch]}>
                        <TText semibold md
                            style={{
                                flex: 1,
                                color: id === currentUserId.current ? colors.white : colors.primary,
                            }}
                        >
                            {user.name}
                        </TText>
                        <Pressable
                            disabled={id === currentUserId.current}
                            onPress={(e) => {
                                removeAccount(id);
                                e.stopPropagation();
                            }}
                            style={{
                                width: 28,
                                height: 28,
                                borderRadius: 999,
                                backgroundColor: id === currentUserId.current ? colors.blackTransparent : colors.background,
                                justifyContent: "center",
                                alignItems: 'center'
                            }}
                        >
                            <Icon iconName="IconTrash" iconColor={colors.primary7}/>
                        </Pressable>
                    </View>
                </Pressable>
            ))}
        </View>
    )
}