export class OnlineAfsprakenAppointment {
    id: number | null
    appointment_type_id: number | null
    time_start: string
    time_end: string
    customer_id: number | null
    description: string
    summary: string
    event_type: string
    resource_id: number | null

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): OnlineAfsprakenAppointment {
        this.id = json.id ? json.id : null;
        this.appointment_type_id = json.appointment_type_id ? json.appointment_type_id : null;
        this.time_start = json.time_start ? json.time_start : '';
        this.time_end = json.time_end ? json.time_end : '';
        this.customer_id = json.customer_id ? json.customer_id : null;
        this.description = json.description ? json.description : '';
        this.summary = json.summary ? json.summary : '';
        this.event_type = json.event_type ? json.event_type : '';
        this.resource_id = json.resource_id ? json.resource_id : null;
        return this;
    }
}
