import { Icon, TextInput, TText } from 'components';
import { DateTimeFormat } from 'enums';
import { useStyle } from 'hooks';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Pressable, View, ViewStyle } from 'react-native';
import { colors } from 'styles';
import { DatePickerModal } from './Modals';
import { DateUtils } from 'utils';

export default ({label, defaultValue, error, onChange, style}: {label: string, error?: string, defaultValue?: string, onChange: (date: string) => void, style?: ViewStyle}) => {

    const styles = useStyle();    
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [date, setDate] = useState('');

    useEffect(() => {
        if (defaultValue) {
            setDate(moment(defaultValue).format(DateTimeFormat.DATE));
        }
    }, [defaultValue])

    return (
        <View style={styles.flexStretch}>            
            {label && 
                <TText sm semibold style={{marginBottom: 5}}>
                    {label}
                </TText>
            }

            <View>
                <TextInput 
                    selectTextOnFocus={true}
                    value={date}
                    onChangeText={(dateString: string) => setDate(DateUtils.autoDateFormatting(dateString))}
                    style={[{paddingLeft: 50}, style]}
                />
                <Pressable
                    focusable={false}
                    accessible={false}
                    onPress={() => { setShowDatePicker(true) }}
                    style={[styles.alignCenter, styles.justifyCenter, {
                        position: 'absolute', 
                        left: 0, 
                        top: 0, 
                        bottom: 0, 
                        width: 40,
                        backgroundColor: colors.primary2,
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                    }]}
                >
                    <Icon iconName="IconCalendar" iconColor={colors.primary}/>
                </Pressable>
            </View>
            
            {error && 
                <TText regular sm error style={{textAlign: 'right'}}>{error}</TText>
            }
            
            {showDatePicker &&
                <DatePickerModal
                    handleDateSelect={(date: Moment) => {
                        setDate(date.format(DateTimeFormat.DATE));
                        onChange(date.format(DateTimeFormat.SERVER_DATE))
                    }}
                    hideModal={() => setShowDatePicker(false)}
                    initialDate={date ? moment(date, DateTimeFormat.DATE) : moment()}
                />
            }
        </View>
    );
}