import {useStyle} from "hooks";
import {Button, DatePicker, RadioGroup, TextInput, TText} from "components";
import {HeaderCard} from "components/Card";
import {bool, object, string} from "yup";
import {useFormik} from "formik";
import {View} from "react-native";
import {EditDataUtils, QueryUtils} from "utils";
import {DateTimeFormat, QueryKeys} from "enums";
import {useQuery} from "react-query";
import {useEffect, useRef, useState} from "react";
import {Customer, Person} from "models";
import moment from "moment";
import {colors} from "styles";

export default () => {

    const styles = useStyle();
    const {data: customer, refetch: refetchCustomer} = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer, {refetchOnWindowFocus: false, staleTime: Infinity});
    const {data: family, refetch: refetchFamily} = useQuery(QueryKeys.FAMILY, QueryUtils.fetchFamily, {refetchOnWindowFocus: false, staleTime: Infinity});
    const [loading, setLoading] = useState(false);
    const response = useRef<undefined | string>(undefined);

    useEffect(() => {
        if (customer instanceof Customer) {
            const newValues = {
                ...values,
                initials: customer.initials,
                first_name: customer.first_name,
                suffix: customer.suffix,
                last_name: customer.last_name,
                phone: String(customer.phone),
                date_of_birth: customer.date_of_birth,
                has_partner: family?.partner instanceof Person
            };

            if (family?.partner instanceof Person) {
                newValues.date_of_birth_partner = family.partner.date_of_birth;
            }

            setValues(newValues);
        }
    }, [customer])

    const changeGeneralSchema = object({
        initials: string(),
        first_name: string().required('Dit veld is verplicht'),
        suffix: string(),
        last_name: string().required('Dit veld is verplicht'),
        phone: string(),
        date_of_birth: string().required('Dit veld is verplicht'),
        date_of_birth_partner: string().when('has_partner', {
            is: true,
            then: string().required('Dit veld is verplicht'),
            otherwise: string().notRequired(),
        }),
        has_partner: bool()
    });

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        setValues,
        values,
        errors,
        touched
    } = useFormik({
        validationSchema: changeGeneralSchema,
        initialValues: {
            initials: "",
            first_name: "",
            suffix: "",
            last_name: "",
            phone: "",
            date_of_birth: "",
            date_of_birth_partner: "",
            has_partner: false
        },
        onSubmit: values => {
            setLoading(true);
            response.current = undefined;
            EditDataUtils.uploadPersonalia({
                ...values,
                date_of_birth: moment(values.date_of_birth).format(DateTimeFormat.SERVER_DATE),
                date_of_birth_partner: values.date_of_birth_partner && moment(values.date_of_birth_partner).format(DateTimeFormat.SERVER_DATE)
            })
                .then(() => {
                    setLoading(false);
                    response.current = {
                        successful: true,
                        label: 'Je gegevens zijn succesvol gewijzigd'
                    };
                    refetchCustomer();
                    refetchFamily();
                })
                .catch((error) => {
                    setLoading(false);
                    response.current = {
                        successful: false,
                        label: error.response.data.error.message.includes('Could not persist object of type \"App\\Models\\Customer\"') ?
                            'Opslaan is niet gelukt, je adviseur is bezig met jouw dossier' :
                            'Er ging iets mis bij het opslaan van je gegevens'
                    };
                });
        },
    });

    const [hasPartnerOptions] = useState([
        {label: 'Ja', value: true},
        {label: 'Nee', value: false},
    ]);

    const handleHasPartnerChange = (newValue: boolean) => {
        setValues(v => ({...v, has_partner: newValue}));
    };

    return (
        <HeaderCard header="Algemeen">
            <View style={styles.gap30}>
                <View style={styles.gap10}>
                    <TText secondary semibold>Persoons gegevens</TText>
                    <TextInput
                        label={"Voorletters"}
                        value={values.initials}
                        onChangeText={(txt: string) => setValues(v => ({...v, initials: txt}))}
                        onBlur={handleBlur('initials')}
                        error={(touched.initials && errors.initials) ? errors.initials : undefined}
                    />
                    <TextInput
                        label={"Voornaam"}
                        value={values.first_name}
                        onChangeText={(txt: string) => setValues(v => ({...v, first_name: txt}))}
                        onBlur={handleBlur('first_name')}
                        error={(touched.first_name && errors.first_name) ? errors.first_name : undefined}
                    />
                    <TextInput
                        label={"Tussenvoegsel"}
                        value={values.suffix}
                        onChangeText={(txt: string) => setValues(v => ({...v, suffix: txt}))}
                        onBlur={handleBlur('suffix')}
                        error={(touched.suffix && errors.suffix) ? errors.suffix : undefined}
                    />
                    <TextInput
                        label={"Achternaam"}
                        value={values.last_name}
                        onChangeText={(txt: string) => setValues(v => ({...v, last_name: txt}))}
                        onBlur={handleBlur('last_name')}
                        error={(touched.last_name && errors.last_name) ? errors.last_name : undefined}
                    />
                    <DatePicker
                        label="Geboortedatum"
                        defaultValue={values.date_of_birth}
                        onChange={(date) => {
                            setValues({...values, date_of_birth: date})
                        }}
                        error={(touched.date_of_birth && errors.date_of_birth) ? errors.date_of_birth : undefined}
                    />
                    {(customer?.can_update_partner) &&
                        <RadioGroup
                            label="Heb je een partner?"
                            options={hasPartnerOptions}
                            value={values.has_partner}
                            onChange={(newValue: boolean) => {
                                setValues({...values, has_partner: newValue})
                            }}
                            error={(touched.has_partner && errors.has_partner) ? errors.has_partner : undefined}
                        />
                    }

                    {(values.has_partner && customer?.can_update_partner) &&
                        <DatePicker
                            label="Geboortedatum van je partner"
                            defaultValue={values.date_of_birth_partner}
                            onChange={(date) => {
                                setValues({...values, date_of_birth_partner: date})
                            }}
                            error={(touched.date_of_birth_partner && errors.date_of_birth_partner) ? errors.date_of_birth_partner : undefined}
                        />
                    }
                </View>

                <View style={styles.gap10}>
                    <TText secondary semibold>Contact gegevens</TText>
                    <TextInput
                        label={"Telefoonnummer"}
                        value={values.phone}
                        onChangeText={(txt: string) => setValues(v => ({...v, phone: txt}))}
                        onBlur={handleBlur('phone')}
                        error={(touched.phone && errors.phone) ? errors.phone : undefined}
                    />
                </View>

                <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                    <Button
                        loading={loading}
                        label={'wijzigingen opslaan'}
                        type={'secondary'}
                        onPress={handleSubmit}
                    />
                    {response.current !== undefined &&
                        <TText style={{color: response.current.successful ? colors.approval : colors.error}}>
                            {response.current.label}
                        </TText>
                    }
                </View>
            </View>
        </HeaderCard>
    );

}
