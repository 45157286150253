export class OnlineAfsprakenAppointmentType {
    id: number | null
    name: string
    description: string
    status: number | null
    priceType: number | null
    price: number | null
    duration: number | null
    minTimeBeforeAppointment: number | null
    maxTimeBeforeAppointment: number | null
    buffer: number | null
    canBeBookedByConsumer: number | null
    category: string
    category_id: number | null
    customFields: Array<any>
    availableCustomFields: Array<any>
    resourceId: string;

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): OnlineAfsprakenAppointmentType {
        this.id = json.id ? json.id : null;
        this.name = json.name ? json.name : '';
        this.description = json.description ? json.description : '';
        this.status = json.status ? json.status : null;
        this.priceType = json.priceType ? json.priceType : null;
        this.price = json.price ? json.price : null;
        this.duration = json.duration ? json.duration : null;
        this.minTimeBeforeAppointment = json.minTimeBeforeAppointment ? json.minTimeBeforeAppointment : null;
        this.maxTimeBeforeAppointment = json.maxTimeBeforeAppointment ? json.maxTimeBeforeAppointment : null;
        this.buffer = json.buffer ? json.buffer : null;
        this.canBeBookedByConsumer = json.canBeBookedByConsumer ? json.canBeBookedByConsumer : null;
        this.category = json.category ? json.category : '';
        this.category_id = json.category_id ? json.category_id : null;
        this.customFields = json.customFields ? json.customFields : [];
        this.availableCustomFields = json.availableCustomFields ? json.availableCustomFields : [];
        this.resourceId = json.resourceId ? json.resourceId : '';
        return this;
    }

    getLabel = () => {
        return this.name;
    }

    getValue = () => {
        return this.id;
    }
}
