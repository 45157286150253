import { useNavigation } from "@react-navigation/native";
import { Icons } from "icons";
import { Button, OpenNow, TText } from "components";
import { MessageModal } from "components/Modals";
import OnlineAfspraakModal from "components/Modals/OnlineAfspraakModal";
import { QueryKeys, TenantShowCategoryKeys } from 'enums';
import { useStyle } from "hooks";
import { ModalContext } from "providers/ModalProvider";
import { useContext } from "react";
import { Dimensions, Image, Pressable, StyleSheet, View } from "react-native";
import { useQuery } from "react-query";
import { colors } from "styles";
import { QueryUtils } from "utils";


export default () => {

    const styles = useStyle();
    const { data: tenant } = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const { showModal } = useContext(ModalContext);
    const navigation = useNavigation();
    const largeScreen = Dimensions.get('window').width > 768;
    const avatarSize = tenant && tenant[TenantShowCategoryKeys.Afspraak] ? 50 : 80;

    return (
        <View style={{ width: '100%', flex: 1 }}>
            <View style={[
                scopedStyles.form,
            ]}>

                <OpenNow />

                <View style={scopedStyles.title}>
                    <TText md semibold>#title_contact</TText>
                    <Pressable onPress={() => navigation.navigate('contact')}>
                        <TText sm semibold>#more</TText>
                    </Pressable>
                </View>

                <View style={{ flex: 1, justifyContent: 'space-between' }}>

                    <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>

                        <View style={{ width: 100, justifyContent: "center" }}>
                            {customer?.advisor?.avatar ? (
                                <Image
                                    source={{ uri: `data:image/png;base64,${customer.advisor.avatar}` }}
                                    width={avatarSize}
                                    height={avatarSize}
                                    style={{
                                        width: avatarSize,
                                        height: avatarSize,
                                        borderRadius: avatarSize / 2
                                    }}
                                />
                            ) : (
                                {/* <Icons.Avatar_male size={avatarSize} color={''} /> */}
                            )}
                        </View>
                        <View style={{ justifyContent: "space-around" }}>
                            <TText sm semibold>{customer?.advisor?.full_name || '#unknown'}</TText>
                            <TText xs regular style={{ color: colors.darkgrey }}>{customer?.advisor?.email || ''}</TText>
                            <TText xs regular style={{ color: colors.darkgrey }}>{customer?.advisor?.phone || ''}</TText>
                        </View>

                    </View>

                    {(tenant && tenant[TenantShowCategoryKeys.AccountManager] !== false) && customer?.account_manager &&
                        <View style={{ flex: 1, flexDirection: "row", alignItems: "center", marginTop: 8 }}>

                            <View style={{ width: 100, justifyContent: "center" }}>
                                {customer?.account_manager?.avatar ? (
                                    <Image
                                        source={{ uri: `data:image/png;base64,${customer.account_manager.avatar}` }}
                                        width={avatarSize}
                                        height={avatarSize}
                                        style={{
                                            width: avatarSize,
                                            height: avatarSize,
                                            borderRadius: avatarSize / 2
                                        }}
                                    />
                                ) : (
                                    {/* <Icons.Avatar_male size={avatarSize} color={''} /> */}
                                )}
                            </View>
                            <View style={{ justifyContent: "space-around" }}>
                                <TText sm semibold>{customer?.account_manager?.full_name || '#unknown'}</TText>
                                <TText xs regular style={{ color: colors.darkgrey }}>{customer?.account_manager?.email || ''}</TText>
                                <TText xs regular style={{ color: colors.darkgrey }}>{customer?.account_manager?.phone || ''}</TText>
                            </View>

                        </View>
                    }

                </View>

                <View style={{ justifyContent: 'space-between', flexShrink: 0 }}>
                    <View style={{ flexDirection: 'column' }}>
                        <Button
                            label="#action_get_contact"
                            type="action"
                            onPress={() => showModal(MessageModal, { contact: customer.advisor }, 'MessageModal')}
                        />
                        {tenant && tenant[TenantShowCategoryKeys.Afspraak] &&
                            <Button
                                label="#action_make_appointment_online"
                                type="action"
                                onPress={() => showModal(OnlineAfspraakModal, {}, 'OnlineAfspraakModal')}
                                style={{ marginTop: 8 }}
                            />
                        }
                    </View>
                </View>

            </View>
        </View>
    )
}

const scopedStyles = StyleSheet.create({
    form: {
        flex: 1,
        backgroundColor: colors.formBackground,
        borderRadius: 4,
        paddingHorizontal: 30,
        paddingVertical: 20,
        overflow: 'visible'
    },
    title: {
        flexDirection: 'row',
        justifyContent: "space-between",
        alignItems: 'center',
    },
    text: {
        flex: 1,
        marginTop: 12,
        alignItems: 'flex-start',
        borderBottomColor: colors.blackTransparent,
        borderBottomWidth: 1
    }
})
