import { Icon, TText } from "components";
import { Card } from "components/Card";
import BaseModal from "components/Modals/BaseModal";
import { DateTimeFormat } from "enums";
import moment from "moment";
import { useState } from "react";
import { Pressable, Text, View } from "react-native";
import { colors } from "styles";
import { ValutaUtils } from "utils";


export default ({person, type, scenarioKey}) => {

    const [showModal, setShowModal] = useState(false);

    const renderInformation = () => {
        const verdict = person.verdict[type];

        switch(type){
            case 'can_pay_mortgage':
                return (
                    <View style={{paddingTop: 20}}>
                        <Text>
                            {scenarioKey === 'retirement' ?
                                <TText>Op {moment(person.mortgage_situation.date).format(DateTimeFormat.DISPLAY_DATE)} bedragen de bruto maandlasten van je hypotheek naar verwachting </TText>
                                :
                                <TText>Wanneer dit gebeurt bedragen de bruto maandlasten van je hypotheek naar verwachting </TText>
                            }                            
                            <TText semibold oneline>{ValutaUtils.valutaString(person.mortgage_situation.monthly_payments)}</TText>
                            <TText> per maand. Dit is </TText>
                            <TText semibold oneline>{ValutaUtils.valutaString(Math.abs(person.maximum_mortgage.monthly_payments - person.mortgage_situation.monthly_payments))}</TText>
                            <TText>{verdict ? ' minder' : ' meer'}dan de Nibud-normen van </TText>
                            <TText semibold oneline>{ValutaUtils.valutaString(person.maximum_mortgage.monthly_payments)} </TText>
                            <TText>voor verantwoord lenen.</TText>
                        </Text>                        
                        <TText>Daarom verwachten wij dat de bruto hypotheeklasten op dat moment{verdict ? '' : 'niet'}goed betaalbaar zijn.</TText>
                    </View>
                );

            case 'can_prolong_mortgage':
                return (
                    <View style={{paddingTop: 20}}>
                        <Text>
                        {scenarioKey === 'retirement' ?
                                <TText>Op {moment(person.mortgage_situation.date).format(DateTimeFormat.DISPLAY_DATE)} bedraagt je hypotheekhoogte </TText>
                                :
                                <TText>Wanneer dit gebeurt bedraagt je hypotheekhoogte </TText>
                            }                            
                            <TText semibold oneline>{ValutaUtils.valutaString(person.mortgage_situation.remaining_sum)}</TText>
                            <TText>. Dit is </TText>
                            <TText semibold oneline>{ValutaUtils.valutaString(Math.abs(person.maximum_mortgage.total_sum - person.mortgage_situation.remaining_sum))}</TText>
                            <TText>{verdict ? ' minder' : ' meer'}dan de Nibud-normen van </TText>
                            <TText semibold oneline>{ValutaUtils.valutaString(person.maximum_mortgage.total_sum)} </TText>
                            <TText>voor verantwoord lenen.</TText>
                        </Text>                        
                        <TText>Daarom verwachten wij dat de bruto hypotheeklasten op dat moment{verdict ? '' : 'niet'}goed betaalbaar zijn.</TText>
                    </View>
                );
        }
    }

    return (
        <View>
            <Pressable onPress={() => setShowModal(true)}>
                <Icon iconName="IconInfoCircle" iconColor={colors.secondary} iconSize={20}/>
            </Pressable>
            <BaseModal
                visible={showModal}
                hideModal={()=>setShowModal(false)}
                style={{width: 450, minHeight: 'auto'}}
            >
                {renderInformation()}
            </BaseModal>
        </View>
    );
}