import { Icons } from "icons";
import { Badge, Button } from "components";
import { TText } from "components";
import CustomTextInput from 'components/TextInput';
import { DamageCategoryNames, DamageTypes, DateTimeFormat, QueryKeys } from 'enums';
import { useStyle } from "hooks";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { StoreContext } from "providers/StoreProvider";
import { useContext, useRef, useState } from "react";
import { Platform, Pressable, TextInput, View, useWindowDimensions } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { DocumentUtils, QueryUtils } from 'utils';
import BaseModal from './BaseModal';
import DatePickerModal from "./DatePickerModal";
import IosUploadSelectModal from "./IosUploadSelectModal";


export default ({ settings }) => {

    const { width } = useWindowDimensions();
    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const { hideModal } = useContext(ModalContext);
    const { width: windowWidth } = useWindowDimensions();
    const { triggerLoading } = useContext(StoreContext);
    const { refetch } = useQuery(QueryKeys.DAMAGE, QueryUtils.fetchDamage);

    const [damageDate, setDamageDate] = useState(moment());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [inputError, setInputError] = useState('');


    const [buttonTop, setButtonTop] = useState(false);
    const [response, setResponse] = useState<true | false | "file_size" | null>(null);
    const [showFileUpload, setShowFileUpload] = useState(false);
    const [iosupload, setIosUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const sending = useRef(false);
    const [cost, setCost] = useState('');
    const [body, setBody] = useState('');
    const [damageType, setDamageType] = useState('home');
    const [damageDocuments, setDamageDocuments] = useState({
        'claim_form': [],
        'purchase_invoice': [],
        'quote_repair_costs': [],
        'other': [],
        'damage': []
    });
    const totalDocuments = damageDocuments ? Object.keys(damageDocuments).reduce((acc, key) => { return acc + (damageDocuments[key].length || 0) }, 0) : 0;

    const addDocument = async (category, iosMethod) => {

        if (Platform.OS == 'ios' && !iosMethod) {
            setIosUpload(category);
            return;
        }

        const pickMethod = Platform.select({
            ios: async () => {
                if (iosMethod == 'image') {
                    return await DocumentUtils.pickImage();
                } else {
                    return await DocumentUtils.pickDocument();
                }
            },
            default: async () => {
                return await DocumentUtils.pickDocument();
            }
        })

        const pickedDocument = await pickMethod();
        if (!pickedDocument) return;

        setIosUpload(false);

        setDamageDocuments(
            {
                ...damageDocuments,
                [category]: [
                    ...damageDocuments[category],
                    pickedDocument
                ]
            }
        )
    }
    const removeDocument = (category, document) => {
        const catDocs = damageDocuments[category].filter(doc => doc !== document);
        setDamageDocuments(
            {
                ...damageDocuments,
                [category]: catDocs
            }
        )
    }



    const send = async () => {

        setInputError('');
        if (!cost || body.trim() == '' || sending.current) {
            let err = '#input_fields_required \n';
            if (!cost) err += '• ' + '#damage_amount \n';
            if (body.trim() == '') err += '• ' + '#description';
            if (!sending.current) setInputError(err);
            return;
        }

        setLoading(true);
        sending.current = true;

        const response = await DocumentUtils.uploadDamage(
            damageType,
            cost,
            damageDate.format('DD-MM-YYYY'),
            body,
            damageDocuments
        );
        setResponse(response?.data?.success ? true : response.status === 413 ? 'file_size' : false);
        if (response?.data?.success) {
            await refetch();
            triggerLoading();
        }

        setLoading(false);
        sending.current = false;

    }

    const reset = () => setResponse(null);







    const renderForm = () => {

        //dont show when selecting images on small screens
        if (windowWidth < 768 && showFileUpload) return;

        return (
            <View style={scopedStyles.container}>

                {response !== null ? (

                    <View style={{ width: '100%', flex: 1, justifyContent: "center" }}>
                        <TText lg regular center error={response !== true}>
                            {response === true ? '#message_sent' : response === false ? '#message_error' : '#message_error_file_size'}
                        </TText>
                        <View style={{ marginTop: 20, flexDirection: "row", justifyContent: 'center' }}>
                            <Button
                                label={response === true ? '#action_close' : '#action_retry'}
                                type={"secondary"}
                                onPress={response === true ? hideModal : reset}
                            />
                        </View>
                    </View>

                ) : (

                    <View style={{ flex: 1 }}>
                        <View style={{ flex: 1, justifyContent: 'space-between', paddingBottom: 20 }}>

                            <TText lg regular style={scopedStyles.title}>#title_damage</TText>

                            {buttonTop &&
                                <View style={{ flexDirection: "row", marginBottom: 20 }} >
                                    <Button
                                        loading={loading}
                                        label={'#action_hide_keyboard'}
                                        type={"secondary"}
                                        onPress={send}
                                    />
                                </View>
                            }

                            {windowWidth < 768 &&
                                <Pressable
                                    onPress={() => setShowFileUpload(s => !s)}
                                    style={{
                                        position: 'absolute',
                                        top: 60,
                                        right: -30.1,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        zIndex: 99
                                    }}
                                >
                                    <View style={{
                                        height: 55,
                                        width: 55,
                                        backgroundColor: colors.primary2,
                                        borderTopLeftRadius: 27.5,
                                        borderBottomLeftRadius: 27.5,
                                        borderWidth: 0,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <Badge
                                            value={totalDocuments}
                                            sm
                                            style={{
                                                position: 'absolute',
                                                top: -8,
                                                left: -6,
                                                backgroundColor: 'red'
                                            }}
                                        />
                                    </View>
                                </Pressable>
                            }


                            <View>
                                <TText sm>#type_description</TText>
                                <View style={{ marginTop: 8, flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {
                                        Object.keys(DamageTypes).map((type, index) => {
                                            return (
                                                <Button
                                                    key={'t' + index}
                                                    label={'#damage_type_' + DamageTypes[type]}
                                                    onPress={() => setDamageType(DamageTypes[type])}
                                                    type={'primary'}
                                                    light={DamageTypes[type] == damageType}
                                                    style={{ paddingRight: 8, marginBottom: 8 }}
                                                />
                                            )
                                        })
                                    }
                                </View>
                            </View>

                            <View>
                                <TText sm>#cost_description</TText>
                                <CustomTextInput
                                    style={{ marginTop: windowWidth <= 768 ? -8 : 8 }}
                                    value={cost}
                                    onChangeText={(text) => (text.search(/[^0-9]/) < 0) && setCost(text)}
                                    keyboardType={'numeric'}
                                    onBlur={() => { setButtonTop(false) }}
                                    onFocus={() => { if (width < 997) setButtonTop(true) }}
                                />
                            </View>

                            <View>
                                <TText sm style={{ marginTop: 8 }}>#damage_date</TText>
                                <View style={{ flexDirection: 'row', marginTop: 8 }}>
                                    <Button
                                        label={damageDate.format(DateTimeFormat.DISPLAY_DATE)}
                                        onPress={() => setShowDatePicker(true)}
                                        light
                                    />
                                </View>
                            </View>

                            <View>
                                <TText sm style={{ marginTop: 8 }}>#reason_description</TText>
                                <TextInput
                                    multiline={true}
                                    autoCorrect={false}
                                    style={[scopedStyles.noteText, styles.text, , styles.textBlack, styles.textRegular, styles.textSM]}
                                    value={body}
                                    onChangeText={(text) => setBody(text)}
                                    onBlur={() => { setButtonTop(false) }}
                                    onFocus={() => { if (width < 997) setButtonTop(true) }}
                                />
                            </View>


                        </View>

                        <View style={{ minHeight: 100, flexDirection: 'row', alignItems: 'center' }}>

                            <View style={{ flexDirection: "row" }}>
                                <Button
                                    loading={loading}
                                    label={'#action_damage'}
                                    type={"secondary"}
                                    onPress={send}
                                />
                            </View>
                            {inputError != '' &&
                                <View style={{ marginLeft: 12, flex: 1 }}>
                                    <TText sm error>{inputError}</TText>
                                </View>
                            }

                        </View>



                    </View>

                )}

            </View >
        )
    }






    const renderFileUpload = () => {
        return (
            <>
                {(windowWidth < 768 && response == null && showFileUpload) &&
                    <Pressable
                        onPress={() => setShowFileUpload(s => !s)}
                        style={{
                            position: 'absolute',
                            top: 80,
                            right: 0,
                            flexDirection: 'row',
                            alignItems: 'center',
                            zIndex: 99
                        }}
                    >
                        <View style={{
                            height: 50,
                            width: 50,
                            backgroundColor: colors.background,
                            borderTopLeftRadius: 25,
                            borderBottomLeftRadius: 25,
                            borderWidth: 0,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Badge
                                value={totalDocuments}
                                sm
                                style={{
                                    position: 'absolute',
                                    top: -8,
                                    left: -6,
                                    backgroundColor: 'red'
                                }}
                            />
                        </View>
                    </Pressable>
                }


                <View style={[
                    scopedStyles.fileContainer,
                    { backgroundColor: colors.primary2 },
                    (windowWidth < 768 && showFileUpload) && { width: '100%', borderBottomLeftRadius: 8, justifyContent: 'space-between' }
                ]}>

                    {(response == null && (windowWidth > 768 || showFileUpload)) &&
                        <>
                            <TText md regular white style={{
                                marginTop: 30,
                                marginBottom: 28,
                                marginHorizontal: (windowWidth > 768) ? 12 : 30
                            }}>
                                #title_add_images
                            </TText>

                            <ScrollView
                                style={{
                                    marginBottom: (windowWidth > 768) ? 120 : 20,
                                    marginHorizontal: (windowWidth > 768) ? 12 : 30,
                                    backgroundColor: colors.blackTransparent,
                                    borderRadius: 8,
                                    flex: 1
                                }}
                                showsHorizontalScrollIndicator={false}
                                contentContainerStyle={{
                                    padding: 12,
                                    paddingBottom: 0
                                }}
                            >
                                {Object.keys(DamageCategoryNames).map(item => {
                                    let key = DamageCategoryNames[item];
                                    return (
                                        <View key={item} style={{
                                            backgroundColor: colors.whiteTransparent,
                                            marginBottom: 12,
                                            padding: 12,
                                            borderRadius: 8,
                                            justifyContent: 'space-between'
                                        }}>

                                            <TText sm semibold white>{'#document_' + key.toLowerCase()}</TText>

                                            {damageDocuments[key].map((document, index) => {
                                                return (
                                                    <View key={document.name} style={scopedStyles.documentItem}>
                                                        <TText oneline sm white regular key={index}>{document.name}</TText>
                                                        <Pressable
                                                            onPress={() => removeDocument(key, document)}
                                                        >
                                                        </Pressable>
                                                    </View>
                                                )
                                            })}

                                            <Button
                                                label='#action_add'
                                                light
                                                onPress={() => addDocument(key)}                                                
                                                style={{ marginTop: 8 }}
                                            />

                                        </View>
                                    )
                                })}

                            </ScrollView>

                            {(windowWidth < 768 && showFileUpload) &&
                                <View style={{
                                    height: 100,
                                    width: '100%',
                                    paddingHorizontal: 30,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    backgroundColor: colors.formDecoration,
                                    borderBottomLeftRadius: 8,
                                    borderBottomRightRadius: 8,
                                }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Button
                                            label='ga terug'
                                            type='action'
                                            onPress={() => setShowFileUpload(s => !s)}
                                        />
                                    </View>
                                </View>
                            }
                        </>
                    }

                </View >
            </>
        )
    }





    return (
        <BaseModal hideCloseButton={showFileUpload} style={scopedStyles.modal}>
            <View
                style={[[
                    {
                        flex: 1,
                        flexDirection: width < 768 ? 'column-reverse' : 'row',
                        justifyContent: "space-between"
                    },
                    Platform.OS === 'web' && { cursor: 'default' }
                ]]}
            >
                <View style={scopedStyles.sliderContainer}>

                    {renderForm()}

                    {renderFileUpload()}

                </View>

                {showDatePicker &&
                    <DatePickerModal
                        handleDateSelect={(e) => { setDamageDate(e) }}
                        hideModal={() => setShowDatePicker(false)}
                        initialDate={damageDate}
                        preventFutureDate
                    />
                }

                {iosupload &&
                    <IosUploadSelectModal
                        close={() => setIosUpload(false)}
                        onPick={(type: string) => {
                            addDocument(iosupload, type);
                        }}
                    />
                }
            </View>
        </BaseModal>

    )

}



const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            width: '60%',
            minWidth: 700,
            minHeight: 700,
            flex: 0,
            marginVertical: 150,
            backgroundColor: colors.background,
            borderRadius: 8,
            overflow: 'visible'
        },
        sliderContainer: {
            flexDirection: 'row',
            flex: 1,
            height: '100%',
            overflow: 'hidden',
            flexWrap: 'nowrap'
        },
        container: {
            flex: 1,
            height: '100%',
            paddingTop: 20,
            paddingHorizontal: 30,
            justifyContent: 'space-between'
        },
        fileContainer: {
            height: '100%',
            width: 300,
            paddingTop: 20,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            overflow: 'hidden'
        },
        title: {
            marginTop: 30,
            marginBottom: 20
        },
        noteText: {
            verticalAlign: "top",
            marginTop: 8,
            padding: 8,
            minHeight: 180,
            borderColor: colors.border,
            borderWidth: 1,
            borderRadius: 4
        },
        documentItem: {
            marginTop: 12,
            padding: 8,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: colors.whiteTransparent,
            borderRadius: 4
        }
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                minWidth: 'auto',
                width: '90%',
                flex: 1,
                marginLeft: 0,
                marginVertical: '20%',
            },
            container: {
                flex: 1,
                width: '100%'
            },
            fileContainer: {
                width: 0
            }
        }
    }
)

