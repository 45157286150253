import { Icons } from "icons";
import { Pressable } from "react-native"
import { colors } from "styles";
import TText from "./TText";
import Icon from "./Icon";
import { useStyle } from "hooks";


export default ({ onPress, style }: { onPress: (e: any) => void, style?: any }) => {

    const styles = useStyle();

    return (
        <Pressable
            style={[
                styles.flexRow, 
                styles.alignCenter, 
                styles.p10, 
                styles.gap10, 
                styles.borderRadius5, 
                {
                    borderWidth: 1, 
                    borderColor: colors.border,
                    alignSelf: 'flex-start'
                }, 
                style
            ]}
            onPress={onPress}
        >
            <TText sm semibold>Sluit venster</TText>
            <Icon iconName="IconClose" iconColor={colors.primary}/>
        </Pressable>
    )
}