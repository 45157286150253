import { Icons } from "icons";
import {Button, Icon, TText} from "components";
import { DocumentCategories, QueryKeys } from 'enums';
import { useTranslation } from "hooks";
import { Document } from "models";
import { ModalContext } from "providers/ModalProvider";
import { StoreContext } from "providers/StoreProvider";
import { useContext, useEffect, useState } from "react";
import { ActivityIndicator, Platform, Pressable, View, useWindowDimensions } from "react-native";
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { DocumentUtils, QueryUtils } from "utils";
import BaseModal from "./BaseModal";
import DocumentModal from "./DocumentModal";
import IosUploadSelectModal from "./IosUploadSelectModal";

enum uploadStates {
    pickDocument = 'pickDocument',
    confirmDocument = 'confirmDocument',
    uploadDocument = 'uploadDocument',
    none = 'none'
}

export default ({ settings }) => {

    //styles
    const { width } = useWindowDimensions();
    const { hideModal } = useContext(ModalContext);
    const scopedStyles = scopedResponsiveStyles();
    const { triggerLoading } = useContext(StoreContext);
    const translate = useTranslation();


    //collect shared documents
    const { data: documents, refetch: refetchDocuments } = useQuery(QueryKeys.DOCUMENTS, QueryUtils.fetchDocuments);
    const [sharedDocuments, setSharedDocuments] = useState([]);
    const [showDocument, setShowDocument] = useState(undefined);
    const [removing, setRemoving] = useState(null);
    useEffect(() => {
        if (documents[DocumentCategories.KLANTEN]) {
            setSharedDocuments(documents[DocumentCategories.KLANTEN].filter(document => uploadedDocumentIds.includes(document.id)));
        }
    }, [documents]);

    //uploading files
    const [uploadState, setUploadState] = useState(uploadStates.none);
    const [iosupload, setIosUpload] = useState(false);

    const [hasUploaded, setHasUploaded] = useState(null);
    const [uploadedDocumentIds, setUploadedDocumentIds] = useState<any[]>([]);

    const upload = async (document = undefined, iosMethod = undefined, reupload = undefined) => {

        const state = reupload ? uploadStates.none : uploadState;

        switch (state) {

            case uploadStates.uploadDocument:

                return;

            case uploadStates.none:

                if (Platform.OS === 'ios' && !iosMethod) {
                    setIosUpload(document);
                    return;
                }

                const pickMethod = Platform.select({
                    ios: async () => {
                        if (iosMethod == 'image') {
                            return await DocumentUtils.pickImage();
                        } else {
                            return await DocumentUtils.pickDocument();
                        }
                    },
                    default: async () => {
                        return await DocumentUtils.pickDocument();
                    }
                })

                const pickedDocument = await pickMethod();
                if (!pickedDocument) return;

                setIosUpload(false);
                setUploadState(uploadStates.uploadDocument);

                DocumentUtils.uploadDocument(pickedDocument, pickedDocument.name, {})
                    .then(async ({ data }) => {

                        if (data?.success) {
                            setHasUploaded({ success: true });
                            setUploadedDocumentIds([
                                ...uploadedDocumentIds,
                                data.id
                            ]);
                            await new Promise(resolve => {
                                setTimeout(async () => {
                                    await refetchDocuments();
                                    resolve(true);
                                }, 1500)
                            });
                            triggerLoading();
                        } else {
                            setHasUploaded({ success: false });
                        }

                        setUploadState(uploadStates.none);

                    });

                break;

        }

    }

    const removeDocument = async (document: Document) => {
        setRemoving(document.id);
        const response = await DocumentUtils.deleteDocument(document);
        if (response.success == true) {
            await refetchDocuments();
            setSharedDocuments(sharedDocuments.filter(doc => doc !== document));
        }
        setRemoving(null);
    }


    const renderItem = (document) => {

        if (uploadState == uploadStates.uploadDocument) {

            return (
                <View style={scopedStyles.uploadIcon}>
                    <ActivityIndicator
                        color={colors.secondary}
                        size={32}
                    />
                </View>
            );

        } else if (hasUploaded) {

            const uploadedSuccesfull = hasUploaded.success;

            return (
                <View style={scopedStyles.uploadIcon}>
                    <View style={{
                        backgroundColor: uploadedSuccesfull ? colors.secondary9 : colors.error,
                        width: 50,
                        height: 50,
                        borderRadius: 25,
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        {uploadedSuccesfull
                            ? <Icon iconName='IconCheck' iconSize={25} iconColor={colors.white} />
                            : <Icon iconName='IconDelete' iconSize={25} iconColor={colors.white} />

                    }
                    </View>

                    <Pressable disabled={uploadState == uploadStates.uploadDocument} onPress={() => upload(document)} >
                        <TText xs regular black center style={{ marginTop: 2, textDecorationLine: 'underline' }} >#uploads_retry</TText>
                    </Pressable>
                </View>
            );

        } else {

            return (
                <Pressable
                    disabled={uploadState == uploadStates.uploadDocument}
                    style={scopedStyles.uploadIcon}
                    onPress={() => upload(document)}
                >
                    <Icon iconName='IconFileUpload' iconSize={120} iconColor={colors.secondary} />
                </Pressable>
            )

        }

    }

    const renderSharedItems = () => {
        return sharedDocuments.map((document: Document, index) => {
            return (
                <View
                    key={index + document.id}
                    style={scopedStyles.sharedDocumentButtonContainer}
                >
                    <TText sm semibold>{document.name}</TText>
                    <View style={scopedStyles.sharedDocumentButtons}>
                        {document.deletable ? (
                            <Button
                                loading={removing === document.id}
                                label={'#action_remove'}
                                type='error'
                                onPress={() => removeDocument(document)}
                                icon={(color) => {/* <Icons.Delete_trash size={16} color={color} /> */}}
                                style={{ marginRight: 8 }}
                            />
                        ) : (
                            <View></View>
                        )}
                        <Button
                            label={'#action_view'}
                            type='primary'
                            onPress={() => {
                                setShowDocument(document);
                            }}
                            icon={(color) => {<Icon iconName={'IconFile'} iconSize={16} iconColor={color} />}}
                        />
                    </View>

                </View>
            )
        })
    }


    return (
        <BaseModal style={scopedStyles.modal}>

            <TText lg regular style={{ margin: '1%', marginTop: 20 }}>#title_upload</TText>

            <TText sm regular black style={{ marginLeft: '1%' }}>#uploads_description</TText>

            <View style={{ marginTop: 20 }}>

                <View style={scopedStyles.uploadContainer}>
                    <View
                        style={[
                            scopedStyles.uploadItem,
                            { width: '100%', borderRadius: 10 }
                        ]}>

                        <TText sm semibold style={{ marginVertical: 5, marginLeft: 10 }}>#uploads_other</TText>

                        <View
                            style={{ flex: 0.9, borderRadius: 10, marginHorizontal: 10, backgroundColor: colors.white }}
                        >
                            {renderItem({
                                id: '',
                                name: translate('#uploads_other')
                            })}
                        </View>
                    </View>
                </View>
            </View>


            {sharedDocuments.length > 0 &&
                <View style={{ marginTop: 20 }}>
                    <TText md semibold style={{ marginLeft: '1%' }}>#shared_documents</TText>
                    <TText sm regular black style={{ marginLeft: '1%', marginBottom: 12 }}>#removing_document</TText>
                    <View style={scopedStyles.sharedContainer}>
                        {renderSharedItems()}
                    </View>
                </View>
            }

            <View style={{ flex: 1 }}></View>

            <View style={{ height: 100, justifyContent: 'center', alignItems: "flex-end" }}>

                <View style={{ flexDirection: "row" }}>
                    <Button
                        label='#close'
                        type={"secondary"}
                        onPress={() => hideModal()}
                    />
                </View>

            </View>

            {showDocument &&
                <DocumentModal settings={{ ...showDocument, hide: () => { setShowDocument(undefined) } }} />
            }

            {iosupload &&
                <IosUploadSelectModal
                    close={() => setIosUpload(false)}
                    onPick={(type: string) => {
                        upload(iosupload, type);
                    }}
                />
            }
        </BaseModal>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            width: '35%',
            minHeight: 'auto',
            paddingTop: 20,
            paddingHorizontal: 30,
            backgroundColor: 'white'
        },
        uploadContainer: {
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: "space-between"
        },
        sharedContainer: {
            marginLeft: '1%',
            marginBottom: 20,
        },
        category: {
            flexDirection: 'row',
            marginBottom: 12
        },
        uploadItem: {
            height: 250,
            margin: '1%',
            width: '50',
            backgroundColor: colors.lightgrey
        },
        uploadMessage: {
            width: '90%',
            height: '30%',
            borderRadius: 8,
            justifyContent: 'center',
            alignItems: 'center'
        },
        uploadIcon: {
            position: "absolute",
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
        },
        sharedDocumentButtonContainer: {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: colors.white,
            borderRadius: 8,
            marginBottom: 20,
            paddingHorizontal: 8,
            paddingVertical: 8
        },
        sharedDocumentButtons: {
            flexDirection: 'row',
            alignItems: "center"
        },
    },
    {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            modal: {
                width: '65%'
            }
        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
            },
            container: {
                flex: 1
            },
            uploadItem: {
                marginBottom: 12,
                marginHorizontal: 0
            },
            uploadIcon: {
                position: "relative",
                margin: 0
            },
            category: {
                flexDirection: 'column',
                marginBottom: 12
            },
            sharedDocumentButtonContainer: {
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start"
            },
            sharedDocumentButtons: {
                marginTop: 8,
                width: '100%',
                justifyContent: 'space-between'
            },
        }
    }
)