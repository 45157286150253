import { useNavigation } from "@react-navigation/native";
import Button from "components/Button";
import { TText } from "components";
import { QueryKeys } from 'enums';
import { useAssets } from "expo-asset";
import * as ImageManipulator from 'expo-image-manipulator';
import { LinearGradient } from "expo-linear-gradient";
import { useStyle, useTranslation } from "hooks";
import { useEffect, useRef, useState } from "react";
import { Image, Platform, ScrollView, View } from "react-native";
import { Circle, Defs, Mask, Rect, Svg } from 'react-native-svg';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CreateResponsiveStyle } from "rn-responsive-styles";
import { colors } from "styles";
import { DocumentUtils, QueryUtils } from "utils";



const defaultImage = require('assets/images/avatar.png');

const SvgMask = ({ size }) => (
    <View style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}>
        <Svg height="100%" width="100%" viewBox={`0 0 ${size} ${size}`}>
            <Defs>
                <Mask id="mask" x="0" y="0" height={size} width={size}>
                    <Rect height="100%" width="100%" fill="#fff" />
                    <Circle r="175" cx="50%" cy="50%" fill='#000' />
                </Mask>
            </Defs>
            <Rect height="100%" width="100%" fill="rgba(0, 0, 0, 0.4)" mask="url(#mask)" />
        </Svg>
    </View>
);


export default ({ settings }) => {

    //data
    const CROP_SIZE = 350;
    const navigation = useNavigation();
    const translate = useTranslation();
    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);

    //reactquery updating avatar
    const queryClient = useQueryClient();
    const { mutate: sendAvatar } = useMutation(async (base64: string) => {

        setUploading(true);
        const {data: response} = await DocumentUtils.uploadAvatar(base64);        

        if (!response.success) {

            setErrorText(translate('#uploads_error'));

        } else {

            navigation.navigate('overzicht');

        }
        setUploading(false);

        return QueryUtils.fetchCustomer();

    }, {
        onSuccess: data => {
            queryClient.setQueryData(QueryKeys.CUSTOMER, data);
        }
    });


    //state
    const [userImage, setUserImage] = useState();
    const [uploading, setUploading] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const mousePressed = useRef(false);
    const imgSize = useRef({ width: 0, height: 0 });
    const scale = useRef(1);
    const offset = useRef({ x: 0, y: 0 });
    const touchStartPos = useRef(null);
    const touchDistance = useRef(null);

    //state not accesible from eventlisteners. UseRef is accessible, but does not rerender page.
    const [_, setForceUpdate] = useState(false);
    const rerender = () => setForceUpdate(f => !f);

    //initialize image
    const [assets] = useAssets(defaultImage);
    useEffect(() => {
        if (assets) setUserImage(customer?.avatar ? `data:image/png;base64,${customer?.avatar}` : assets[0].uri);
    }, [assets]);

    //process new image
    useEffect(() => {
        if (!userImage) return;

        Image.getSize(userImage, (width, height) => {

            //set size
            imgSize.current = { width, height };

            //set minimum scale
            const minScale = Math.max(CROP_SIZE / width, CROP_SIZE / height);
            scale.current = minScale;

            //set offset
            const mx = (imgSize.current.width * scale.current) - CROP_SIZE;
            const my = (imgSize.current.height * scale.current) - CROP_SIZE;
            offset.current = { x: mx * -0.5, y: my * -0.5 };
            rerender();

        });
    }, [userImage]);









    //web events
    useEffect(() => {
        if (Platform.OS != 'web') return;

        window.addEventListener('wheel', scaleImg);
        window.addEventListener('mousedown', mousePress);
        window.addEventListener('mouseup', mouseRelease);
        window.addEventListener('mousemove', moveImg);

        window.addEventListener('touchstart', touchStart);
        window.addEventListener('touchmove', touchMove);
        window.addEventListener('touchend', touchEnd);

        return () => {

            window.removeEventListener('mousemove', moveImg);
            window.removeEventListener('mousedown', mousePress);
            window.removeEventListener('mouseup', mouseRelease);
            window.removeEventListener('wheel', scaleImg);

            window.removeEventListener('touchstart', touchStart);
            window.removeEventListener('touchmove', touchMove);
            window.removeEventListener('touchend', touchEnd);

        }
    }, [])

    //event handlers
    const mousePress = () => {
        mousePressed.current = true;
    }
    const mouseRelease = () => {
        mousePressed.current = false;
    }
    const touchStart = (e) => {
        if (e.nativeEvent) e = e.nativeEvent;
        const pageX = e.touches[0].pageX;
        const pageY = e.touches[0].pageY;


        if (!touchStartPos.current) {

            //safe first touch position
            touchStartPos.current = {
                x: e.locationX || pageX,
                y: e.locationY || pageY,
            }

        } else if (e.touches.length == 2) {

            const touch = e.touches;
            let dx = (touch[0].locationX) ? touch[0].locationX - touch[1].locationX : touch[0].pageX - touch[1].pageX;
            let dy = (touch[0].locationY) ? touch[0].locationY - touch[1].locationY : touch[0].pageX - touch[1].pageX;
            touchDistance.current = Math.sqrt(dx * dx + dy * dy);
            touchStartPos.current = null;

        } else {

            touchDistance.current = null;
            touchStartPos.current = null;

        }

    }
    const touchMove = (e) => {
        if (e.nativeEvent) e = e.nativeEvent;
        const touch = e.touches;
        const pageX = touch[0].pageX;
        const pageY = touch[0].pageY;

        if (touchDistance.current && touch.length > 1) {

            let dx = (touch[0].locationX) ? touch[0].locationX - touch[1].locationX : touch[0].pageX - touch[1].pageX;
            let dy = (touch[0].locationY) ? touch[0].locationY - touch[1].locationY : touch[0].pageX - touch[1].pageX;
            const currentDistance = Math.sqrt(dx * dx + dy * dy);
            scaleImg({ deltaY: (currentDistance - touchDistance.current) * 1.2 })
            touchDistance.current = currentDistance;

        } else if (touchStartPos.current) {

            const currentPos = {
                x: e.locationX || pageX,
                y: e.locationY || pageY,
            };
            moveImg({
                movementX: currentPos.x - touchStartPos.current.x,
                movementY: currentPos.y - touchStartPos.current.y
            });
            touchStartPos.current = currentPos;

        }
    }
    const touchEnd = (e) => {
        if (e.nativeEvent) e = e.nativeEvent;
        if (e.touches.length == 0) {
            touchDistance.current = null;
            touchStartPos.current = null;
        }
    }








    //image fgunctions
    const scaleImg = (e) => {
        const dir = Math.sign(e.deltaY) * 0.025;
        const nScale = scale.current + dir;

        const underSize = imgSize.current.width * nScale < CROP_SIZE || imgSize.current.height * nScale < CROP_SIZE;
        if (dir < 0 && underSize) return;

        const nOffset = {
            x: offset.current.x - (imgSize.current.width * dir * 0.5),
            y: offset.current.y - (imgSize.current.height * dir * 0.5)
        }

        scale.current = nScale;
        offset.current = clampOffset(nOffset);
        rerender();
    }

    const moveImg = (e) => {
        if (mousePressed.current || touchStartPos.current) {
            const nOffset = {
                x: offset.current.x + e.movementX,
                y: offset.current.y + e.movementY
            };
            offset.current = clampOffset(nOffset);
            rerender();
        }
    }


    //actions: crop&Send, openImg en clampOffset
    const cropAndSendImg = async () => {

        // prevent crop outside image borders
        const originX = Math.max(0, (offset.current.x * -1) / scale.current);
        const originY = Math.max(0, (offset.current.y * -1) / scale.current);
        const cropWidth = Math.min(imgSize.current.width - originX, CROP_SIZE / scale.current);
        const cropHeight = Math.min(imgSize.current.height - originY, CROP_SIZE / scale.current);

        const croppedImage = await ImageManipulator.manipulateAsync(
            userImage,
            [
                {
                    crop: {
                        originX,
                        originY,
                        width: cropWidth,
                        height: cropHeight
                    }
                },
                {
                    resize: {
                        width: CROP_SIZE,
                        height: CROP_SIZE
                    }
                }
            ],
            {
                base64: true,
                format: ImageManipulator.SaveFormat.PNG
            }
        )

        sendAvatar(croppedImage.base64);

    }

    const openImg = async () => {
        const newImage = await DocumentUtils.pickImage();

        if (!newImage || !newImage.uri) {
            setErrorText(translate('#uploads_error'));
        } else {
            setUserImage(newImage.uri);
        }
    }

    const clampOffset = (offset) => {
        //clamp image offset, the edge of the image will not be visible
        const mx = (imgSize.current.width * -scale.current) + CROP_SIZE;
        const my = (imgSize.current.height * -scale.current) + CROP_SIZE;
        offset.x = Math.min(0, Math.max(mx, offset.x));
        offset.y = Math.min(0, Math.max(my, offset.y));
        return offset;
    }

    //image renderer
    const renderImage = () => {

        if (!userImage) return;

        return (
            <Image
                source={{ uri: userImage }}
                style={{
                    width: imgSize.current.width * scale.current,
                    height: imgSize.current.height * scale.current,
                    marginLeft: offset.current.x,
                    marginTop: offset.current.y
                }}
            />
        )
    }



    const [preventScroll, setPreventScroll] = useState(false);


    return (
        <ScrollView
            scrollEnabled={!preventScroll}
            style={{ width: '100%', flex: 1 }}
            contentContainerStyle={{ flexGrow: 1 }}
            onTouchStart={Platform.OS != 'web' ? touchStart : undefined}
            onTouchMove={Platform.OS != 'web' ? touchMove : undefined}
            onTouchEnd={Platform.OS != 'web' ? touchEnd : undefined}
        >
            <View style={[styles.fullscreen]}>

                <View style={[scopedStyles.topContainer]}>
                    <TText xl regular>#title_avatar</TText>
                </View>

                <LinearGradient
                    colors={[colors.gradientFrom, colors.gradientTo]}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1 }}
                    style={scopedStyles.gradientContainer}
                >


                    <View style={scopedStyles.cropContainer}>

                        <View
                            style={[scopedStyles.imgCrop, { width: CROP_SIZE, height: CROP_SIZE }]}
                            onTouchStart={() => setPreventScroll(true)}
                            onTouchEnd={(e: any) => { if (e.nativeEvent.touches.length == 0) setPreventScroll(false) }}
                        >
                            {renderImage()}
                            <SvgMask size={CROP_SIZE} />
                        </View>

                        <View style={{ height: 'auto', marginVertical: 30, width: CROP_SIZE, borderRadius: 4, backgroundColor: colors.background }}>
                            <TText sm error center style={{ marginTop: 20 }}>{errorText ? errorText : ''}</TText>
                            <View>
                                <Button
                                    label="#pick_image"
                                    onPress={openImg}
                                    style={{ marginTop: 20 }}
                                />
                                <Button
                                    loading={uploading}
                                    label="#save"
                                    onPress={cropAndSendImg}
                                    style={{ marginVertical: 20 }}
                                />
                            </View>
                        </View>

                    </View>

                </LinearGradient>

            </View>
        </ScrollView>
    )
}




const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        container: {
            width: '100%',
            flex: 1,
            alignItems: 'center',
            justifyContent: "center"
        },
        topContainer: {
            height: 100,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: "center",
            backgroundColor: colors.background
        },
        gradientContainer: {
            width: '100%',
            flex: 1,
        },
        cropContainer: {
            paddingTop: 50,
            paddingBottom: 125,
            flex: 1,
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
        imgCrop: {
            backgroundColor: colors.background,
            overflow: "hidden",
            borderRadius: 4
        }
    },
    {
    }
)