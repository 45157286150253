import AsyncStorage from "@react-native-async-storage/async-storage";

const getStorageItem = async (key: string) => {
    return key ? await AsyncStorage.getItem(key) : undefined;
}

const setStorageItem = async (key: string, value: string) => {
    return key ? await AsyncStorage.setItem(key, value) : undefined;
}

const removeStorageItem = async (key: string) => {
    return await AsyncStorage.removeItem(key);
}

const setSessionStorageItem = (key: string, value: string) => {

    if (!window.sessionStorage) return;
    window.onunload = () => { sessionStorage.clear() };
    sessionStorage.setItem(key, value);

}

const getSessionStorageItem = (key: string): string | null => {

    if (!window.sessionStorage || !key) return null;
    return sessionStorage.getItem(key);

}

export default {
    getStorageItem,
    setStorageItem,
    removeStorageItem,
    setSessionStorageItem,
    getSessionStorageItem,
}
