import { Icon, TText } from "components";
import { Card } from "components/Card";
import { BaseDrawerModal } from "components/Modals";
import BaseModal from "components/Modals/BaseModal";
import { DateTimeFormat } from "enums";
import { useStyle } from "hooks";
import moment from "moment";
import { useState } from "react";
import { Pressable, Text, View } from "react-native";
import { colors } from "styles";
import { ValutaUtils } from "utils";


export default ({visible, onClose}) => {

    const styles = useStyle();
    // const [showModal, setShowModal] = useState(true);

    return (
        <BaseDrawerModal
            drawerWidth={1000} 
            header={'Uitgangspunten berekening'}
            visible={visible}
            onClose={onClose}
            drawerStyle={[styles.gap20]}
        >
            <TText>
                Deze Oversluiten rekentool geeft je een beeld van de situatie na oversluiten naar Philips Pensioenfonds.
                Op deze plek leggen we uit welke uitgangspunten we kiezen bij het maken van de berekeningen.
            </TText>
            <TText>
                In de berekeningen is er geen rekening gehouden met de hoogte van heffingskortingen en (eventuele) toeslagen; door het aanpassen van de hypotheekrente kan de hoogte hiervan wijzigen.
                De berekeningen zijn indicatief, er kunnen geen rechten aan worden ontleend.
                Ze zijn berekend op basis van de door jou ingevulde gegevens, die aan te passen zijn via Mijn Gegevens.
            </TText>
            <TText>
                Neem voor meer informatie contact op met Eigen Huis Hypotheekadvies via het formulier onderaan de pagina.
            </TText>

            <View style={{gap: 5}}>
                {[
                    "We berekenen de vergoeding op basis van de richtlijnen van de AFM. Mocht je huidige geldverstrekker hiervan afwijken, dan kan de vergoeding ook afwijken.",
                    "De eenmalige oversluitkosten (notariskosten, advieskosten, taxatiekosten) zijn indicatief: de werkelijke kosten kunnen afwijken.",
                    "We gaan uit van een boetevrij aflospercentage van 10 procent per jaar. Sommige geldverstrekkers hanteren een hoger percentage. In dat geval valt je vergoeding lager uit.",
                    "We gaan er bij het berekenen van de vergoeding vanuit dat je dit jaar nog niet eerder extra afgelost hebt.",
                    "We nemen bij de berekeningen aan dat je huidige rente na het aflopen van je rentevastperiode gelijk blijft.",
                    "De eenmalige oversluitkosten kun je met eigen geld betalen, of deze meefinancieren in je nieuwe hypotheek (in een nieuw annuïtair deel).",
                    "We rekenen met een risicoklasse gebaseerd op jouw huidige schuld-marktwaardeverhouding. We gaan er dus vanuit dat je geldverstrekker automatisch de risicoklasse aanpast. Als je een geldverstrekker hebt die dit niet doet, is de boete in werkelijkheid mogelijk lager dan we hier tonen.",
                    "De totale besparing in de nieuwe rentevastperiode is gebaseerd op het verschil in bruto maandlasten.",
                    "We gaan ervan uit dat je, indien je meerdere leningdelen hebt, dezelfde rentevastperiode kiest voor al je leningdelen. Mocht je verschillende rentevastperiodes willen kiezen, dan kun je dit bespreken met je adviseur.",
                    "Let op: het kan zijn dat Philips Pensioenfonds eisen stelt aan de samenstelling en betaalbaarheid van je hypotheek. Bespreek met je adviseur of het inderdaad mogelijk is om over te sluiten.",
                    "De hoogte van de maximale hypotheek is vanaf 2024 mede afhankelijk van het energielabel van je woning. In de berekeningen is rekening gehouden met een toetsing op energielabel E, F, G. Als je woning een hoger energielabel heeft, is het mogelijk dat je maximale hypotheek hoger uitvalt dan het berekende bedrag."
                ].map(line => (
                    <View style={[styles.flexRow, styles.gap20]}>
                        <TText>•</TText>
                        <TText>{line}</TText>
                    </View>
                ))}                
            </View>            

        </BaseDrawerModal>
    );
}

