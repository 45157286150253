import {useHover, useStyle, useTranslation} from "hooks";
import {Button, DatePicker, Dropdown, Icon, RadioGroup, TextInput, TText, ValutaInput, PercentageInput} from "components";
import {Card, HeaderCard, IconCard} from "components/Card";
import {colors} from "styles";
import {EditDataUtils, QueryUtils, ValutaUtils} from "utils";
import {DateTimeFormat, QueryKeys, RepaymentTypeEnum} from "enums";
import {useQuery} from "react-query";
import {Pressable, View} from "react-native";
import {HDN_Company, HDN_Company_Label, Mortgage} from "models";
import {useEffect, useRef, useState} from "react";
import {array, number, object, string} from "yup";
import {useFormik} from "formik";
import moment from "moment";

export default () => {

    const styles = useStyle();
    const translate = useTranslation();
    const {data: mortgages, refetch: refetchMortgages} = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage, {refetchOnWindowFocus: false, staleTime: Infinity});
    const {data: agencies} = useQuery(QueryKeys.AGENCIES, QueryUtils.fetchAgencies, {refetchOnWindowFocus: false, staleTime: Infinity});

    const AVAILABLE_REPAYMENT_TYPES = [
        RepaymentTypeEnum.INTEREST_ONLY,
        RepaymentTypeEnum.LINEAR,
        RepaymentTypeEnum.ANNUITY,
        RepaymentTypeEnum.BANK_SAVINGS,
        RepaymentTypeEnum.LIFE_INSURANCE,
        RepaymentTypeEnum.INVESTMENT_MORTGAGE,
    ];

    const REPAYMENT_TYPES_WITH_CURRENT_VALUE = [
        RepaymentTypeEnum.BANK_SAVINGS,
        RepaymentTypeEnum.LIFE_INSURANCE,
        RepaymentTypeEnum.INVESTMENT_MORTGAGE
    ];

    const [repaymentOptions] = useState(Object.values(AVAILABLE_REPAYMENT_TYPES).map(repaymentType => ({
        label: translate(`#repayment_type_${repaymentType}`),
        value: repaymentType
    })));
    const [interestTypeOptions] = useState([
        {label: 'Vast', value: 'fixed'},
        {label: 'Variabel', value: 'variable'},
    ]);

    const response = useRef<undefined | string>(undefined);
    const [selectedMortgage, setSelectedMortgage] = useState<number | undefined>(undefined)
    const [selectedPart, setSelectedPart] = useState<number | undefined>(undefined)
    const [agencyOptions, setAgencyOptions] = useState([]);
    const [agencyLabelOptions, setAgencyLabelOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const valuesSet = useRef(false);

    const hoverBank = useHover(99);

    useEffect(() => {
        if (mortgages && !valuesSet.current) {
            // @ts-ignore
            setValues(mortgages.map(mortgage => {
                return {
                    id: mortgage.id,
                    hdn_company_code: mortgage.agency?.hdn_code,
                    hdn_company_label_id: mortgage.hdn_company_label_id,
                    mortgage_number: mortgage.hypotheeknummer,
                    parts: mortgage.mortgageParts.map(part => {
                        return {
                            id: part.id,
                            part_number: part.leningdeelnummer,
                            repayment_type: part.repayment_type,
                            start_date: part.begindatum,
                            end_date: part.einddatum,
                            principal_sum: part.hoofdsom,
                            remaining_debt: part.remaining_debt,
                            current_value: part.current_value,
                            interest: part.interest,
                            interest_type: part.interest_type,
                            fixed_rate_end_date: part.einddatumRentevast,
                            fixed_interest_duration: part.rentevasteperiode,
                            nhg: part.national_mortgage_guarantee
                        }
                    })
                }
            }));
            valuesSet.current = true;
        }

    }, [mortgages]);

    const mortgagePart = object({
        id: string(),
        part_number: string(),
        repayment_type: number(),
        start_date: string().required('Dit veld is verplicht'),
        end_date: string().required('Dit veld is verplicht'),
        principal_sum: number().required('Dit veld is verplicht'),
        current_value: number(),
        remaining_debt: number(),
        interest: number(),
        interest_type: string(),
        fixed_rate_end_date: string(),
        fixed_interest_duration: number(),
        nhg: number()
    })

    const changeMortgageSchema = object({
        id: string(),
        hdn_company_code: string(),
        hdn_company_label_id: string(),
        mortgage_number: string(),
        parts: array().of(mortgagePart)
    });
    const arraySchema = array().of(changeMortgageSchema);

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        setValues,
        setFieldValue,
        values,
        errors,
        touched
    } = useFormik({
        validationSchema: arraySchema,
        initialValues: [],
        onSubmit: values => {
            setLoading(true);
            response.current = undefined;
            EditDataUtils.uploadMortgage({
                mortgages: values.map((mortgage: Object) => ({
                    ...mortgage,
                    parts: mortgage.parts.map((part: {
                        start_date: string,
                        end_date: string,
                        fixed_rate_end_date: string,
                        remaining_debt: number,
                        part_number: number | string
                    }) => ({
                        ...part,
                        part_number: String(part.part_number),
                        remaining_debt: Math.floor(part.remaining_debt || 0),
                        start_date: part.start_date ? moment(part.start_date).format(DateTimeFormat.SERVER_DATE) : '',
                        end_date: part.end_date ? moment(part.end_date).format(DateTimeFormat.SERVER_DATE) : '',
                        fixed_rate_end_date: part.fixed_rate_end_date ? moment(part.fixed_rate_end_date).format(DateTimeFormat.SERVER_DATE) : '',
                    }))
                }))
            })
                .then(() => {
                    setLoading(false);
                    setSelectedPart(undefined);
                    response.current = {
                        successful: true,
                        label: 'Je gegevens zijn succesvol gewijzigd'
                    };
                    refetchMortgages();
                })
                .catch((error) => {
                    setLoading(false);
                    response.current = {
                        successful: false,
                        label: error.response.data.error.message.includes('Could not persist object of type \"App\\Models\\Customer\"') ?
                            'Opslaan is niet gelukt, je adviseur is bezig met jouw dossier' :
                            'Er ging iets mis bij het opslaan van je gegevens'
                    };
                });
        },
    });

    const hdn_code = values[selectedMortgage]?.hdn_company_code;
    const {data: hdn_labels} = useQuery([QueryKeys.AGENCYLABELS, hdn_code], () => QueryUtils.fetchAgencyLabel(hdn_code));
    useEffect(() => {
        if (agencies) setAgencyOptions(agencies.map((agency: HDN_Company) => agency.getOption()))
        if (hdn_labels) {
            setAgencyLabelOptions(hdn_labels.map((label: HDN_Company_Label) => label.getOption()))
        }
    }, [agencies, hdn_labels]);

    const [newMortgageAdded, setNewMortgageAdded] = useState(null);
    const addMortgage = () => {
        const newValues = [
            ...values,
            {
                id: '',
                hdn_company_code: '',
                hdn_company_label_id: '',
                mortgage_number: '',
                parts: [{
                    id: '',
                    part_number: '',
                    repayment_type: 1,
                    start_date: '',
                    end_date: '',
                    principal_sum: 0,
                    remaining_debt: 0,
                    interest: 0,
                    interest_type: '',
                    fixed_rate_end_date: '',
                    fixed_interest_duration: 0,
                    nhg: 0,
                    current_value: 0,
                }]
            }
        ];
        /* @ts-ignore */
        setValues(newValues);
        setSelectedMortgage(newValues.length - 1);
        setNewMortgageAdded(newValues.length - 1);
    }

    useEffect(() => {
        if (newMortgageAdded !== null) {
            setSelectedMortgage(newMortgageAdded);
            // setSelectedPart(0); // Assuming the first part is at index 0
            setNewMortgageAdded(null);
        }
    }, [values, newMortgageAdded]);

    const removeMortgage = (index: number) => {
        const newValues = [...values];
        newValues.splice(index, 1);
        setSelectedMortgage(undefined);
        setValues(newValues);
        handleSubmit();
    }

    const [newPartAdded, setNewPartAdded] = useState(null);
    const addPart = (index: number) => {
        const newMortgages = [...values];
        newMortgages[index].parts.push({
            id: '',
            part_number: '',
            repayment_type: 1,
            start_date: '',
            end_date: '',
            principal_sum: 0,
            remaining_debt: 0,
            interest: 0,
            interest_type: '',
            fixed_rate_end_date: '',
            fixed_interest_duration: 0,
            nhg: 0
        })
        setValues(newMortgages);
        setNewPartAdded({ mortgageIndex: index, partIndex: newMortgages[index].parts.length - 1 });

    }

    useEffect(() => {
        if (newPartAdded) {
            setSelectedMortgage(newPartAdded.mortgageIndex);
            setSelectedPart(newPartAdded.partIndex);
            setNewPartAdded(null); // Reset the flag
        }
    }, [values, newPartAdded]);

    const removePart = (index: number, partIndex: number) => {
        setFieldValue(`${index}.parts`, values[index].parts.filter((_, pIndex: number) => pIndex !== partIndex));
        setSelectedPart(undefined);
    }


    const renderMortgage = (valueMortgage: { parts: [], id: string }, index: number) => {

        const isMortgageSelected = selectedMortgage === index;
        const mortgage = mortgages?.find((mortgage: Mortgage) => mortgage.id === valueMortgage.id);

        return (
            <View style={styles.gap10}>
                <Pressable
                    onPress={() => {
                        setSelectedMortgage(isMortgageSelected ? undefined : index);
                        setSelectedPart(undefined);
                    }}
                    style={[
                        styles.flexRow,
                        styles.alignCenter,
                        styles.gap30,
                        styles.p20,
                        styles.borderRadius10,
                        {backgroundColor: isMortgageSelected ? colors.secondary : colors.secondary2}
                    ]}
                >
                    <Icon iconName="IconHome" iconSize={30} iconColor={isMortgageSelected ? colors.white : colors.primary}/>

                    {mortgage?.mainProperty.street &&
                        <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                            <TText md semibold white={isMortgageSelected}>{mortgage.mainProperty.street ? `${mortgage.mainProperty.street} ${mortgage.mainProperty.number}${mortgage.mainProperty.number_addition}` : ''}</TText>
                            <TText sm white={isMortgageSelected}>{mortgage.mainProperty.street ? `${mortgage.mainProperty.postal_code}, ${mortgage.mainProperty.city}` : ' '}</TText>
                        </View>
                    }

                    {mortgage?.hypotheeknummer ? (
                        <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                            <TText md semibold white={isMortgageSelected}>{mortgage.agency_name}</TText>
                            <TText sm white={isMortgageSelected}>{mortgage.hypotheeknummer}</TText>
                        </View>
                    ) : (
                        <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                            <TText md semibold white={isMortgageSelected}>Nieuwe hypotheek</TText>
                        </View>
                    )}
                </Pressable>

                {isMortgageSelected && renderMortgageForm(valueMortgage, index)}

            </View>
        );
    }


    const renderMortgageForm = (valueMortgage: { parts: [] }, index: number) => {
        return (
            <View style={styles.gap30}>
                <View style={styles.gap20}>
                    <TText secondary semibold>Geldverstrekker</TText>
                    <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                        <Dropdown
                            label={"Geldverstrekker"}
                            options={agencyOptions || []}
                            value={values[index].hdn_company_code}
                            onChange={(value) => setFieldValue(`${index}.hdn_company_code`, value)}
                            search
                        />
                        <Dropdown
                            label={"Product"}
                            options={agencyLabelOptions || []}
                            value={values[index].hdn_company_label_id}
                            onChange={(value) => setFieldValue(`${index}.hdn_company_label_id`, value)}
                            search
                            disable={agencyLabelOptions.length === 0}
                        />
                    </View>
                </View>
                <View style={styles.gap20}>
                    <TText secondary semibold>Hypotheek en leningdelen</TText>
                    {renderMortgageParts(valueMortgage, index)}
                </View>
                <View style={[styles.flexRow, styles.alignCenter, styles.justifyBetween, styles.gap20, styles.pt20]}>
                    <Button
                        label={'hypotheek verwijderen'}
                        type={'error'}
                        onPress={() => removeMortgage(index)}
                    />
                    <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                        <Button
                            label={'Toevoegen leningdeel'}
                            type={'secondary'}
                            onPress={() => addPart(index)}
                        />
                        <Button
                            loading={loading}
                            label={'wijzigingen opslaan'}
                            type={'secondary'}
                            onPress={handleSubmit}
                        />
                    </View>
                </View>
                {response.current !== undefined &&
                    <TText style={{color: response.current.successful ? colors.approval : colors.error}}>
                        {response.current.label}
                    </TText>
                }
            </View>
        );
    }

    const renderMortgageParts = (valueMortgage: { parts: [] }, index: number) => {
        return (
            <View>
                <View style={[styles.flexRow, styles.alignCenter, styles.p10, {
                    borderBottomColor: colors.border,
                    borderBottomWidth: 1
                }]}>
                    <TText semibold style={{width: '25%'}}>Leningdeel</TText>
                    <TText semibold style={{width: '25%'}}>Aflosvorm</TText>
                    <TText semibold style={{width: '25%'}}>Huidige hoogte leningdeel</TText>
                    <TText semibold style={{width: '25%'}}>Rente</TText>
                </View>
                <View>
                    {
                        valueMortgage.parts.map((_, partIndex) => {
                            const isPartSelected = selectedPart === partIndex;
                            const [hovered, hoverElt] = hoverBank[partIndex];
                            return (
                                <View style={[
                                    {
                                        paddingVertical: 10,
                                        gap: 10,
                                        borderRadius: 0,
                                        borderBottomColor: colors.grey,
                                        borderBottomWidth: 1,
                                    }
                                ]}>
                                    <Pressable
                                        ref={hoverElt}
                                        onPress={() => partIndex === selectedPart ? setSelectedPart(undefined) : setSelectedPart(partIndex)}
                                        style={[
                                            styles.flexRow,
                                            styles.alignCenter,
                                            styles.p10,
                                            styles.borderRadius10,
                                            hovered && {backgroundColor: colors.greyTransparent},
                                        ]}
                                    >
                                        <TText style={{width: '25%'}}>{values[index].parts[partIndex].part_number}</TText>
                                        <TText style={{width: '25%'}}>{'#repayment_type_' + values[index].parts[partIndex].repayment_type}</TText>
                                        <TText style={{width: '25%'}}>{ValutaUtils.valutaString(values[index].parts[partIndex].remaining_debt)}</TText>
                                        <TText style={{width: '25%'}}>{values[index].parts[partIndex].interest}%</TText>

                                        {(!isPartSelected && errors[index]?.parts && errors[index]?.parts[partIndex]) &&
                                            <IconCard
                                                iconName="IconExclamationCircle"
                                                iconColor={colors.white}
                                                iconSize={20}
                                                style={{
                                                    position: 'absolute',
                                                    right: 0,
                                                    width: 30,
                                                    height: 30,
                                                    backgroundColor: colors.error
                                                }}
                                            />
                                        }
                                    </Pressable>
                                    {isPartSelected && renderMortgagePartForm(index, partIndex)}
                                </View>
                            );
                        })
                    }
                </View>
            </View>
        );
    }

    const handleInterestTypeChange = (index, partIndex, value) => {
        setFieldValue(`${index}.parts.${partIndex}.interest_type`, value);
        if (value === 'fixed') {
            setFieldValue(`${index}.parts.${partIndex}.fixed_rate_end_date`, '');
            setFieldValue(`${index}.parts.${partIndex}.fixed_interest_duration`, 1);
        } else if (value === 'variable') {
            // Reset fields when switching back to 'fixed' interest type
            setFieldValue(`${index}.parts.${partIndex}.fixed_rate_end_date`, moment().add(1, 'month').format(DateTimeFormat.SERVER_DATE));
            setFieldValue(`${index}.parts.${partIndex}.fixed_interest_duration`, 0); // Example default value
        }
    };

    const toValidNumber = (val: string | number) => {
        // wanneer er een komma als decimal separator wordt gebruikt, formateer dan naar een float met . als decimal separator.
        if (typeof val === 'string' && val.includes(',')){
            val = val.replace(/\./g, '')
                .replace(',', '.');
        }
        return Number(val);
    }

    const renderMortgagePartForm = (index: number, partIndex: number) => {
        const touchedPart = touched?.[index]?.parts?.[partIndex];
        const errorPart = errors?.[index]?.parts?.[partIndex];

        return (
            <Card style={[styles.gap20, {backgroundColor: colors.background}]}>
                <Pressable
                    onPress={() => setSelectedPart(undefined)}
                    style={{
                        position: "absolute",
                        width: 20,
                        height: 20,
                        top: 10,
                        right: 10,
                        zIndex: 99,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Icon iconName="IconClose" iconColor={colors.primary} iconSize={20}/>
                </Pressable>
                <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                    <TextInput
                        label={"Leningdeelnummer"}
                        value={values[index].parts[partIndex].part_number}
                        onChangeText={(txt: string) => setFieldValue(`${index}.parts.${partIndex}.part_number`, txt)}
                        onBlur={handleBlur(`${index}.parts.${partIndex}.part_number`)}
                        error={(touched && touchedPart?.part_number && errors && errorPart?.part_number) ? errorPart?.part_number : undefined}
                        style={{backgroundColor: colors.white}}
                    />
                    <Dropdown
                        options={repaymentOptions}
                        label="Aflosvorm"
                        value={values[index].parts[partIndex].repayment_type}
                        onChange={(option) => setFieldValue(`${index}.parts.${partIndex}.repayment_type`, option)}
                        style={{backgroundColor: colors.white}}
                    />
                    <PercentageInput
                        label="Rentepercentage"
                        value={values[index].parts[partIndex].interest}
                        onChange={(number) => setFieldValue(`${index}.parts.${partIndex}.interest`, number)}
                        error={(touchedPart?.interest && errorPart?.interest) ? errorPart?.interest : undefined}
                        style={{backgroundColor: colors.white}}
                    />
                </View>
                <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                    <DatePicker
                        label="Ingangsdatum leningdeel"
                        defaultValue={values[index].parts[partIndex].start_date}
                        onChange={(date) => setFieldValue(`${index}.parts.${partIndex}.start_date`, date)}
                        error={(errorPart?.start_date) ? errorPart?.start_date : undefined}
                        style={{backgroundColor: colors.white}}
                    />
                    <DatePicker
                        label="Einddatum leningdeel"
                        defaultValue={values[index].parts[partIndex].end_date}
                        onChange={(date) => setFieldValue(`${index}.parts.${partIndex}.end_date`, date)}
                        error={(errorPart?.end_date) ? errorPart?.end_date : undefined}
                        style={{backgroundColor: colors.white}}
                    />
                </View>
                <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                    <ValutaInput
                        label="Oorspronkeijke hoogte leningdeel"
                        value={values[index].parts[partIndex].principal_sum}
                        onChange={(number) => setFieldValue(`${index}.parts.${partIndex}.principal_sum`, number)}
                        error={(touchedPart?.principal_sum && errorPart?.principal_sum) ? errorPart?.principal_sum : undefined}
                        style={{backgroundColor: colors.white}}
                    />
                    <ValutaInput
                        label="Huidige hoogte leningdeel"
                        value={values[index].parts[partIndex].remaining_debt}
                        onChange={(number) => setFieldValue(`${index}.parts.${partIndex}.remaining_debt`, number)}
                        error={(touchedPart?.remaining_debt && errorPart?.remaining_debt) ? errorPart?.remaining_debt : undefined}
                        style={{backgroundColor: colors.white}}
                    />
                </View>
                {REPAYMENT_TYPES_WITH_CURRENT_VALUE.includes(values[index].parts[partIndex].repayment_type) && (
                    <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                        <ValutaInput
                            label="Opgebouwde (spaar)waarde"
                            value={values[index].parts[partIndex].current_value}
                            onChange={(number) => setFieldValue(`${index}.parts.${partIndex}.current_value`, number)}
                            error={(touchedPart?.current_value && errorPart?.current_value) ? errorPart?.current_value : undefined}
                            style={{backgroundColor: colors.white}}
                        />
                    </View>
                )}
                <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                    {values[index].parts[partIndex].interest_type === 'variable' ? (
                            <TextInput
                                disabled
                                label="Einddatum rentevaste periode"
                                value={moment(values[index].parts[partIndex].fixed_rate_end_date).format(DateTimeFormat.DATE)}
                            />
                    ) : (
                        <DatePicker
                            label="Einddatum rentevaste periode"
                            defaultValue={values[index].parts[partIndex].fixed_rate_end_date}
                            onChange={(date) => setFieldValue(`${index}.parts.${partIndex}.fixed_rate_end_date`, date)}
                            style={{backgroundColor: colors.white}}
                        />
                    )}
                    <TextInput
                        disabled={values[index].parts[partIndex].interest_type === 'variable'}
                        label={"Rentevaste periode in jaren"}
                        value={(values[index].parts[partIndex].fixed_interest_duration / 12).toFixed(0)}
                        onChangeText={newValue => {
                            const textIsNumbers = !newValue.match(/[^0-9]/);
                            textIsNumbers && setFieldValue(`${index}.parts.${partIndex}.fixed_interest_duration`, newValue * 12)
                        }}
                        onBlur={handleBlur(`${index}.parts.${partIndex}.fixed_interest_duration`)}
                        error={(touchedPart?.fixed_interest_duration && errorPart?.fixed_interest_duration) ? errorPart?.fixed_interest_duration : undefined}
                        style={{backgroundColor: colors.white}}
                    />
                </View>
                <View style={[styles.flexRow, styles.alignCenter, styles.gap20]}>
                    <RadioGroup
                        label="Nationale Hypotheek Garantie"
                        options={[
                            {label: '#yes', value: 1},
                            {label: '#no', value: 0},
                        ]}
                        value={values[index].parts[partIndex].nhg}
                        onChange={(value) => setFieldValue(`${index}.parts.${partIndex}.nhg`, value)}
                    />
                    <RadioGroup
                        label="Soort rente"
                        options={interestTypeOptions}
                        value={values[index].parts[partIndex].interest_type}
                        onChange={(value) => handleInterestTypeChange(index, partIndex, value)}
                    />
                </View>

                <View style={[styles.flexRow, styles.alignCenter, styles.justifyBetween, styles.gap20, styles.pt20]}>
                    <Button
                        label={'#remove'}
                        type={'error'}
                        onPress={() => removePart(index, partIndex)}
                    />

                    <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                        <Button
                            loading={loading}
                            label={'wijzigingen opslaan'}
                            type={'secondary'}
                            onPress={handleSubmit}
                        />
                    </View>
                </View>
            </Card>
        );
    }

    return (
        <HeaderCard header="Hypotheek">
            <View style={styles.gap30}>
                {response.current !== undefined &&
                    <TText style={{color: response.current.successful ? colors.approval : colors.error}}>
                        {response.current.label}
                    </TText>
                }

                <TText>Hoe is je huidige hypotheek samengesteld?</TText>
                {valuesSet.current && values?.map((valueMortgage, mortgageIndex) => renderMortgage(valueMortgage, mortgageIndex))}

                <Button
                    loading={loading}
                    label={'hypotheek toevoegen'}
                    type={'secondary'}
                    onPress={addMortgage}
                />
            </View>
        </HeaderCard>
    );

}
