

export class Damage {

    id: string
    cause: string
    date: string
    description: string
    estimated: number
    policy_contract_number: string
    settled: boolean
    status: boolean
    progress_items: any[]
    payments: Record<string, string | number>[]
    pay_to: number
    seen_by_customer: boolean

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Damage {

        this.id = json.id ? json.id : '';
        this.cause = json.cause ? json.cause : '';
        this.date = json.date ? json.date : '';
        this.description = json.description ? json.description : '';
        this.estimated = json.estimated ? json.estimated : '';
        this.policy_contract_number = json.policy_contract_number ? json.policy_contract_number : '';
        this.settled = json.settled ? Boolean(json.settled) : false;
        this.status = json.status ? Boolean(json.status) : false;
        this.progress_items = json.progress_items ? json.progress_items.data : [];
        this.payments = json.payments ? json.payments : [];
        this.pay_to = json.pay_to ? json.pay_to : 1;
        this.seen_by_customer = json.seen_by_customer ? json.seen_by_customer : false;

        return this;
    }

}
