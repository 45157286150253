import { MortgageStatus, ObligationTypes } from 'enums';
import { Mortgage } from 'models';


export default {
    totalMortgage: (mortgages: Mortgage[]) => {
        const result = {
            total: 0,
            restant: 0,
            restant_old_age: 0,
            payed: 0
        }
        mortgages && mortgages.map(mortgage => {
            if (mortgage.status !== MortgageStatus.IN_AANVRAAG){
                result.total += mortgage.hoofdsomHypotheek;
                result.restant += mortgage.restantHoofdsom;
                result.restant_old_age += mortgage.remaining_debt_old_age_pension;
                result.payed += mortgage.hoofdsomHypotheek - mortgage.restantHoofdsom;
            }
        })
        return result;
    },
    monthlyMortgage: (mortgages) => {
        const result = {
            monthly: 0,
            aflossing: 0,
            rente: 0
        }
        mortgages && mortgages.map(mortgage => {
            result.monthly += mortgage.monthly_amount;
            result.aflossing += mortgage.monthly_repayment_amount;
            result.rente += mortgage.monthly_interest_amount;
        })

        return result;
    },
    totalIncome: (income) => {

        const result = {
            employment: 0,
            company: 0,
            benefit: 0,
            retirement: 0,
            other: 0
        };
        let total = 0;

        for (let key in result) {

            income && income[key + '_incomes'].forEach(income => {
                if (income.gross_annual_income) result[key] += income.gross_annual_income;
                if (income.holiday_bonus) result[key] += income.holiday_bonus;
                if (income.fixed_13th_month) result[key] += income.fixed_13th_month;
                if (income.fixed_end_year_benefit) result[key] += income.fixed_end_year_benefit;
                if (income.irregularity_bonus) result[key] += income.irregularity_bonus;
                if (income.overtime_per_year) result[key] += income.overtime_per_year;
                if (income.provision_per_year) result[key] += income.provision_per_year;
                if (income.gross_annual_allowance) result[key] += income.gross_annual_allowance;
                if (income.independent_income) result[key] += income.independent_income;
            })
            total += result[key];

        }

        return { ...result, total };
    },
    calculatePossession: (possessions) => {
        return possessions && possessions.reduce((acc, possession) => {
            return acc + Number(possession.current_balance);
        }, 0);
    },
    calculateMortgage: (mortgages) => {
        let restant = 0;
        mortgages && mortgages.map(mortage => {
            restant += mortage.restantHoofdsom;
        })
        return restant;
    },
    calculateRetirement: (retirement) => {

        let totalAccumulatedRetirement = 0;
        let totalRetirementTarget = 0;

        const getTarget = (record) => {
            for (let recKey in record) {
                if (recKey == 'target_pension_amount' || recKey == 'gross_annual_allowance') totalRetirementTarget += Number(record[recKey]);
            }
        }

        const getAccumulated = (record) => {
            for (let recKey in record) {
                if (recKey.includes('accumulated')) totalAccumulatedRetirement += Number(record[recKey]);
            }
        }


        for (let key in retirement) {
            if (Array.isArray(retirement[key])) {
                retirement[key].forEach(record => {
                    getAccumulated(record);
                    getTarget(record);
                })
            } else {
                getAccumulated(retirement[key]);
                getTarget(retirement[key]);
            }
        }

        return { totalAccumulatedRetirement, totalRetirementTarget };
    },
    calculateDept: (obligations) => {
        return obligations && obligations.reduce((acc, val) => {
            switch (val.type) {
                case ObligationTypes.PAYMENT:
                    if (val.current_balance < 0) {
                        return acc + (val.current_balance * -1)
                    } else {
                        return acc;
                    }

                case ObligationTypes.LOAN:
                    return acc + (val.principal_amount - val.current_balance);

                case ObligationTypes.CREDIT:
                    return acc + (val.credit_limit - val.current_balance);

                case ObligationTypes.STUDYADVANCE:
                case ObligationTypes.STUDYLOAN:
                case ObligationTypes.ALIMONY:
                    return acc;
            }
        }, 0);
    },
    calculateObligations: (obligations) => {

        return obligations && obligations.reduce((acc, val) => {
            return acc + val.monthly_amount;
        }, 0);

    }
}