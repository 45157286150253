import { useRoute } from '@react-navigation/native';
import { Icons } from 'icons';
import { CustomerDrawerToggle, TText } from 'components';
import { QueryKeys } from 'enums';
import { useHover, useStyle } from 'hooks';
import { useEffect, useState } from 'react';
import { Dimensions, Image, Platform, Pressable, View, useWindowDimensions } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { initialWindowMetrics } from 'react-native-safe-area-context';
import { useQuery } from 'react-query';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import colors from 'styles/colors';
import { QueryUtils, SentryUtils } from 'utils';
import { Card, IconCard } from 'components/Card';
import { transform } from '@babel/core';
import { render } from '@testing-library/react-native';

const header_height = 120;

const Header: React.FC<{ navigation: any, route: any }> = ({ navigation, route }) => {

    
    const scopedStyles = scopedResponsiveStyle();
    const styles = useStyle();

    const state = navigation.getState();
    const currentRouteTitle = state.routes.find(navRoute => navRoute.name === route.name).params?.title;

    const hoverBank = useHover(2);

    const items = [
        {iconName: 'IconFileDescription', title: '#title_documents', route: 'documenten'}
    ];

    const renderItems = () => {
        return items.map((item, itemIndex) => {
            const [hovered, hoverElt] = hoverBank[itemIndex];
            return (
                <Pressable
                    ref={hoverElt}
                    onPress={() => navigation.navigate(item.route)}
                >
                    <IconCard
                        iconName={item.iconName}
                        iconSize={25}
                        iconColor={hovered ? colors.primary9 : colors.primary4}
                        style={{backgroundColor: colors.transparent}}
                    />
                    {hovered &&
                        <Card style={scopedStyles.description}>
                            <View
                                style={{
                                    position: 'absolute',
                                    width: 10, height: 10,
                                    backgroundColor: colors.grey,
                                    left: '50%',
                                    top: -5,
                                    transform: 'rotate(45deg)'
                                }}
                            />
                            <TText white oneline>{item.title}</TText>
                        </Card>
                    }
                </Pressable>
            )
        })
    }
    
    return (
        <>
            <View style={scopedStyles.header}>

                <TText lg semibold>{currentRouteTitle || route.name}</TText>

                <View style={[styles.flexStretch, styles.flexRow, styles.gap20, styles.alignCenter, styles.justifyEnd]}>

                    <View style={[styles.flexRow, styles.gap10, styles.alignCenter, styles.justifyEnd]}>
                        {renderItems()}
                    </View>

                    <CustomerDrawerToggle/>
                </View>
               
            </View>
        </>

    );
};

const scopedResponsiveStyle = CreateResponsiveStyle(
    {
        header: {
            height: header_height,
            width: '100%',
            backgroundColor: colors.background,
            zIndex: 999,
            borderTopLeftRadius: 35,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: "space-between",
            paddingHorizontal: 40
        },        
        description:{
            position: 'absolute', 
            top: '100%', 
            backgroundColor: colors.grey, 
            marginTop: 10,
            marginLeft: 11,
            borderRadius: 10,
            padding: 5, 
            paddingHorizontal: 10,
            transform: 'translateX(-50%)',
            overflow: 'visible'
        }
    }
)

export default Header;
