import { QueryKeys } from "enums";
import { useStyle } from "hooks";
import { Policy } from "models";
import { useEffect, useRef, useState } from "react";
import { Platform, StyleProp, View } from "react-native";
import { PieChart } from 'react-native-svg-charts';
import { useQuery } from "react-query";
import { colors } from 'styles';
import { QueryUtils, ValutaUtils } from "utils";
import TText from "./TText";

interface pieProps {
    style?: StyleProp<any>
    dark?: boolean
    opklik?: boolean
}

export default ({ style, dark, opklik }: pieProps) => {

    const styles = useStyle();
    const { data: policies, isLoading } = useQuery(QueryKeys.POLICY, QueryUtils.fetchPolicy);
    const [data, setData] = useState({ data: [], totaal: 0 });
    const [selectedPart, setSelectedPart] = useState(null);
    const [pieSize, setPieSize] = useState<number | null>(null);


    //prepare pie data
    useEffect(() => {
        if (!isLoading) {

            let colorInd = 0;
            const rColors = [
                colors.secondary,
                colors.secondary9,
                colors.secondary8,
                colors.secondary7,
                colors.secondary6,
                colors.secondary5,
                colors.secondary4,
                colors.secondary3,
                colors.secondary2,
                colors.secondary1,
            ];
            const getRandomColor = () => {
                const rColor = rColors[colorInd];
                if (++colorInd >= rColors.length) colorInd = 0;
                return rColor;
            }

            const data = policies.map((policy: Policy) => {
                if (!policy.is_package && policy.mutation_code === 0) { //only count active policies with mutation_code 0!!
                    return {
                        color: getRandomColor(),
                        value: policy.monthly_term_amount,
                        title: policy.descriptions[0] ? policy.descriptions[0].split(' ')[0] : '#unknown'
                    }
                }
            }).filter((val) => val);

            const totaal = data.reduce((acc, val) => acc + val.value, 0);

            setData({ data, totaal })

        }
    }, [isLoading])

    //compile pie data
    const money = (selectedPart === null) ? ValutaUtils.valutaString(data.totaal) : ValutaUtils.valutaString(data.data[selectedPart].value);
    const title = (selectedPart === null) ? '#polissen' : data.data[selectedPart].title;
    const pieData = data.data.map((pd, index) => ({
        key: `pie-${index}`,
        value: pd.value,
        svg: {
            fill: pd.color,
            onPress: () => { (opklik) && setSelectedPart(index === selectedPart ? null : index) },
        },
        arc: {
            innerRadius: selectedPart === index ? '75%' : '80%',
            outerRadius: selectedPart === index ? '99%' : '95%'
        }
    }))

    //add hover events to pie
    const hoverElt = useRef(null);
    if (opklik && hoverElt.current && Platform.OS === 'web') {
        setTimeout(() => {
            if (!hoverElt.current) return;
            const parts = hoverElt.current.querySelectorAll('path');
            parts.forEach((part, index) => {
                part.addEventListener('mouseenter', () => setSelectedPart(index))
                part.addEventListener('mouseleave', () => setSelectedPart(null))
            })
        }, 200)
    }

    return (
        <View 
            ref={hoverElt} 
            style={[{height: '100%'}, style]} 
            onLayout={(e) => setPieSize(e.nativeEvent.layout.height)}
        >

            {(policies !== undefined) && <View style={{ 
                position: 'absolute', 
                height: pieSize, 
                width: pieSize, 
                justifyContent: 'center',
                gap: 10,
                zIndex: opklik ? 0 : 99 
            }}>
                <TText semibold sm grey center style={{textTransform: 'uppercase', letterSpacing: 2}}>{title}</TText>
                <TText semibold md center style={{ transform: 'scale(1.5)' }}>{money}</TText>
                <TText sm center>#per_month</TText>
            </View>}

            <PieChart
                style={{height: pieSize, width: pieSize}}
                data={pieData}
                innerRadius={'85%'}
                outerRadius={'95%'}
                padAngle={0}
            />

        </View>
    )

}