import { useStyle } from "hooks";
import { Button, DatePicker, Dropdown, TextInput, TText } from "components";
import { HeaderCard } from "components/Card";
import { object, string } from "yup";
import { useFormik } from "formik";
import { View } from "react-native";
import { EditDataUtils, QueryUtils } from "utils";
import { DateTimeFormat, QueryKeys } from "enums";
import { useQuery } from "react-query";
import { useEffect, useRef, useState } from "react";
import { Customer } from "models";
import moment from "moment";
import { colors } from "styles";

export default () => {

    const styles = useStyle();
    const { data: customer, refetch: refetchCustomer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer, {refetchOnWindowFocus: false, staleTime: Infinity});
    const [loading, setLoading]  = useState(false);
    const response = useRef<undefined | string>(undefined);

    useEffect(() => {
        if ( customer instanceof Customer){
            setValues({
                ...values,
                phone: customer.phone
            })
        }
    }, [customer])

    const changeGeneralSchema = object({ phone: string() });

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        setValues,
        values,
        errors,
        touched
    } = useFormik({
        validationSchema: changeGeneralSchema,
        initialValues: { phone: "" },
        onSubmit: values => {
            setLoading(true);
            response.current = undefined;
            EditDataUtils.uploadContactDetails(values)
                .then(() => {
                    setLoading(false);
                    response.current = {
                        successful: true,
                        label: 'Je gegevens zijn succesvol gewijzigd'
                    };
                    refetchCustomer();
                })
                .catch((error) => {
                    setLoading(false);
                    response.current = {
                        successful: false,
                        label: error.response.data.error.message.includes('Could not persist object of type \"App\\Models\\Customer\"') ?
                            'Opslaan is niet gelukt, je adviseur is bezig met jouw dossier' :
                            'Er ging iets mis bij het opslaan van je gegevens'
                    };
                });
        },
    });

    return (                  
        <HeaderCard header="Contact gegevens">
            <View style={styles.gap30}>                
                <View style={styles.gap10}>
                    <TextInput
                        disabled
                        label={"E-mail"}
                        value={customer?.email_address}
                    />
                    <TextInput
                        label={"Telefoonnummer"}
                        value={values.phone}
                        onChangeText={(txt: string) => setValues(v => ({ ...v, phone: txt }))}
                        onBlur={handleBlur('phone')}
                        error={(touched.phone && errors.phone) ? errors.phone : undefined}
                    />
                </View>
                <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                    <Button
                        loading={loading}
                        label={'wijzigingen opslaan'}
                        type={'secondary'}
                        onPress={handleSubmit}
                    />
                    {response.current !== undefined && 
                        <TText style={{color: response.current.successful ? colors.approval : colors.error}}>
                            {response.current.label}
                        </TText>
                    }
                </View>
            </View>
        </HeaderCard>        
    );

}
