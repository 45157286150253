export enum EnergyLabels {
    a_plus_plus_plus_plus = 'A++++', 
    a_plus_plus_plus = 'A+++',
    a_plus_plus = 'A++',
    a_plus = 'A+',
    a = 'A',
    b = 'B',
    c = 'C', 
    d = 'D', 
    e = 'E', 
    f = 'F', 
    g = 'G'
}