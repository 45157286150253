
export class Possession {
    account_number: number
    bank: string
    bank_description: string
    common_interest: number
    current_balance: string
    description: string
    id: number
    monthly_contribution: string
    name: string
    type: string
    type_description: string
    updated_at: string

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Possession {
        this.account_number = json.account_number ? json.account_number : 0;
        this.bank = json.bank ? json.bank : '';
        this.bank_description = json.bank_description ? json.bank_description : '';
        this.common_interest = json.common_interest ? json.common_interest : 0;
        this.current_balance = json.current_balance ? json.current_balance : '';
        this.description = json.description ? json.description : '';
        this.id = json.id ? json.id : 0;
        this.monthly_contribution = json.monthly_contribution ? json.monthly_contribution : '';
        this.name = json.name ? json.name : '';
        this.type_description = json.type_description ? json.type_description : '';
        this.updated_at = json.updated_at ? json.updated_at : '';
        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId()
        };
    }

    getId(): string {
        return this.id;
    }
}
