import { QueryKeys } from 'enums';
import moment from "moment";
import { StoreContext } from 'providers/StoreProvider';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { QueryUtils } from 'utils';

export default () => {

    const { loggedIn } = useContext(StoreContext);
    const { data: customer } = loggedIn ? useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer) : { data: { first_name: '' } };
    const name = customer?.first_name || "";

    const hour = moment().hour();
    if (hour < 12) {
        return '#greeting_morning' + ' ' + name;
    } else if (hour < 18) {
        return '#greeting_afternoon' + ' ' + name;
    } else {
        return '#greeting_evening' + ' ' + name;
    }

}