import { useState } from "react";
import { useWindowDimensions, View } from "react-native"
import { colors } from "styles"
import TText from "./TText";
import {Mortgage} from "models";
import moment from "moment";
import {DateTimeFormat} from "enums";

interface MortgageItemCardProps{
    mortgage: Mortgage,
    componentWidth: number,
}
interface StepInterface {
    title: string,
    subtitle: string,
    date: moment.Moment,
}
interface CircleLocationInterface {
    diffInMonths: number,
    pxAmount: number,
    date: moment.Moment,
    active: Boolean,
}

export default ({ mortgage, componentWidth }: MortgageItemCardProps) => {

    const smallScreen = componentWidth < 600;
    const staticItems = componentWidth < 500;
    const today = moment();
    const defaultCircleSize = staticItems ? 20 : (smallScreen ? 25 : 30);
    const [sizes, setSizes] = useState({ circle: defaultCircleSize, circleBorder: 1, line: 300, lineWidth: 8, width: 300, activeWidth: 0, textWidth: 118 });

    let steps: StepInterface[] = [];
    let circleLocations: CircleLocationInterface[] = [];
    const passeerdatum = moment(mortgage.passeerdatum);
    steps.push({
        title: '#passeerdatum',
        subtitle: '',
        date: passeerdatum,
    });
    circleLocations.push({
        diffInMonths: 0,
        pxAmount: 0,
        date: passeerdatum,
        active: passeerdatum.isBefore(today)
    })
    let firstStep = steps[0];

    const numMortgageParts = mortgage.mortgageParts.length;
    mortgage.mortgageParts.forEach((mortgagePart) => {
        const subtitle = numMortgageParts > 1 ? '#leningdeel '+mortgagePart.leningdeelnummer : ''
        const renteVast = moment(mortgagePart.einddatumRentevast);
        const einddatum = moment(mortgagePart.einddatum);
        steps.push({
            title: '#einde_rentevast_periode',
            subtitle: subtitle,
            date: renteVast,
        });
        circleLocations.push({
            diffInMonths: renteVast.diff(firstStep.date, 'months'),
            pxAmount: 0,
            date: renteVast,
            active: renteVast.isBefore(today)
        });
        steps.push({
            title: '#einde_rentevast_periode',
            subtitle: subtitle,
            date: einddatum,
        });
        circleLocations.push({
            diffInMonths: einddatum.diff(firstStep.date, 'months'),
            pxAmount: 0,
            date: einddatum,
            active: einddatum.isBefore(today)
        });
    });

    steps.sort((a, b) => a.date.diff(b.date))
    circleLocations.sort((a, b) => a.date.diff(b.date))

    let previousPxAmount = componentWidth;
    let lastStep = steps.slice(-1)[0];
    let timelineAmount = lastStep.date.diff(firstStep.date, 'months');

    let currentDateDiff = today.diff(firstStep.date, 'months');
    let maxItemWidth = 0;
    const defaultItemWidth = componentWidth / (circleLocations.length - 1);
    const showText = componentWidth >= 150 && (defaultItemWidth > 50);

    if(staticItems) {
        circleLocations.sort((a, b) => a.date.diff(b.date))
        circleLocations.map((circleLocation, key) => {
            circleLocation.pxAmount = defaultItemWidth * key;
            if(circleLocation.active) {
                maxItemWidth = circleLocation.pxAmount + (sizes.circle);
            }
        });
    } else {
        circleLocations.sort((a, b) => b.date.diff(a.date))
        circleLocations.map((circleLocation, key) => {
            let pxAmount = Math.trunc(circleLocation.diffInMonths * componentWidth / timelineAmount);
            if(key === 0) {
                circleLocation.pxAmount = pxAmount;
            }
            if(key > 0 && key < (circleLocations.length - 1) && (previousPxAmount - pxAmount) < (sizes.circle + defaultCircleSize)) {
                pxAmount = previousPxAmount - (sizes.circle + defaultCircleSize);
            }
            circleLocation.pxAmount = pxAmount;
            previousPxAmount = pxAmount;
        });
        previousPxAmount = 0;
        circleLocations.sort((a, b) => a.date.diff(b.date))
        circleLocations.map((circleLocation, key) => {
            let pxAmount = Math.trunc(circleLocation.diffInMonths * componentWidth / timelineAmount);
            if(key > 0 && key < (circleLocations.length - 1)) {
                if((pxAmount - previousPxAmount) < (sizes.circle + defaultCircleSize)) {
                    pxAmount = previousPxAmount + (sizes.circle + defaultCircleSize);
                } else if ((pxAmount - circleLocations[key+1].pxAmount) < sizes.circle) {
                    pxAmount = circleLocation.pxAmount;
                }
                circleLocation.pxAmount = pxAmount;
            }
            if(circleLocation.active && (circleLocation.pxAmount > maxItemWidth)) {
                maxItemWidth = circleLocation.pxAmount;
            }
            previousPxAmount = pxAmount;
        });
    }

    const handleLayout = (e: any) => {
        const width = e.nativeEvent.layout.width;
        const circleSize = Math.min((width * 0.7) / 5, defaultCircleSize);
        setSizes({
            circle: circleSize,
            circleBorder: Math.min((width * 0.3) / 4 * 0.16, 2),
            line: width,
            lineWidth: Math.min((width * 0.3) / 4 * 0.16, 8),
            width: width,
            activeWidth: Math.max(maxItemWidth, Math.trunc(currentDateDiff * width / timelineAmount)),
            textWidth: staticItems ? (defaultItemWidth*2)-2 : ((circleSize+defaultCircleSize) * 2)-2
        })
    }


    return (
        <View style={{
            marginVertical: smallScreen ? 30 : 55,
            marginHorizontal: 40,
            justifyContent: "center",
            alignItems: "center",
        }}>
            <View
                key={'emptyLine'}
                style={{
                    width: sizes.line,
                    borderBottomColor: colors.greyTransparent,
                    borderBottomWidth: sizes.lineWidth,
                    position: 'absolute',
                    left: 0,
                }}
            />
            <View
                key={'filledLine'}
                style={{
                    width: sizes.activeWidth,
                    borderBottomColor: colors.secondary,
                    borderBottomWidth: sizes.lineWidth,
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    position: 'absolute',
                    left: 0,
                }}
            />
            <View style={{ flexDirection: 'row', alignItems: "center", justifyContent: 'center', width: '100%' }} onLayout={handleLayout}>
                {steps.map((step, key) => {
                    const isOdd = (key % 2) === 0;
                    return [
                        (<View
                            key={key}
                            aria-label={`${step.title} ${step.subtitle} - ${step.date.format(DateTimeFormat.DISPLAY_DATE)}`}
                            style={{
                                position: "absolute",
                                width: sizes.circle,
                                height: sizes.circle,
                                borderRadius: sizes.circle,
                                borderWidth: sizes.circleBorder,
                                borderColor: colors.white,
                                backgroundColor: circleLocations[key].active ? colors.secondary : colors.lightgrey,
                                justifyContent: "center",
                                alignItems: "center",
                                overflow: "visible",
                                left: circleLocations[key].pxAmount - (sizes.circle/2),
                                zIndex: 5,
                            }}
                        >
                            {showText &&
                                <TText
                                    white
                                    xs
                                    semibold
                                    style={[{
                                        position: "absolute",
                                        fontSize: smallScreen ? 10 : 12,
                                        textAlign: "center",
                                        width: sizes.textWidth,
                                        lineHeight: smallScreen ? 12 : 16,
                                    },
                                    isOdd ? {
                                        top: smallScreen ? (sizes.circle + 12) : (sizes.circle + 16)
                                    } : {
                                        bottom: smallScreen ? (sizes.circle + 12) : (sizes.circle + 16)
                                    }
                                    ]}
                                >
                                    {step.title}
                                    {smallScreen ? '' : step.subtitle}
                                </TText>

                            }
                            <TText
                                white
                                xs
                                style={[{
                                    position: "absolute",
                                    fontSize: smallScreen ? 10 : 12,
                                    textAlign: "center",
                                    width: sizes.textWidth,
                                    lineHeight: smallScreen ? 12 : 16,
                                },
                                    isOdd ? {
                                        top: sizes.circle,
                                    } : {
                                        bottom: sizes.circle
                                    }
                                ]}
                            >
                                {showText ? step.date.format(DateTimeFormat.DISPLAY_DATE) : step.date.format(DateTimeFormat.DATE)}
                            </TText>
                        </View>)
                    ];
                }).flat()}
            </View>
        </View>
    )
}
