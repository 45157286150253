import { StyleSheet, ViewStyle, View } from "react-native"
import { useHover, useStyle } from "hooks";
import { colors } from "styles";

interface props {
    children?: React.ReactNode
    style?: ViewStyle | ViewStyle[],
    hoverable?: boolean,
    animated?: boolean
}

export default ({ children, style = [], hoverable, animated }: props) => {

    const styles = useStyle();
    const [hovered, hoverElt] = useHover();

    return (
        <View 
            ref={hoverElt}
            style={[
                hoverable && { transform: hovered ? 'scale(1.015)' : undefined },
                {backgroundColor: colors.white}, 
                styles.borderRadius20, 
                styles.p30, 
                style
            ]}
        >
            {children}
        </View>
    )
}
