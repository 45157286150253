import { Button, TText, TextInput } from 'components';
import { useFormik } from 'formik';
import { useStyle, useTranslation } from 'hooks';
import { StoreContext } from 'providers/StoreProvider';
import { useContext } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { object, string } from 'yup';

interface FormProps {
    loading: boolean
    handleRecovery: (v: any) => void
}

export default ({ loading, handleRecovery }: FormProps) => {

    const { query } = useContext(StoreContext);
    const styles = useStyle();
    const translate = useTranslation();

    const [subdomain, domain, tld] = window?.location ? location.hostname.split('.') : [];
    const tenant = ((domain == 'klantportaal' && tld == 'net') || domain == 'localhost') ? subdomain : undefined;
    const username = query.get('username') || '';

    const loginSchema = object({
        code: tenant ? string() : string().required('#login_error_tenant'),
        username: string().required('#login_error_username')
    });

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        setValues,
        values,
        errors,
        touched
    } = useFormik({
        validationSchema: loginSchema,
        initialValues: { code: '', username, tenant },
        onSubmit: values => handleRecovery(values),
    });

    return (
        <View>
            {!tenant &&
                <>
                    <TextInput
                        tabIndex={1}
                        label='#login_advisor'
                        style={[styles.text, scopedStyles.input]}
                        placeholder={translate('#login_advisor_code')}
                        value={values.code}
                        onChangeText={(txt: string) => { setValues(v => ({ ...v, code: txt.toUpperCase() })) }}
                        keyboardType={Platform.OS === 'ios' ? 'default' : 'visible-password'}
                        onBlur={handleBlur('code')}
                        onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                        autoCapitalize='none'
                        error={(touched.code && errors.code) ? errors.code : undefined}
                    />
                </>
            }

            <TextInput
                label='#login_username'
                style={[styles.text, scopedStyles.input]}
                placeholder={translate("#login_username")}
                value={values.username}
                onChangeText={handleChange('username')}
                onBlur={handleBlur('username')}
                onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                autoCapitalize='none'
                error={(touched.username && errors.username) ? errors.username : undefined}
            />

            <Button 
                label='#login_renewpassword' 
                onPress={handleSubmit} 
                style={scopedStyles.button} 
                loading={loading} 
            />

        </View>
    );
};

const scopedStyles = StyleSheet.create({
    input: {
        width: '100%',
        marginVertical: 8
    },
    button: {
        marginTop: 24,
        width: '100%'
    }
});

