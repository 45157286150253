import { Icons } from "icons";
import { ViewStyle } from "react-native"

interface IconProps{
    iconName: string, 
    iconSize?: number, 
    iconColor?: string, 
    style?: ViewStyle, 
}

export default ({ iconName, iconSize = 15, iconColor, style = {} }: IconProps) => {
    const IconComponent = Icons[iconName];
    return IconComponent ? (
        <IconComponent color={iconColor} size={iconSize} style={style}/>
    ) : (
        <></>
    );
}
