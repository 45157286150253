export default {
    //greetings
    '#greeting_morning': 'Goedemorgen',
    '#greeting_afternoon': 'Goedemiddag',
    '#greeting_evening': 'Goedenavond',
    '#welcome': 'Welkom bij het online dossier van je financiele dienstverlener.',
    '#welcome_u': 'Welkom bij het online dossier van uw financiele dienstverlener.',

    //login
    '#login_advisor': 'Kantoor',
    '#login_advisor_code': 'Kantoor code',
    '#login_username': 'Gebruikersnaam',
    '#login_password': 'Wachtwoord',
    '#login_login': 'inloggen',
    '#login_forgotpassword': 'wachtwoord vergeten',
    '#login_back': 'terug naar inloggen',
    '#login_renewpassword': 'Nieuw wachtwoord aanvragen',
    '#login_recoverysent': 'Er is een e-mail verstuurd. Klik op de link in de mail om je wachtwoord te resetten.',
    '#login_recoverysent_u': 'Er is een e-mail verstuurd. Klik op de link in de mail om uw wachtwoord te resetten.',

    '#login_error': 'Er is een fout ontstaan tijdens het inloggen',
    '#login_error_token': 'De link is niet meer geldig',
    '#login_error_retry': 'Er ging iets verkeerd. Probeer het opnieuw',
    '#login_error_notfound': 'De inlog gegevens zijn niet bekend',
    '#login_error_noauth': 'Gebruikersnaam of wachtwoord is onjuist',
    '#login_error_username': 'Gebruikersnaam mag niet leeg zijn',
    '#login_error_password': 'Wachtwoord mag niet leeg zijn',
    '#login_error_tenant': 'Adviseur mag niet leeg zijn',

    '#login_2FA': 'Authenticatie code',
    '#login_authcode_sent': 'Er is een email verstuurd met een authenticatie code. De code kun je hieronder invullen.',
    '#login_authcode_sent_u': 'Er is een email verstuurd met een authenticatie code. De code kunt u hieronder invullen.',
    '#login_error_authcode': 'Authenticatie code mag niet leeg zijn',

    //brondata
    '#brondata_collect': 'Verzamel al je gegevens',
    '#brondata_collect_u': 'Verzamel al uw gegevens',
    '#brondata_description': 'Voor je eerste gesprek met jouw adviseur hebben we een aantal gegevens van je nodig. Via ons inventarisatie portaal kun je deze makkelijk verzamelen. Afhankelijk van je gekozen optie kost dit ongeveer 5 tot 20 minuten.',
    '#brondata_description_u': 'Voor uw eerste gesprek met jouw adviseur hebben we een aantal gegevens van u nodig. Via ons inventarisatie portaal kunt u deze makkelijk verzamelen. Afhankelijk van uw gekozen optie kost dit ongeveer 5 tot 20 minuten.',
    '#brondata_description_short': 'Voor je eerste gesprek met jouw adviseur hebben we een aantal gegevens van je nodig. Via ons inventarisatie portaal kun je deze makkelijk verzamelen.',
    '#brondata_description_short_u': 'Voor uw eerste gesprek met jouw adviseur hebben we een aantal gegevens van u nodig. Via ons inventarisatie portaal kunt u deze makkelijk verzamelen.',
    '#brondata_action': 'start inventarisatie',


    //numeric overview
    '#possession': 'Vermogen',
    '#mortgage': 'Hypotheek',
    '#income': 'Inkomen',
    '#pension': 'Pensioen',
    '#debt': 'Schulden',
    '#obligation': 'Verplichtingen',

    //mortgage overview
    '#redeemed': 'Aflossing',
    '#interest': 'Rente',
    '#premium': 'Premie',

    //page titles
    '#title_number': 'Mijn cijfers',
    '#title_mortgage': 'Mijn hypotheken',
    '#title_policy': 'Mijn verzekeringen',
    '#title_income': 'Mijn inkomen',
    '#title_obligations': 'Mijn verplichtingen',
    '#title_inbox': 'Mijn berichten',
    '#title_tasks': 'Mijn taken',
    '#title_damages': 'Mijn schademeldingen',
    '#title_appointments': 'Mijn afspraken',
    '#title_documents': 'Mijn documenten',
    '#title_notes': 'Mijn notities',
    '#title_upload': 'Document uploaden',
    '#title_upload_multiple': 'Meerdere stukken uploaden',
    '#title_message': 'Bericht',
    '#title_userdetails': 'Mijn gegevens',
    '#title_contact': 'Contact',
    '#title_settings': 'Mijn instellingen',
    '#title_avatar': 'Profielfoto bewerken',
    '#title_send_message': 'Bericht versturen',
    '#title_reply_message': 'Antwoorden op een bericht',
    '#title_damage': 'Geef hier direct je schade door',
    '#title_damage_u': 'Geef hier direct uw schade door',

    '#title_tasks_and_messages': 'Taken en berichten',

    //undertitles
    '#undertitle_documents': 'Hier kun je alle documenten terugvinden die je ooit hebt verstuurd. Heb je een document waar wij naar moeten kijken? Dan kun je die hier uploaden.',
    '#undertitle_policies': 'Hier vind je alle informatie die betrekking heeft tot je verzekeringen.',
    '#undertitle_mortgage': 'Hier vind je alle informatie die betrekking heeft tot je hypotheken.',
    '#undertitle_income': 'Hier vind je alle informatie die betrekking heeft tot je inkomen.',
    '#undertitle_obligation': 'Hier vind je alle informatie die betrekking heeft tot je verplichtingen.',
    '#undertitle_inbox': 'Hier kun je alle communicatie terugvinden die met je adviseur heeft plaatsgevonden.',
    '#undertitle_tasks': 'Hier kun je alle taken terugvinden die je adviseur voor je heeft klaargezet.',
    '#undertitle_damages': 'Hier kun je alle schademeldingen terugvinden die aan je adviseur zijn gemeld.',
    '#undertitle_appointments': 'Hier kun je jouw afspraken terugvinden die je met jouw adviseur hebt gemaakt.',
    '#undertitle_create_appointment': 'Hier kun je een afspraak maken met je adviseur. Selecteer een datum en reden voor de afspraak om de beschikbare tijden op te halen.',

    '#undertitle_documents_u': 'Hier kunt u alle terugvinden die u ooit heeft verstuurd. Heeft u een document waar wij naar moeten kijken? Dan kunt u die hier uploaden.',
    '#undertitle_policies_u': 'Hier vind u alle informatie die betrekking heeft tot uw verzekeringen.',
    '#undertitle_mortgage_u': 'Hier vind u alle informatie die betrekking heeft tot uw hypotheken.',
    '#undertitle_income_u': 'Hier vind u alle informatie die betrekking heeft tot uw inkomen.',
    '#undertitle_obligation_u': 'Hier vind u alle informatie die betrekking heeft tot uw verplichtingen.',
    '#undertitle_inbox_u': 'Hier kunt u alle communicatie terugvinden die met uw adviseur heeft plaatsgevonden.',
    '#undertitle_tasks_u': 'Hier kunt u alle taken terugvinden die uw adviseur voor u heeft klaargezet.',
    '#undertitle_damages_u': 'Hier kunt u alle schademeldingen terugvinden die aan uw adviseur zijn gemeld.',
    '#undertitle_appointments_u': 'Hier kunt u uw afspraken terugvinden die u met uw adviseur heeft gemaakt.',
    '#undertitle_create_appointment_u': 'Hier kunt u een afspraak maken met uw adviseur. Selecteer een datum en reden voor de afspraak om de beschikbare tijden op te halen.',


    //misc.
    '#hello': 'Hallo',
    '#on': 'Staan aan',
    '#off': 'Staan uit',
    '#search': 'zoeken..',
    '#more': 'Bekijk meer',
    '#total': 'TOTAAL',
    '#total_small': 'Totaal',
    '#afgelost': 'afgelost',
    '#openstaand': 'openstaand',
    '#mortgage_data': 'Hypotheekgegevens',
    '#hover': 'Klik op de cirkel voor meer details.',
    '#description': 'Omschrijving',
    '#add_note': 'notitie toevoegen',
    '#remove': 'verwijderen',
    '#language': 'Taal',
    '#notifications': 'Notificaties',
    '#clear_cache': 'cache verwijderen',
    '#all_messages': 'alle berichten',
    '#type_message_placeholder': 'Schrijf hier je bericht..',
    '#type_message_placeholder_u': 'Schrijf hier uw bericht..',
    '#service_line': 'Waarmee kunnen we je van dienst zijn?',
    '#service_line_u': 'Waarmee kunnen we u van dienst zijn?',
    '#edit_line': 'Welke wijzigingen wil je doorgeven?',
    '#edit_line_u': 'Welke wijzigingen wilt u doorgeven?',
    '#next': 'Volgende',
    '#previous': 'Vorige',
    '#optional': 'optioneel',

    //documents
    '#upload': 'document uploaden',
    '#download': 'downloaden',
    '#open': 'open',
    '#klanten': 'persoonlijk',
    '#polissen': 'verzekeringen',
    '#hypotheken': 'hypotheken',
    '#krediet': 'inkomen',
    '#verplichtingen': 'verplichtingen',
    '#bankzaken': 'bankzaken',


    //inbox
    '#message_single': 'Bericht',
    '#message_action_read': 'lezen',
    '#message_read': 'Gelezen bericht',
    '#message_new': 'Nieuw bericht',

    //openinghours
    '#opening_hours': 'Openingstijden',
    '#closed': 'Gesloten',
    '#monday': 'Maandag',
    '#tuesday': 'Dinsdag',
    '#wednesday': 'Woensdag',
    '#thursday': 'Donderdag',
    '#friday': 'Vrijdag',
    '#saturday': 'Zaterdag',
    '#sunday': 'Zondag',
    '#hour': 'uur',

    '#monday_short': 'Ma',
    '#tuesday_short': 'Di',
    '#wednesday_short': 'Wo',
    '#thursday_short': 'Do',
    '#friday_short': 'Vr',
    '#saturday_short': 'Za',
    '#sunday_short': 'Zo',


    //uploads
    '#uploads_other': 'Overige uploads',
    '#uploads_different_document': 'ander document selecteren',
    '#uploads_cancel': 'annuleren',
    '#uploads_confirm': 'bevestigen',
    '#uploads_description': 'Upload een bestand om te delen met je adviseur',
    '#uploads_description_u': 'Upload een bestand om te delen met uw adviseur',
    '#uploads_retry': 'opnieuw uploaden',
    '#uploads_error': 'Er ging iets mis. Probeer het opnieuw',

    //values and periods
    '#original_value': 'Oorspronkelijk bedrag',
    '#rest_value': 'Openstaand bedrag',
    '#paid_value': 'Afgelost bedrag',
    '#monthly_value': 'Maandbedrag',
    '#per_month': 'per maand',
    '#per_year': 'per jaar',
    '#monthly': 'Maandelijks',
    '#monthly_interest': 'Maandelijke rente',
    '#monthly_redeemed': 'Maandelijke aflossing',
    '#monthly_premium': 'Maandelijke premie',
    '#p/j': 'p/j',
    '#p/m': 'p/m',

    //actions
    '#action_view': 'bekijk',
    '#action_edit': 'wijzigen',
    '#action_edit_long': 'Wijziging doorgeven ',
    '#action_damage': 'schade melden',
    '#action_send_message': 'bericht versturen',
    '#action_view_message': 'bekijk bericht',
    '#action_view_complete_message': 'bekijk volledig bericht',
    '#action_upload': 'uploaden',
    '#action_add': 'toevoegen',
    '#action_logout': 'Uitloggen',
    '#action_close': 'sluiten',
    '#action_retry': 'opnieuw',
    '#action_react': 'beantwoorden',
    '#action_date': 'selecteer datum',
    '#action_appointment_type': 'selecteer afspraak reden',
    '#action_appointment_type_resource': 'selecteer adviseur',
    '#action_retrieve': 'ophalen',

    //menu items
    '#menu_overview': 'Mijn overzicht',
    '#menu_mortgage': 'Mijn hypotheken',
    '#menu_policy': 'Mijn verzekeringen',
    '#menu_living': 'Mijn woning',
    '#menu_income': 'Mijn inkomen',
    '#menu_retirement': 'Mijn pensioen',
    '#menu_obligation': 'Mijn verplichtingen',
    '#menu_other_services': 'Andere diensten',
    '#menu_contact': 'Contact',

    //genders and age
    '#gender_male': 'Man',
    '#gender_female': 'Vrouw',
    '#unknown': 'Onbekend',
    '#age': 'jaar',
    '#birth_on': 'Geboren op',
    '#birth_at': 'te',

    //family
    '#partner': 'Partner',
    '#children': 'Kinderen',

    //uploading
    '#upload_empty': 'We hebben op dit moment geen documenten van je nodig.',
    '#upload_pre': 'Om je hypotheekaanvraag compleet te maken hebben we document',
    '#upload_post': 'van je nodig.',
    '#upload_pre_multi': 'Om onze inventarisatie compleet te maken hebben we nog',
    '#upload_post_multi': 'documenten van je nodig.',

    '#upload_empty_u': 'We hebben op dit moment geen documenten van u nodig.',
    '#upload_pre_u': 'Om uw hypotheekaanvraag compleet te maken hebben we document',
    '#upload_post_u': 'van u nodig.',
    '#upload_pre_multi_u': 'Om onze inventarisatie compleet te maken hebben we nog',
    '#upload_post_multi_u': 'documenten van u nodig.',

    //empty overview strings
    '#empty_livingOverview': 'Hier kun je in één oogopslag alles over jouw woning zien. Van energielabel tot marktwaarde, alles in één handig overzicht.',
    '#empty_mortgageOverview': 'In dit blok zie je snel wat er open staat aan hypotheken en wat je per maand aflost.',
    '#empty_policyOverview': 'Vanuit dit blok kan je direct zien hoeveel je aan verzekeringen kwijt bent per maand en waaruit dit is opgebouwd.',

    '#empty_livingOverview_u': 'Hier kunt u in één oogopslag alles over jouw woning zien. Van energielabel tot marktwaarde, alles in één handig overzicht.',
    '#empty_mortgageOverview_u': 'In dit blok ziet u snel wat er open staat aan hypotheken en wat u per maand aflost.',
    '#empty_policyOverview_u': 'Vanuit dit blok kunt u direct zien hoeveel u aan verzekeringen kwijt bent per maand en waaruit dit is opgebouwd.',

    //empty pages string
    '#empty_screen_pre': 'Je hebt nog geen',
    '#empty_screen_post': 'bij ons afgesloten.',
    '#empty_screen_pre_u': 'U heeft nog geen',
    '#empty_screen_pre_unknown': 'Er zijn nog geen',
    '#empty_screen_post_unknown': 'bij ons bekend.',

    '#empty_screen_mortgages': 'hypotheken',
    '#empty_screen_policies': 'verzekeringen',
    '#empty_screen_incomes': 'inkomens',
    '#empty_screen_retirements': 'pensioenen',
    '#empty_screen_obligations': 'verplichtingen',

    '#empty_screen_inbox': "Je hebt nog geen berichten van ons ontvangen.",
    '#empty_screen_tasks': "Je hebt nog geen taken van ons ontvangen.",
    '#empty_screen_damages': "Je hebt nog geen schade bij ons gemeld.",
    '#empty_screen_appointments': "Je hebt nog geen afspraken met ons gepland.",

    '#empty_screen_inbox_u': "U heeft nog geen berichten van ons ontvangen.",
    '#empty_screen_tasks_u': "U heeft nog geen taken van ons ontvangen.",
    '#empty_screen_damages_u': "U heeft nog geen schade bij ons gemeld.",
    '#empty_screen_appointments_u': "U heeft nog geen afspraken met ons gepland.",


    //income
    '#income_employment': 'Loondienst',
    '#income_company': 'Onderneming',
    '#income_benefit': 'Uitkering',
    '#income_retirement': 'Pensioen',
    '#income_other': 'Overige inkomsten',

    '#income_type': 'Type inkomen',
    '#start_date': 'Startdatum',
    '#end_date': 'Einddatum',
    '#company_name': 'Naam bedrijf',
    '#company_employes': 'Heeft het bedrijf personeel in dienst?',
    '#company_start_date': 'Startdatum onderneming',
    '#employment_name': 'Naam werkgever',
    '#employment_city': 'Vestigingsplaats',
    '#employment_start_service': 'Start dienstverband',
    '#employment_end_service': 'Einde dienstverband',
    '#employment_function': 'Functie',
    '#employment_fulltime': 'Fulltime contract',
    '#employment_hours_per_week': 'Aantal uren per week',

    '#income_gross_yearly': 'Bruto uitkering per jaar',
    '#income_holiday_bonus': 'Vakantietoeslag',
    '#income_net_profit': 'Netto winst',
    '#income_net_profit_total': 'Gemiddelde netto winst afgelopen 3 jaar',
    '#income_gross_salery_yearly': 'Bruto jaarsalaris',
    '#income_13th_month': '13e maand',
    '#income_end_of_year': 'Eindejaarsuitkering',
    '#income_irregular': 'Onregelmatigheidstoeslag',
    '#income_overtime': 'Overwerk',
    '#income_provision': 'Provisie',

    '#retirement_agency': 'Uitkeringsinstantie',
    '#retirement_aow_years': 'Op te bouwen AOW jaren',
    '#retirement_date': 'Verwachtte AOW-datum',
    '#retirement_type': 'Type',
    '#retirement_monthly_premium': 'Bruto pensioenbedrag per maand',
    '#retirement_saved': 'Opgebouwd pensioen',
    '#retirement_goal': 'Te bereiken pensioen',

    '#obligation_agency': 'Maatschappij',
    '#obligation_bank_account': 'Rekeningnummer',
    '#obligation_hoofdsom': 'Hoofdsom',
    '#obligation_original_debt': 'Start bedrag',
    '#obligation_monthly_amount': 'Maandbedrag',
    '#obligation_yearly_amount': 'Jaarbedrag',
    '#obligation_additional_redeemed': 'Extra afgelost bedrag',
    '#obligation_current_balance': 'Actueel saldo',
    '#obligation_current_balance_update': 'Saldo per',





    //obligations
    '#original_debt': 'Oorspronkelijk',
    '#rest_debt': 'Resterend',
    '#monthly_obligation': 'Maandelijke verplichting',
    '#Betaalrekening_met_roodstandmogelijkheid': 'Betaalrekening met roodstand',
    '#Persoonlijke_lening': 'Persoonlijke lening',
    '#Doorlopend_krediet': 'Doorlopend krediet',
    '#Studievoorschot': 'Studievoorschot',
    '#Studieschuld': 'Studieschuld',
    '#Alimentatie_ex-partner': 'Alimentatie ex-partner',

    //avatar
    '#pick_image': 'kies afbeelding',
    '#save': 'opslaan',

    //message
    '#subject': 'Onderwerp',
    '#message_to_advisor': 'Een bericht aan je adviseur..',
    '#message_to_advisor_u': 'Een bericht aan uw adviseur..',
    '#message_sent': 'Het bericht is verstuurd.\nJe adviseur zal zo snel mogelijk contact met je opnemen.',
    '#message_sent_u': 'Het bericht is verstuurd.\nJe adviseur zal zo snel mogelijk contact met u opnemen.',
    '#message_error': 'Het bericht is niet correct verstuurd.\nJe kunt het met de knop hieronder opnieuw proberen.',
    '#message_error_file_size': 'Het bericht is niet correct verstuurd.\nEen van de bijgevoegde documenten is te groot. De documenten mogen maximaal 2MB zijn.\nJe kunt het met de knop hieronder opnieuw proberen.',

    //damage modal
    '#damage_type_home': 'wonen',
    '#damage_type_vehicle': 'voertuig',
    '#damage_type_liability': 'aansprakelijkheid',
    '#damage_type_travel': 'reizen',
    '#damage_type_theft': 'diefstal',
    '#damage_type_other': 'overig',
    '#type_description': 'Welke schade heb je?',
    '#type_description_u': 'Welke schade heeft u?',
    '#date_description': 'Wanneer is de schade ontstaan?',
    '#cost_description': 'Wat is het (geschatte) schadebedrag?',
    '#damage_date': 'Wanneer is het gebeurd?',
    '#reason_description': 'Wat is er gebeurd?',

    '#document_claim_form': 'Schadeformulier',
    '#document_purchase_invoice': 'Aankoopnota',
    '#document_quote_repair_costs': 'Offerte reparatie',
    '#document_damage': 'Beschadigde zaken',
    '#document_other': 'Overig',
    '#document_drivers_license': 'Kopie rijbewijs',
    '#document_other_party': 'Gegevens tegenpartij',
    '#document_repairing_party': 'Gegevens schadehersteller',
    '#document_account_number_payment': 'Rekeningnummer voor uitbetaling',

    '#title_add_images': "Heb je documenten? Dan kun je die hieronder toevoegen.",
    '#title_add_images_u': "Heeft u documenten? Dan kunt u die hieronder toevoegen.",


    '#address': 'Adres',
    '#primary_living': 'primair woonadres',
    '#action_make_appointment': 'Afspraak plannen',
    '#action_make_appointment_online': 'plan online je afspraak in',
    '#action_make_appointment_online_u': 'plan online uw afspraak in',
    '#action_get_contact': 'neem contact op',
    '#preferred_date': 'Welke datum heeft jouw voorkeur?',
    '#preferred_date_u': 'Welke datum heeft uw voorkeur?',
    '#preferred_appointment_type': 'Wat wil je tijdens de afspraak graag bespreken?',
    '#preferred_appointment_type_u': 'Wat wilt u tijdens de afspraak graag bespreken?',
    '#appointment_category': 'selecteer categorie',
    '#appointment_type': 'selecteer afspraaktype',
    '#appointment_reason': 'Waar gaat de afspraak over?',
    '#my_appointments': "afspraken",
    '#cannot_create_appointment': 'Er kan geen afspraak gepland worden. Neem contact op met jouw adviseur om een afspraak te plannen.',
    '#cannot_create_appointment_u': 'Er kan geen afspraak gepland worden. Neem contact op met uw adviseur.',
    '#pakket': 'pakket',
    '#advisor_judge': 'ter beoordeling van je adviseur',
    '#advisor_judge_u': 'ter beoordeling van uw adviseur',

    '#undertitle_contact_1': 'Heb je vragen of een opmerking? Via ',
    '#undertitle_contact_1_u': 'Heeft u vragen of een opmerking? Via ',
    '#undertitle_contact_2': ' of ',
    '#undertitle_contact_3': ' helpen we je graag verder. Wil je meer ',
    '#undertitle_contact_3_u': ' helpen we u graag verder. Wilt u meer ',
    '#undertitle_contact_4': 'informatie lezen over onze dienstverlening? Bekijk dan onze ',
    '#route': 'Routebeschrijving',
    '#save_password': 'wachtwoord opslaan',
    '#title_change_password': 'Hier kun je je wachtwoord veranderen',
    '#title_change_password_u': 'Hier kunt u uw wachtwoord veranderen',
    '#current_password': 'Huidig wachtwoord',
    '#new_password': 'Nieuw wachtwoord',
    '#new_password_confirmation': 'Herhaal nieuw wachtwoord',
    '#new_password_equal': 'Beide wachtwoorden moeten gelijk zijn',
    '#password_changed': 'Je wachtwoord is succesvol veranderd.',
    '#password_changed_u': 'Uw wachtwoord is succesvol veranderd.',
    '#password_saved': 'Je wachtwoord is succesvol opgeslagen.',
    '#password_saved_u': 'Uw wachtwoord is succesvol opgeslagen.',
    '#password_changeError': 'Het is helaas niet geluk om je wachtwoord te veranderen.\nJe kunt het met de knop hieronder opnieuw proberen.',
    '#password_changeError_u': 'Het is helaas niet geluk om uw wachtwoord te veranderen.\nJe kunt het met de knop hieronder opnieuw proberen.',
    '#password_min_length': 'Het wachtwoord moet minstens 8 tekens lang zijn',

    //nieuwe    
    '#income_now': 'Inkomen',
    '#income_later': 'Pensioen',
    '#built_up_retirement': 'Opgebouwd pensioen',
    '#currently_open': 'we zijn open',
    '#currently_closed': 'we zijn gesloten',
    '#expires_on': 'Loopt af op',
    '#yes': 'Ja',
    '#no': 'Nee',
    '#close': 'sluiten',
    '#cost': 'Kosten',
    '#coverage': 'Dekking',
    '#data': 'Gegevens',
    '#insured_person': 'Verzekerd persoon:',
    '#policy_number': 'Polis nummer',
    '#advisor': 'adviseur',
    '#clear_cookies_1': 'Blijft dit gebeuren? Probeer dan het',
    '#clear_cookies_2': 'verwijderen dan je cookies',
    '#clear_cookies_2_u': 'verwijderen dan uw cookies',

    '#your_mortgages': 'Jouw hypotheken',
    '#mortgages_info': 'Hier vind je alle relevante informatie over je bestaande hypotheek.',
    '#mortgage_number': 'Hypotheek nummer',
    '#mortgage_parts': 'Leningdelen',
    '#mortgage_inschrijving': 'Hypothecaire inschrijving',
    '#mortgage_original_hoofdsom': 'Oorspronkelijke hypotheekhoofdsom',
    '#mortgage_rest_hoofdsom': 'Resterende hoofdsom van de hypotheek',
    '#mortgage_verhogen_zonder_notaris': 'Ruimte om hypotheek te verhogen zonder notaris',
    '#mortgage_vorm': 'Aflosvorm',
    '#mortgage_interest_type': 'Rentetype',
    '#mortgage_interest_percentage': 'Rentepercentage',
    '#mortgage_fixed_interest': 'rentevaste periode',
    '#mortgage_costs': 'Hypothekenkosten',

    '#title_living': 'Mijn woning',
    '#part': 'Deel',

    '#passeerdatum': 'Passeerdatum',
    '#einde_rentevast_periode': 'Einde rentevastperiode',
    '#einddatum': 'Einddatum',
    '#leningdeel': 'deel',

    '#inventarisatie': 'Inventarisatie',
    '#documenten_leveren': 'Documenten uploaden',
    '#action_documenten_leveren': 'upload documenten',
    '#rente_voorstel': 'Rentevoorstel aanvragen',
    '#beoordeling_geldverstrekker': 'Beoordeling door geldverstrekker',
    '#beoordeling_notaris': 'Beoordeling door notaris',

    '#inventarisatie_short': 'Start',
    '#documenten_leveren_short': 'Documenten',
    '#rente_voorstel_short': 'Voorstel',
    '#beoordeling_geldverstrekker_short': 'Geldverstrekker',
    '#beoordeling_notaris_short': 'Notaris',

    '#step': 'Stap',
    '#of': 'van',

    '#koopsom': 'Koopsom',
    '#marktwaarde': 'Marktwaarde',
    '#wozwaarde': 'WOZ waarde',
    '#bouwjaar': 'Bouwjaar',
    '#opp_woning': 'Oppervlakte woning',
    '#opp_perceel': 'Oppervlakte perceel',
    '#kale_huur': 'Kale huurprijs',
    '#service_kosten': 'Service kosten',
    '#other_kosten': 'Overige kosten',

    '#documents': 'Documenten',
    '#damage_amount': 'Schade bedrag',
    '#damage': 'schade',
    '#damage_payments': 'Uitbetalingen',
    '#damage_payment_to': 'uitbetaald aan',
    '#damage_payment_to_1': 'jou',
    '#damage_payment_to_1_u': 'u',
    '#damage_payment_to_2': 'de reperateur',
    '#damage_payment_to_3': 'de tegenpartij',
    '#damage_payment_on': 'op',



    '#total_retirement_title': 'Totaal aan pensioen',
    '#total_retirement_goal': 'Totaal te bereiken pensioen',
    '#total_retirement_accumulated': 'Totaal opgebouwd pensioen',
    '#build-up': 'OPGEBOUWD',

    '#house_build': 'Bouw',
    '#house_size': 'Oppervlakten',
    '#house_energy': 'Energie',
    '#house_type': 'Soort woning',
    '#house_buildyear': 'Bouwjaar',
    '#house_function': 'Functie',
    '#house_function_status': 'Functie status',
    '#house_rentable': 'Verhuurbaar',
    '#house_house': 'Woning',
    '#house_land': 'Perceel',
    '#house_solar': 'Zonnepanelen',
    '#house_smartmeter': 'Slimme meter',
    '#house_energy_label': 'Energielabel',
    '#house_energy_label_end_date': 'Energielabel geldig tot',

    '#image': 'Afbeelding',
    '#document': 'Document',

    '#action_hide_keyboard': 'Toetsenbord verbergen',

    '#sell': 'verkoopplannen',
    '#sustain': 'verduurzamen',
    '#appraise': 'taxeren',

    '#title_living_sell': 'Verkoopplannen voor een woning',
    '#title_living_sustain': 'Een woning verduurzamen',
    '#title_living_appraise': 'Een woning taxeren',

    '#undertitle_living_sell': 'Je hebt plannen om je woning te verkopen. Je adviseur kijkt graag met je naar de mogelijkheden.',
    '#undertitle_living_sustain': 'Je hebt plannen om je woning te verduurzamen. Je adviseur kijkt graag met je naar de mogelijkheden.',
    '#undertitle_living_appraise': 'Je wilt je woning graag laten taxeren? Je adviseur kijkt graag met je naar de mogelijkheden.',

    '#undertitle_living_sell_u': 'U heeft plannen om je woning te verkopen. Uw adviseur kijkt graag met u naar de mogelijkheden.',
    '#undertitle_living_sustain_u': 'U heeft plannen om je woning te verduurzamen. Uw adviseur kijkt graag met u naar de mogelijkheden.',
    '#undertitle_living_appraise_u': 'Je wilt je woning graag laten taxeren? Uw adviseur kijkt graag met u naar de mogelijkheden.',


    '#add_comment': 'Wil je meer informatie delen? Voeg dan een korte omschrijving toe',
    '#add_comment_sell': 'Kun je vast wat informatie delen over je plannen? Zo kunnen we ons goed voorbereiden op het gesprek.',
    '#add_comment_sustain': 'Kun je vast wat informatie delen over je plannen? Zo kunnen we ons goed voorbereiden op het gesprek.',
    '#add_comment_appraise': 'Kun je vast wat informatie delen? Zo kunnen we ons goed voorbereiden op het gesprek.',

    '#add_comment_u': 'Wilt u meer informatie delen? Voeg dan een korte omschrijving toe',
    '#add_comment_sell_u': 'Kunt u vast wat informatie delen over uw plannen? Zo kunnen we ons goed voorbereiden op het gesprek.',
    '#add_comment_sustain_u': 'Kunt u vast wat informatie delen over uw plannen? Zo kunnen we ons goed voorbereiden op het gesprek.',
    '#add_comment_appraise_u': 'Kunt u vast wat informatie delen? Zo kunnen we ons goed voorbereiden op het gesprek.',

    '#action_request': 'aanvragen',

    '#select_house_sell': 'Hieronder kun je de woning selecteren die je wilt verkopen',
    '#select_house_sustain': 'Hieronder kun je de woning selecteren die je wilt verduurzamen',
    '#select_house_appraise': 'Hieronder kun je de woning selecteren die je wilt taxeren',
    '#selected_house_sell': 'De woning die je wilt verkopen',
    '#selected_house_sustain': 'De woning die je wilt verduurzamen',
    '#selected_house_appraise': 'De woning die je wilt taxeren',

    '#select_house_sell_u': 'Hieronder kunt u de woning selecteren die u wilt verkopen',
    '#select_house_sustain_u': 'Hieronder kunt u de woning selecteren die u wilt verduurzamen',
    '#select_house_appraise_u': 'Hieronder kunt u de woning selecteren die u wilt taxeren',
    '#selected_house_sell_u': 'De woning die u wilt verkopen',
    '#selected_house_sustain_u': 'De woning die u wilt verduurzamen',
    '#selected_house_appraise_u': 'De woning die u wilt taxeren',

    '#damage_report': 'Schademelding',
    '#shared_documents': 'Aangeleverde documenten',

    '#input_fields_required': 'Niet alle verplichte velden zijn ingevuld.',
    '#input_fields_select_house': 'Vergeet je niet om een woning te selecteren?',
    '#input_fields_select_house_u': 'Vergeet u niet om een woning te selecteren?',

    '#mortgage_requested': 'Hypotheek in aanvraag',
    '#title_reset_password': 'Wachtwoord resetten',
    '#title_save_password': 'Wachtwoord instellen',
    '#action_goto_login': 'naar de inlog pagina',

    '#notifications_title': 'Sta notificaties toe',
    '#notifications_text': 'Wil je geen belangrijke berichten missen? Dan kun je hier de app instellingen openen om notificaties aan te zetten.',
    '#notifications_text_u': 'Wilt u geen belangrijke berichten missen? Dan kunt u hier de app instellingen openen om notificaties aan te zetten.',
    '#cancel': 'Annuleren',
    '#open_settings': 'Open instellingen',


    '#action_add_insurance': 'verzekering toevoegen',
    '#title_add_insurance': 'Een verzekering toevoegen',
    '#add_insurance_policy': 'Heb je ook verzekeringen afgesloten bij andere partijen? Upload dan hieronder je polisblad. Deze wordt dan gedeeld met je adviseur en de verzekering zal later binnen dit overzicht worden getoond.',
    '#add_insurance_policy_u': 'Heeft u ook verzekeringen afgesloten bij andere partijen? Upload hier uw polisblad. Deze wordt dan gedeeld met uw adviseur en de verzekering zal later binnen dit overzicht worden getoond.',
    '#policies_tenant': 'via je adviseur',
    '#policies_other': 'elders afgesloten',

    '#add_obligation_contract': 'Heb je ook verplichtingen die nog niet bekend zijn bij je adviseur? Upload dan hieronder je contract. Deze wordt dan gedeeld met je adviseur en de verplichting zal later binnen dit overzicht worden getoond.',
    '#add_obligation_contract_u': 'Heeft u ook verplichtingen die nog niet bekend zijn bij uw adviseur? Upload dan hieronder uw contract. Deze wordt dan gedeeld met uw adviseur en de verplichting zal later binnen dit overzicht worden getoond.',
    '#action_add_obligation': 'verplichting toevoegen',
    '#title_add_obligation': 'Een verplichting toevoegen',

    '#input_fields_document': 'Vergeet je niet om een document toe te voegen?',
    '#input_fields_document_u': 'Vergeet u niet om een document toe te voegen?',


    '#mortgage_verbouwen': 'verbouwen',
    '#mortgage_verhuizen': 'verhuizen',
    '#mortgage_hypotheekcheck': 'hypotheekcheck',
    '#mortgage_enrich': 'hypotheek aanpassen',
    '#mortgage_create': 'hypotheek toevoegen',

    '#title_mortgage_verhuizen': 'Een verhuizing doorgeven',
    '#title_mortgage_verbouwen': 'Een verbouwing doorgeven',
    '#title_mortgage_hypotheekcheck': 'Een hypotheekcheck aanvragen',

    '#undertitle_mortgage_verhuizen': 'Je hebt plannen om een ander huis te gaan kopen. Heb je een ander huis op het oog en zou je graag willen onderzoeken wat de mogelijkheden zijn om te verhuizen? Je adviseur kijkt tijdens een gesprek graag met je mee wat de opties zijn.',
    '#undertitle_mortgage_verbouwen': 'Je wilt graag verbouwen en bent benieuwd wat de mogelijkheden zijn voor het aanvragen van een bouwdepot, een lening of een wijziging binnen je hypotheek? Wellicht kun je de verbouwing zelf bekostigen. Je adviseur kijkt graag met je mee.',
    '#undertitle_mortgage_hypotheekcheck': 'Je hebt al een hypotheek, maar je wilt weten of deze beter of goedkoper kan? Je adviseur kijkt graag samen naar je hypotheek om te bepalen of die nog bij je wensen past.',

    '#undertitle_mortgage_verhuizen_u': 'U heeft plannen om een ander huis te gaan kopen. Heeft u een ander huis op het oog en zou u graag willen onderzoeken wat de mogelijkheden zijn om te verhuizen? Uw adviseur kijkt tijdens een gesprek graag met u mee wat de opties zijn.',
    '#undertitle_mortgage_verbouwen_u': 'U wilt graag verbouwen en bent benieuwd wat de mogelijkheden zijn voor het aanvragen van een bouwdepot, een lening of een wijziging binnen je hypotheek? Wellicht kunt u de verbouwing zelf bekostigen. Uw adviseur kijkt graag met u mee.',
    '#undertitle_mortgage_hypotheekcheck_u': 'U heeft al een hypotheek, maar u wilt weten of deze beter of goedkoper kan? Uw adviseur kijkt graag samen naar uw hypotheek om te bepalen of die nog bij uw wensen past.',

    '#add_comment_mortgage': 'Kun je vast wat informatie delen? Zo kunnen we ons goed voorbereiden op het gesprek.',
    '#add_comment_mortgage_u': 'Kunt u vast wat informatie delen? Zo kunnen we ons goed voorbereiden op het gesprek.',


    '#select_house_verhuizen': 'Hieronder kun je een woning selecteren die je wilt verbouwen',
    '#select_house_verbouwen': 'Hieronder kun je een woning selecteren waarvoor een verhuizing gepland staat',
    '#select_house_hypotheekcheck': 'Hieronder kun je een woning selecteren',
    '#select_house_verbouwen_u': 'Hieronder kunt u een woning selecteren die u wilt verbouwen',
    '#select_house_verhuizen_u': 'Hieronder kunt u een woning selecteren waarvoor een verhuizing gepland staat',
    '#select_house_hypotheekcheck_u': 'Hieronder kunt u een woning selecteren',

    '#selected_house_verbouwen': 'De woning die je wilt verbouwen',
    '#selected_house_verbouwen_u': 'De woning die u wilt verbouwen',
    '#selected_house_verhuizen': 'De woning waarvoor een verhuizing gepland staat',
    '#selected_house_hypotheekcheck': 'De geselecteerde woning',



    '#founded_on': 'Oprichtingsdatum',

    '#footer_text': 'Hypotheekwacht © ',
    '#footer_disclaimer1': 'Aan dit overzicht kunnnen geen rechten worden ontleend. ',
    '#footer_disclaimer2': 'De administatie van de verzekeraar en de geldverstrekker is altijd leidend.',

    '#no_results_bookable_times': 'Er zijn geen beschikbare tijden voor deze datum. Kies een andere datum.',
    '#send_appointment_error': 'Er is iets mis gegaan met het aanmaken van de afspraak.',
    '#made_appointment': `Afspraak is aangemaakt. In 'Mijn afspraken' kun je de afspraak terug vinden.`,
    '#made_appointment_u': `Afspraak is aangemaakt. In 'Mijn afspraken' kunt u de afspraak terug vinden.`,
    '#appointment_with': 'Afspraak met:',
    '#appointment_on': 'Afspraak op:',
    '#appointment_description': 'Afspraak omschrijving:',

    '#cancel_appointment_title': 'Afspraak annuleren',
    '#cancel_appointment_text': 'Weet je zeker dat je de afspraak wilt annuleren?',
    '#cancel_appointment_text_u': 'Weet u zeker dat u de afspraak wilt annuleren?',

    '#after_successful_login': 'Je bent succesvol ingelogd.\nWe laden je gegevens.',
    '#after_successful_login_u': 'U bent succesvol ingelogd.\nWe laden uw gegevens.',

    '#loading_mortgage_data': 'Je hypotheekgegevens worden op dit moment geladen.\nGraag een ogenblik geduld...',
    '#loading_mortgage_data_u': 'Uw hypotheekgegevens worden op dit moment geladen.\nGraag een ogenblik geduld...',

    '#loading_policy_data': 'Je verzekeringsgegevens worden op dit moment geladen.\nGraag een ogenblik geduld...',
    '#loading_policy_data_u': 'Uw verzekeringsgegevens worden op dit moment geladen.\nGraag een ogenblik geduld...',

    '#loading_living_data': 'Je woninggegevens worden op dit moment geladen.\nGraag een ogenblik geduld...',
    '#loading_living_data_u': 'Uw woninggegevens worden op dit moment geladen.\nGraag een ogenblik geduld...',

    '#recently_shared_documents': 'Gedeelde documenten',

    '#living_address': 'Woonadres',

    '#install_update': 'Er is een nieuwe versie beschikbaar, wil je het klantportaal nu updaten?',
    '#install_update_u': 'Er is een nieuwe versie beschikbaar, wilt u het klantportaal nu updaten?',

    '#times_available': 'Tijd(en) beschikbaar',

    '#select_account': 'Selecteer een account',
    '#login_other_account': 'Account wisselen',

    '#change_avatar': 'Profielfoto wijzigen',
    '#change_info': 'Gegevens wijzigen',
    '#change_password': 'Wachtwoord wijzigen',
    '#status': 'Status',

    '#action_remove': 'verwijder',
    '#remove_document_text': 'Weet je zeker dat je het document wilt verwijderen?',
    '#remove_document_text_u': 'Weet je zeker dat u het document wilt verwijderen?',

    '#removing_document': 'Je kunt documenten verwijderen tot een uur na het uploaden.',
    '#removing_document_u': 'U kunt documenten verwijderen tot een uur na het uploaden.',

    '#message_to_reply_to': 'Het bericht waar je op reageert',
    '#message_to_reply_to_u': 'Het bericht waar u op reageert',

    '#action_read_more': 'lees meer',

    '#repayment_type_1': 'Annuïteit',
    '#repayment_type_2': 'Lineair',
    '#repayment_type_3': 'Leven',
    '#repayment_type_5': 'Aflossingsvrij',
    '#repayment_type_9': 'Krediet of bankhypotheek',
    '#repayment_type_10': 'Beleggingshypotheek',
    '#repayment_type_13': 'Spaarrekening',
    '#repayment_type_50': 'Banksparen',
    '#repayment_type_51': 'Bausparen',
    '#repayment_type_52': 'Starterslening',
    '#repayment_type_53': 'Spaarhypotheek',

    '#services_title': 'Diensten voor jou',
    '#services_description': 'Verplichtingen zoals leningen en kredieten zijn financiële afspraken waarbij geld wordt geleend met de verplichting om het bedrag met rente terug te betalen binnen een overeengekomen periode. Wij onderscheiden een verplichting van een hypotheek omdat dit een algemene schuld betreft, waar een hypotheek een specifieke lening is voor de aankoop van een woning, en wordt gedekt door het onderpand.'
}
