import { QueryKeys } from "enums";
import { useNewMessageCount, useStyle } from "hooks";
import { Image, Modal, Platform, Pressable, View } from "react-native";
import { useQuery } from "react-query";
import { colors } from "styles";
import { CustomerUtils, QueryUtils } from "utils";
import Badge from "./Badge";
import TText from "./TText";
import { AvatarCard, IconCard } from "./Card";
import { useState } from "react";
import { CustomerDrawer } from "screens/navigation";

export default () => {

    const styles = useStyle();
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const newMessages = useNewMessageCount();
    const badgeValue = newMessages?.totalNew;    

    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <>
            <Pressable 
                onPress={() => setDrawerOpen(!drawerOpen)} 
                style={[
                    styles.flexRow, styles.gap10, styles.alignCenter, styles.p10, styles.justifyEnd, styles.borderRadius10,
                    { backgroundColor: colors.white, minWidth: 200}
                ]}
            >
                <IconCard iconName="IconChevronLeft" iconColor={colors.primary} style={{backgroundColor: colors.transparent}} />
                
                <TText 
                semibold 
                primary 
                sm 
                style={[
                    styles.flexStretch,
                    Platform.OS == 'web' && { userSelect: 'none' }
                ]}>
                    {CustomerUtils.getFullName(customer)}
                </TText>
                
                <View>
                    <AvatarCard avatar={customer?.avatar}/>
                    {badgeValue > 0 && <Badge sm value={badgeValue} style={{ position: "absolute", right: -10, top: -4, backgroundColor: 'red' }} />}
                </View>
            </Pressable>
            <CustomerDrawer
                visible={drawerOpen}
                onClose={()=>setDrawerOpen(!drawerOpen)}
            />
        </>
    )
}
