import { StyleSheet, Image, View } from 'react-native';
import colors from 'styles/colors';


export default () => {
    return (
        <View style={scopedStyle.header}>
            <Image
                source={require('assets/images/header.png')}
                style={scopedStyle.image}
                resizeMode='contain'
            />
        </View >
    );
};

const scopedStyle = StyleSheet.create({
    header: {
        height: 60,
        backgroundColor: colors.white,
    },
    image: {
        width: '100%',
        height: '100%',
    }
})