
import { Advisor } from "./Advisor";

export class Customer {
    id: string;
    salutation: string;
    full_name: string;
    initials: string;
    first_name: string;
    official_first_names: string;
    suffix: string;
    last_name: string;
    username: string;
    date_of_birth: string;
    age: number;
    avatar: string;
    sex: number;
    place_of_birth: string;
    smoking: number;
    education_level: unknown;
    email_address: string;
    phone: string;
    mobile: number;
    phone_work: number;
    office_id: string;
    retirement_date: number;
    iban: string;
    bank_number: string;
    support_types: unknown;
    marital_status: number;
    kvk: number;
    marital_status_description: string;
    marital_date: number;
    married_before: number;
    marriage_dissolved_at: number;
    marriage_dissolved_type: string;
    marriage_dissolved_type_description: string;
    is_main_customer: boolean;
    is_business: boolean;
    addresses: any;
    nationality: any;
    invitation: any;

    gross_annual_income: number;
    gross_annual_old_age_pension_amount: number;
    gross_annual_employment_pension_amount: number;
    gross_annual_annuity_amount: number;
    gross_annual_half_orphan_benefit_amount: number;
    gross_annual_partner_pension_amount: number;

    can_update_self: boolean;
    can_update_partner: boolean;


    /** RELATIONS **/
    advisor?: Advisor;
    account_manager?: Advisor;


    constructor(json?: any) {
        this.fromJson(json);
    }

    /* - - - - - - - - - TRANSFORMERS - - - - - - - - -  */

    fromJson(json: any): Customer {
        this.id = json.id ? json.id : "";
        this.salutation = json.salutation ? json.salutation : "";
        this.full_name = json.full_name ? json.full_name : "";
        this.initials = json.initials ? json.initials : "";
        this.first_name = json.first_name ? json.first_name : "";
        this.official_first_names = json.official_first_names ? json.official_first_names : "";
        this.suffix = json.suffix ? json.suffix : "";
        this.last_name = json.last_name ? json.last_name : "";
        this.username = json.username ? json.username : "";
        this.date_of_birth = json.date_of_birth ? json.date_of_birth : '';
        this.age = json.age ? json.age : 0;
        this.sex = json.sex ? json.sex : 0;
        this.place_of_birth = json.place_of_birth ? json.place_of_birth : "";
        this.smoking = json.smoking ? json.smoking : 0;
        this.education_level = json.education_level ? json.education_level : 0;
        this.email_address = json.email_address ? json.email_address : "";
        this.phone = json.phone ? json.phone : '';
        this.mobile = json.mobile ? json.mobile : '';
        this.phone_work = json.phone_work ? json.phone_work : '';
        this.office_id = json.office_id ? json.office_id : "";
        this.retirement_date = json.retirement_date ? json.retirement_date : '';
        this.username = json.username ? json.username : "";
        this.iban = json.iban ? json.iban : "";
        this.bank_number = json.bank_number ? json.bank_number : "";
        this.support_types = json.support_types ? json.support_types : "";
        this.marital_status = json.marital_status ? json.marital_status : 0;
        this.kvk = json.kvk ? json.kvk : 0;
        this.marital_status_description = json.marital_status_description ? json.marital_status_description : "";
        this.marital_date = json.marital_date ? json.marital_date : '';
        this.married_before = json.married_before ? json.married_before : '';
        this.marriage_dissolved_at = json.marriage_dissolved_at ? json.marriage_dissolved_at : '';
        this.marriage_dissolved_type = json.marriage_dissolved_type ? json.marriage_dissolved_type : "";
        this.marriage_dissolved_type_description = json.marriage_dissolved_type_description ? json.marriage_dissolved_type_description : "";
        this.is_main_customer = json.is_main_customer ? json.is_main_customer : "";
        this.is_business = json.is_business ? json.is_business : "";
        this.addresses = json.addresses ? json.addresses.data : [];
        this.nationality = json.nationality ? json.nationality.data : [];
        this.avatar = json.avatar.data ? json.avatar.data.content : '';
        this.invitation = json?.invitation?.data?.token ? json?.invitation?.data : ''

        this.gross_annual_income = json.gross_annual_income ? json.gross_annual_income : 0;
        this.gross_annual_old_age_pension_amount = json.gross_annual_old_age_pension_amount ? json.gross_annual_old_age_pension_amount : 0;
        this.gross_annual_employment_pension_amount = json.gross_annual_employment_pension_amount ? json.gross_annual_employment_pension_amount : 0;
        this.gross_annual_annuity_amount = json.gross_annual_annuity_amount ? json.gross_annual_annuity_amount : 0;
        this.gross_annual_half_orphan_benefit_amount = json.gross_annual_half_orphan_benefit_amount ? json.gross_annual_half_orphan_benefit_amount : 0;
        this.gross_annual_partner_pension_amount = json.gross_annual_partner_pension_amount ? json.gross_annual_partner_pension_amount : 0;

        this.can_update_self = json.can_update_self ? json.can_update_self : false;
        this.can_update_partner = json.can_update_partner ? json.can_update_partner : false;

        this.advisor = json?.advisor?.data ? new Advisor(json.advisor.data) : null;
        this.account_manager = json?.account_manager?.data ? new Advisor(json.account_manager.data) : null;

        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId(),
        };
    }

    /* - - - - - - - - - ACCESSORS - - - - - - - - -  */

    getId(): string {
        return this.id;
    }
}
