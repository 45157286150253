import mapHtml from 'assets/html/maps';
import {Button, Icon, PlatformWebview, TText} from "components";
import {MessageModal} from "components/Modals";
import {QueryKeys, TenantShowCategoryKeys} from 'enums';
import Constants from "expo-constants";
import {useStyle} from "hooks";
import {ModalContext} from "providers/ModalProvider";
import React, {useContext} from "react";
import {Image, useWindowDimensions, View} from "react-native";
import {useQuery} from "react-query";
import {CreateResponsiveStyle, DEVICE_SIZES, maxSize} from "rn-responsive-styles";
import {colors} from "styles";
import {QueryUtils} from "utils";
import {AvatarCard, Card} from 'components/Card';
import PageContainer from "components/PageContainer";
import ContactForm from "components/ContactForm";
import OnlineAfspraakModal from 'components/Modals/OnlineAfspraakModal';

const Config = Constants.expoConfig.extra.config;

export default () => {

    const {width} = useWindowDimensions();
    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const {showModal} = useContext(ModalContext);
    const {data: customer} = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const {data: tenant} = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);
    const office = tenant?.offices?.filter(office => office.id == customer?.office_id)[0] || tenant?.offices?.length > 0 ? tenant?.offices[0] : null;

    const largeScreen = width > 768;
    const mapURL = encodeURI(office ? `https://maps.google.com/maps?q=${office.street_name} ${office.house_number}${office.house_number_addition}, ${office.city}&t=&z=16&ie=UTF8&iwloc=&output=embed` : `https://maps.google.com/maps?q=Noorderhaven Z.z. 46, Groningen&t=&z=16&ie=UTF8&iwloc=&output=embed`);
    const streetviewURL = encodeURI(office ? `${Config.PROXY_URL}/streetview?location=${office.street_name} ${office.house_number}${office.house_number_addition}, ${office.city}&size=600x400` : '');

    const renderMap = () => {

        return (
            <View>
                <PlatformWebview
                    html={mapHtml}
                    replaceVars={[
                        ['MAPURL', mapURL]
                    ]}
                    style={{minHeight: 350, borderRadius: 12}}
                />
                <View style={scopedStyles.mapLogo}>
                    <Image
                        source={tenant?.logo ? { uri: 'data:image/png;base64,' + tenant?.logo } : {}}
                        resizeMode='contain'
                        style={{width: 350, height: 350}}
                    />
                    <View style={{position: 'absolute', top: '75%', left: 20}}>
                        <TText sm semibold>{`${office?.street_name || ''} ${office?.house_number || ''}${office?.house_number_addition || ''}`}</TText>
                        <TText sm semibold>{`${office?.postcode || ''}${office?.city ? ', ' + office?.city : ''}`}</TText>
                    </View>
                </View>
            </View>
        )
    }

    const renderAdvisor = () => {
        return (
            <Card style={[scopedStyles.advisorContainer, {
                flex: width < 1200 ? 1 : 0.40,                
                backgroundColor: colors.primary,
            }]}>

                {/* Streetview */}
                <Image
                    source={{uri: streetviewURL}}
                    style={scopedStyles.streetView}
                />

                {/* Adviseur */}
                {customer?.advisor &&
                    <View>
                        <View style={[scopedStyles.advisorElement]}>

                            {customer?.advisor?.avatar ? (
                                <Image
                                    source={{uri: `data:image/png;base64,${customer?.advisor?.avatar}`}}
                                    width={largeScreen ? 120 : 90}
                                    height={largeScreen ? 120 : 90}
                                    style={{
                                        width: largeScreen ? 120 : 90,
                                        height: largeScreen ? 120 : 90,
                                        borderRadius: largeScreen ? 90 : 60
                                    }}
                                />
                            ) : (
                                <Icon iconName='IconUserFilled' iconSize={largeScreen ? 120 : 60} iconColor={''}/>
                            )}

                            <View style={scopedStyles.advisorContact}>
                                <TText white sm semibold style={{marginBottom: 10}}>Jouw adviseur</TText>

                                <TText white sm semibold>{customer?.advisor?.full_name}</TText>
                                <TText white xs regular>{customer?.advisor?.email}</TText>
                                <TText white xs regular>{customer?.advisor?.phone}</TText>
                            </View>

                        </View>

                        {/* <View style={[scopedStyles.advisorButton]}>
                            <Button
                                label="#action_get_contact"
                                type="secondary"
                                onPress={() => showModal(MessageModal, {contact: customer.advisor}, 'MessageModal')}
                            />
                        </View> */}
                    </View>
                }


                {/* Behandelaar (account manager) */}
                {(tenant && tenant[TenantShowCategoryKeys.AccountManager] !== false) && customer?.account_manager &&
                    <View>
                        <View style={[scopedStyles.advisorElement]}>

                            {/* { ? (
                                <Image
                                    source={{ uri: `data:image/png;base64,${customer?.account_manager?.avatar}` }}
                                    width={largeScreen ? 180 : 120}
                                    height={largeScreen ? 180 : 120}
                                    style={{
                                        width: largeScreen ? 180 : 120,
                                        height: largeScreen ? 180 : 120,
                                        borderRadius: largeScreen ? 90 : 60
                                    }}
                                />
                            ) : (

                            )} */}
                            <AvatarCard avatar={customer?.account_manager?.avatar}/>

                            <View style={scopedStyles.advisorContact}>
                                <TText white sm semibold style={{marginBottom: 10,}}>Jouw account manager</TText>

                                <TText white sm semibold>{customer?.account_manager?.full_name}</TText>
                                <TText white xs regular>{customer?.account_manager?.email}</TText>
                                <TText white xs regular>{customer?.account_manager?.phone}</TText>
                            </View>
                        </View>                        
                    </View>
                }

                {tenant && tenant[TenantShowCategoryKeys.Afspraak] &&
                            <View style={{marginTop: 20, flexDirection: 'row'}}>
                                {/* <Button
                                    label="#action_get_contact"
                                    type="secondary"
                                    onPress={() => showModal(MessageModal, {contact: customer.account_manager}, 'MessageModal')}
                                /> */}
                                <Button
                                    label="#action_make_appointment_online"
                                    type="secondary"
                                    onPress={() => showModal(OnlineAfspraakModal, {}, 'OnlineAfspraakModal')}
                                    style={{ marginTop: 8 }}
                                />
                            </View>
                        }
            </Card>
        )
    }

    return (
        <PageContainer>

            <View style={[width > 1200 ? styles.flexRowDynamic : styles.flexCol, styles.gap30]}>

                <ContactForm />

                {renderAdvisor()}

            </View>

            <View>

                {renderMap()}

            </View>

        </PageContainer>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        container: {
            flex: 1,
            height: '100%',
            paddingTop: 20,
            paddingHorizontal: 30
        },
        streetView: {
            height: 300,
            borderRadius: 12
        },
        noteText: {
            backgroundColor: colors.background,
            flexGrow: 1,
            marginBottom: 10,
            padding: 8,
            borderRadius: 8,
            verticalAlign: "top",
        },
        mapLogo: {
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 10,
            backgroundColor: 'white',
            borderRadius: 12,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            height: 350,
            width: 350,
        },
        mapContainer: {
            position: 'absolute',
            right: 0,
            top: 0,
            height: '100%',
            width: '40%',
            borderRadius: 12,
            overflow: 'visible',
            zIndex: 1
        },
        advisorContainer: {
            minWidth: 400,
            gap: 10
        },
        advisorElement: {
            flexDirection: 'row',
            paddingTop: 20,
        },
        advisorContact: {
            justifyContent: "center",
            marginLeft: 20,
        },
        advisorButton: {
            marginTop: 30,
        }
    }, {

        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            advisorContainer: {
                flexDirection: 'column'
            },
            advisorButton: {
                marginTop: 15,
                marginVertical: 15,
            },
        },
        [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
            advisorContainer: {
                flexDirection: 'column'
            },
            advisorElement: {
                height: 'auto',
                width: 360,
            },
            mapLogo: {
                display: 'none',
            },
        },

        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            mapLogo: {
                display: 'none',
            },
            mapContainer: {
                position: 'relative',
                width: '100%',
                height: 'auto',
                marginBottom: 25
            },
            advisorContainer: {
                minWidth: 0,
                flexDirection: 'column',
                width: '100%'
            },
            advisorElement: {
                flex: 1,
                width: '100%',
                height: 'auto',
                flexDirection: 'row',
                alignItems: 'center',
                paddingBottom: 12,
            },
            advisorContact: {
                marginTop: 0,
                justifyContent: "space-between"
            },
        }
    }
)
