import { Carousel, Footer } from "components";
import { QueryKeys, TenantShowCategoryKeys } from "enums";
import { useStyle } from "hooks";
import React, { useState } from "react";
import { ScrollView, StyleSheet, View, useWindowDimensions } from "react-native";
import { useQuery } from "react-query";
import { colors } from "styles";
import { QueryUtils } from "utils";
import SignalItems from "./SignalItems";



export default () => {

    const { width } = useWindowDimensions();
    const styles = useStyle();
    const { data: tenant } = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);

    const showPolicies = tenant[TenantShowCategoryKeys.Polissen];
    const showMortgages = tenant[TenantShowCategoryKeys.Hypotheken];
    const showMaps = !tenant[TenantShowCategoryKeys.Polissen] && !tenant[TenantShowCategoryKeys.Hypotheken];

    const getLayout = (height: number) => ({
        width: width * 0.88,
        marginHorizontal: width * 0.03,
        pageWidth: width * 0.94,
        height: height * 0.64 - 30
    })
    const [infoViewLayout, setInfoViewLayout] = useState(getLayout(300));
    const setDynamicInfoView = (e: any) => {
        setInfoViewLayout(getLayout(e.nativeEvent.layout.height))
    }

    return (
        <ScrollView
            style={scopedStyles.container}
            onLayout={setDynamicInfoView}
            showsVerticalScrollIndicator={false}
        >
            {/* <View
                style={[
                    scopedStyles.gradientContainer,
                    { height: 'auto' }
                ]}
            >
                <Carousel
                    paginated={!showMaps}
                    pageWidth={showMaps ? undefined : infoViewLayout.pageWidth}
                    style={styles.fullscreen}
                >
                    <LivingOverview style={[scopedStyles.infoView, infoViewLayout]} showMaps={showMaps}/>
                    { showMortgages && <MortgageOverview style={[scopedStyles.infoView, infoViewLayout]} /> }
                    { showPolicies && <PolicyOverview style={[scopedStyles.infoView, infoViewLayout]} /> }
                </Carousel>
            </View> */}


            <SignalItems
                itemStyle={scopedStyles.scrollItem}
            />

            <View style={{ height: 50 }}></View>

            <Footer style={{ bottom: 4 }} textStyle={{ color: colors.black }} />

        </ScrollView>
    )
}

const scopedStyles = StyleSheet.create({
    container: {
        width: '100%',
        flex: 1,
        backgroundColor: colors.background
    },
    gradientContainer: {
        position: 'relative',
        width: '100%'
    },
    infoView: {
        minHeight: 400,
        marginVertical: 30,
        paddingVertical: 12,
        paddingHorizontal: 20,
        borderRadius: 8,
        backgroundColor: colors.blackTransparent
    },
    scrollView: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 100,
    },
    scrollItem: {
        minHeight: 310,
        marginTop: 30,
        marginHorizontal: '5%',
        marginBottom: 0,
    }
})