import { Button, IconTitle } from "components";
import { TText } from "components";
import { DateTimeFormat, IncomeTypes } from "enums";
import { useStyle } from "hooks";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { useContext } from "react";
import { Dimensions, View } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { ValutaUtils } from "utils";
import BaseModal from "./BaseModal";



export default ({ settings }) => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const { hideModal } = useContext(ModalContext);
    const largeScreen = Dimensions.get('window').width > 992;


    const incomes = settings.incomes;
    incomes.map((income, index) => {
        switch (income.income_type) {
            case IncomeTypes.benefit:
                incomes[index].customDescriptions = [
                    { title: '#income_type', value: income.benefit_type },
                    { title: '#start_date', value: moment(income.start_date).format(DateTimeFormat.DISPLAY_DATE) },
                    { title: '#end_date', value: moment(income.end_date).format(DateTimeFormat.DISPLAY_DATE) }
                ]
                incomes[index].customIncome = [
                    { title: '#income_gross_yearly', value: ValutaUtils.valutaString(income.gross_annual_allowance) },
                    { title: '#income_holiday_bonus', value: ValutaUtils.valutaString(income.holiday_bonus) },
                ]
                break;

            case IncomeTypes.company:
                incomes[index].customDescriptions = [
                    { title: '#company_name', value: income.name },
                    { title: '#company_employes', value: income.has_personnel ? '#yes' : '#no' },
                    { title: '#company_start_date', value: moment(income.start_date).format(DateTimeFormat.DISPLAY_DATE) },
                ]
                incomes[index].customIncome = [
                    { title: `#income_net_profit ${moment().subtract(1, 'year').format(DateTimeFormat.YEAR)}`, value: ValutaUtils.valutaString(income.income_first_year) },
                    { title: `#income_net_profit ${moment().subtract(2, 'year').format(DateTimeFormat.YEAR)}`, value: ValutaUtils.valutaString(income.income_second_year) },
                    { title: `#income_net_profit ${moment().subtract(3, 'year').format(DateTimeFormat.YEAR)}`, value: ValutaUtils.valutaString(income.income_third_year) },
                    { title: `#income_net_profit_total`, value: ValutaUtils.valutaString(income.independent_income) },
                ]
                break;

            case IncomeTypes.employment:
                incomes[index].customDescriptions = [
                    { title: '#employment_name', value: income.employer_name },
                    { title: '#employment_city', value: income.employer_city },
                    { title: '#employment_start_service', value: moment(income.start_date).format(DateTimeFormat.DISPLAY_DATE) },
                    income.temporary_contract_end_date && { title: '#employment_end_service', value: moment(income.temporary_contract_end_date).format(DateTimeFormat.DISPLAY_DATE) },
                    { title: '#employment_function', value: income.function },
                    { title: '#employment_fulltime', value: income.has_full_time_contract ? '#yes' : '#no' },
                    { title: '#employment_hours_per_week', value: income.hours_per_week },
                ]
                incomes[index].customIncome = [
                    { title: '#income_gross_salery_yearly', value: ValutaUtils.valutaString(income.gross_annual_income) },
                    { title: '#income_holiday_bonus', value: ValutaUtils.valutaString(income.holiday_bonus) },
                    { title: '#income_13th_month', value: ValutaUtils.valutaString(income.fixed_13th_month) },
                    { title: '#income_end_of_year', value: ValutaUtils.valutaString(income.fixed_end_year_benefit) },
                    { title: '#income_irregular', value: ValutaUtils.valutaString(income.irregularity_bonus) },
                    { title: '#income_overtime', value: ValutaUtils.valutaString(income.overtime_per_year) },
                    { title: '#income_provision', value: ValutaUtils.valutaString(income.provision_per_year) },
                    { title: '#total_small', value: income.description ? "€ " + income.description.split(' € ')[1] : '' },
                ]
                break;

            case IncomeTypes.other:
                incomes[index].customDescriptions = [
                    { title: '#income_type', value: income.type_description },
                    income.end_date && { title: '#start_date', value: moment(income.start_date).format(DateTimeFormat.DISPLAY_DATE) },
                ]
                incomes[index].customIncome = [
                    { title: '#income_gross_yearly', value: ValutaUtils.valutaString(income.gross_annual_income) },
                ]
                break;

            case IncomeTypes.retirement:
                incomes[index].customDescriptions = [
                    income.authority && { title: '#retirement_agency', value: income.authority },
                    income.agency && { title: '#retirement_agency', value: income.agency },
                    income.aow_years && { title: '#retirement_aow_years', value: income.aow_years },
                    income.start_date && { title: '#retirement_date', value: moment(income.start_date).format(DateTimeFormat.DISPLAY_DATE) },
                    income.type && { title: '#retirement_type', value: income.type }
                ]
                incomes[index].customIncome = [
                    income.monthly_premium && { title: '#retirement_monthly_premium', value: ValutaUtils.valutaString(income.monthly_premium) },
                    { title: '#retirement_saved', value: ValutaUtils.valutaString(income.accumulated_pension_amount || income.accumulated_amount) },
                    { title: '#retirement_goal', value: ValutaUtils.valutaString(income.target_pension_amount || income.gross_annual_allowance) },
                ]
                break;
        }
    })


    const renderForm = () => {

        return (
            <View style={scopedStyles.container}>

                <View style={{ flex: 1, marginBottom: 20 }}>

                    <IconTitle
                        iconName={settings.iconName}
                        title={settings.title}
                    >
                        <TText lg semibold>{ValutaUtils.valutaString(settings.total)}</TText>
                        <TText md> #p/j</TText>
                    </IconTitle>

                    {largeScreen ? renderLargeScreenParts() : renderSmallScreenParts()}

                </View>


                <View style={{ height: 100, justifyContent: 'center', alignItems: "flex-end" }}>

                    <View style={{ flexDirection: "row" }}>
                        <Button
                            label='#action_close'
                            type={"secondary"}
                            onPress={() => hideModal()}
                        />
                    </View>

                </View>

            </View >
        )
    }

    const renderLargeScreenParts = () => {

        return (
            <View style={{ flex: 1, justifyContent: 'center' }}>
                {
                    incomes.map((income, index) => {
                        return (
                            <View key={'p' + index} style={[scopedStyles.dataForm]}>

                                <View style={scopedStyles.dataFormParts}>

                                    <TText sm black semibold style={[scopedStyles.nItem, { borderTopLeftRadius: 4, borderTopRightRadius: largeScreen ? 0 : 4 }]}>#description</TText>

                                    <View style={[{ paddingLeft: 8 }, { marginRight: 8 }]}>
                                        {income.customDescriptions.map((desc, index) => {
                                            return desc && (
                                                <View key={'d' + index} style={{ flexDirection: 'row', justifyContent: "space-between", paddingVertical: 4 }}>
                                                    <TText sm black semibold>{desc.title}</TText>
                                                    <TText sm grey semibold>{desc.value}</TText>
                                                </View>
                                            )
                                        })}
                                    </View>

                                </View>

                                <View style={scopedStyles.dataFormParts}>

                                    <TText sm black semibold style={[scopedStyles.nItem, { borderTopRightRadius: 4, borderTopLeftRadius: largeScreen ? 0 : 4 }]}>#income</TText>

                                    <View style={{ paddingHorizontal: 8 }}>
                                        {income.customIncome.map((desc, index) => {
                                            return desc && (
                                                <View key={'i' + index} style={{ flexDirection: 'row', justifyContent: "space-between", paddingVertical: 4 }}>
                                                    <TText oneline sm black style={{ flex: 1 }}>{desc.title}</TText>
                                                    <TText sm grey semibold>{desc.value}</TText>
                                                </View>
                                            )
                                        })}
                                    </View>

                                </View>

                            </View>
                        );
                    })
                }
            </View>
        );

    }


    const renderSmallScreenParts = () => {

        return (
            <View style={{ flex: 1, justifyContent: 'center' }}>
                {incomes.map((income, index) => {
                    return (
                        <View key={'sp' + index} style={{ marginBottom: 20 }}>

                            <TText sm black semibold style={[scopedStyles.nItem, { borderTopLeftRadius: 4, borderTopRightRadius: 4 }]}>#description</TText>

                            <View style={[{ paddingLeft: 8, paddingRight: 8, backgroundColor: colors.white }]}>
                                {income.customDescriptions.map((desc, index) => {
                                    return desc && (
                                        <View key={'sd' + index} style={{ paddingVertical: 4 }}>
                                            <TText sm black semibold>{desc.title}</TText>
                                            <TText sm grey semibold>{desc.value}</TText>
                                        </View>
                                    )
                                })}
                            </View>

                            <TText sm black semibold style={[scopedStyles.nItem, { borderTopRightRadius: 4, borderTopLeftRadius: 4 }]}>#income</TText>

                            <View style={[{ paddingLeft: 8, paddingRight: 8, backgroundColor: colors.white }]}>
                                {income.customIncome.map((desc, index) => {
                                    return desc && (
                                        <View key={'snd' + index} style={{ paddingVertical: 4 }}>
                                            <TText sm black style={{ flex: 1 }}>{desc.title}</TText>
                                            <TText sm grey semibold>{desc.value}</TText>
                                        </View>
                                    )
                                })}
                            </View>

                        </View>
                    )
                })}
            </View>
        );

    }


    return (
        <BaseModal style={scopedStyles.modal}>

            {renderForm()}

        </BaseModal>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            width: '50%',
            height: 'auto',
            minHeight: 0,
            minWidth: 900,
            marginTop: 120,
            backgroundColor: colors.background,
            borderRadius: 8,
            overflow: 'visible'
        },
        container: {
            height: '100%',
            paddingTop: 20,
            paddingHorizontal: 30
        },
        titel: {
            marginVertical: 30
        },
        noteText: {
            backgroundColor: colors.lightgrey,
            flexGrow: 1,
            marginBottom: 30,
            padding: 8,
            borderRadius: 8,
            verticalAlign: "top",
            borderColor: colors.grey,
            borderWidth: 1
        },
        dataForm: {
            width: '100%',
            flexDirection: 'row',
            marginBottom: 20
        },
        dataFormParts: {
            flex: 1,
            backgroundColor: colors.white,
            borderRadius: 4
        },
        nItem: {
            backgroundColor: colors.formDecoration,
            paddingVertical: 12,
            paddingLeft: 8
        }
    },
    {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            form: {
                width: '65%'
            }
        },
        [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
            modal: {
                minWidth: 'auto',
                width: '70%',
            },
            dataForm: {
                width: '100%',
                flexDirection: 'column'
            },
            dataFormParts: {
                flex: 1,
                height: 'auto',
                marginBottom: 20
            }
        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
            },
            titel: {
                marginBottom: 20,
                marginTop: 0
            },
        }
    }
)