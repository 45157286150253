
export class HDN_Company_Label {
    id: number;
    company_id: number;
    hdn_code: string;
    max_hdn_version: string;
    min_hdn_version: string;
    name: string;
    product_id: number;

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): HDN_Company {
        this.id = json.id ? json.id : "";
        this.company_id = json.company_id ? json.company_id : "";
        this.hdn_code = json.hdn_code ? json.hdn_code : "";
        this.max_hdn_version = json.max_hdn_version ? json.max_hdn_version : "";
        this.min_hdn_version = json.min_hdn_version ? json.min_hdn_version : "";
        this.name = json?.name ? json.name : "";
        this.product_id = json?.product_id ? json.product_id : "";
        return this;
    }

    getOption(){
        return {
            label: this.name,
            value: this.id
        }
    }
}
