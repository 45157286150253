import { Button, TText, SecureTextInput } from "components";
import { useFormik } from "formik";
import { useStyle, useTranslation } from "hooks";
import { useState } from "react";
import { Dimensions, Platform, View } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { AuthUtils } from "utils";
import { object, ref, string } from "yup";


export default () => {

    const styles = useStyle();
    const translate = useTranslation();
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const changePasswordSchema = object({
        currentPassword: string().required('#login_error_password'),
        newPassword: string().required('#login_error_password').min(8, '#password_min_length'),
        newPasswordConfirmation: string().required('#login_error_password').min(8, '#password_min_length').when('newPassword', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: string().oneOf([ref('newPassword')], '#new_password_equal')
        })
    });

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        errors,
        touched
    } = useFormik({
        validationSchema: changePasswordSchema,
        initialValues: { currentPassword: '', newPassword: '', newPasswordConfirmation: '' },
        onSubmit: values => changePassword(values),
    });

    const changePassword = async ({ currentPassword, newPassword, newPasswordConfirmation }: { currentPassword: string, newPassword: string, newPasswordConfirmation: string }) => {
        if (loading) return;
        setLoading(true);
        const response = await AuthUtils.changePassword(currentPassword, newPassword, newPasswordConfirmation);
        setTimeout(() => {
            setResponse(response.data.success);
            setLoading(false);
        }, 2000)

    }

    return (
        <View style={[styles.flexStretch, styles.gap30]}>

            <View style={[styles.flexStretch, styles.gap10]}>

                <TText secondary semibold>Wachtwoord wijzigen</TText>

                <SecureTextInput
                    tabIndex={1}
                    label="#current_password"
                    placeholder={translate("#current_password")}
                    value={values.currentPassword}
                    onChangeText={handleChange('currentPassword')}
                    onBlur={handleBlur('currentPassword')}
                    onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                    error={(touched.currentPassword && errors.currentPassword) ? errors.currentPassword : undefined}
                />

                <SecureTextInput
                    tabIndex={2}
                    autoCompleteType="password"
                    label="#new_password"
                    placeholder={translate("#new_password")}
                    value={values.newPassword}
                    onChangeText={handleChange('newPassword')}
                    onBlur={handleBlur('newPassword')}
                    onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                    error={(touched.newPassword && errors.newPassword) ? errors.newPassword : undefined}
                />

                <SecureTextInput
                    tabIndex={3}
                    label="#new_password_confirmation"
                    placeholder={translate("#new_password_confirmation")}
                    value={values.newPasswordConfirmation}
                    onChangeText={handleChange('newPasswordConfirmation')}
                    onBlur={handleBlur('newPasswordConfirmation')}
                    onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                    error={(touched.newPasswordConfirmation && errors.newPasswordConfirmation) ? errors.newPasswordConfirmation : undefined}
                />

            </View>
            
            <View style={[styles.flexRowDynamic, styles.alignCenter, styles.gap10]}>
                <Button
                    loading={loading}
                    label={`${'#change_password'}`}
                    type={"secondary"}
                    onPress={handleSubmit}
                />            

                {response !== null &&                    
                    <TText error={!response}>{response ? '#password_changed' : '#password_changeError'}</TText>                    
                }
            </View>

        </View >
    );

}
