
export class HDN_Company {
    id: string;
    hdn_code: string;
    is_receiver: boolean;
    is_sender: boolean;
    max_hdn_version: string;
    min_hdn_version: string;
    receiver_name: string;
    sender_name: string;

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): HDN_Company {
        this.id = json.id ? json.id : "";
        this.hdn_code = json.hdn_code ? json.hdn_code : "";
        this.is_receiver = json.is_receiver ? !!json.is_receiver : false;
        this.is_sender = json.is_sender ? !!json.is_sender : false;
        this.max_hdn_version = json.max_hdn_version ? json.max_hdn_version : "";
        this.min_hdn_version = json.min_hdn_version ? json.min_hdn_version : "";
        this.receiver_name = json?.receiver_name ? json.receiver_name : json.name ? json.name : "";
        this.sender_name = json?.sender_name ? json.sender_name : "";
        return this;
    }

    getOption(){
        return {
            label: this.receiver_name || this.sender_name,
            value: this.hdn_code
        }
    }
}
