import {ViewStyle, View, ImageStyle, useWindowDimensions} from "react-native"
import { useStyle } from "hooks";
import Card from "./Card";
import { colors } from "styles";
import TText from "components/TText";
import IconCard from "./IconCard";
import React, {useState} from "react";
import {Button, ProgressBar} from "components";
import {ValutaUtils} from "utils";
import {CreateResponsiveStyle, DEVICE_SIZES, maxSize} from "rn-responsive-styles";
import {Mortgage} from "models";
import {PieChart} from "react-native-svg-charts";

interface MortgageItemCardProps{
    mortgage: Mortgage,
    activeAddress?: any
}

export default ({ mortgage, activeAddress }: MortgageItemCardProps) => {

    const scopedStyles = scopedResponsiveStyles();
    const { width, height } = useWindowDimensions();
    const styles = useStyle();
    const [itemSize, setItemSize] = useState('large' as 'large'|'medium'|'small');

    const total = mortgage.hoofdsomHypotheek;
    const restant = mortgage.restantHoofdsom;
    const payed = mortgage.hoofdsomHypotheek - mortgage.restantHoofdsom;
    const monthly = mortgage.monthly_amount;
    const aflossing = mortgage.monthly_repayment_amount;
    const rente = mortgage.monthly_interest_amount;
    const currentAdress = mortgage.mainProperty.street && activeAddress && (
        mortgage.mainProperty.street == activeAddress.street_name &&
        mortgage.mainProperty.number == activeAddress.house_number &&
        mortgage.mainProperty.number_addition == activeAddress.house_number_addition &&
        mortgage.mainProperty.postal_code == activeAddress.postcode
    );

    const renderCurrentAddressBadge = (currentAdress: boolean) => {
        return (currentAdress) ? (
            <Button
                label="#primary_living"
                type='action'
                onPress={() => { }}
                unclickable
                style={{
                    position: 'absolute',
                    left: -10,
                    top: -10
                }}
            />
        ) : undefined;
    }

    const renderPie = (data: any, label = 0, drawZero = false) => {
        const pieData = data.map((val: any, index: any) => ({
            value: val.value,
            svg: {
                fill: val.color,
            },
            key: `pie-${index}`,
        }))

        return (drawZero && (!label || label == 0)) ? (
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <View style={{
                    width: width < 992 ? 100 : 80,
                    height: width < 992 ? 100 : 80,
                    borderRadius: 200,
                    borderColor: colors.primary,
                    borderWidth: 8,
                    justifyContent: "center",
                    alignItems: 'center'
                }}>
                    <TText sm center >{ValutaUtils.valutaString('0,0')}</TText>
                </View>
            </View>
        ) : (
            <View style={styles.fullscreen}>
                <PieChart style={{ width: '100%', height: '100%' }} data={pieData} innerRadius={'85%'} padAngle={0} />
                <View style={{ position: 'absolute', height: '100%', width: '100%', justifyContent: 'center' }}>
                    {label !== 0 && <TText sm regular center >{ValutaUtils.valutaString(label)}</TText>}
                </View>
            </View>
        );
    }

    const handleLayout = (e: any) => {
        const width = e.nativeEvent.layout.width;
        if(width > 835) {
            setItemSize('large');
        } else if(width > 480) {
            setItemSize('medium');
        } else {
            setItemSize('small');
        }
    }

    return (
        <View onLayout={handleLayout}>
            <Card style={[
                scopedStyles.mortgageItem,
                itemSize === "large" ? scopedStyles.mortgageItemLarge : {},
                itemSize === "medium" ? scopedStyles.mortgageItemMedium : {},
                itemSize === "small" ? scopedStyles.mortgageItemSmall : {},
            ]}>

                {/* current address icon */}
                {renderCurrentAddressBadge(currentAdress)}

                <View style={[
                    scopedStyles.addressContainer,
                    itemSize === "large" ? scopedStyles.addressContainerLarge : {},
                    itemSize === "medium" ? scopedStyles.addressContainerMedium : {},
                    itemSize === "small" ? scopedStyles.addressContainerSmall : {},
                ]}>

                    {/* house icon */}
                    <IconCard
                        iconName={'IconHome'}
                        iconSize={
                            itemSize === "large"
                                ? 25
                                : itemSize === "medium"
                                    ? 20
                                    : itemSize === "small"
                                        ? 15
                                        : 10
                        }
                        iconColor={colors.white}
                        style={

                            itemSize === "large"
                                ? scopedStyles.iconCardLarge
                                : itemSize === "medium"
                                    ? scopedStyles.iconCardMedium
                                    : itemSize === "small"
                                        ? scopedStyles.iconCardSmall
                                        : {}
                        }
                    />

                    {/* address */}
                    <View style={[
                        scopedStyles.homeAddress,
                        itemSize === "large" ? scopedStyles.homeAddressLarge : {},
                        itemSize === "medium" ? scopedStyles.homeAddressMedium : {},
                        itemSize === "small" ? scopedStyles.homeAddressSmall : {},
                    ]}>
                        <TText md semibold center>{mortgage.mainProperty.street ? `${mortgage.mainProperty.street} ${mortgage.mainProperty.number}${mortgage.mainProperty.number_addition}` : ''}</TText>
                        <TText sm regular center>{mortgage.mainProperty.street ? `${mortgage.mainProperty.postal_code}, ${mortgage.mainProperty.city}` : ' '}</TText>
                    </View>

                    {/* Agency */}
                    <View style={[
                        scopedStyles.agencyName,
                        itemSize === "large" ? scopedStyles.agencyNameLarge : {},
                        itemSize === "medium" ? scopedStyles.agencyNameMedium : {},
                        itemSize === "small" ? scopedStyles.agencyNameSmall : {},
                    ]}>
                        <TText md semibold center>{mortgage.agency_name}</TText>
                        <View style={{ width: '100%', paddingHorizontal: 20, alignItems: "center" }}>
                            <TText sm center>{mortgage.hypotheeknummer}</TText>
                        </View>
                    </View>


                </View>

                <View style={[
                    scopedStyles.totalsContainer,
                    itemSize === "large" ? scopedStyles.totalsContainerLarge : {},
                    itemSize === "medium" ? scopedStyles.totalsContainerMedium : {},
                    itemSize === "small" ? scopedStyles.totalsContainerSmall : {},
                ]}>

                    {/* total overview */}
                    <Card style={[
                        scopedStyles.mortgageTotalOverview,
                        itemSize === "large" ? scopedStyles.mortgageTotalOverviewLarge : {},
                        itemSize === "medium" ? scopedStyles.mortgageTotalOverviewMedium : {},
                        itemSize === "small" ? scopedStyles.mortgageTotalOverviewSmall : {},
                    ]}>
                        <View style={{
                            flex: 1,
                            marginTop: 4,
                            justifyContent: 'space-evenly',
                            borderRadius: 8,
                        }}>
                            <TText sm semibold>#mortgage_data</TText>
                            <View style={scopedStyles.mortgageValueContainer}>
                                <ProgressBar
                                    percentage={restant / total}
                                    style={scopedStyles.progressBar}
                                    barStyle={{ backgroundColor: colors.secondary9, borderRadius: 4 }}
                                />
                            </View>
                            <View style={scopedStyles.mortgageValueContainer}>
                                <TText sm>#original_value</TText>
                                <TText sm >{ValutaUtils.valutaString(total, true)}</TText>
                            </View>
                            <View style={scopedStyles.mortgageValueContainer}>
                                <TText sm>#rest_value</TText>
                                <TText sm >{ValutaUtils.valutaString(restant, true)}</TText>
                            </View>
                            <View style={scopedStyles.mortgageValueContainer}>
                                <TText sm>#paid_value</TText>
                                <TText sm >{ValutaUtils.valutaString(payed, true)}</TText>
                            </View>
                        </View>
                    </Card>

                    {/* monthly overview */}
                    <Card style={[
                        scopedStyles.mortgageMonthlyOverview,
                        itemSize === "large" ? scopedStyles.mortgageMonthlyOverviewLarge : {},
                        itemSize === "medium" ? scopedStyles.mortgageMonthlyOverviewMedium : {},
                        itemSize === "small" ? scopedStyles.mortgageMonthlyOverviewSmall : {},
                    ]}>
                        <TText sm semibold>#monthly_value</TText>
                        <View style={{
                            flex: 1,
                            marginTop: 4,
                            justifyContent: 'space-evenly',
                            borderRadius: 8,
                            flexDirection: "row"
                        }}>
                            <View style={[
                                scopedStyles.mortgageMonthlyPie,
                                itemSize === "large" ? scopedStyles.mortgageMonthlyPieLarge : {},
                                itemSize === "medium" ? scopedStyles.mortgageMonthlyPieMedium : {},
                                itemSize === "small" ? scopedStyles.mortgageMonthlyPieSmall : {},
                            ]}>

                                <View style={{ minHeight: 102, height: 102 }}>
                                    {renderPie(
                                        [
                                            { value: aflossing, color: colors.secondary9 },
                                            { value: rente, color: colors.secondary5 },
                                        ],
                                        monthly,
                                        true
                                    )}
                                </View>

                            </View>

                            <View style={[
                                scopedStyles.mortgageMonthlyList,
                                itemSize === "large" ? scopedStyles.mortgageMonthlyListLarge : {},
                                itemSize === "medium" ? scopedStyles.mortgageMonthlyListMedium : {},
                                itemSize === "small" ? scopedStyles.mortgageMonthlyListSmall : {},
                            ]}>

                                <View style={{ height: '50%', flexDirection: 'row', alignItems: "center", paddingVertical: 5 }}>
                                    <TText sm regular style={{ alignSelf: 'center', flexGrow: 1 }}>#redeemed</TText>
                                    <TText sm style={{ alignSelf: 'center' }} >{ValutaUtils.valutaString(aflossing)}</TText>

                                </View>

                                <View style={{ height: '50%', flexDirection: 'row', alignItems: "center", paddingVertical: 5 }}>
                                    <TText sm regular style={{ alignSelf: 'center', flexGrow: 1 }}>#interest</TText>
                                    <TText sm style={{ alignSelf: 'center' }} >{ValutaUtils.valutaString(rente)}</TText>
                                </View>
                            </View>

                        </View>
                    </Card>

                </View>

            </Card>

        </View>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        iconCardLarge: {
            width: 40,
            height: 40,
        },
        iconCardMedium: {
            width: 35,
            height: 35,
        },
        iconCardSmall: {
            width: 30,
            height: 30,
        },
        mortgageItem: {
            width: '100%',
            borderRadius: 20,
            paddingVertical: 30,
            paddingHorizontal: 30,
            backgroundColor: colors.background,
            alignItems: "center",
            marginBottom: 15,
        },
        mortgageItemLarge: {
            flexDirection: 'row',
            height: 200
        },
        mortgageItemMedium: {
            paddingHorizontal: 10,
            height: 'auto'
        },
        mortgageItemSmall: {
            paddingHorizontal: 10,
            height: 'auto'
        },
        addressContainer: {
            flexDirection: 'row',
            alignItems: "center",
        },
        addressContainerLarge: {
            flex: 0.4,
            height: '100%'
        },
        addressContainerMedium: {
            width: '100%'
        },
        addressContainerSmall: {
            width: '100%',
            flexDirection: 'column',
        },
        totalsContainer: {
            flexDirection: 'row',
            alignItems: "center",
        },
        totalsContainerLarge: {
            flex: 0.6,
            height: '100%'
        },
        totalsContainerMedium: {
            width: '100%'
        },
        totalsContainerSmall: {
            width: '100%',
            flexDirection: 'column',
        },
        homeAddress: {
            paddingLeft: 10,
            flex: 0.5,
            justifyContent: "space-between"
        },
        homeAddressLarge: {
        },
        homeAddressMedium: {
        },
        homeAddressSmall: {
        },
        agencyName: {
            paddingHorizontal: 10,
            paddingVertical: 8,
            flex: 0.5,
            justifyContent: "space-between"
        },
        agencyNameLarge: {
        },
        agencyNameMedium: {
        },
        agencyNameSmall: {
        },
        mortgageTotalOverview: {
            flex: 0.5,
            justifyContent: 'space-between',
            padding: 10,
        },
        mortgageTotalOverviewLarge: {
        },
        mortgageTotalOverviewMedium: {
        },
        mortgageTotalOverviewSmall: {
            margin: 10,
            flex: 1,
            width: '100%'
        },
        mortgageMonthlyOverview: {
            marginLeft: 10,
            flex: 0.5,
            justifyContent: 'space-between',
            padding: 10,
        },
        mortgageMonthlyOverviewLarge: {
        },
        mortgageMonthlyOverviewMedium: {
        },
        mortgageMonthlyOverviewSmall: {
            flex: 1,
            width: '100%'
        },
        mortgageMonthlyList: {
            flex: 0.6,
            height: '100%',
            justifyContent: "space-between",
            alignSelf: 'center'
        },
        mortgageMonthlyListLarge: {

        },
        mortgageMonthlyListMedium: {

        },
        mortgageMonthlyListSmall: {
            flex: 0.5,
        },
        mortgageMonthlyPie: {
            flex: 0.4,
            justifyContent: "center",
            paddingRight: 10
        },
        mortgageMonthlyPieLarge: {

        },
        mortgageMonthlyPieMedium: {

        },
        mortgageMonthlyPieSmall: {
            flex: 0.5,
        },
        progressBar: {
            width: '100%',
            height: 10,
            borderRadius: 4,
            backgroundColor: colors.secondary5,
            overflow: 'hidden'
        },
        mortgageValueContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginVertical: 3,
        },
    }
);
