export class OnlineAfsprakenResource {
    id: number | null
    name: string

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): OnlineAfsprakenResource {
        this.id = json.id ? json.id : null;
        this.name = json.name ? json.name : '';
        return this;
    }

    getLabel = () => {
        return this.name;
    }

    getValue = () => {
        return this.id;
    }
}
