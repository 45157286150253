import moment from "moment";

export class Document {
    id: string;
    name: string;
    type: string;
    link: string;
    mime_type: string;
    customer_id: number;
    documentcategory_id: number;
    extension: string;
    migrated: boolean;
    deletable: boolean;
    filesize: number;
    decoded: boolean;
    user_id: number;
    is_tenant_document: boolean;
    damage: any | undefined;
    mortgage: any | undefined;
    created_at: string;
    updated_at: string;

    constructor(json = {}) {
        this.fromJson(json);
    }

    fromJson(json: any): Document {
        this.id = json.id ? json.id : "";
        this.name = json.name ? json.name : "";
        this.type = json.type ? json.type : "";
        this.link = json.link ? json.link : "";
        this.mime_type = json.mime_type ? json.mime_type : "";
        this.customer_id = json.customer_id ? json.customer_id : 0;
        this.documentcategory_id = json.documentcategory_id ? json.documentcategory_id : 0;
        this.extension = json.extension ? json.extension : "";
        this.migrated = json.migrated ? json.migrated : false;
        this.filesize = json.filesize ? json.filesize : 0;
        this.decoded = json.decoded ? json.decoded : false;
        this.user_id = json.user_id ? json.user_id : 0;
        this.is_tenant_document = json.is_tenant_document ? json.is_tenant_document : false;
        this.damage = json.damage ? json.damage.data : '';
        this.mortgage = json.mortgage ? json.mortgage.data : '';
        this.created_at = json.created_at ? json.created_at : '';
        this.updated_at = json.updated_at ? json.updated_at : '';
        this.deletable = json.created_at ? moment().diff(moment(json.created_at), 'minute') <= 60 : false;
        return this;
    }

    toJson(): { [key: string]: any } {
        return {
            id: this.getId()
        };
    }

    getId(): string {
        return this.id;
    }
}
