import 'react-native-gesture-handler';

import { RootNavigation } from 'navigation';
import { ModalProvider, ReactQueryProvider, StoreProvider } from 'providers';
import { SafeAreaProvider, SafeAreaView, initialWindowMetrics } from 'react-native-safe-area-context';
import * as SentryNative from '@sentry/react-native';
import ThemeProvider from 'providers/ThemeProvider';
import { NavigationContainer } from '@react-navigation/native';


const Root = () => {
    return (
        <SafeAreaProvider initialMetrics={initialWindowMetrics}>
            <ReactQueryProvider>
                <NavigationContainer documentTitle={{ enabled: false }}>
                    <StoreProvider>
                        <ThemeProvider>
                            <ModalProvider>
                                <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
                                    <RootNavigation />
                                </SafeAreaView>
                            </ModalProvider>
                        </ThemeProvider>
                    </StoreProvider>
                </NavigationContainer>
            </ReactQueryProvider>
        </SafeAreaProvider>
    );
}

export default SentryNative.wrap(Root);