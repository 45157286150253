import TText from "../../TText";
import { useHover, useStyle } from "hooks";
import Button from "components/Button";
import { colors } from "styles";
import { ValutaUtils } from "utils";
import { Card } from "components/Card";
import { Image, View, Text, Pressable } from "react-native";
import Constants from "expo-constants";
import { BaseDrawerModal } from "components/Modals";
import { useState } from "react";
import PrincipleInformation from "./PrincipleInformation";
import { CreateResponsiveStyle } from "rn-responsive-styles";
import { MortgagePart } from "models/MortgagePart";
import { Mortgage } from "models";
import HdnCompanyImage from "components/HdnCompanyImage";

const Config = Constants.expoConfig.extra.config;

interface proposalProps {
    proposal: {}, 
    matchedMortgage: Mortgage, 
    currentSituation: {
        net_monthly_payments: number, 
        gross_monthly_payments: number
    },
    refinanceCosts: {
        appraisal_costs : number,
        mortgage_brokerage_costs: number,
        notarial_mortgage_act_costs: number
    }
    repaymentPenalty: number, 
    selectedPeriod: number, 
    onClose: () => void
}


export default ({proposal, matchedMortgage, currentSituation, refinanceCosts, repaymentPenalty, selectedPeriod, onClose}: proposalProps) => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const [showPrincipleInformation, setShowPrincipleInformation] = useState(false);

    console.log({proposal, matchedMortgage, currentSituation});

    const renderTable = () => {
        return (
            <Card style={[styles.borderRadius10, styles.gap20, {padding: 0, overflow: "hidden", backgroundColor: colors.background, minHeight: 80}]}>
                
                <View style={{
                    flexDirection: 'row', 
                    backgroundColor: colors.primary2, 
                    paddingVertical: 10,
                    width: '100%'
                }}>
                    <View style={scopedStyles.left}>
                        <View style={scopedStyles.i3}></View>
                        <TText semibold right style={scopedStyles.i3}>Rentevastperiode</TText>
                        <TText semibold right style={scopedStyles.i3}>Rentepercentage</TText>
                    </View>
                    <View style={scopedStyles.right}>
                        <TText semibold right style={scopedStyles.i2}>Bruto</TText>
                        <TText semibold right style={scopedStyles.i2}>Netto</TText>
                    </View>
                </View>
                
                {renderCurrentMortgage()}

                {renderProposalMortgage()}

                <View style={{
                    flexDirection: 'row', 
                    backgroundColor: colors.primary2, 
                    paddingVertical: 10
                }}>
                    <View style={scopedStyles.left}>
                        <TText semibold style={scopedStyles.i3}>Verschil per maand</TText>
                        <View style={scopedStyles.i3}></View>
                        <View style={scopedStyles.i3}></View>
                    </View>
                    <View style={scopedStyles.right}>
                        <TText right semibold style={scopedStyles.i2}>{ValutaUtils.valutaString(proposal.gross_total_refinance_difference)}</TText>
                        <TText right semibold style={scopedStyles.i2}>{ValutaUtils.valutaString(proposal.net_total_difference_during_interest_period)}</TText>
                    </View>
                </View>
            </Card>
        )
    }

    const renderCosts = () => {
        return (
            <View style={{
                borderColor: colors.border,
                borderWidth: 1,
                borderRadius: 10,
                paddingVertical: 10,
                width: '100%',
                gap: 20
            }}>

                <View style={[styles.flexRow]}>
                    <View style={scopedStyles.left}>                
                    </View>
                    <View style={scopedStyles.right}>
                        <TText semibold right style={scopedStyles.i2}>Bruto</TText>
                        <TText semibold right style={scopedStyles.i2}>Netto</TText>
                    </View>
                </View>

                <View>
                    <View style={[styles.flexRow]}>
                        <View style={scopedStyles.proposalLeft}>
                            <TText>Verschil over {selectedPeriod / 12} jaar</TText>
                        </View>
                        <View style={scopedStyles.right}>
                            <TText right style={scopedStyles.i2}>{ValutaUtils.valutaString(proposal.gross_total_difference_during_interest_period)}</TText>    
                            <TText right style={scopedStyles.i2}>{ValutaUtils.valutaString(proposal.net_total_difference_during_interest_period)}</TText>    
                        </View>
                    </View>
                    <View style={[styles.flexRow]}>
                        <View style={scopedStyles.proposalLeft}>
                            <TText>Terugverdientijd</TText>
                        </View>
                        <View style={scopedStyles.right}>
                            <View style={scopedStyles.i2}/>
                            <TText right style={scopedStyles.i2}>{proposal.payback_time_in_months || 0} jaar</TText>
                        </View>
                    </View>
                </View>

                
                <View>
                    <View style={[styles.flexRow]}>
                        <View style={scopedStyles.proposalLeft}>
                            <TText>Vergoeding (eventueel meegefinancierd)</TText>
                        </View>
                        <View style={scopedStyles.right}>
                            <TText right style={scopedStyles.i2}></TText>
                            <TText right style={scopedStyles.i2}>{ValutaUtils.valutaString(repaymentPenalty)}</TText>    
                        </View>
                    </View>
                    <View style={[styles.flexRow]}>
                        <View style={scopedStyles.proposalLeft}>
                            <TText>Advieskosten</TText>
                        </View>
                        <View style={scopedStyles.right}>
                            <TText right style={scopedStyles.i2}></TText>
                            <TText right style={scopedStyles.i2}>{ValutaUtils.valutaString(refinanceCosts.mortgage_brokerage_costs)}</TText>
                        </View>
                    </View>
                    <View style={[styles.flexRow]}>
                        <View style={scopedStyles.proposalLeft}>
                            <TText>Notariskosten</TText>
                        </View>
                        <View style={scopedStyles.right}>
                            <TText right style={scopedStyles.i2}></TText>
                            <TText right style={scopedStyles.i2}>{ValutaUtils.valutaString(refinanceCosts.notarial_mortgage_act_costs)}</TText>
                        </View>
                    </View>
                    <View style={[styles.flexRow]}>
                        <View style={scopedStyles.proposalLeft}>
                            <TText>Taxatiekosten</TText>
                        </View>
                        <View style={scopedStyles.right}>
                            <TText right style={scopedStyles.i2}></TText>
                            <TText right style={scopedStyles.i2}>{ValutaUtils.valutaString(refinanceCosts.appraisal_costs)}</TText>
                        </View>
                    </View>
                </View>   

                <View style={[styles.flexRow, styles.alignCenter, styles.justifyBetween, styles.pl10, {paddingRight: 55}]}>
                    <TText md semibold>Totaal voordeel over {selectedPeriod / 12} jaar</TText>
                    <TText md semibold>{ValutaUtils.valutaString(proposal.gross_total_difference_during_interest_period)} </TText>
                </View>

            </View>
        );
    }

    const renderCurrentMortgage = () => {

        const totalFixedRatePeriod = matchedMortgage.mortgageParts.reduce((acc, part: MortgagePart) => acc + Number(part.rentevasteperiode), 0);
        const highestInterest = matchedMortgage.mortgageParts.reduce((acc, part: MortgagePart) => {
            if (part.interest > acc) return part.interest;
            return acc;
        }, 0);

        return (
            <View>
                <View style={[styles.flexRow]}>
                    <View style={scopedStyles.left}>
                        <TText style={[scopedStyles.i3, {marginLeft: 10}]}>Jouw huidige hypotheek</TText>
                        <TText right style={scopedStyles.i3}>{totalFixedRatePeriod}jaar</TText>
                        <TText right style={scopedStyles.i3}>{highestInterest}%</TText>
                    </View>
                    <View style={scopedStyles.right}>
                        <TText right style={scopedStyles.i2}>{ValutaUtils.valutaString(currentSituation.gross_monthly_payments)}</TText>
                        <TText right style={scopedStyles.i2}>{ValutaUtils.valutaString(currentSituation.net_monthly_payments)}</TText>
                    </View>
                </View>

                <View style={{padding: 10}}>
                    <Card style={{borderRadius: 5, paddingVertical: 5, paddingHorizontal: 0, paddingLeft: 15}}>
                        <View style={[styles.flexRow]}>
                            <View style={scopedStyles.left}>
                                <TText semibold style={scopedStyles.i3}>Leningdeel</TText>
                                <TText right semibold style={scopedStyles.i3}>Rentevastperiode</TText>
                                <TText right semibold style={scopedStyles.i3}>Rentepercentage</TText>
                            </View>
                            <View style={scopedStyles.right}></View>
                        </View>
                        {matchedMortgage.mortgageParts.map((part: MortgagePart) => (
                            <View style={[styles.flexRow]}>
                                <View style={scopedStyles.left}>
                                    <TText style={scopedStyles.i3}>{part.leningdeelnummer}</TText>
                                    <TText right style={scopedStyles.i3}>{part.rentevasteperiode}jaar</TText>
                                    <TText right style={scopedStyles.i3}>{part.interest}%</TText>
                                </View>
                                <View style={scopedStyles.right}></View>
                            </View>
                        ))}
                    </Card>
                </View>
            </View>
        );
    }

    const renderProposalMortgage = () => {
        return (
            <View>
                <View style={[styles.flexRow]}>
                    <View style={scopedStyles.left}>
                        <TText style={[scopedStyles.i3, {marginLeft: 10}]}>De hypotheek bij {proposal.product_line_name}</TText>
                        <TText right style={scopedStyles.i3}>{Math.floor(proposal.new_interest_period_in_months / 12)}jaar</TText>
                        <TText right style={scopedStyles.i3}>{Number(proposal.interest_rate_largest_mortgage_part * 100).toFixed(2)}%</TText>
                    </View>
                    <View style={scopedStyles.right}>
                        <TText right style={scopedStyles.i2}>{ValutaUtils.valutaString(proposal.gross_monthly_payments)}</TText>
                        <TText right style={scopedStyles.i2}>{ValutaUtils.valutaString(proposal.net_monthly_payments)}</TText>
                    </View>
                </View>
                <View style={{padding: 10}}>
                    <Card style={{borderRadius: 5, paddingVertical: 5, paddingHorizontal: 0, paddingLeft: 15}}>
                        <View style={[styles.flexRow]}>
                            <View style={scopedStyles.left}>
                                <TText semibold style={scopedStyles.i3}>Leningdeel</TText>
                                <TText right semibold style={scopedStyles.i3}>Rentevastperiode</TText>
                                <TText right semibold style={scopedStyles.i3}>Rentepercentage</TText>
                            </View>
                            <View style={scopedStyles.right}></View>
                        </View>
                        {proposal.interest_rates_per_mortgage_part.map((interest: number, index: number) => (
                            <View style={[styles.flexRow]}>
                                <View style={scopedStyles.left}>
                                    <TText style={scopedStyles.i3}>{index + 1}</TText>
                                    <TText right style={scopedStyles.i3}>{Math.floor(proposal.new_interest_period_in_months / 12)}jaar</TText>
                                    <TText right style={scopedStyles.i3}>{Number(interest * 100).toFixed(2)}%</TText>
                                </View>
                                <View style={scopedStyles.right}></View>
                            </View>
                        ))}
                    </Card>
                </View>
            </View>
        );
    }

    return (
        <BaseDrawerModal
            drawerWidth={1200}
            visible={true}
            onClose={onClose}
            header=""
            headerComponent={() => (
                <View style={[styles.flexRow, styles.alignCenter, styles.gap30, {height: 100}]}>
            
                    <Card style={[{borderWidth: 1, borderColor: colors.background, padding: 5, borderRadius: 10, height: '100%'}]}>
                        <HdnCompanyImage
                            code={proposal.lender_id}
                        />
                    </Card>
                    
                    <TText md semibold>{proposal.product_line_name}</TText>
                </View>
            )}
            drawerStyle={[styles.pv20, styles.gap20]}
        >
            

            <Card style={[styles.flexRow, styles.alignCenter, styles.justifyBetween, styles.p20, {backgroundColor: colors.primary}]}>
                <View>
                    <Text>
                        <TText md white>Bespaar </TText>
                        <TText md semibold white>{ValutaUtils.valutaString(Math.abs(proposal.net_total_difference_during_interest_period))} </TText>
                        <TText md white>per maand bij {proposal.product_line_name}</TText>
                    </Text>
                </View>
                <Button
                    label="Advies aanvragen"
                    type="secondary"
                    onPress={() => {}}
                />
            </Card>

            <TText>Als je overstapt naar {proposal.product_line_name} heeft dat consequenties voor je hypotheek. Hieronder zie je wat het precies betekent! Om meer inzicht te krijgen in jouw persoonlijke situatie adviseren we je om het scenario “Kan ik blijven wonen?” te doorlopen.</TText>
            
            {renderTable()}            

            {renderCosts()}

            <TText>Een alternatief voor oversluiten zou rentemiddeling kunnen zijn, houd er rekening mee dat niet alle geldverstrekkers aan rentemiddeling meewerken. Als je overweegt om te verhuizen, dan geldt vaak dat er bij verkoop van de woning geen vergoeding betaald hoeft te worden. Als de vergoeding aan de geldverstrekker erg hoog is dan zou verhuizen daarom een aantrekkelijke optie kunnen zijn. Voor je nieuwe woning kun je een nieuwe hypotheek nemen met de huidige hypotheekrentes. Wij verzorgen dan graag het hypotheekadvies.</TText>

            <Pressable onPress={() => setShowPrincipleInformation(true)}>
                <TText semibold secondary>Uitgangspunten berekening {'>'}</TText>
            </Pressable>

            <View style={{flex: 1}}></View>

            <View style={[styles.flexRow, styles.alignCenter, styles.gap10]}>
                <Button
                    label="Advies aanvragen"
                    type="secondary"
                    onPress={() => {}}
                />
                <Button
                    label="Vraag stellen"
                    type="secondary"
                    onPress={() => {}}
                />

            </View>

            <PrincipleInformation
                visible={showPrincipleInformation}
                onClose={() => setShowPrincipleInformation(false)}
            />

        </BaseDrawerModal>
    );
};

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        left: {
            paddingLeft: 10,
            width: '70%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly'
        },
        right: {
            width: '30%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: 60
        },
        i3: {
            width: '33.33%'
        },
        i2: {
            width: '50%'
        },
        proposalLeft: {
            paddingLeft: 10,
            width: '70%',
        },
        proposalRight: {
            width: '30%',            
        },
    }
);