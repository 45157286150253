import { useQuery } from "react-query";
import { QueryKeys } from "enums";
import { AdviesWidgetUtils, QueryUtils } from "utils";

import { useEffect, useState } from "react";
import BaseWidget from "./BaseWidget";
import { BaseDrawerModal } from "components/Modals";


export default ({settings}) => {

    
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const { data: family } = useQuery(QueryKeys.FAMILY, QueryUtils.fetchFamily);
    const { data: obligations } = useQuery(QueryKeys.OBLIGATION, QueryUtils.fetchObligation);
    const { data: living } = useQuery(QueryKeys.LIVING, QueryUtils.fetchLiving);

    const [state, setState] = useState('')
    const widgetName = "MaximumMortgageWidget";
    const widgetUrl = "https://blackbirds-cdn.cluster02.k8s.rapide.software/max-mortgage/latest/bundle.js";

    useEffect(() => {
        if (customer && family && obligations && living){
            setState(AdviesWidgetUtils.getMaximumMortgageState(customer, family, obligations, living));
        }
    }, [customer])
   
    return (
        <BaseDrawerModal 
            drawerWidth={1200} 
            visible={true}
            onClose={settings.hideModal}
            header={'Hoeveel kan ik lenen?'}
        >
            <BaseWidget
                state={state}
                widgetName={widgetName}
                widgetUrl={widgetUrl}
            />
        </BaseDrawerModal>
    )

}
