import { Button, TText, SecureTextInput, TextInput } from 'components';
import { useFormik } from 'formik';
import { useStyle, useTranslation } from 'hooks';
import { UserProfile } from 'models/UserProfile';
import { StoreContext } from 'providers/StoreProvider';
import { useContext, useEffect } from 'react';
import { KeyboardAvoidingView, Platform, StyleSheet } from 'react-native';
import { AuthUtils } from 'utils';
import { object, string } from 'yup';


interface FormProps {
    loading: boolean
    handleLogin: (v: any) => void
}

export default ({ loading, handleLogin }: FormProps) => {

    const styles = useStyle();
    const translate = useTranslation();
    const { query } = useContext(StoreContext);

    const [subdomain, domain, tld] = window?.location ? window.location.hostname.split('.') : [];
    const tenant = ((domain == 'klantportaal' && tld == 'net') || domain == 'localhost') ? subdomain : '';
    const username = query.get('username') || '';

    useEffect(() => {
        const getUserData = async () => {
            const currentUser: UserProfile = await AuthUtils.getCurrentUser();
            if (currentUser) {
                setValues({
                    code: currentUser.tenant_code,
                    username: currentUser.username,
                    password: ''
                });
            } else {
                setValues({
                    code: '',
                    username,
                    password: ''
                });
            }
        }
        getUserData();
    }, [])

    const loginSchema = object({
        code: tenant ? string() : string().required('#login_error_tenant'),
        username: string().required('#login_error_username'),
        password: string().min(8, '#password_min_length').required('#login_error_password')
    });

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        setValues,
        values,
        errors,
        touched,
    } = useFormik({
        validationSchema: loginSchema,
        initialValues: {
            code: '',
            username,
            password: ''
        },
        onSubmit: values => handleLogin({ ...values, tenant }),
    });

    return (
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} >
            {!tenant &&
                <>
                    <TextInput
                        label='#login_advisor'
                        style={[styles.text, scopedStyles.input]}
                        placeholder={translate('#login_advisor_code')}
                        value={values.code}
                        onChangeText={(txt: string) => { setValues(v => ({ ...v, code: txt.toUpperCase() })) }}
                        keyboardType={Platform.OS === 'ios' ? 'default' : 'visible-password'}
                        onBlur={handleBlur('code')}
                        onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                        autoCapitalize='characters'
                        autoCompleteType='code'
                        error={(touched.code && errors.code) ? errors.code : undefined}
                    />
                </>
            }

            <TextInput
                label='#login_username'
                style={[styles.text, scopedStyles.input]}
                placeholder={translate('#login_username')}
                value={values.username}
                onChangeText={handleChange('username')}
                onBlur={handleBlur('username')}
                onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                autoCapitalize='none'
                autoCompleteType='username'
                error={(touched.username && errors.username) ? errors.username : undefined}
            />

            <SecureTextInput
                label='#login_password'
                style={[styles.text, scopedStyles.input]}
                placeholder={translate('#login_password')}
                value={values.password}
                onChangeText={handleChange('password')}
                onBlur={handleBlur('password')}
                onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                autoCapitalize='none'
                autoCompleteType='password'
                error={(touched.password && errors.password) ? errors.password : undefined}
            />

            <Button 
                label='#login_login' 
                onPress={handleSubmit} 
                style={scopedStyles.button} 
                loading={loading} 
            />

        </KeyboardAvoidingView>
    );
};

const scopedStyles = StyleSheet.create({
    input: {
        width: '100%',
        marginVertical: 8
    },
    button: {
        marginTop: 24,
        width: '100%'
    }
});

