import { useHover, useStyle } from 'hooks';
import React from 'react';
import { ActivityIndicator, Platform, Pressable, View, ViewStyle } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { colors } from 'styles';
import TText from './TText';
import Icon from "components/Icon";


interface Props {
    label: string
    onPress?: () => void
    style?: ViewStyle,
    icon?: (color: string) => React.ReactNode,
    type?: 'primary' | 'secondary' | 'error' | 'action'
    text?: boolean,
    rounded?: boolean,
    disabled?: boolean,
    loading?: boolean,
    success?: boolean,
    unclickable?: boolean
    textAlignLeft?: boolean
    textAlignRight?: boolean
}

//
//
//
// A buttom component with 3 types of styling, you can give an icon or set the loading property, that shows a loading icon.
//
//
//

const loadingIcon = (color, size) => <ActivityIndicator color={color} size={size} />;
const successIcon = (color, size) => <Icon iconName={'IconCheck'} iconColor={color} iconSize={size} />;

const Button: React.FC<Props> = ({ label, onPress, style, icon, type, text, rounded, disabled, loading, success, unclickable, textAlignLeft, textAlignRight }) => {
    
    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const [hovered, hoverElt] = useHover();

    const color = (!text) ? colors.white : (type === 'secondary') ? colors.secondary : (type === 'error') ? colors.error : colors.primary;
    const bgColor = text ? colors.transparent : (type == 'secondary') ? colors.secondary : (type == 'error') ? colors.error : colors.primary
    const hoverBgColor = (type == 'secondary') ? colors.secondary9 : (type == 'error') ? colors.errorDarker : colors.primary9
    
    const iconElement = icon && icon(color);
    const loadingIconElement = loadingIcon(color, 16);
    const successIconElement = successIcon(color, 16);

    return disabled ? (
        
            <View style={[
                scopedStyles.button,
                { 
                    backgroundColor: colors.grey,
                    justifyContent: textAlignLeft ? 'flex-start' : textAlignRight ? 'flex-end' : 'center',
                },
                rounded && scopedStyles.buttonRounded,
                style
            ]}>
                {iconElement && iconElement}
                <TText 
                    sm
                    semibold
                    oneline 
                    style={[styles.textWhite, {userSelect: 'none'}]}
                >
                    {label}
                </TText>
            </View>
        

    ) : (


        <Pressable
            disabled={unclickable}
            onPress={() => {if (!loading && onPress) onPress()}}
            ref={hoverElt} 
            style={[
                scopedStyles.button,
                { 
                    backgroundColor: bgColor,
                    justifyContent: textAlignLeft ? 'flex-start' : textAlignRight ? 'flex-end' : 'center',
                },
                (hovered && !unclickable) && { backgroundColor: hoverBgColor },
                rounded && scopedStyles.buttonRounded,
                Platform.OS == 'web' ? { transition: 'all 0.12s linear' } : {},
                style
            ]}
        >

                {(iconElement && !loading) && iconElement}
                {loading && loadingIconElement}
                {success && successIconElement}

                <TText
                    oneline
                    sm
                    semibold
                    white
                    grey={disabled}
                    style={{ color: color, userSelect: 'none' }}>
                    {label}
                </TText>            
        </Pressable>

    );
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        button: {
            borderRadius: 6,
            paddingVertical: 8,
            paddingHorizontal: 15,
            flexDirection: 'row',            
            alignItems: 'center',
            gap: 10,
            alignSelf: 'flex-start',
        },        
        buttonError: {
            backgroundColor: colors.error,
        },
        buttonDisabled: {
            backgroundColor: colors.disabled,
        },
        buttonRounded: {
            borderRadius: 24
        },
        buttonHovered: {
            opacity: 0.95
        },        
    },
    {
        [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
            button: {
                paddingVertical: 10,
            },
        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            button: {
                paddingVertical: 10
            },
        }
    }
)

export default Button;
