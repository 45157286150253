// utils/PercentageUtils.js

const percentageString = (val: string | number = 0, withoutDecimals?: boolean) => {
    let mVal = formatPercentage(val);
    if (withoutDecimals && mVal.includes(',')) {
        mVal = mVal.substring(0, mVal.indexOf(','));
    }
    return `${mVal}%`;
};

const formatPercentage = (val: string | number) => {
    let mVal = unformatPercentage(val)
        .toFixed(2)
        .replace('.', ',')
        .replace('-', '');
    let idx = mVal.search(',');
    if (idx < 0) idx = mVal.length;
    for (let i = idx - 3; i > 0; i -= 3) {
        mVal = mVal.substring(0, i) + '.' + mVal.substring(i);
    }
    return Number(val) < 0 ? `-${mVal}` : mVal;
};

const unformatPercentage = (val: string | number) => {
    if (typeof val === 'string') {
        // Remove percentage symbol if present
        val = val.replace('%', '');
        // Handle comma as decimal separator
        if (val.includes(',')) {
            val = val.replace(/\./g, '').replace(',', '.');
        }
    }
    return Number(val);
};

export default {
    percentageString,
    formatPercentage,
    unformatPercentage,
};
