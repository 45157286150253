import {
    IconUserFilled,
    IconLayoutDashboard,
    IconHome,
    IconHomeShield,
    IconClipboardData,
    IconChartPie4,
    IconCash,
    IconOld,
    IconChecklist,
    IconMessage,
    IconMailPin,
    IconFidgetSpinner,
    IconChevronLeft,
    IconAlertOctagon,
    IconArrowRight,
    IconSeeding,
    IconPigMoney,
    IconTrash,
    IconEye,
    IconEyeOff,
    IconCheck,
    IconFileDescription,
    IconFileUpload,
    IconFileExport,
    IconFileSearch,
    IconBriefcase2,
    IconDots,
    IconMoneybag,
    IconHomeStats,
    IconX,
    IconCalendarMonth,
    IconDisabled,
    IconBriefcaseOff,
    IconGrave2,
    IconHeartOff,
    IconHourglassEmpty,
    IconHourglassLow,
    IconInfoCircle,
    IconExclamationCircle,
} from 'icons/tabler';

export const Icons = {
    IconUserFilled,
    IconLayoutDashboard,
    IconHome,
    IconHomeShield,
    IconClipboardData,
    IconChartPie4,
    IconCash,
    IconOld,
    IconChecklist,
    IconMessage,
    IconMailPin,
    IconFidgetSpinner,
    IconChevronLeft,
    IconAlertOctagon,
    IconArrowRight,
    IconSeeding,
    IconPigMoney,
    IconTrash,
    IconEye,
    IconEyeOff,
    IconCheck,
    IconFileUpload,
    IconFileExport,
    IconFileSearch,
    IconBriefcase2,
    IconDots,
    IconMoneybag,
    IconHomeStats,
    IconClose: IconX,
    IconCalendar: IconCalendarMonth,
    IconDisabled,
    IconBriefcaseOff,
    IconGrave2,
    IconHeartOff,
    IconHourglassEmpty,
    IconHourglassLow,
    IconInfoCircle,
    IconExclamationCircle,
    IconFileDescription,
}
    