import { Button, MortgageProgress, MortgageProgressCircle, TText } from "components";
import { BrondataCard, Card, ScenariosCard, TasksAndMessagesCard } from "components/Card";
import PageContainer from "components/PageContainer";
import PolicyPie from "components/PolicyPie";
import { useStyle } from "hooks";
import { ScrollView, View } from "react-native";
import { colors } from "styles";


export default () => {

    const styles = useStyle();

    return (
        <PageContainer>
            
            <View style={[styles.flexRowDynamic, styles.flexWrap, styles.gap30, {minHeight: 320, maxWidth: '100%'}]}>
                
                <BrondataCard/>

                <Card style={[styles.flexStretch, styles.flexRowDynamic, {maxWidth: '100%'}]}>
                    <View style={{flex: 0.5, height: '100%', alignItems: "center"}}>
                        <MortgageProgressCircle/>
                    </View>
                    <View style={{flex: 0.5, height: '100%', alignItems: "center"}}>
                        <PolicyPie opklik={true}/>
                    </View>                    
                </Card>
            </View>
                
            <ScenariosCard />

            {/* <Card style={[styles.flexRow, styles.flexWrap, styles.gap10]}>
                <View style={{gap: 4}}>
                    <Button
                        label='primary'
                        type="primary"
                    />
                    <Button
                        label='primary light'
                        type="primary"
                        light
                    />
                    <Button
                        label='primary text'
                        type="primary"
                        text
                    />
                    <Button
                        label='primary disabled'
                        type="primary"
                        disabled
                    />
                    <Button
                        label='primary disabled light'
                        type="primary"
                        light
                        disabled
                    />
                </View>
                <View style={{gap: 4}}>
                    <Button
                        label='secondary'
                        type="secondary"
                    />
                    <Button
                        label='secondary light'
                        type="secondary"
                        light
                    />
                    <Button
                        label='secondary text'
                        type="secondary"
                        text
                    />
                    <Button
                        label='secondary disabled'
                        type="secondary"
                        disabled
                    />
                    <Button
                        label='secondary disabled light'
                        type="secondary"
                        light
                        disabled
                    />
                </View>

                <View style={{gap: 4}}>
                    <Button
                        label='error'
                        type="error"
                    />
                    <Button
                        label='error light'
                        type="error"
                        light
                    />
                    <Button
                        label='error text'
                        type="error"
                        text
                    />
                    <Button
                        label='error disabled'
                        type="error"
                        disabled
                    />
                    <Button
                        label='error disabled light'
                        type="error"
                        light
                        disabled
                    />                    
                </View>
            </Card> */}

            <TasksAndMessagesCard style={[{minHeight: 320}]}/>

        </PageContainer>
    );
}
